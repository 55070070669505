import PropTypes from 'prop-types';
import React from 'react';
import {emptyFunction} from '../../utils/Utils';
import ModalDialog from './ModalDialog';


/**
 * Confirmation dialog that shows the user a message and allows for confirmation or cancel.
 */
export default function ConfirmDialog(props) {
   const {
      title, titleKey, titleVariant='h5', message, messageKey, messageVariant = 'h6', open, onClose, onConfirm, confirmLabel, confirmKey, messageValues,
      confirmColor, children
   } = props;

   return (
     <ModalDialog open={open} title={title} titleKey={titleKey} titleVariant={titleVariant} message={message} messageKey={messageKey} messageVariant={messageVariant} submitColor={confirmColor}
                  onClose={onClose} onSubmit={onConfirm} submitKey={confirmKey} submitLabel={confirmLabel} messageValues={messageValues}>
        {children}
     </ModalDialog>
   );
}

ConfirmDialog.propTypes = {
   message: PropTypes.string,       // Message to be displayed to the user. Use either message or messageKey but not
                                    //    both.
   messageKey: PropTypes.string,    // Message key of the message to be displayed to the user.
   messageVariant: PropTypes.string,// The variant for the message typography.
   onConfirm: PropTypes.func,       // Called when the user confirms the message.
   onCancel: PropTypes.func,        // Called when the user cancels.
   open: PropTypes.bool,            // Indicates if the dialog should be open or not.
   title: PropTypes.string,         // Title for the confirmation dialog.
   titleKey: PropTypes.string,      // Localization key for the Title for the confirmation dialog.
   confirmLabel: PropTypes.string,  // Label for the confirm button.
   confirmKey: PropTypes.string,    // Localization key for the confirm button label.
   messageValues: PropTypes.object,        // Localization values for the message.
}

ConfirmDialog.defaultProps = {
   open: true,
   onConfirm: emptyFunction,
   onCancel: emptyFunction,
   confirmLabel: 'OK',
   confirmKey: 'ok.button',
}
