import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import defer from 'lodash/defer';
import React, {useState, useEffect, useRef, useContext} from 'react';

import GaugeChart from 'react-gauge-chart';
import AuthContext from '../../components/AuthContext';
import TitleCardInner from '../../components/TitleCardInner';
import Grid from '../../fhg/components/Grid';
import Online from '../../fhg/components/Online';
import SessionContext from '../../fhg/components/security/SessionContext';
import TypographyFHG from '../../fhg/components/Typography';
import useWidthRule from '../../fhg/components/useWidthRule';
import useMessage from '../../fhg/utils/useMessage';
import FieldRecord from './record/FieldRecord';

const WASTE_APPLICATION_RECORD = 'waste application record';
const NO_RECORD = 'no record';

const useStyles = makeStyles(theme => ({
   addButtonStyle: {
      color: theme.palette.text.secondary,
   },
   desktopFrameStyle: {},
   mobileFrameStyle: {},
}), {name: 'FieldClientStyles'});

FieldClient.propTypes = {};

export default function FieldClient({defaultItem: field, onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const ref = useRef();
   const isDesktopWidth = useWidthRule('up', 'sm');
   const {fieldAccess} = useContext(SessionContext);
   const {isAdmin} = useContext(AuthContext);

   const [actionType, setActionType] = useState(NO_RECORD);
   const annualSum = field.annualSum || 0;
   const annualSumFull = field.annualSumFull ? JSON.parse(field.annualSumFull) : {};
   const currentYear = (new Date())?.getFullYear();
   const lastYear = currentYear - 1;
   const previousAnnualSum = annualSumFull?.[lastYear];
   const balanceRate = (field.nmpMaxRate || 0) - annualSum;
   const balanceRatePrevious = (previousAnnualSum?.nmpMaxRate || 0) - (previousAnnualSum?.annualSum || 0);
   const annualSumMessage = useMessage('clientField.sum.label', undefined, {annualSum});
   const previousYearSumMessage = useMessage('clientField.previousYearSum.label', undefined, {previousAnnualSum: previousAnnualSum?.annualSum || 0});

   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }
   }, [ref]);

   const handleClose = () => {
      setActionType(NO_RECORD)
   };

   return (
      <Grid ref={ref} container direction={'column'} wrap={'nowrap'} resizable>
         <Grid item resizable={isDesktopWidth ? false : undefined} fullHeight={isDesktopWidth ? false : undefined}
               className={isDesktopWidth ? classes.desktopFrameStyle : classes.mobileFrameStyle}>
            <TitleCardInner
               titleId={'clientField.title'}
               values={{...field, hasBold: true}}
               onClose={onClose}
            >
               {(actionType === NO_RECORD || isDesktopWidth) && (
                  <Grid name={'field frame'} container direction={'column'} style={{position: 'relative'}}>
                     <Online marginTop={0}>
                        <Grid name={'field frame'} container direction={'column'}>
                           <Grid name={'field acres'} item>
                              <TypographyFHG variant={'subtitle1'} id={'clientField.acres'} hasBold
                                             values={{acreage: field.acreage || ''}}/>
                              <TypographyFHG variant={'subtitle1'}><b>{currentYear}</b></TypographyFHG>
                              <TypographyFHG variant={'subtitle1'} id={'clientField.annualSum.label'} hasBold
                                             values={{annualSum}}/>
                              <TypographyFHG variant={'subtitle1'} id={field.nmpMaxRate ? 'clientField.nmpMaxRate.label': 'clientField.nmpMaxRate.error'} hasBold
                                             values={{nmpMaxRate: field.nmpMaxRate || ''}}/>
                              <TypographyFHG variant={'subtitle1'} id={'clientField.balanceRate.label'} hasBold
                                             values={{balanceRate}}/>
                           </Grid>
                           <Grid item fullWidth>
                              <GaugeChart
                                 key={`gauge ${field.uuid} ${annualSum}`}
                                 id={'gaugeField' + field.uuid}
                                 cornerRadius={2}
                                 arcsLength={[0.75, 0.15, 0.1]}
                                 colors={[
                                    theme.palette.success.main, theme.palette.warning.main,
                                    theme.palette.error.main
                                 ]}
                                 textColor={theme.palette.text.primary}
                                 needleColor={'#d2d2d2'}
                                 needleBaseColor={'#a2a2a2'}
                                 formatTextValue={() => annualSumMessage}
                                 percent={annualSum < field.nmpMaxRate ? annualSum / field.nmpMaxRate : 1}
                              />
                           </Grid>
                           {(previousAnnualSum && previousYearSumMessage) && <>
                                 <Grid name={'previous field acres'} item>
                                    <TypographyFHG variant={'subtitle1'}><b>{lastYear}</b></TypographyFHG>
                                    <TypographyFHG variant={'subtitle1'} id={'clientField.annualSum.label'} hasBold
                                                   values={{annualSum: previousAnnualSum.annualSum}}/>
                                    <TypographyFHG variant={'subtitle1'} id={'clientField.nmpMaxRate.label'} hasBold
                                                   values={{nmpMaxRate: previousAnnualSum.nmpMaxRate || ''}}/>
                                    <TypographyFHG variant={'subtitle1'} id={'clientField.balanceRate.label'} hasBold
                                                   values={{balanceRate:balanceRatePrevious}}/>
                                 </Grid>
                                 <Grid item fullWidth>
                                    <GaugeChart
                                       key={`previous ${field.uuid} ${previousAnnualSum.annualSum}`}
                                       id={'gaugePreviousField' + field.uuid}
                                       cornerRadius={2}
                                       arcsLength={[0.75, 0.15, 0.1]}
                                       colors={[
                                          theme.palette.success.main, theme.palette.warning.main,
                                          theme.palette.error.main
                                       ]}
                                       textColor={theme.palette.text.primary}
                                       needleColor={'#d2d2d2'}
                                       needleBaseColor={'#a2a2a2'}
                                       formatTextValue={() => previousYearSumMessage}
                                       percent={previousAnnualSum.annualSum < previousAnnualSum.nmpMaxRate ?
                                          previousAnnualSum.annualSum / previousAnnualSum.nmpMaxRate : 1}
                                    />
                                 </Grid>
                           </>}
                        </Grid>
                     </Online>
                     <Grid container item name={'field actions'} direction={'row'}>
                        <Button color={'primary'} className={classes.addButtonStyle} size={'small'}
                                onClick={() => setActionType(WASTE_APPLICATION_RECORD)}
                                disabled={!isAdmin && !fieldAccess}>
                           <TypographyFHG variant='button' color={'inherit'}
                                          id={'clientField.wasteApplicationRecord.button'}
                                          style={{textDecoration: 'underline'}}/>
                        </Button>
                     </Grid>
                  </Grid>
               )}
            </TitleCardInner>
         </Grid>
         <Grid item resizable={isDesktopWidth} style={{minHeight: actionType === NO_RECORD ? 0 : 190}}>
            {{
               [NO_RECORD]: undefined,
               [WASTE_APPLICATION_RECORD]: <FieldRecord parentItem={field} onClose={handleClose}/>,
            }[actionType]}
         </Grid>
      </Grid>
   );
}
