import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useIsConnected} from '@wora/detect-network';
import gql from 'graphql-tag';
import defer from 'lodash/defer';
import filter from 'lodash/filter';
import get from 'lodash/get';
import React, {useState, useEffect, useRef, useContext} from 'react';
import {useParams} from 'react-router-dom';
import AuthContext from '../../components/AuthContext';
import TitleCardInner from '../../components/TitleCardInner';
import useQueryOfflineFHG from '../../fhg/components/data/useQueryOfflineFHG';
import Grid from '../../fhg/components/Grid';
import Loading from '../../fhg/components/Loading';
import SessionContext from '../../fhg/components/security/SessionContext';
import TypographyFHG from '../../fhg/components/Typography';
import useWidthRule from '../../fhg/components/useWidthRule';
import {hasElements} from '../../fhg/utils/Utils';
import WellAnnualReport from './record/WellAnnualReport';
import WellRecord from './record/WellRecord';
import {MONTHLY_BREAKDOWN_FRAGMENT} from '../admin/client/FragmentsGL';

import AllotmentGauge from './AllotmentGauge';

const METER_READING_RECORD = 'meter reading record';
const ANNUAL_REPORT = 'annual water report';
const NO_RECORD = 'no record';

const useStyles = makeStyles(
   (theme) => ({
      addButtonStyle: {
         color: theme.palette.text.secondary,
      },
   }),
   {name: 'WellClientStyles'}
);

export const WELL_BY_UUID_QUERY = gql`
   query getWellByUuidForWellEdit($uuid: String!) {
      well: well_ByUuid(wellUuid: $uuid) {
         id
         uuid
         name: commonName
         facilityId
         allotments {
            id
            uuid
            allotment
            fileNumbers
         }
         meters {
            id
            uuid
            allotment {
               allotment
            }
            acreFeetMultiplier
            acreInchesMultiplier
            beginningMeter
            gallonsUnitMultiplier
            isDeleted
         }
         latitude
         longitude
         isDeleted
      }
   }
`;

export const GAUGE_QUERY = gql`
   query getGauges($wellId: Int) {
      wellGauge: gauge_AllByWell(wellId: $wellId) {
         id
         uuid
         errorDetected
         allotments {
            uuid
            gallons
            acreFeet
            allotment
            fileNumbers
         }
         meterGauges: meters {
            id
            uuid
            allotment {
               uuid
               gallons
               acreFeet
               allotment
               fileNumbers
            }
            errorDetected
            monthly {
               id
               ...monthlyBreakdownInfo
            }
         }
         monthly {
            ...monthlyBreakdownInfo
         }
      }
   }
   ${MONTHLY_BREAKDOWN_FRAGMENT}
`;

/**
 * Component to show the general information about a well.
 *
 * Reviewed: 7/9/2020
 *
 * @param defaultItem
 * @param onActionChange
 */
export default function WellClient({defaultItem, onClose, onActionChange}) {
   const classes = useStyles();
   const {uuid} = useParams();
   const ref = useRef();
   const isDesktopWidth = useWidthRule('up', 'sm');
   const {wellAccess} = useContext(SessionContext);
   const {isAdmin} = useContext(AuthContext);
   const isConnected = useIsConnected();

   const {data, loading} = useQueryOfflineFHG(WELL_BY_UUID_QUERY, {variables: {uuid}, skip: !uuid}, 'well.type');

   const wellId = get(defaultItem, 'id');
   const {data: gaugeData} = useQueryOfflineFHG(GAUGE_QUERY, {skip: !wellId, variables: {wellId}}, 'gauge.type');

   const [actionType, setActionType] = useState(NO_RECORD);
   const [well, setWell] = useState({});
   const [wellGauge, setWellGauge] = useState();
   const [meterGauges, setMeterGauges] = useState([]);

   useEffect(() => {
      if (!!data) {
         setWell(data.well);
      }
   }, [data]);

   useEffect(() => {
      if (!!gaugeData) {
         const wellGauge = get(gaugeData, 'wellGauge') || {};
         setWellGauge(wellGauge.allotments ? wellGauge : undefined);
         setMeterGauges(filter(wellGauge.meterGauges || [], (gauge) => get(gauge, 'allotment.allotment', 0) > 0));
      }
   }, [gaugeData]);

   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }
   }, [ref]);

   const handleClose = () => {
      setActionType(NO_RECORD);
   };

   if (loading) {
      return <Loading isLoading={loading} />;
   }

   const handleActionChange = (action) => {
      setActionType(action);
      onActionChange && onActionChange(action);
   };

   return (
      <Grid ref={ref} container direction={'column'} wrap={'nowrap'} resizable>
         <Grid item resizable={isDesktopWidth ? false : undefined} fullHeight={isDesktopWidth ? false : undefined}>
            <TitleCardInner titleId={'clientWell.title'} values={{...defaultItem, hasBold: true}} onClose={onClose}>
               {(actionType === NO_RECORD || isDesktopWidth) && (
                  <Grid name={'well frame'} container direction={'column'} style={{position: 'relative'}}>
                     {/*<Online>*/}
                     {isConnected && (
                        <Grid name={'gauge frame'} container item>
                           {hasElements(wellGauge, 'allotments') &&
                              wellGauge.allotments.map((allotmentGauge, index) => (
                                 <Grid key={'wellGauge' + index} item xs={6} sm={12} md={6}>
                                    <AllotmentGauge allotment={allotmentGauge} />
                                 </Grid>
                              ))}
                           {hasElements(meterGauges) &&
                              meterGauges.map((meterGauge, index) => (
                                 <Grid key={'gauge' + index} item xs={6} sm={12} md={6}>
                                    <AllotmentGauge allotment={get(meterGauge, 'allotment')} />
                                 </Grid>
                              ))}
                        </Grid>
                     )}
                     {/*</Online>*/}
                     <Grid container item name={'well actions'} direction={'row'}>
                        <Button
                           className={classes.addButtonStyle}
                           size={'small'}
                           onClick={() => handleActionChange(METER_READING_RECORD)}
                           disabled={!isAdmin && !wellAccess}
                        >
                           <TypographyFHG
                              variant='button'
                              color={'inherit'}
                              id={'wellRecord.title'}
                              style={{textDecoration: 'underline'}}
                           />
                        </Button>
                        <Button
                           className={classes.addButtonStyle}
                           size={'small'}
                           onClick={() => handleActionChange(ANNUAL_REPORT)}
                           disabled={!isAdmin && !wellAccess}
                        >
                           <TypographyFHG
                              variant='button'
                              color={'inherit'}
                              id={'wellRecord.annualTitle'}
                              style={{textDecoration: 'underline'}}
                           />
                        </Button>
                     </Grid>
                  </Grid>
               )}
            </TitleCardInner>
         </Grid>
         <Grid item resizable={isDesktopWidth} style={{minHeight: actionType === NO_RECORD ? 0 : 190}}>
            {
               {
                  [NO_RECORD]: undefined,
                  [METER_READING_RECORD]: <WellRecord parentItem={well} onClose={handleClose} />,
                  [ANNUAL_REPORT]: (
                     <WellAnnualReport
                        parentItem={well}
                        meterGauges={gaugeData?.wellGauge?.meterGauges}
                        onClose={handleClose}
                     />
                  ),
               }[actionType]
            }
         </Grid>
      </Grid>
   );
}
