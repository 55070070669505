import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import React, {useState, useRef, useEffect} from 'react';
import TextFieldFHG from '../../components/TextField';
import useEditData from './edit/useEditData';
import useKeyDown from './useKeyDown';

const useStyles = makeStyles(theme => ({
   searchStyle: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
   },
   secondaryColor: {
      color: theme.palette.secondary.dark,
   },
}), {name: 'SearchFieldStyles'});

export default function SearchField({name, value, onChange, ...textFieldProps}) {
   const classes = useStyles();

   const [editValues, handleChange, {defaultValues, setDefaultValues, setEditValues,  resetValues}] = useEditData({[name]: value});

   const [showClose, setShowClose] = useState(false);

   useEffect(() => {
      if (value !== editValues[name]) {
         setDefaultValues({[name]: value});
         setEditValues({})
      }
   }, [value]);

   /**
    * When the search is canceled, clear the search field.
    */
   const handleCancel = () => {
      resetValues();
      onChange && onChange({[name]: undefined});
   };

   const [handleKeyDown] = useKeyDown(handleCancel);

   const handleChangeDebounced = useRef(debounce(onChange, 750)).current;

   const handleSearchChange = (event) => {
      const newValue = handleChange(event);
      handleChangeDebounced(newValue);
   };

   return (
      <TextFieldFHG
         name={name}
         autoFocus
         value={editValues[name]}
         onChange={handleSearchChange}
         className={classes.searchStyle}
         placeholder={'Search'}
         defaultValue={defaultValues[name]}
         onKeyDown={handleKeyDown}
         InputProps={{
            'aria-label': 'Search',
            onFocus: () => setShowClose(true),
            onBlur: () => setShowClose(false),
            endAdornment: (
               <InputAdornment position='end'>
                  {showClose ?
                     <CloseIcon color={'primary'} onMouseDown={handleCancel}/> :
                     <Search color={'primary'}/>
                  }
               </InputAdornment>
            ),
         }}
         {...textFieldProps}
      />
   );
}
