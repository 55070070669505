import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {useState, useContext, useEffect} from 'react';
import TextFieldFHG from '../../../components/TextField';
import {COUNTY_PATH, MIN_ZOOM_DEFAULT, MAX_ZOOM_DEFAULT, ZOOM_DEFAULT} from '../../../Constants';
import {fetchData} from '../../utils/Utils';
import useEditData from '../edit/useEditData';
import Grid from '../Grid';
import StatusContext from '../StatusContext';
import MapFHG from './MapFHG';

export const counties = [
   'Allen',
   'Anderson',
   'Atchison',
   'Barber',
   'Barton',
   'Bourbon',
   'Brown',
   'Butler',
   'Chase',
   'Chautauqua',
   'Cherokee',
   'Cheyenne',
   'Clark',
   'Clay',
   'Cloud',
   'Coffey',
   'Comanche',
   'Cowley',
   'Crawford',
   'Decatur',
   'Dickinson',
   'Doniphan',
   'Douglas',
   'Edwards',
   'Elk',
   'Ellis',
   'Ellsworth',
   'Finney',
   'Ford',
   'Franklin',
   'Geary',
   'Gove',
   'Graham',
   'Grant',
   'Gray',
   'Greeley',
   'Greenwood',
   'Hamilton',
   'Harper',
   'Harvey',
   'Haskell',
   'Hodgeman',
   'Jackson',
   'Jefferson',
   'Jewell',
   'Johnson',
   'Kearny',
   'Kingman',
   'Kiowa',
   'Labette',
   'Lane',
   'Leavenworth',
   'Lincoln',
   'Linn',
   'Logan',
   'Lyon',
   'Marion',
   'Marshall',
   'McPherson',
   'Meade',
   'Miami',
   'Mitchell',
   'Montgomery',
   'Morris',
   'Morton',
   'Nemaha',
   'Neosho',
   'Ness',
   'Norton',
   'Osage',
   'Osborne',
   'Ottawa',
   'Pawnee',
   'Phillips',
   'Pottawatomie',
   'Pratt',
   'Rawlins',
   'Reno',
   'Republic',
   'Rice',
   'Riley',
   'Rooks',
   'Rush',
   'Russell',
   'Saline',
   'Scott',
   'Sedgwick',
   'Seward',
   'Shawnee',
   'Sheridan',
   'Sherman',
   'Smith',
   'Stafford',
   'Stanton',
   'Stevens',
   'Sumner',
   'Thomas',
   'Trego',
   'Wabaunsee',
   'Wallace',
   'Washington',
   'Wichita',
   'Wilson',
   'Woodson',
   'Wyandotte',
];

const useStyles = makeStyles({
   countySelectStyle: {
      maxWidth: 300,
   },
}, {name: 'countySelectionMapStyles'});

/**
 * The ErrorProvider component.
 *
 * Reviewed:
 */
export default function CountySelectionMapComponent({name, marker, center, defaultZoom = ZOOM_DEFAULT, zoom, showCountySelection = true, onClick, onChange, onPopupClose, allowEdit, geoJson, onGeoChange, onMarkerClick, bounds}) {
   const classes = useStyles();
   const {setError} = useContext(StatusContext);

   const [editValues, handleEditChange, {setEditValues}] = useEditData({});
   const [newMarker, setNewMarker] = useState();

   useEffect(() => {
       if (editValues.latitude && editValues.longitude) {
          setNewMarker({latitude: editValues.latitude, longitude: editValues.longitude});
       }
   }, [editValues]);

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param reason The reason of the value change if Autocomplete
    */
   const handleChange = async (event, value, reason) => {

      if (value && reason === 'select-option') {
         try {
            const data = await fetchData(COUNTY_PATH.format({county: value}), 'GET');
            setEditValues({
               ...editValues,
               county: value,
               center: {lat: data[0].lat, lng: data[0].lon},
               bounds: [
                  [data[0].boundingbox[0], data[0].boundingbox[2]], [data[0].boundingbox[1], data[0].boundingbox[3]]
               ]
            });
         } catch (error) {
            setError('county.error', undefined, error);
         }
      }
   };

   return (
      <Grid name='CountyMapComponent root' container spacing={2} direction={'column'} fullHeight fullWidth>
         <Grid item resizable>
            <MapFHG key={'fhgMap' + name} name={name} markers={marker || newMarker} defaultZoom={defaultZoom} zoom={zoom}
                    minZoom={MIN_ZOOM_DEFAULT} maxZoom={MAX_ZOOM_DEFAULT}
                    bounds={editValues.bounds || bounds} center={editValues.center || center} onClick={onClick}
                    allowEdit={allowEdit} onMarkerClick={onMarkerClick}
                    onChange={onChange} onPopupClose={onPopupClose} geoJson={geoJson} onGeoChange={onGeoChange}/>
         </Grid>
         {showCountySelection && (
            <Grid item resizable={false}>
               <Autocomplete
                  name={'county'}
                  autoSelect
                  autoComplete
                  autoHighlight
                  options={counties}
                  className={classes.countySelectStyle}
                  value={editValues.county}
                  onChange={handleChange}
                  isMulti={false}
                  disableClearable
                  fullWidth
                  renderInput={params => (
                     <TextFieldFHG name={'county'}{...params} labelKey={'county.label'}/>
                  )}
               />
               {localStorage.showLatLng && (
                  <Grid item fullWidth>
                     <Grid item xs={6}>
                        <TextFieldFHG
                           name={'latitude'}
                           label={'Latitude'}
                           value={editValues.latitude}
                           onChange={handleEditChange}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <TextFieldFHG
                           name={'longitude'}
                           label={'longitude'}
                           value={editValues.longitude}
                           onChange={handleEditChange}
                           fullWidth
                        />
                     </Grid>
                  </Grid>
               )}
            </Grid>
         )}
      </Grid>
   );
}
