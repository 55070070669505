import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import ExpandMore from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import filter from 'lodash/filter';
import defer from 'lodash/defer';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useEffect, useState, useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import TitleCardInner from '../../../components/TitleCardInner';
import {KANSAS_STATE_ID, DATE_DB_FORMAT} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useLazyQueryOfflineFHG from '../../../fhg/components/data/useLazyQueryOfflineFHG';
import useMutationFHG, {DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import DateRangePicker from '../../../fhg/components/edit/DateRangePicker';
import Grid from '../../../fhg/components/Grid';
import TypographyFHG from '../../../fhg/components/Typography';
import {cacheDelete} from '../../../fhg/utils/DataUtil';
import useMessage from '../../../fhg/utils/useMessage';
import {removeOne, areDatesValid, getDays} from '../../../fhg/utils/Utils';
import {WASTE_EXPORT_FRAGMENT} from '../../admin/client/FragmentsGL';
import WasteExportEdit from './WasteExportEdit';

const useStyles = makeStyles(theme => ({
   buttonStyle: {
      marginBottom: '0.35em',
   },
   expansionStyle: {
      border: '1px solid lightgrey',
      width: '100%',
   },
   contentStyle: {
      margin: theme.spacing(.75, 0),
      '&.Mui-expanded': {
         margin: `${theme.spacing(1, 0)}!important`,
      }
   },
   rootStyle: {
      '&.Mui-expanded': {
         minHeight: 52,
      }
   },
}), {name: 'WasteExportStyles'});

// Query for all the waste exports for the facility.
export const WASTE_EXPORT_BY_RANGE_QUERY = gql`
   query getWasteExportByRange($facilityId: [Int], $beginDate: String, $endDate: String)
   {
      wasteExport:wasteExport_AllWhere(wasteExportSearch: {facilityId: $facilityId, beginDate: $beginDate, endDate: $endDate}) {
         ...wasteExportOnWasteExport
      }
   }
   ${WASTE_EXPORT_FRAGMENT}
`;

// Delete the waste export on the server.
const WASTE_EXPORT_DELETE = {
   mutation: gql`
      mutation WasteExportDelete($id: Int!) {
         wasteExport_Delete(wasteExportId: $id)
      }
   `,
   typeKey: 'wasteExport.type',
   actionKey: DELETE_ACTION,
};

WasteExport.propTypes = {
   facility: PropTypes.object,   //Facility related to the waste export.
   onClose: PropTypes.func,      // Callback when the waste export is closed.
};

/**
 * Component to record a waste export. Selecting a date will display the existing waste export for that day.
 *
 * Reviewed: 7/15/2020
 *
 * @param facility Facility related to the waste export.
 * @param onClose Callback when the waste export is closed.
 * @return {*}
 * @constructor
 */
export default function WasteExport({facility, onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const wasteExportType = useMessage('wasteExport.type');

   const [loadWasteExportRange, {data: wasteExportRangeData}] = useLazyQueryOfflineFHG(WASTE_EXPORT_BY_RANGE_QUERY, undefined,
      'wasteExport.type');

   const [wasteExportDelete] = useMutationFHG(WASTE_EXPORT_DELETE);

   const [wasteExportList, setWasteExportList] = useState([]);

   const [entryDays, setEntryDays] = useState([]);
   const [monthRange, setMonthRange] = useState(
      {beginDate: moment().startOf('month'), endDate: moment().endOf('month')});
   const [daySelected, setDaySelected] = useState([moment(), moment()]);
   const [isPickerOpen, setIsPickerOpen] = useState(false);
   const [expanded, setExpanded] = useState();

   useEffect(() => {
      defer(() => {
         const titleElement = document.getElementById('wasteExport.add.button');
         if (titleElement) {
            titleElement.scrollIntoView(false);
         }
      });
   }, []);

   useEffect(() => {
      if (facility) {
         loadWasteExportRange({
            variables: {
               facilityId: facility && facility.id,
               beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
               endDate: monthRange.endDate.format(DATE_DB_FORMAT),
            }
         });
      }
      // eslint-disable-next-line
   }, [facility]);

   const createNew = () => {
      return {
         uuid: uuid(),
         startDate: daySelected[0],
         stopDate: daySelected[1],
         facilityId: get(facility, 'id'),
         stateId: KANSAS_STATE_ID,
         analysisProvided: true
      }
   };

   const setInitialValues = useCallback((isSetEntryDays, dates = daySelected) => {
      const wasteExport = get(wasteExportRangeData, 'wasteExport') || [];

      if (wasteExport.length > 0) {
         if (isSetEntryDays) {
            setEntryDays(getDays(wasteExport, {endField: 'stopDate'}));
         }
         const filteredDays = filter(wasteExport,
            item => moment(item.startDate, DATE_DB_FORMAT).isSame(dates[0], 'day') &&
               moment(item.stopDate, DATE_DB_FORMAT).isSame(dates[1], 'day')) || [];

         if (filteredDays.length > 0) {
            setWasteExportList(filteredDays);
         } else {
            setWasteExportList([
               createNew()
            ]);
         }
      } else {
         if (isSetEntryDays) {
            setEntryDays([]);
         }
         setWasteExportList([
            createNew()
         ]);
      }
   }, [facility, wasteExportRangeData]);

   useEffect(() => {
      if (wasteExportRangeData) {
         setInitialValues(true, daySelected);
      }
      //Don't change on daySelected. Will reset the entryDays needlessly.
      // eslint-disable-next-line
   }, [wasteExportRangeData, setInitialValues]);

   const getRefetchQueries = () => {
      const beginDate = moment(monthRange.beginDate);
      const endDate = moment(monthRange.endDate);
      const variables = {
         facilityId: facility && facility.id,
         beginDate: beginDate.format(DATE_DB_FORMAT),
         endDate: endDate.format(DATE_DB_FORMAT),
      };
      const queries =  [{query: WASTE_EXPORT_BY_RANGE_QUERY, variables: {...variables}}];
      if (!beginDate.isSame(endDate, 'month')) {
         variables.endDate = moment(beginDate).endOf('month').format(DATE_DB_FORMAT);
         queries.push({query: WASTE_EXPORT_BY_RANGE_QUERY, variables: {...variables}});

         variables.beginDate = moment(endDate).startOf('month').format(DATE_DB_FORMAT);
         variables.endDate = moment(endDate).endOf('month').format(DATE_DB_FORMAT);
         queries.push({query: WASTE_EXPORT_BY_RANGE_QUERY, variables: {...variables}});
      }
      return queries;
   };

   const handleDateChange = async (event, dates) => {

      if (areDatesValid(dates)) {
         setDaySelected(dates);
         if (!dates[0].isBetween(monthRange.beginDate, monthRange.endDate, 'day', '[]')) {
            await handleMonthChange(dates);
         } else {
            setInitialValues(false, dates);
         }
      }
   };

   const handleMonthChange = (dates) => {
      let beginDate, endDate;

      if (isArray(dates)) {
         beginDate = moment(dates[0]).startOf('month');
         if (dates.length > 1) {
            endDate = moment(dates.length > 1 ? dates[1] : dates[0]).endOf('month');
         }
      } else {
         beginDate = moment(dates).startOf('month');
         endDate = moment(dates).endOf('month');
      }
      setMonthRange({beginDate, endDate});
      return loadWasteExportRange({
         variables: {
            facilityId: facility && facility.id,
            beginDate: beginDate.format(DATE_DB_FORMAT),
            endDate: endDate.format(DATE_DB_FORMAT),
         }
      });
   };

   const handleDelete = (wasteExport, index) => async () => {
      if (wasteExport && wasteExport.id) {
         await wasteExportDelete({
            variables: {id: wasteExport.id},
            optimisticResponse: {wasteExport_Delete: 1},
            update: cacheDelete(getRefetchQueries(), wasteExport.uuid, 'wasteExport'),
         });
      } else {
         const updatedList = removeOne([...wasteExportList], index);
         setWasteExportList(updatedList);
      }
   };

   const handleAdd = (event) => {
      event.stopPropagation();
      event.preventDefault();

      setExpanded(wasteExportList.length);

      setWasteExportList([
         ...wasteExportList,
         createNew(),
      ]);
   };

   const handleCloseEdit = () => {
      if (wasteExportList && wasteExportList.length <= 1) {
         onClose && onClose();
      } else {
         setExpanded(undefined);
      }
   };

   const handleExpand = index => (event) => {
      if (!event.isDefaultPrevented()) {
         setExpanded(expanded === index ? undefined : index);
      }
   };

   return (
      <TitleCardInner
         titleId={'clientFacility.wasteExport.button'}
         variant={'subtitle1'}
         onClose={isPickerOpen ? undefined : onClose}
         headerAction={(
            <Grid container>
               {((wasteExportList.length > 1) || (wasteExportList.length === 1 && wasteExportList[0].id)) && (
                  <Button className={classes.buttonStyle} onClick={handleAdd}
                          style={{color: theme.palette.primary.light}}>
                     <TypographyFHG variant='button' color='inherit' style={{textDecoration: 'underline'}}
                                    id={'wasteExport.add.button'}/>
                  </Button>
               )}
               <ConfirmButton
                  onConfirm={handleDelete(get(wasteExportList, '[0]'), 0)}
                  style={{display: wasteExportList.length !== 1 || !wasteExportList[0].id ? 'none' : undefined}}
                  titleKey={'delete.confirm.title'}
                  messageKey={'delete.confirm.message'}
                  titleValues={{type: wasteExportType}}
                  values={{type: wasteExportType}}
                  color='secondary'
                  buttonLabelKey={'delete.button'}
                  disableRipple
                  className={classes.buttonStyle}
                  buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
               />
            </Grid>
         )}
      >
         <Grid container direction={'row'} alignItems={'center'} overflow={'visible'}>
            <Grid item xs={12}>
               <DateRangePicker
                  name={'date'}
                  open={isPickerOpen}
                  labelKey={'field.date.label'}
                  value={daySelected}
                  autoOk={false}
                  disableFuture={true}
                  onChange={handleDateChange}
                  onMonthChange={handleMonthChange}
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  entryDays={entryDays}
                  autoFocus
                  required
               />
            </Grid>
            {wasteExportList.length > 1 ? wasteExportList.map((wasteExport, index) => (
               <Accordion key={'expansionPanel' + (wasteExport && wasteExport.uuid) || index} name={index}
                          classes={{root: classes.expansionStyle}} expanded={expanded === index}
                          onChange={handleExpand(index)}>
                  <AccordionSummary
                     expandIcon={<ExpandMore/>}
                     aria-controls='panel1bh-content'
                     id='panel1bh-header'
                     classes={{root: classes.rootStyle, content: classes.contentStyle}}
                  >
                     <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
                        <Grid item>
                           <TypographyFHG variant={'body1'} color={'primary'} style={{fontWeight: 500}}>
                              {`${wasteExport.recipientName || ''} ${wasteExport.amount || ''}`}
                           </TypographyFHG>
                        </Grid>
                        <Grid item>
                           <ConfirmButton
                              onConfirm={handleDelete(wasteExport, index)}
                              titleKey={'delete.confirm.title'}
                              messageKey={'delete.confirm.message'}
                              titleValues={{type: wasteExportType}}
                              values={{type: wasteExportType}}
                              color='secondary'
                              buttonLabelKey={'delete.button'}
                              disableRipple
                              className={classes.buttonStyle}
                              buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
                           />
                        </Grid>
                     </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                     <WasteExportEdit facility={facility} wasteExport={wasteExport} monthRange={monthRange}
                                      daySelected={daySelected} onClose={handleCloseEdit}
                                      onUpdateCache={getRefetchQueries}/>
                  </AccordionDetails>
               </Accordion>
            )) : (
               <WasteExportEdit wasteExport={get(wasteExportList,
                  '[0]')} daySelected={daySelected} onClose={handleCloseEdit} onUpdateCache={getRefetchQueries}/>
            )}
         </Grid>
      </TitleCardInner>
   );
}
