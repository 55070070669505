import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import sortBy from 'lodash/sortBy';
import set from 'lodash/set';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useState, useEffect, useRef} from 'react';
import {v4 as uuid} from 'uuid';
import AutocompleteFHG from '../../../components/AutocompleteFHG';
import CheckboxFHG from '../../../components/CheckboxFHG';
import ConvertUnits from '../../../components/ConvertUnits';
import TextFieldFHG from '../../../components/TextField';
import {DATE_DB_FORMAT} from '../../../Constants';
import useMutationFHG, {CREATE_UPDATE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import Form from '../../../fhg/components/edit/Form';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import Typography from '../../../fhg/components/Typography';
import {cacheUpdate} from '../../../fhg/utils/DataUtil';
import {renderOptions, hasValue,} from '../../../fhg/utils/Utils';
import {Calculate} from '../../../Icons';
import {WASTE_STORAGE_OPTIONS_QUERY} from '../../../data/QueriesGL';

const useStyles = makeStyles({
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
      position: 'relative',
   },
   checkboxStyle: {
      marginTop: 0,
   }
}, {name: 'WasteApplicationRecordStyles'});

const defaultWasteApplication = {
   id: 1,
   isDeleted: false,
   note: '',
   wasteSource: null,
   wasteStorage: null,
   isAfterHarvest: false,
}

// Default properties needed for the waste application record.
const WASTE_APPLICATION_FRAGMENT = gql`
   fragment wasteApplicationRecordEditInfo on WasteApplication {
      id
      amount
      applicationMethodId
      startDate
      endDate
      fieldCoverId
      fieldId
      isDeleted
      note
      soilConditionId
      wasteSourceId
      wasteSource {
         id
         uuid
      }
      wasteStorageId
      wasteStorage {
         id
         uuid
      }
      wasteTypeId
      uuid
      isAfterHarvest
   }
`;

// Create or Update the waste application with the given properties.
const WASTE_APPLICATION_CREATE_UPDATE = {
   mutation: gql`
      mutation WasteApplicationCreateUpdate(
         $amount: Float
         $applicationMethodId: Int
         $startDate: String
         $endDate: String
         $fieldCoverId: Int
         $fieldId: Int
         $note: String
         $soilConditionId: Int
         $uuid: String!
         $wasteSourceId: Int
         $wasteStorageId: Int
         $wasteTypeId: Int
         $isAfterHarvest: Boolean
      ) {
         wasteApplication: wasteApplication_CreateUpdate(wasteApplication: {
            amount: $amount
            applicationMethodId: $applicationMethodId
            startDate: $startDate
            endDate: $endDate
            fieldCoverId: $fieldCoverId
            fieldId: $fieldId
            note: $note
            soilConditionId: $soilConditionId
            uuid: $uuid
            wasteSourceId: $wasteSourceId
            wasteStorageId: $wasteStorageId
            wasteTypeId: $wasteTypeId
            isAfterHarvest: $isAfterHarvest
         }) {
            ...wasteApplicationRecordEditInfo
         }
      }
      ${WASTE_APPLICATION_FRAGMENT}
   `,
   typeKey: 'wasteApplication.type',
   actionKey: CREATE_UPDATE_ACTION,
};

/**
 * Query to get all the values for the drop down options.
 */
export const FIELD_OPTIONS_QUERY = gql`
   query getFieldOptions {
      fieldCover: fieldCover_All {
         id
         uuid
         name
         isDeleted
      }
      soilConditions: soilCondition_All {
         id
         uuid
         name
         abbreviation
         isDeleted
      }
      applicationMethods:applicationMethod_All {
         id
         uuid
         name
         isDeleted
      }
      wasteSource: wasteSource_All {
         id
         uuid
         name
         isDeleted
      }
      wasteType: wasteType_All {
         id
         uuid
         name
         isDeleted
      }
   }
`;

/**
 * Query to get all the values for the drop down options.
 */
export const FIELD_WASTE_APPLICATION_QUERY = gql`
   query getFieldWasteApplication($fieldId: [Int]) {
      wasteApplication: wasteApplication_AllWhere(limit: 1, sortOrder: [{fieldName:"id", direction: "desc"}], wasteApplicationSearch: {fieldId: $fieldId}) {
         id
         fieldCoverId
         applicationMethodId
         soilConditionId
         wasteSourceId
         wasteStorageId
         wasteTypeId
         isDeleted
      }
   }
`;

FieldRecordEdit.propTypes = {
   field: PropTypes.object.isRequired,             // The field for the waste application.
   daySelected: PropTypes.array.isRequired,        // The array of days selected for the date range.
   getRefetchQueries: PropTypes.func.isRequired,   // The callback to get the refetch queries on submit.
   onClose: PropTypes.func.isRequired,             // The callback for the close event.
};

/**
 * The component to record waste application records for the field.
 *
 * @param field The admin field data.
 * @param wasteApplication The selected waste application.
 * @param daySelected The date range for this field record.
 * @param getRefetchQueries The queries to update cache from server calculations
 * @param getCacheQueries The queries to update cache that can be done on the client.
 * @param onClose Callback when the panel is closed.
 */
export default function FieldRecordEdit({field, wasteApplication, daySelected, getRefetchQueries, getCacheQueries, onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const {data} = useQueryOfflineFHG(FIELD_OPTIONS_QUERY, {skip: !field}, 'field.type');
   const {data:wasteStorageData} = useQueryOfflineFHG(WASTE_STORAGE_OPTIONS_QUERY,
      {variables: {facilityId: field?.facilityId, }, skip: !field?.facilityId}, 'wasteStorage.type');

   const {data:wasteApplicationData} = useQueryOfflineFHG(FIELD_WASTE_APPLICATION_QUERY,
      {variables: {fieldId: field?.id}, skip: !field}, 'wasteStorage.type');

   const [wasteApplicationCreateUpdate] = useMutationFHG(WASTE_APPLICATION_CREATE_UPDATE);

   const initialDefaultValues = useRef({
      uuid: uuid(), fieldId: field?.id, fieldCoverId: field?.fieldCoverId
   }).current;
   const [
      editValues, handleChange, {
         setEditValues,
         isChanged,
         defaultValues,
         setDefaultValues,
         setIsChanged,
      }
   ] = useEditData(
      initialDefaultValues, [
         'id', 'uuid', 'fieldId', 'fieldCoverId', 'applicationMethodId', 'wasteTypeId', 'soilConditionId',
         'wasteSourceUuid'
      ]);

   const [options, setOptions] = useState({});
   const [wasteSourceComboList, setWasteSourceComboList] = useState([]);

   const [showConvert, setShowConvert] = useState(false);
   const [showNote, setShowNote] = useState(false);
   const [isSaving, setIsSaving] = useState(false);

   useEffect(() => {
      let initialValuesChanged = false;
      let sortedList = [];

      if (!!data) {
         setOptions(data);
      }
      if (!!data && !!wasteStorageData) {
         sortedList = sortBy(wasteStorageData.wasteStorageList, ['name']);
         setWasteSourceComboList([...data.wasteSource, ...sortedList]);
      }
      if (!!data  && !!wasteApplicationData) {
         if (!hasValue(wasteApplication)) {
            const fieldCoverId = get(wasteApplicationData, 'wasteApplication[0].fieldCoverId');
            if (fieldCoverId && initialDefaultValues.fieldCoverId !== fieldCoverId) {
               initialDefaultValues.fieldCoverId = fieldCoverId;
               initialValuesChanged = true;
            }
            const applicationMethodId = get(wasteApplicationData, 'wasteApplication[0].applicationMethodId');
            if (applicationMethodId && initialDefaultValues.applicationMethodId !== applicationMethodId) {
               initialDefaultValues.applicationMethodId = applicationMethodId;
               initialValuesChanged = true;
            }
            const soilConditionId = get(wasteApplicationData, 'wasteApplication[0].soilConditionId');
            if (soilConditionId && initialDefaultValues.soilConditionId !== soilConditionId) {
               initialDefaultValues.soilConditionId = soilConditionId;
               initialValuesChanged = true;
            }
            const wasteTypeId = get(wasteApplicationData, 'wasteApplication[0].wasteTypeId');
            if (wasteTypeId && initialDefaultValues.wasteTypeId !== wasteTypeId) {
               initialDefaultValues.wasteTypeId = wasteTypeId;
               initialValuesChanged = true;
            }
            const wasteSourceId = get(wasteApplicationData, 'wasteApplication[0].wasteSourceId');
            const wasteStorageId = get(wasteApplicationData, 'wasteApplication[0].wasteStorageId');
            if ((wasteSourceId || wasteStorageId) &&
               (initialDefaultValues.wasteSourceId !== wasteSourceId && initialDefaultValues.wasteStorageId !==
                  wasteStorageId)) {
               if (wasteSourceId) {
                  initialDefaultValues.wasteSourceUuid = get(find(data.wasteSource, {id: wasteSourceId}), 'uuid');
               } else if (wasteStorageId) {
                  initialDefaultValues.wasteSourceUuid = get(find(sortedList, {id: wasteStorageId}), 'uuid');
               }
               initialValuesChanged = true;
            }

            if (initialValuesChanged) {
               // Don't replace default values from actual data, just if there isn't anything selected.
               setDefaultValues({...initialDefaultValues});
            }
         } else {
            const wasteSourceUuid = wasteApplication.wasteSourceId ? wasteApplication?.wasteSource?.uuid :
               (wasteApplication.wasteStorageId ? wasteApplication?.wasteStorage?.uuid : undefined);
            setDefaultValues({...wasteApplication, wasteSourceUuid});
            setShowNote(!!wasteApplication.note);
         }
      }
   }, [data, wasteStorageData, wasteApplicationData, initialDefaultValues, setDefaultValues, wasteApplication]);

   /**
    * Submit the waste application.
    * @return {Promise<void>}
    */
   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);
            const useDefaultWasteApplication = {...defaultWasteApplication};
            const useStorage = {};
            set(useDefaultWasteApplication, 'wasteSource.uuid', uuid());
            set(useDefaultWasteApplication, 'wasteStorage.uuid', uuid());

            if (editValues.wasteSourceUuid) {
               const wasteSource = find(wasteSourceComboList, {uuid: editValues.wasteSourceUuid});
               if (wasteSource) {
                  if (wasteSource.__typename === 'WasteSource') {
                     editValues.wasteSourceId = wasteSource.id;
                     set(useStorage, 'wasteSource', wasteSource);
                     set(useStorage, 'wasteSourceId', wasteSource.id);
                     set(useStorage, 'wasteStorage', null);
                     set(useStorage, 'wasteStorageId', null);
                     editValues.wasteStorageId = null;
                  } else {
                     editValues.wasteSourceId = null;
                     editValues.wasteStorageId = wasteSource.id;
                     set(useStorage, 'wasteStorage', wasteSource);
                     set(useStorage, 'wasteStorageId', wasteSource.id);
                     set(useStorage, 'wasteSource', null);
                     set(useStorage, 'wasteSourceId', null);
                  }
               }
            }
            const variables = {
            ...editValues,
                  startDate: daySelected[0].format(DATE_DB_FORMAT),
                  endDate: (daySelected[1] || daySelected[0]).format(DATE_DB_FORMAT)
            };
            await wasteApplicationCreateUpdate({
               variables,
               optimisticResponse: {
                  __typename: 'Mutation',
                  refetchQueries: getRefetchQueries,
                  wasteApplication: {
                     __typename: 'WasteApplication',
                     ...defaultWasteApplication,
                     ...defaultValues,
                     ...variables,
                     ...useStorage,
                  }
               },
               refetchQueries: getRefetchQueries,
               update: cacheUpdate(getCacheQueries(), editValues?.uuid, 'wasteApplication'),
            });
            setIsChanged(false);
            onClose && onClose();
         } catch (e) {
            console.log(e);
         } finally {
            setIsSaving(false);
         }
      } else {
         onClose && onClose();
      }
   };

   const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      setShowConvert(event.currentTarget);
   };

   const renderWasteStorage = (wasteStorage, input) => {
      const label = typeof wasteStorage === 'string' ? wasteStorage : wasteStorage.name;
      return renderOptions(label, input);
   };

   const handleEnterGallons = (gallons) => {
      setEditValues({...editValues, amount: gallons});
      setIsChanged(true);
      setShowConvert(null);
   };

   return (
      <Form className={classes.formStyle} onSubmit={handleSubmit}>
         {!!showConvert && (
            <ConvertUnits anchorEl={showConvert} defaultValue={editValues.amount || defaultValues.amount}
                          onClose={() => {setShowConvert(undefined)}}
                          onSubmit={handleEnterGallons}/>
         )}
         <Grid item container direction={'row'} spacing={1} alignItems={'center'}
               style={{padding: 0, height: 'fit-content'}}>
            <Grid item xs={6} md={12} lg={6}>
               <AutocompleteFHG
                  key={'soilConditionId' + defaultValues.soilConditionId}
                  name='soilConditionId'
                  labelKey={'wasteApplication.soilCondition.label'}
                  options={options.soilConditions}
                  getOptionLabel={condition => (condition && condition.name ?
                     `${condition.name} (${condition.abbreviation})` : undefined)}
                  defaultValue={defaultValues.soilConditionId}
                  value={editValues.soilConditionId}
                  onChange={handleChange}
                  disableClearable
                  renderOption={(condition, input) => (renderOptions(
                     condition && condition.name ? `${condition.name} (${condition.abbreviation})` : undefined,
                     input))}
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item xs={6} md={12} lg={6}>
               <AutocompleteFHG
                  key={'fieldCoverId' + defaultValues.fieldCoverId}
                  name='fieldCoverId'
                  labelKey='fieldCover.label'
                  options={options.fieldCover}
                  defaultValue={defaultValues.fieldCoverId}
                  value={editValues.fieldCoverId}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item xs={6} md={12} lg={6}>
               <AutocompleteFHG
                  key={'wasteSourceUuid' + defaultValues.wasteSourceUuid}
                  name='wasteSourceUuid'
                  labelKey='wasteSource.label'
                  valueKey='uuid'
                  options={wasteSourceComboList}
                  renderOption={renderWasteStorage}
                  defaultValue={defaultValues.wasteSourceUuid}
                  value={editValues.wasteSourceUuid}
                  onChange={handleChange}
                  disableClearable
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item xs={6} md={12} lg={6}>
               <AutocompleteFHG
                  key={'applicationMethodId' + defaultValues.applicationMethodId}
                  name='applicationMethodId'
                  labelKey='applicationMethod.label'
                  options={options.applicationMethods}
                  defaultValue={defaultValues.applicationMethodId}
                  value={editValues.applicationMethodId}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item xs={6} md={12} lg={6}>
               <AutocompleteFHG
                  key={'wasteTypeId' + defaultValues.wasteTypeId}
                  name={'wasteTypeId'}
                  labelKey={'wasteType.label'}
                  options={options.wasteType}
                  defaultValue={defaultValues.wasteTypeId}
                  value={editValues.wasteTypeId}
                  onChange={handleChange}
                  disableClearable
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={6}>
               <TextFieldFHG
                  name={'amount'}
                  isFormattedNumber
                  labelKey={'wasteAmount.label'}
                  defaultValue={defaultValues.amount}
                  value={editValues.amount}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
                  InputProps={((editValues.wasteTypeId && editValues.wasteTypeId === 2) ||
                     (!editValues.wasteTypeId && defaultValues.wasteTypeId === 2)) ? {
                     'aria-label': 'calculator',
                     endAdornment: (
                        <InputAdornment position='end'>
                           <Tooltip title={'Convert other units to gallons'}>
                              <IconButton onMouseDown={handleClick} size={'small'}>
                                 <Calculate/>
                              </IconButton>
                           </Tooltip>
                        </InputAdornment>
                     )
                  } : undefined}
               />
            </Grid>
            <Grid item xs={6} sm={12} lg={6}>
               <CheckboxFHG
                  style={{marginLeft: theme.spacing(1), marginTop: 0}}
                  classes={{checkboxStyle: classes.checkboxStyle}}
                  name={'isAfterHarvest'}
                  onChange={handleChange}
                  color={'default'}
                  labelKey={'wasteApplication.afterHarvest.label'}
                  value={'isAfterHarvest'}
                  defaultChecked={defaultValues.isAfterHarvest}
                  disabled={isSaving}
                  checked={editValues.isAfterHarvest}
               />
            </Grid>
            <Grid item container direction={'row'} spacing={1} xs={6} sm={12} lg={6}>
               <Grid item fullWidth>
                  {!showNote ? (
                     <Button color='primary' size={'small'} onClick={() => {setShowNote(true)}}>
                        <Typography variant={'inherit'} id={'pond.addNote.button'}/>
                     </Button>
                  ) : (
                     <TextFieldFHG
                        name={'note'}
                        labelKey={'pond.note.label'}
                        autoFocus
                        defaultValue={defaultValues.note}
                        value={editValues.note}
                        onChange={handleChange}
                        rows={2}
                        rowsMax={4}
                        fullWidth
                        multiline
                        disabled={isSaving}
                     />
                  )}
               </Grid>
            </Grid>
            <Grid item
                  style={{
                     marginTop: theme.spacing(1),
                     position: 'sticky',
                     bottom: 0,
                     backgroundColor: theme.palette.background.paper
                  }}
                  fullWidth>
               <Button variant='contained' color='primary' type={'submit'} size={'small'} disabled={isSaving}>
                  <Typography variant={'inherit'} id={'save.label'}/>
               </Button>
            </Grid>
         </Grid>
      </Form>
   );
}
