import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Auth} from 'aws-amplify';
import gql from "graphql-tag";
import get from 'lodash/get';
import React, {useState, useEffect} from 'react';
import useLazyQueryFHG from '../data/useLazyQueryFHG';
import SessionContext from './SessionContext';

const useStyles = makeStyles({
   progressStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
   },
});

// The user with the given user ID and property ID.
const USER_BY_COGNITO_SUB_QUERY = gql`
   query getUsersByCognitoSub($cognitoSub: [String])
   {
      user:user_AllWhere (userSearch:{cognitoSub: $cognitoSub}){
         id
         isAdmin
         managerName
         clientId
         facilityAccess
         fieldAccess
         wasteStorageAccess
         wellAccess
      }
   }
`;

/**
 * The SessionProvider component. Get the user groups and name.
 *
 * Reviewed:
 */
export default function SessionProvider({authData, onUser, children}) {
   const classes = useStyles();
   const [user, setUser] = useState();

   const [loadUser, {data}] = useLazyQueryFHG(USER_BY_COGNITO_SUB_QUERY, undefined, 'users.type');
   const [facilityAccess, setFacilityAccess] = useState(false);
   const [fieldAccess, setFieldAccess] = useState(false);
   const [wasteStorageAccess, setWasteStorageAccess] = useState(false);
   const [wellAccess, setWellAccess] = useState(false);


   /**
    * Get all the user information.
    */
   useEffect(() => {
      if (!!authData) {
         loadUser({variables: {cognitoSub: get(authData, 'attributes.sub')}});
      }
   }, [loadUser, authData]);

   /**
    * When the data returns set the user information.
    */
   useEffect(() => {
      if (!!data) {
         const user = get(data, 'user[0]');
         setUser(user || '');
         if (user) {
            setFacilityAccess(user.facilityAccess);
            setFieldAccess(user.fieldAccess);
            setWasteStorageAccess(user.wasteStorageAccess);
            setWellAccess(user.wellAccess);
            onUser && onUser(user);
         }
      }
   }, [data, onUser]);

   const signOut = () => {
      setUser(undefined);
      onUser?.(undefined);
      Auth.signOut();
   };

   return (
      <SessionContext.Provider value={{user, facilityAccess, fieldAccess, wellAccess, wasteStorageAccess, signOut}}>
         {user ? children : (
            <CircularProgress className={classes.progressStyle}/>
         )}
      </SessionContext.Provider>
   );
}
