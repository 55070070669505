import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useTheme from '@material-ui/core/styles/useTheme';
import Home from '@material-ui/icons/Home';
import Email from '@material-ui/icons/Email';
import Folder from '@material-ui/icons/Folder';
import FolderOpen from '@material-ui/icons/FolderOpen';
import {useIsConnected} from '@wora/detect-network';
import React, {useContext} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import DrawerContext from '../components/DrawerContext';
import {
   CLIENT_PATH, KDHE_REPORT_PATH, CLIENT_DASHBOARD_PATH, MESSAGES_CLIENT_PATH, BULLETIN_PATH, FILES_CLIENT_PATH,
   WATER_USE_REPORT_PATH,
} from '../Constants';
import {CLIENT_BY_ID_QUERY} from '../data/QueriesGL';
import useQueryFHG from '../fhg/components/data/useQueryFHG';
import MailContext from '../fhg/components/MailContext';
import ResponsiveMobileDrawer from '../fhg/components/ResponsiveMobileDrawer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {toNumber} from '../fhg/utils/Utils';
import {IIcon} from '../Icons';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
   badgeStyle: {
      height: 12,
      minWidth: 12,
      borderRadius: 6,
   }
}, {name: 'NavigationDrawerStyles'});

/**
 * Drawer to navigate to reports and other screens.
 */
export default function NavigationDrawer() {
   const classes = useStyles();
   const {clientId} = useParams();
   const theme = useTheme();
   const location = useLocation();
   const {onDrawerClose } = useContext(DrawerContext);
   const {unreadCount} = useContext(MailContext);
   const isConnected = useIsConnected();

   const contrastTextColor = theme.palette.getContrastText(theme.palette.primary.main);

   const {data} = useQueryFHG(CLIENT_BY_ID_QUERY,
      {variables: {clientId: +clientId}, skip: !toNumber(clientId)}, 'client.type');

   if (location.pathname.indexOf(CLIENT_PATH) === 0) {
      return (
         <ResponsiveMobileDrawer backgroundColor={theme.palette.primary.main}>
            <List dense>
               <ListItem button component={Link} to={CLIENT_DASHBOARD_PATH.replace(':clientId', clientId)} selected={location.pathname === CLIENT_DASHBOARD_PATH}
                         onClick={onDrawerClose}>
                  <ListItemIcon style={{minWidth: 36}}>
                     <Home fontSize="small" style={{color: contrastTextColor}}/>
                  </ListItemIcon>
                  <ListItemText primary={'HOME'} primaryTypographyProps={{style: {color: contrastTextColor}}}/>
               </ListItem>
               <ListItem button component={Link} to={KDHE_REPORT_PATH.replace(':clientId', clientId)} selected={location.pathname === KDHE_REPORT_PATH}
                         onClick={onDrawerClose} disabled={!isConnected}>
                  <ListItemIcon style={{minWidth: 36}}>
                     <AssignmentIcon fontSize="small" style={{color: contrastTextColor}}/>
                  </ListItemIcon>
                  <ListItemText primary={'KDHE Operations Report'} primaryTypographyProps={{style: {color: contrastTextColor}}}/>
               </ListItem>
               <ListItem button component={Link} to={WATER_USE_REPORT_PATH.replace(':clientId', clientId)} selected={location.pathname === WATER_USE_REPORT_PATH}
                         onClick={onDrawerClose} disabled={!isConnected}>
                  <ListItemIcon style={{minWidth: 36}}>
                     <AssignmentIcon fontSize="small" style={{color: contrastTextColor}}/>
                  </ListItemIcon>
                  <ListItemText primary={'Water Use Report'} primaryTypographyProps={{style: {color: contrastTextColor}}}/>
               </ListItem>
               {data?.client?.isManaged && (
                  <ListItem button component={Link} to={MESSAGES_CLIENT_PATH.replace(':clientId', clientId)}
                            selected={location.pathname === MESSAGES_CLIENT_PATH} disabled={!isConnected}>
                     <ListItemIcon style={{minWidth: 36}}>
                        <Badge badgeContent={unreadCount} color='secondary' classes={{badge: classes.badgeStyle}}
                               style={{color: contrastTextColor}}><Email fontSize={'small'}
                                                                         style={{color: contrastTextColor}}/></Badge>
                     </ListItemIcon>
                     <ListItemText primary={'Messages'} primaryTypographyProps={{style: {color: contrastTextColor}}}/>
                  </ListItem>
               )}
               <ListItem button component={Link} to={FILES_CLIENT_PATH.replace(':clientId', clientId)} selected={location.pathname === FILES_CLIENT_PATH} disabled={!isConnected}>
                  <ListItemIcon style={{minWidth: 36}}>
                     {location.pathname === FILES_CLIENT_PATH ? (
                        <FolderOpen fontSize="small" style={{color: contrastTextColor}}/>
                     ) : (
                        <Folder fontSize="small" style={{color: contrastTextColor}}/>
                     )}
                  </ListItemIcon>
                  <ListItemText primary={'Files'} primaryTypographyProps={{style: {color: contrastTextColor}}}/>
               </ListItem>
               <ListItem button component={Link} to={BULLETIN_PATH.replace(':clientId', clientId)} selected={location.pathname === BULLETIN_PATH}
                         onClick={onDrawerClose} disabled={!isConnected}>
                  <ListItemIcon style={{minWidth: 36}}>
                     <IIcon style={{color: contrastTextColor}}/>
                  </ListItemIcon>
                  <ListItemText primary={'Compliance Bulletin'} primaryTypographyProps={{style: {color: contrastTextColor}}}/>
               </ListItem>
            </List>
         </ResponsiveMobileDrawer>
      );
   } else {
      return null;
   }
}
