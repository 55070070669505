import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import toString from 'lodash/toString';
import find from 'lodash/find';
import pick from 'lodash/pick';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect, useCallback, Fragment, useImperativeHandle, forwardRef} from 'react';
import {v4 as uuid} from 'uuid';
import KeyboardDatePickerFHG from '../../../components/KeyboardDatePickerFHG';
import TextFieldFHG from '../../../components/TextField';
import {
   NEW_STATUS, EDIT_STATUS, GALLON_UNIT, ACRE_INCHES_UNIT, ACRE_FEET_UNIT, DELETE_STATUS
} from '../../../Constants';
import useMutationFHG, {DELETE_ACTION, UPDATE_ACTION, CREATE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import ValidateTarget from '../../../fhg/components/edit/ValidateTarget';
import Grid from '../../../fhg/components/Grid';
import TypographyFHG from '../../../fhg/components/Typography';
import {editChange, toNumber} from '../../../fhg/utils/Utils';
import {GAUGE_QUERY} from '../../client/WellClient';
import Allotment from './Allotment';
import {METER_FRAGMENT} from './FragmentsGL';

const useStyles = makeStyles(theme => ({
   meterStyle: {
      marginTop: theme.spacing(1),
   },
   textFieldStyle: {
      marginBottom: theme.spacing(0.5),
   },
   radioStyle: {
      padding: theme.spacing(0, .5, 0, 0),
   },
}), {name: 'meterEditInfoStyles'});

const METER_QUERY = gql`
   query getMeterForEdit($uuid: [String]){
      meters:meter_AllWhere(meterSearch: {uuid: $uuid}) {
         ...meterInfo
      }

      useTypes: useType_All {
         id
         name
         isDeleted
      }
   }
   ${METER_FRAGMENT}
`;

// Update the fields with the given properties.
export const METER_DELETE = {
   mutation: gql`
      mutation MeterDelete($meterId: Int!) {
         meter_Delete(meterId: $meterId)
      }
   `,
   typeKey: 'meter.type',
   actionKey: DELETE_ACTION,
};

// Update the wasteStorage with the given properties.
export const METER_UPDATE = {
   mutation: gql`
      mutation MeterUpdate($id: Int!, $name: String, $acreFeetMultiplier: Float, $acreInchesMultiplier: Float, $beginningMeter: Float, $date: String, $gallonsUnitMultiplier: Int, $wellId: Int, $allotment: AllotmentInput, $useTypeId: Int, $digits: Int) {
         meter: meter_Update(meterId: $id, meter: {name: $name, acreFeetMultiplier: $acreFeetMultiplier, acreInchesMultiplier: $acreInchesMultiplier, beginningMeter: $beginningMeter, date: $date, gallonsUnitMultiplier: $gallonsUnitMultiplier, wellId: $wellId, allotment: $allotment, useTypeId: $useTypeId, digits: $digits}) {
            ...meterInfo
         }
      }
      ${METER_FRAGMENT}
   `,
   typeKey: 'meter.type',
   actionKey: UPDATE_ACTION
};

// Update the wasteStorage with the given properties.
export const METER_CREATE = {
   mutation: gql`
      mutation MeterCreate($name: String, $acreFeetMultiplier: Float, $acreInchesMultiplier: Float, $beginningMeter: Float, $date: String, $gallonsUnitMultiplier: Int, $wellId: Int!, $allotment: AllotmentInput, $useTypeId: Int, $digits: Int) {
         meter: meter_Create(meter: {name: $name, acreFeetMultiplier: $acreFeetMultiplier, acreInchesMultiplier: $acreInchesMultiplier, beginningMeter: $beginningMeter, date: $date, gallonsUnitMultiplier: $gallonsUnitMultiplier, wellId: $wellId, allotment: $allotment, useTypeId: $useTypeId, digits: $digits}) {
            ...meterInfo
         }
      }
      ${METER_FRAGMENT}
   `,
   typeKey: 'meter.type',
   actionKey: CREATE_ACTION,
};

export const METER_OPTIONS_QUERY = gql`
   query getMeterOptionsForEdit{
      useTypes: useType_All {
         id
         name
         isDeleted
      }
      unitTypes: unitType_All {
         id
         name
         isDeleted
      }
   }
`;

const MeterEditInfo = forwardRef(
function MeterEditInfo({editItem, defaultItem = {}, isSelected, onEditChange, onChange, onAdd}, ref) {
   const classes = useStyles();
   const theme = useTheme();

   // Required to update the allotments.
   const {data} = useQueryFHG(METER_QUERY,
      {
         variables: {uuid: (defaultItem && defaultItem.uuid) || null},
         skip: !(defaultItem && defaultItem.uuid),
         fetchPolicy: 'cache-and-network'
      },
      'meter.type');
   const {data: meterOptionsData} = useQueryFHG(METER_OPTIONS_QUERY, undefined, 'meter.type');

   const [meterCreate] = useMutationFHG(METER_CREATE);
   const [meterUpdate] = useMutationFHG(METER_UPDATE);
   const [meterDelete] = useMutationFHG(METER_DELETE);

   const [useTypes, setUseTypes] = useState([]);

   const [defaultMeter, setDefaultMeter] = useState(defaultItem);

   useEffect(() => {
      if (!!data && (get(data, 'meters') || []).length > 0) {
         setDefaultMeter(get(data, 'meters[0]') || defaultItem);
      }
   }, [defaultItem, data]);

   useEffect(() => {
      if (!!meterOptionsData && useTypes.length <= 0) {
         setUseTypes(meterOptionsData.useTypes)
      }
   }, [meterOptionsData, useTypes.length]);

   useEffect(() => {
      editItem.gallonsUnitMultiplier =
         editItem.gallonsUnitMultiplier ? editItem.gallonsUnitMultiplier.toString() : undefined;
      editItem.acreInchesMultiplier =
         editItem.acreInchesMultiplier ? editItem.acreInchesMultiplier.toString() : undefined;
      editItem.acreFeetMultiplier = editItem.acreFeetMultiplier ? editItem.acreFeetMultiplier.toString() : undefined;
      if (editItem.allotment && editItem.allotment.allotment === null && editItem.allotment.fileNumbers === null) {
         editItem.allotment = null;
      }
   }, [editItem]);

   useImperativeHandle(ref, () => ({
      async submit(meter, wellId) {
         const getRefetchQueries = () => {
            return [
               {query: GAUGE_QUERY, variables: {wellId}, skip: !wellId},
            ]
         }
         if (meter.__state === EDIT_STATUS) {
            meter.gallonsUnitMultiplier = toNumber(meter.gallonsUnitMultiplier);
            meter.acreInchesMultiplier = toNumber(meter.acreInchesMultiplier);
            meter.acreFeetMultiplier = toNumber(meter.acreFeetMultiplier);
            if (meter.allotment) {
               // noinspection JSValidateTypes
               meter.allotment = pick(meter.allotment, 'allotment', 'fileNumbers');
            } else {
               meter.allotment = {};
            }
            if (meter.id) {
               await meterUpdate({variables: {...meter, wellId}, refetchQueries: getRefetchQueries});
            } else {
               await meterCreate({variables: {...meter, wellId}, refetchQueries: getRefetchQueries});
            }
         } else if (meter.__state === DELETE_STATUS && meter.id) {
            await meterDelete({variables: {meterId: meter.id}, refetchQueries: getRefetchQueries});
         }
      }
   }), [meterCreate, meterDelete, meterUpdate]);

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param reason The reason of the value change if Autocomplete
    */
   const handleChange = useCallback((event, value, reason) => {
      if (reason !== 'date-picker' || !value || !value.isValid || value.isValid()) {
         onEditChange && onEditChange(editItem, event, value, reason);
      }
   }, [onEditChange, editItem]);

   const handleRadioChange = (event, value) => {
      editItem.gallonsUnitMultiplier = undefined;
      editItem.acreInchesMultiplier = undefined;
      editItem.acreFeetMultiplier = undefined;

      const unitTypes = get(meterOptionsData, 'unitTypes') || [];
      switch (get(event, 'target.name')) {
         case 'acreInchesMultiplier':
            editItem.unitTypeId = get(find(unitTypes, {name: ACRE_INCHES_UNIT}), 'id');
            break;
         case 'acreFeetMultiplier':
            editItem.unitTypeId = get(find(unitTypes, {name: ACRE_FEET_UNIT}), 'id');
            break;
         case 'gallonsUnitMultiplier':
         default: //Intentional fall through
            editItem.unitTypeId = get(find(unitTypes, {name: GALLON_UNIT}), 'id');
            break;
      }
      onEditChange && onEditChange(editItem, event, value);
   };

   const handleRemoveAllotment = () => {
      editItem.allotment = null;
      onChange && onChange(editItem);
   };

   const handleEditAllotment = (allotmentItem, event, value, reason, theNewValue, name) => {
      const {componentName, newValue} = editChange(event, value, reason, false, theNewValue, name);
      allotmentItem[componentName] = newValue;
      allotmentItem.__state = EDIT_STATUS;

      editItem.allotment = allotmentItem;
      onChange && onChange(editItem);
   };

   const handleAddAllotment = () => {
      editItem.allotment = {
         allotment: undefined,
         fileNumbers: undefined,
         years: 1,
      };
      onChange && onChange(editItem);
   };

   const handleNewMeterForReplaced = () => {
      const newMeter = {...editItem, beginningMeter: 0, date: editItem.replacedDate, replacedDate: null, __state: EDIT_STATUS, id: null, uuid: uuid()};

      if (editItem.name && editItem.name.indexOf('(old)') < 0) {
         editItem.name = editItem.name + '(old)';
      } else {
         newMeter.name = newMeter.name.replace('(old)', '');
      }
      editItem.__state = EDIT_STATUS;

      onAdd && onAdd(newMeter);
   };

   if (!editItem) {
      return null;
   }

   const hasUnitMultiplerSelected = editItem.gallonsUnitMultiplier || editItem.acreFeetMultiplier || editItem.acreInchesMultiplier;
   return (
      <Grid container spacing={2} fullHeight alignItems={'center'}>
         <Grid item fullWidth xs={6} sm={12} md={6}>
            <TextFieldFHG
               name={'name'}
               labelKey={'meter.name.label'}
               defaultValue={get(defaultMeter, 'name')}
               value={editItem.name}
               onChange={handleChange}
               autoFocus={isSelected && editItem.__state === NEW_STATUS}
               fullWidth
               required
            />
         </Grid>
         <Grid item fullWidth xs={6} sm={12} md={6}>
            <TextFieldFHG
               name={'useTypeId'}
               labelKey={'meter.useType.label'}
               defaultValue={get(defaultMeter, 'useTypeId') || ''}
               value={editItem.useTypeId}
               onChange={handleChange}
               select
               fullWidth
               required
            >
               {useTypes && useTypes.map((type, index) => (
                  <MenuItem key={type + index} value={type.id}>
                     <TypographyFHG>{type.name}</TypographyFHG>
                  </MenuItem>
               ))}
            </TextFieldFHG>
         </Grid>
         {/*<Grid item fullWidth xs={12} sm={!!allotment ? 12 : 6} lg={6}>*/}
         <Allotment editItem={editItem.allotment} defaultItem={defaultMeter.allotment}
                    onRemoveAllotment={handleRemoveAllotment}
                    onEditChange={handleEditAllotment} onAddAllotment={handleAddAllotment}
                    hasAllotment={!!editItem.allotment} canDelete={true}
         />
         {/*</Grid>*/}
         <Grid item fullWidth xs={6} sm={12} md={6}>
            <TextFieldFHG
               name={'beginningMeter'}
               isFormattedNumber
               labelKey={'facility.beginningMeterReading.label'}
               defaultValue={defaultMeter.beginningMeter}
               value={editItem.beginningMeter}
               onChange={handleChange}
               fullWidth
               required
            />
         </Grid>
         <Grid item fullWidth xs={6} sm={12} md={6} overflow={'visible'}>
            <KeyboardDatePickerFHG
               name={'date'}
               variant='inline'
               labelKey={'date.label'}
               value={editItem.date}
               onChange={handleChange}
               required
            />
         </Grid>
         <Grid item fullWidth xs={6} sm={12} md={12} lg={4}>
            <TextFieldFHG
               name={'digits'}
               isFormattedNumber
               labelKey={'facility.digits.label'}
               defaultValue={defaultMeter.digits}
               value={editItem.digits}
               onChange={handleChange}
               fullWidth
               required
            />
         </Grid>
         {editItem.replacedDate && (
            <Fragment>
               <Grid item fullWidth xs={6} sm={12} md={6} lg={4} overflow={'visible'} color='secondary'>
                  <KeyboardDatePickerFHG
                     name={'replacedDate'}
                     variant='inline'
                     labelKey={'meter.replaced.label'}
                     value={editItem.replacedDate}
                     onChange={handleChange}
                     disabled
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6} lg={4} overflow={'visible'} color='secondary'
                     style={{marginTop: theme.spacing(2)}}>
                  <Button onClick={handleNewMeterForReplaced} style={{color: theme.palette.primary.light}}>
                     <TypographyFHG variant='button' color='inherit' style={{textDecoration: 'underline'}}
                                    id={'meter.replaced.button'}/>
                  </Button>
               </Grid>
            </Fragment>
         )}
         <Grid item fullWidth xs={12} sm={12} lg={12}>
            <TypographyFHG className={classes.textFieldStyle} id={'facility.unitType.label'}
                           display={'block'}/>
            <ValidateTarget name={'validate unit and multiplier'} value={hasUnitMultiplerSelected} title={'Selection one of the unit and multiplier options'} left={0}/>
            <Grid container direction={'row'}>
               <Grid item>
                  {/*<TypographyFHG className={classes.textFieldStyle} id={'facility.gallons.label'}/>*/}
                  <FormControl component='fieldset' fullWidth className={classes.leftSpace} required={!hasUnitMultiplerSelected}>
                     <FormLabel component='legend'><TypographyFHG className={classes.textFieldStyle}
                                                                  id={'facility.gallons.label'}/></FormLabel>
                     <RadioGroup
                        key={'gallonsUnitMultiplier' + editItem.uuid + editItem.gallonsUnitMultiplier}
                        aria-label='standard'
                        name='gallonsUnitMultiplier'
                        // defaultValue={defaultMeter.gallonsUnitMultiplier}
                        value={toString(editItem.gallonsUnitMultiplier)}
                        onChange={handleRadioChange}
                     >
                        <MenuItem dense>
                           <FormControlLabel value={'1'} control={<Radio className={classes.radioStyle}/>}
                                             label='x 1'/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'10'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 10'}/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'100'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 100'}/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'1000'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 1000'}/>
                        </MenuItem>
                     </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl component='fieldset' fullWidth className={classes.leftSpace} required={!hasUnitMultiplerSelected}>
                     <FormLabel component='legend'><TypographyFHG className={classes.textFieldStyle}
                                                                  id={'facility.acreInches.label'}/></FormLabel>
                     <RadioGroup
                        key={'acreInchesMultiplier' + editItem.uuid + defaultMeter.acreInchesMultiplier}
                        aria-label='standard'
                        name='acreInchesMultiplier'
                        // defaultValue={defaultMeter.acreInchesMultiplier}
                        value={toString(editItem.acreInchesMultiplier)}
                        onChange={handleRadioChange}
                     >
                        <MenuItem dense>
                           <FormControlLabel value={'0.1'} control={<Radio className={classes.radioStyle}/>}
                                             label='x 0.1'/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'0.01'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 0.01'}/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'0.001'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 0.001'}/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'0.0001'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 0.0001'}/>
                        </MenuItem>
                     </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl component='fieldset' fullWidth className={classes.leftSpace} required={!hasUnitMultiplerSelected}>
                     <FormLabel component='legend'><TypographyFHG className={classes.textFieldStyle}
                                                                  id={'facility.acreFeet.label'}/></FormLabel>
                     <RadioGroup
                        aria-label='standard'
                        key={'acreFeetMultipler' + editItem.uuid + defaultMeter.acreFeetMultiplier}
                        // row
                        name='acreFeetMultiplier'
                        // defaultValue={defaultMeter.acreFeetMultiplier}
                        value={toString(editItem.acreFeetMultiplier)}
                        onChange={handleRadioChange}
                     >
                        <MenuItem dense>
                           <FormControlLabel value={'0.1'} control={<Radio className={classes.radioStyle}/>}
                                             label='x 0.1'/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'0.01'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 0.01'}/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'0.001'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 0.001'}/>
                        </MenuItem>
                        <MenuItem dense>
                           <FormControlLabel value={'0.0001'}
                                             control={<Radio className={classes.radioStyle}/>}
                                             label={'x 0.0001'}/>
                        </MenuItem>
                     </RadioGroup>
                  </FormControl>
               </Grid>
            </Grid>
         </Grid>
      </Grid>
   )
})

export default MeterEditInfo;

// noinspection JSValidateTypes
MeterEditInfo.propTypes = {
   editItem: PropTypes.any,
   meterData: PropTypes.any,
   meterTypes: PropTypes.any,
   optionLabel: PropTypes.func,
   meterType: PropTypes.any,
   onChange: PropTypes.any,
   renderInput: PropTypes.func,
   renderOption: PropTypes.func,
   meterMax: PropTypes.any
};
