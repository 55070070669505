import React from "react";

// noinspection JSUnusedLocalSymbols
/**
 * Context to support the Mobile(small screens).
 *
 * Reviewed: 6/19/20
 */
export default React.createContext({
   title: undefined,
   titleKey: undefined,
   titleValues: undefined,
   titleUrl: undefined,
   subtitle: undefined,
   subtitleKey: undefined,
   subtitleValues: undefined,
   setTitle: (titleKey, values, title) => {},
   setTitleUrl: (titleUrl) => {},
   setSubtitle: (subtitleKey, values, subtitle) => {},
   clear: () => {},
});
