import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet.sector';
import castArray from 'lodash/castArray';
import isEqual from 'lodash/isEqual';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect, useRef} from 'react';
import {Map, TileLayer, FeatureGroup} from 'react-leaflet';
import {CENTER_MAP_DEFAULT, ZOOM_DEFAULT, FIELD_GEO_JSON_COLOR} from '../../../Constants';
import EditControlFHG from './EditControl';
import GeoJsonFHG from './GeoJsonFHG';
import './Leaflet.draw-sector';
import MarkerColoredFHG from './MarkerColoredFHG';

// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
   iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
   iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

MapFHG.propTypes = {
   onGeoDelete: PropTypes.func,
}

export default function MapFHG({
   name, markers = [], center, defaultZoom, zoom, minZoom, maxZoom, bounds, onChange,
   onClick, onMarkerClick, onPopupClose, onPopupOpen, closePopup = false, draggableMarker = true, draggableMap = true,
   allowEdit = false, geoJson, onGeoChange, onGeoDelete, geoAndMarkers = false, zoomOnGeo = true,
   limitShapes = Number.MAX_SAFE_INTEGER, ...mapProps
}) {
   const markerList = castArray(markers);
   const geoJsonObject = typeof geoJson === 'string' ? JSON.parse(geoJson) : geoJson;
   const geoList = castArray(geoJsonObject || []);
   const [geoBounds, setGeoBounds] = useState(bounds);
   const featureGroupGeoRef = useRef();
   const mapRef = useRef();

   useEffect(() => {
      if (mapRef.current && closePopup) {
         mapRef.current.leafletElement.closePopup();
      }
   }, [mapRef, closePopup]);

   const [viewport, setViewport] = useState(bounds ? undefined : {
      zoom: zoom || defaultZoom,
      center: center && center.latitude ? [center.latitude, center.longitude] : CENTER_MAP_DEFAULT
   });

   useEffect(() => {
      if (bounds) {

         setGeoBounds(bounds);
         setViewport(undefined);
      } else if (!geoBounds) {
         setViewport({
            zoom: zoom || defaultZoom,
            center: center && center.latitude ? [center.latitude, center.longitude] : CENTER_MAP_DEFAULT
         });
      }
   }, [bounds]);

   const pointToLayer = (feature, latlng) => {
      if (feature.properties.type === 'Sector') {
         return new L.Sector({ ...feature.properties, weight: 3});
      }
      else if (feature.properties.radius) {
         return new L.Circle(latlng, feature.properties.radius);
      } else {
         return new L.Marker(latlng);
      }
   };

   const addGeoJsonToFeatureGroup = (featureGroupRef) => {

      if (featureGroupRef) {
         featureGroupGeoRef.current = featureGroupRef;
         let leafletFG = featureGroupRef.leafletElement;
         leafletFG.clearLayers();

         if (geoList.length > 0) {
            const features = [];
            for (let feature of geoList) {
               if (feature !== null) {
                  if (feature.data) {
                     const data = {
                        'type': 'Feature',
                        ...feature.data,
                     };
                     data.properties.uuid = feature.uuid;
                     data.properties.facilityId = feature.facilityId;
                     features.push(data);
                  } else {
                     features.push({
                        'type': 'Feature',
                        ...feature,
                     });
                  }
               }
            }
            const useJson = {
               'type': 'FeatureCollection',
               features,
            };

            let leafletGeoJSON = new L.GeoJSON(useJson, {
               pointToLayer,
               style: {color: FIELD_GEO_JSON_COLOR}
            });
            let center;
            leafletGeoJSON.eachLayer((layer) => {
               leafletFG.addLayer(layer);
               if (zoomOnGeo && center === undefined) {
                  if (layer instanceof L.Circle || layer instanceof L.Marker) {
                     center = layer.getLatLng();
                  } else if (layer.getCenter) {
                     try {
                        center = layer.getCenter();
                     } catch (e) {
                        console.log('Cannot access getCenter');
                        console.trace();
                     }
                  }
               }
            });
            setViewport(undefined);
         }
      }
   };

   useEffect(() => {
      if (featureGroupGeoRef.current && geoList && geoList.length > 0 && zoomOnGeo && geoAndMarkers) {
         const leaflet = featureGroupGeoRef.current.leafletElement;

         try {
            const newBounds = leaflet.getBounds();
            if (!isEqual(newBounds, geoBounds)) {
               setGeoBounds(newBounds);
               setViewport(undefined);
            }
         } catch (e) {
            //Intentionally left blank.
         }
      }
   }, [featureGroupGeoRef.current, geoList, zoomOnGeo, geoAndMarkers]);

   useEffect(() => {
      // Only setup the viewport when initializing so user changes are kept.
      if ((!viewport || !viewport.center) && !bounds && !geoBounds) {
         const newViewport = {
            ...viewport,
            zoom: zoom || defaultZoom || (viewport && viewport.zoom) || ZOOM_DEFAULT,
            center: center && center.latitude ? [center.latitude, center.longitude] : [38.48583333, -98.68583333],
         };

         setViewport(newViewport);
      } else {
         setViewport(undefined);
      }
      //setting viewport so we can't check viewport.
      // eslint-disable-next-line
   }, [center, zoom, defaultZoom, bounds, geoBounds]);

   useEffect(() => {
      if (defaultZoom && !bounds && !geoBounds) {
         const newViewport = {
            ...viewport,
            zoom: zoom || defaultZoom || (viewport && viewport.zoom) || ZOOM_DEFAULT,
            center: center && center.latitude ? [center.latitude, center.longitude] : [38.48583333, -98.68583333],
         };

         setViewport(newViewport);
      }
   }, [bounds, geoBounds, zoom, defaultZoom]);

   const handleClick = (event) => {
      if (!allowEdit) {
         if (onClick) {
            onClick(event);
         } else if (onChange) {
            onChange(event.latlng, event);
         }
      } else if (onChange) {
         onChange(event.latlng, event);
      }
   };

   const handleDragEnd = (event) => {
      const latlng = event.target.getLatLng();

      if (latlng) {
         onChange && onChange(latlng, event);
      } else {
         console.log('Cannot get the latitude and longitude from the event.', event);
      }
   };

   return (
      <Map key={'Map' + name} ref={mapRef} viewport={viewport} bounds={geoBounds}
           touchZoom={false} dragging={draggableMap}
           style={{height: '100%', width: '100%', minHeight: 150}} onClick={handleClick} minZoom={minZoom}
           maxZoom={maxZoom} {...mapProps}>

         <TileLayer
            key={'tileLayer' + name}
            attribution={'Google.com'}
            url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
            subdomains={['mt0', 'mt1']}
         />
         {allowEdit ? (
            <FeatureGroup ref={featureGroupRef => addGeoJsonToFeatureGroup(featureGroupRef)}>
               <EditControlFHG allowEdit={allowEdit} onChange={onGeoChange} onDelete={onGeoDelete}
                               count={geoList.length} limitShapes={limitShapes}/>
            </FeatureGroup>
         ) : geoList.map((shape, index) => (
            <FeatureGroup key={'fg' + index}>
               <GeoJsonFHG key={`geo ${shape.uuid}`} ref={featureGroupGeoRef} name={`${name} ${shape.uuid}`}
                           data={shape.data} shape={shape}
                           color={shape.color} content={shape.content} uuid={shape.uuid}
                           onPopupClose={onPopupClose} onPopupOpen={() => onPopupOpen(shape.uuid, true)}
                           pointToLayer={pointToLayer}/>
            </FeatureGroup>
         ))}
         {markerList.length > 0 && markerList.map((marker = {}, index) => (
            <MarkerColoredFHG key={`marker ${marker.uuid}`} name={`${name} ${marker.uuid} ${index}`} marker={marker}
                              location={marker}
                              draggable={draggableMarker} onClick={onMarkerClick}
                              color={marker.color} type={marker.type}
                              content={marker.content} uuid={marker.uuid}
                              onPopupClose={onPopupClose} onPopupOpen={onPopupOpen}
                              onDragEnd={handleDragEnd}
            />
         ))}

      </Map>
   );
}

