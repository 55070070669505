import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber} from 'react-intl';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
   () => ({
      cellStyle: {
         paddingLeft: '0 !important',
      },
   }),
   {name: 'MeterBreakdownTableStyles'}
);

export function Row(props) {
   const {row, columns} = props;
   const classes = useStyles();

   return (
      <React.Fragment>
         <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
            <TableCell scope='row'>
               <b>{row.month}</b>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
         </TableRow>
         <TableRow>
            <TableCell
               name='sub table'
               style={{paddingBottom: 0, paddingTop: 0, margin: 1, border: 'unset'}}
               className={classes.cellStyle}
               colSpan={6}
            >
               <Table size='small' name='sub table'>
                  <TableHead>
                     <TableRow>{(columns) => (column) => <TableCell>{column.name}</TableCell>}</TableRow>
                  </TableHead>
                  <TableBody>
                     {row.meters.map((meter, index) => (
                        <TableRow key={'meter' + index}>
                           {columns.map((column, index2) => (
                              <TableCell
                                 key={'inner' + index2}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                              >
                                 {column?.type === 'number' ? (
                                    <FormattedNumber
                                       value={meter?.[column?.id]}
                                       maximumFractionDigits={column.maximumFractionDigits}
                                       maximumSignificantDigits={column.maximumSignificantDigits}
                                    />
                                 ) : (
                                    meter?.[column?.id]
                                 )}
                              </TableCell>
                           ))}
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableCell>
         </TableRow>
      </React.Fragment>
   );
}

Row.propTypes = {
   row: PropTypes.shape({
      calories: PropTypes.number.isRequired,
      carbs: PropTypes.number.isRequired,
      fat: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
         PropTypes.shape({
            amount: PropTypes.number.isRequired,
            customerId: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
         })
      ).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protein: PropTypes.number.isRequired,
   }).isRequired,
};
