import {useQuery} from '@apollo/react-hooks';
import {useContext, useEffect} from 'react';
import StatusContext from '../StatusContext';

export default function useQueryFHG(query, options, typeKey, showLoading = true) {
   const {setError, setErrorGeneral, setProgress} = useContext(StatusContext);

   const {loading, error, data} = useQuery(query, {fetchPolicy: 'cache-and-network', ...options});

   useEffect(() => {
      return () => {
         setProgress(false);
      }
   }, []);

   useEffect(() => {
      if (showLoading) {
         setProgress(loading)
      }
   }, [loading, showLoading, setProgress]);

   useEffect(() => {
      if (error &&  typeKey) {
         console.log('Error type', typeKey);
         console.log(error, error.stackTrace);
         if (typeKey) {
            setErrorGeneral({typeKey, error, actionKey: 'load'});
         }
      }
   }, [error, setErrorGeneral, setError]);

   return {loading, error, data};
}
