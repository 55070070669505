import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Close from '@material-ui/icons/Close';
import delay from 'lodash/delay';
import round from 'lodash/round';
import React, {useState, useEffect} from 'react';
import useEditData from '../fhg/components/edit/useEditData';
import Grid from '../fhg/components/Grid';
import Typography from '../fhg/components/Typography';
import TypographyFHG from '../fhg/components/Typography';
import {editChange} from '../fhg/utils/Utils';
import TextFieldFHG from './TextField';

const useStyles = makeStyles(theme => ({
   paperStyle: {
      width: 340,
      padding: theme.spacing(1),
   },
   radioStyle: {
      marginTop: theme.spacing(2),
      width: 50,
   }
}), {name: 'ConvertUnitsStyles'});

ConvertUnits.propTypes = {};

function ConvertUnits({anchorEl, defaultValue, value, onClose, onSubmit}) {
   const classes = useStyles();
   const theme = useTheme();

   // eslint-disable-next-line
   const [editValues, handleChange, {setEditValues, defaultValues, setDefaultValues}] = useEditData(
      {acreFeet: null, acreInches: null, pumpCapacity: null, hoursPumped: null});
   const [selected, setSelected] = useState();
   const [gallons, setGallons] = useState();

   useEffect(() => {
       if (defaultValue) {
          setGallons(defaultValue);
          setEditValues({acreFeet: defaultValue / 325851.43188913, acreInches: defaultValue / 27154.285990761});
       }
   }, [setEditValues, defaultValue]);


   const doChange = (event) => {
      let useEditValues;
      const {componentName, newValue} = editChange(event, undefined, undefined, false);

      if (componentName === 'hoursPumped' || componentName === 'pumpCapacity') {
         useEditValues = {...editValues, ...{acreFeet: null, acreInches: null}, [componentName]: newValue};
         // noinspection JSCheckFunctionSignatures
         setSelected('pumpCapacity');
         if (useEditValues.pumpCapacity !== undefined && useEditValues.hoursPumped !== undefined) {
            //pumpedCapacity is in gallons pumped per minute.
            setGallons(useEditValues.pumpCapacity * useEditValues.hoursPumped * 60);
         }
      } else {
         useEditValues =
            {...{acreFeet: null, acreInches: null, hoursPumped: null, pumpCapacity: null}, [componentName]: newValue};
         setSelected(componentName);

         switch (componentName) {
            case 'acreFeet':
               // noinspection JSCheckFunctionSignatures
               setGallons(newValue * 325851.43188913);
               break;
            case 'acreInches':
               // noinspection JSCheckFunctionSignatures
               setGallons(newValue * 27154.285990761);
               break;
            default:
               console.log('Bad component name', componentName);
               break;
         }
      }
      setEditValues(useEditValues);
   };

   const handleSubmit = () => {
      onSubmit(round(gallons));
   };

   const handleRadioChange = ({currentTarget}) => {

      if (currentTarget.name === 'hoursPumped' || currentTarget.name === 'pumpCapacity') {
         // noinspection JSCheckFunctionSignatures
         setSelected('pumpCapacity');
      } else {
         setSelected(currentTarget.name);
      }

      if (currentTarget.name !== selected) {
         delay(() => {
            const element = document.getElementById(currentTarget.name);
            if (element) {
               element.focus();
               element.select();
            }
         }, 4);
      }
   };

   if (!defaultValues) return null;
   return (
      <Popover
         open={true}
         onClose={onClose}
         anchorEl={anchorEl}
         PaperProps={{
            className: classes.paperStyle
         }}
      >
         <Grid container direction={'column'}>
            <Grid item container direction={'row'} justify={'space-between'}>
               <Grid item>
                  <TypographyFHG variant={'h6'} id={'convertUnits.title'}/>
               </Grid>
               <Grid item>
                  <IconButton onClick={onClose}>
                     <Close/>
                  </IconButton>
               </Grid>
            </Grid>
         <TypographyFHG variant={'subtitle2'} id={'convertUnits.subtitle'}/>
         </Grid>
         <Grid container spacing={1}>
            <Grid container direction={'row'} spacing={1} item xs={12} alignItems={'center'}>
               <Grid item>
                  <Radio
                     className={classes.radioStyle}
                     checked={selected === 'acreFeet'}
                     onChange={handleRadioChange}
                     value='acreFeet'
                     name='acreFeet'
                  />
               </Grid>
               <Grid item resizable>
                  <TextFieldFHG
                     id={'acreFeet'}
                     name={'acreFeet'}
                     autoFocus
                     labelKey={'acreFeet.label'}
                     defaultValue={defaultValues.acreFeet}
                     value={editValues.acreFeet}
                     onChange={doChange}
                     fullWidth
                  />
               </Grid>
            </Grid>
            <Grid item xs={12} container direction={'row'} spacing={1} alignItems={'center'}>
               <Grid item>
                  <Radio
                     className={classes.radioStyle}
                     checked={selected === 'acreInches'}
                     onChange={handleRadioChange}
                     value='acreInches'
                     name='acreInches'
                  />
               </Grid>
               <Grid item resizable>
                  <TextFieldFHG
                     id={'acreInches'}
                     name={'acreInches'}
                     labelKey={'acreInches.label'}
                     defaultValue={defaultValues.acreInches}
                     value={editValues.acreInches}
                     onChange={doChange}
                     fullWidth
                     required
                  />
               </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} container direction={'row'} spacing={1} alignItems={'center'} wrap={'nowrap'}>
               <Radio
                  className={classes.radioStyle}
                  checked={selected === 'pumpCapacity'}
                  onChange={handleRadioChange}
                  value='pumpCapacity'
                  name='pumpCapacity'
                  inputProps={{'aria-label': 'acre-inches'}}
               />
               <TextFieldFHG
                  id={'pumpCapacity'}
                  name={'pumpCapacity'}
                  labelKey={'clientField.pumpCapacity.label'}
                  defaultValue={defaultValues.pumpCapacity}
                  value={editValues.pumpCapacity}
                  onChange={doChange}
                  required={editValues.hoursPumped}
                  style={{marginRight: theme.spacing(1)}}
               />
               <TextFieldFHG
                  name={'hoursPumped'}
                  labelKey={'clientField.hoursPumped.label'}
                  defaultValue={defaultValues.hoursPumped}
                  value={editValues.hoursPumped}
                  onChange={doChange}
                  required={editValues.pumpCapacity}
                  margin={'normal'}
               />
            </Grid>
            <Grid container item direction={'row'} alignItems={'center'} spacing={2}>
               <Grid item>
                  <Button variant='contained' size={'small'} color='primary' type={'submit'} onClick={handleSubmit}>
                     <Typography variant={'inherit'} id={'convertUnits.button'}/>
                  </Button>
               </Grid>
               <Grid item>
                  <Typography variant={'subtitle2'} id={'convertUnits.gallons.label'} values={{gallons: gallons}}/>
               </Grid>
            </Grid>
         </Grid>
      </Popover>
   );
}

export default ConvertUnits;
