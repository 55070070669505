import React, {useState, useCallback} from 'react';
import MobileContext from './MobileContext';

/**
 * The MobileProvider component.
 *
 * Reviewed: 6/19/20
 */
export default function MobileProvider({children}) {
   const [title, setTitleState] = useState();
   const [titleUrl, setTitleUrlState] = useState();
   const [titleKey, setTitleKey] = useState();
   const [titleValues, setTitleValues] = useState();

   const [subtitle, setSubtitleState] = useState();
   const [subtitleKey, setSubtitleKey] = useState();
   const [subtitleValues, setSubtitleValues] = useState();


   const setTitle = useCallback((titleKey, values, title) => {
      setTitleKey(titleKey);
      setTitleState(title);
      setTitleValues(values);
   }, []);

   const setTitleUrl = useCallback((titleUrl) => {
      setTitleUrlState(titleUrl);
   }, []);

   const setSubtitle = useCallback((subtitleKey, values, subtitle) => {
      setSubtitleKey(subtitleKey);
      setSubtitleState(subtitle);
      setSubtitleValues(values);
   }, []);

   const clear = () => {
      setTitleKey(undefined);
      setTitleState(undefined);
      setTitleValues(undefined);
      setTitleUrlState(undefined);
      setSubtitleKey(undefined);
      setSubtitleState(undefined);
      setSubtitleValues(undefined);
   };

   return (
      <MobileContext.Provider value={{titleKey, title, titleValues, titleUrl, subtitleKey, subtitle, subtitleValues, setTitle, setTitleUrl, setSubtitle, clear}}>
         {children}
      </MobileContext.Provider>
   );
}
