import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import ExpandMore from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useState, useEffect, useLayoutEffect, useCallback} from 'react';
import {useRouteMatch} from 'react-router-dom';
import TitleCardInner from '../../../components/TitleCardInner';
import {DATE_DB_FORMAT, CLIENT_DASHBOARD_PATH} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useLazyQueryOfflineFHG from '../../../fhg/components/data/useLazyQueryOfflineFHG';
import useMutationFHG, {DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import DateRangePicker from '../../../fhg/components/edit/DateRangePicker';
import Grid from '../../../fhg/components/Grid';
import TypographyFHG from '../../../fhg/components/Typography';
import {cacheDelete} from '../../../fhg/utils/DataUtil';
import useMessage from '../../../fhg/utils/useMessage';
import {toNumber, getDays, areDatesValid, removeOne} from '../../../fhg/utils/Utils';
import {CLIENT_BY_ID_DASHBOARD_QUERY} from '../ClientDashboard';
import FieldRecordEdit, {FIELD_WASTE_APPLICATION_QUERY} from './FieldRecordEdit';

const useStyles = makeStyles({
   paperStyle: {
      height: 'fit-content',
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
      position: 'relative',
   },
   buttonStyle: {
      marginBottom: '0.35em',
   },
}, {name: 'WasteApplicationRecordStyles'});

// Default properties needed for the waste application record.
const WASTE_APPLICATION_RECORD_FRAGMENT = gql`
   fragment wasteApplicationRecordInfo on WasteApplication {
      id
      amount
      applicationMethodId
      startDate
      endDate
      fieldCoverId
      fieldId
      isDeleted
      note
      soilConditionId
      wasteSourceId
      wasteSource {
         id
         name
         uuid
      }
      wasteStorageId
      wasteStorage {
         id
         name
         uuid
      }
      wasteTypeId
      uuid
      isAfterHarvest
   }
`;

// Query for all the waste application records for the facility.
export const WASTE_APPLICATION_BY_RANGE_QUERY = gql`
   query getWasteApplicationByRange($fieldId: [Int], $beginDate: String, $endDate: String)
   {
      wasteApplication:wasteApplication_AllWhere(wasteApplicationSearch: {fieldId: $fieldId, beginDate: $beginDate, endDate: $endDate}) {
         ...wasteApplicationRecordInfo
      }
   }
   ${WASTE_APPLICATION_RECORD_FRAGMENT}
`;

// Delete the waste export on the server.
const WASTE_APPLICATION_DELETE = {
   mutation: gql`
      mutation WasteApplicationDelete($id: Int!) {
         wasteApplication_Delete(wasteApplicationId: $id)
      }
   `,
   typeKey: 'wasteApplication.type',
   actionKey: DELETE_ACTION,
};
FieldRecord.propTypes = {
   parentItem: PropTypes.object.isRequired,
   onClose: PropTypes.func.isRequired,
};

/**
 * The component to record waste application records for the field.
 *
 * @param parentItem The admin field data.
 * @param onClose Callback when the panel is closed.
 */
export default function FieldRecord({parentItem = {}, onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const wasteApplicationType = useMessage('wasteApplication.type');
   let messageMatch = useRouteMatch({path: CLIENT_DASHBOARD_PATH, strict: false, sensitive: false});

   const [loadWasteApplicationRange, {data: wasteApplicationRangeData}] = useLazyQueryOfflineFHG(
      WASTE_APPLICATION_BY_RANGE_QUERY, undefined, 'wasteApplication.type');

   const [wasteApplicationDelete] = useMutationFHG(WASTE_APPLICATION_DELETE);

   const [wasteApplicationList, setWasteApplicationList] = useState([]);

   const [entryDays, setEntryDays] = useState([]);
   const [daySelected, setDaySelected] = useState([moment().startOf('day'), moment().endOf('day')]);
   const [monthRange, setMonthRange] = useState(
      {beginDate: moment().startOf('month'), endDate: moment().endOf('month')});
   const [isPickerOpen, setIsPickerOpen] = useState(false);
   const [expanded, setExpanded] = useState();

   useLayoutEffect(() => {
      const titleElement = document.getElementById('clientField.wasteApplicationRecord.button');
      if (titleElement) {
         titleElement.scrollIntoView(false);
      }
   }, []);

   useEffect(() => {
      if (parentItem) {
         loadWasteApplicationRange({
            variables: {
               fieldId: parentItem && parentItem.id,
               beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
               endDate: monthRange.endDate.format(DATE_DB_FORMAT),
            }
         });
      }
      // eslint-disable-next-line
   }, [parentItem]);

   const setInitialValues = useCallback((isSetEntryDays, dates = daySelected) => {
      const wasteApplication = get(wasteApplicationRangeData, 'wasteApplication') || [];

      if (wasteApplication.length > 0) {
         if (isSetEntryDays) {
            setEntryDays(getDays(wasteApplication));
         }
         const filteredDays = filter(wasteApplication, item => {
            const isStartSame =  moment(item.startDate, DATE_DB_FORMAT).isSame(dates[0], 'day');
            const isEndDSame = moment(item.endDate, DATE_DB_FORMAT).isSame(dates[1] || dates[0], 'day');
            return isStartSame && isEndDSame;
         }) || [];

         if (filteredDays.length > 0) {
            setWasteApplicationList(filteredDays);
         } else {
            setWasteApplicationList([{}]);
         }
      } else {
         if (isSetEntryDays) {
            setEntryDays([]);
         }
         setWasteApplicationList([{}]);
      }
      //Don't want to change on daySelected
      // eslint-disable-next-line
   }, [wasteApplicationRangeData]);

   useEffect(() => {
      if (wasteApplicationRangeData) {
         setInitialValues(true, daySelected);
      }
      //Don't change on daySelected. Will reset the entryDays needlessly.
      // eslint-disable-next-line
   }, [wasteApplicationRangeData, setInitialValues]);

   const getRefetchQueries = () => {
      return [
         {query: CLIENT_BY_ID_DASHBOARD_QUERY, variables: {clientId: toNumber(get(messageMatch, 'params.clientId'))}},
      ];
   };

   const getCacheQueries = () => {
      const beginDate = moment(get(daySelected, '[0]'));
      const endDate = moment(get(daySelected, '[1]'));

      const variablesForMonth = {
         fieldId: parentItem && parentItem.id,
         beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
         endDate: monthRange.endDate.format(DATE_DB_FORMAT)
      };
      const today = moment();
      const variablesForYear = {
         fieldId: parentItem && parentItem.id,
         beginDate: today.startOf('year').format(DATE_DB_FORMAT),
         endDate: today.endOf('year').format(DATE_DB_FORMAT),
      };
      const queries = [
         {query: WASTE_APPLICATION_BY_RANGE_QUERY, variables: variablesForMonth},
         {query: WASTE_APPLICATION_BY_RANGE_QUERY, variables: variablesForYear},
         {query: FIELD_WASTE_APPLICATION_QUERY, variables: {fieldId: parentItem.id}},
      ];

      if (!beginDate.isSame(endDate, 'month')) {
         const startMonth = {fieldId: parentItem && parentItem.id};
         startMonth.beginDate = moment(beginDate).startOf('month').format(DATE_DB_FORMAT);
         startMonth.endDate = moment(beginDate).endOf('month').format(DATE_DB_FORMAT);
         queries.push({query: WASTE_APPLICATION_BY_RANGE_QUERY, variables: {...startMonth}});

         const endMonth = {fieldId: parentItem && parentItem.id};
         endMonth.beginDate = moment(endDate).startOf('month').format(DATE_DB_FORMAT);
         endMonth.endDate = moment(endDate).endOf('month').format(DATE_DB_FORMAT);
         queries.push({query: WASTE_APPLICATION_BY_RANGE_QUERY, variables: {...endMonth}});
      }
      return queries;
   };

   const handleDateChange = async (event, dates) => {

      if (areDatesValid(dates)) {
         setDaySelected(dates);
         if (!dates[0].isBetween(monthRange.beginDate, monthRange.endDate, 'day', '[]')) {
            await handleMonthChange(dates);
         } else {
            setInitialValues(false, dates);
         }
      }
   };

   const handleMonthChange = (dates) => {
      let beginDate, endDate;

      if (isArray(dates)) {
         beginDate = moment(dates[0]).startOf('month');
         if (dates.length > 1) {
            endDate = moment(dates.length > 1 ? dates[1] : dates[0]).endOf('month');
         }
      } else {
         beginDate = moment(dates).startOf('month');
         endDate = moment(dates).endOf('month');
      }
      setMonthRange({beginDate, endDate});
      return loadWasteApplicationRange({
         variables: {
            fieldId: parentItem && parentItem.id,
            beginDate: beginDate.format(DATE_DB_FORMAT),
            endDate: endDate.format(DATE_DB_FORMAT),
         }
      });
   };

   const handleDelete = (wasteApplication, index) => async () => {

      if (wasteApplication && wasteApplication.id) {
         await wasteApplicationDelete({
            variables: {id: wasteApplication.id},
            optimisticResponse: {refetchQueries: getRefetchQueries, wasteApplication_Delete: 1},
            update: cacheDelete(getCacheQueries(), wasteApplication.uuid, 'wasteApplication'),
            refetchQueries: getRefetchQueries,
         });
      } else {
         const updatedList = removeOne([...wasteApplicationList], index);
         setWasteApplicationList(updatedList);
      }
   };

   const handleAdd = (event) => {
      event.stopPropagation();
      event.preventDefault();

      setExpanded(wasteApplicationList.length);

      setWasteApplicationList([
         ...wasteApplicationList,
         {},
      ]);
   };

   const handleCloseEdit = () => {

      if (wasteApplicationList && wasteApplicationList.length <= 1) {
         onClose && onClose();
      } else {
         setExpanded(undefined);
      }
   };

   const handleExpand = index => (event) => {
      if (!event.isDefaultPrevented()) {
         setExpanded(expanded === index ? undefined : index);
      }
   };

   return (
      <TitleCardInner
         titleId={'clientField.wasteApplicationRecord.button'}
         variant={'subtitle1'}
         onClose={isPickerOpen ? undefined : onClose}
         classes={{paperStyle: classes.paperStyle}}
         headerAction={(
            <Grid container>
               {((wasteApplicationList.length > 1) ||
                  (wasteApplicationList.length === 1 && wasteApplicationList[0].id)) && (
                  <Button className={classes.buttonStyle} onClick={handleAdd}
                          style={{color: theme.palette.primary.light}}>
                     <TypographyFHG variant='button' color='inherit' style={{textDecoration: 'underline'}}
                                    id={'wasteApplication.add.button'}/>
                  </Button>
               )}
               <ConfirmButton
                  onConfirm={handleDelete(get(wasteApplicationList, '[0]'), 0)}
                  style={{
                     display: wasteApplicationList.length !== 1 || !wasteApplicationList[0].id ? 'none' : undefined
                  }}
                  titleKey={'delete.confirm.title'}
                  messageKey={'delete.confirm.message'}
                  titleValues={{type: wasteApplicationType}}
                  values={{type: wasteApplicationType}}
                  color='secondary'
                  buttonLabelKey={'delete.button'}
                  disableRipple
                  className={classes.buttonStyle}
                  buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
               />
            </Grid>
         )}
      >
         <Grid item container direction={'row'} spacing={1} alignItems={'center'} overflow={'visible'}
               style={{padding: 0, height: 'fit-content'}}>
            <Grid item xs={12}>
               <DateRangePicker
                  name={'date'}
                  open={isPickerOpen}
                  labelKey={'field.date.label'}
                  value={daySelected}
                  autoOk={false}
                  disableFuture={true}
                  onChange={handleDateChange}
                  onMonthChange={handleMonthChange}
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  entryDays={entryDays}
                  autoFocus
                  required
               />
            </Grid>
            {wasteApplicationList.length > 1 ? wasteApplicationList.map((wasteApplication, index) => (
               <Accordion key={'expansionPanel' + (wasteApplication && wasteApplication.uuid) || index} name={index}
                          classes={{root: classes.expansionStyle}} expanded={expanded === index}
                          onChange={handleExpand(index)}>
                  <AccordionSummary
                     expandIcon={<ExpandMore/>}
                     aria-controls='panel1bh-content'
                     id='panel1bh-header'
                     classes={{root: classes.rootStyle, content: classes.contentStyle}}
                  >
                     <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
                        <Grid item>
                           <TypographyFHG variant={'body1'} color={'primary'} style={{fontWeight: 500}}>
                              {`${get(wasteApplication, 'wasteSource.name', get(wasteApplication, 'wasteStorage.name')) || ''} Amount: ${wasteApplication.amount || ''}`}
                           </TypographyFHG>
                        </Grid>
                        <Grid item>
                           <ConfirmButton
                              onConfirm={handleDelete(wasteApplication, index)}
                              titleKey={'delete.confirm.title'}
                              messageKey={'delete.confirm.message'}
                              titleValues={{type: wasteApplicationType}}
                              values={{type: wasteApplicationType}}
                              color='secondary'
                              buttonLabelKey={'delete.button'}
                              disableRipple
                              className={classes.buttonStyle}
                              buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
                           />
                        </Grid>
                     </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                     <FieldRecordEdit field={parentItem} wasteApplication={wasteApplication} daySelected={daySelected}
                                      onClose={handleCloseEdit} getCacheQueries={getCacheQueries}
                                      getRefetchQueries={getRefetchQueries}/>
                  </AccordionDetails>
               </Accordion>
            )) : (
               <FieldRecordEdit field={parentItem} wasteApplication={get(wasteApplicationList, '[0]')}
                                daySelected={daySelected} getCacheQueries={getCacheQueries}
                                onClose={handleCloseEdit} getRefetchQueries={getRefetchQueries}
               />
            )}
         </Grid>
      </TitleCardInner>
   );
}
