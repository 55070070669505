import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../fhg/components/Grid';

const useStyles = makeStyles(theme => ({
   notificationStyle: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      paddingRight: theme.spacing(6),
   },
   closeButtonStyle: {
      position: 'absolute',
      right: 0,
      top: 0,
      marginLeft: 'auto',
      zIndex: 1001,
   },
   alertStyle: {
      backgroundColor: theme.palette.background.paper,
   },
}), {name: 'NotificationSnackbarStyles'});

/**
 * Component to show the notifications.
 *
 * Reviewed:
 */
export default function NotificationSnackbar({open = true, onClose, onNotificationClose, notifications = []}) {
   const classes = useStyles();

   return (
      <Snackbar
         open={open}
         anchorOrigin={{vertical: 'top', horizontal: 'center'}}
         ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
               root: classes.notificationStyle,
            }
         }}
         message={(
            <Grid container spacing={1} direction='column' isScrollable style={{maxHeight:200}}>
               {(notifications && notifications.length > 0) && notifications.map((notification, index) => (
                  <Grid key={'notification'+ notification.id } item fullWidth>
                  <Collapse in={open}>
                     <Alert
                        severity={notification.severity}
                        variant={'outlined'}
                        classes={{outlinedError: classes.alertStyle, outlinedInfo: classes.alertStyle}}
                        action= {notification.__typename === 'Alert' ? (
                           <IconButton
                              aria-label='close'
                              color='inherit'
                              size='small'
                              onClick={() => {
                                 onNotificationClose && onNotificationClose(notification);
                              }}
                           >
                              <CloseIcon fontSize='inherit'/>
                           </IconButton>
                        ) : undefined}
                     >
                        <AlertTitle>{notification.title}</AlertTitle>
                        {notification.message}
                     </Alert>
                  </Collapse>
                     </Grid>
               ))}
            </Grid>
         )}
         action={[
            onClose && (
               <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.closeButtonStyle}
                  onClick={onClose}
               >
                  <CloseIcon/>
               </IconButton>
            ),
         ]}
      />
   );
}

NotificationSnackbar.propTypes = {
   open: PropTypes.bool,                     // Indicates if the Error snackbar should be shown to the user.
   onClose: PropTypes.func,                  // Callback when the snackbar is closed.
   message: PropTypes.string,                // Text for the error message.
};
