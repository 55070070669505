import React, {useState, useEffect} from 'react';
import {isFullScreenMode} from '../utils/Utils';
import FullScreenContext from './FullScreenContext';

/**
 * The FullscreenProvider component.
 *
 * Reviewed:
 */
export default function FullscreenProvider({children}) {
   const [isFullscreen, setFullscreen] = useState();

   /**
    * Set fullscreen based on initial setting.
    */
   useEffect(() => {
      setFullscreen(isFullScreenMode());
   }, []);

   const elem = document.documentElement;

   /**
    * Request to change to fullscreen.
    */
   const requestFullscreen = async () => {
      try {
         if (elem.requestFullscreen) {
            await elem.requestFullscreen();
         } else { // noinspection JSUnresolvedVariable
            if (elem.mozRequestFullScreen) { /* Firefox */
               elem.mozRequestFullScreen();
            } else { // noinspection JSUnresolvedVariable
               if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                              elem.webkitRequestFullscreen();
                           } else { // noinspection JSUnresolvedVariable
                  if (elem.msRequestFullscreen) { /* IE/Edge */
                                                elem.msRequestFullscreen();
                                             }
               }
            }
         }
         // noinspection JSCheckFunctionSignatures
         setFullscreen(true);
      } catch (error) {
         console.log('Error requesting fullscreen', error);
      }
   };

   /**
    * Exit fullscreen.
    */
   async function exitFullscreen() {
      try {
         if (document.exitFullscreen) {
            await document.exitFullscreen();
         } else { // noinspection JSUnresolvedVariable
            if (document.mozCancelFullScreen) { /* Firefox */
                        document.mozCancelFullScreen();
                     } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                        document.webkitExitFullscreen();
                     } else { // noinspection JSUnresolvedVariable
               if (document.msExitFullscreen) { /* IE/Edge */
                                       document.msExitFullscreen();
                                    }
            }
         }
         // noinspection JSCheckFunctionSignatures
         setFullscreen(false);
      } catch (e) {
         console.log('Error exiting fullscreen', e);
      }
   }

   /**
    * Handle the changes to fullscreen.
    * @param newIsFullScreen True if changing to fullscreen.
    */
   const handleFullScreen = async (newIsFullScreen) => {
      if (newIsFullScreen) {
         await requestFullscreen();
      } else {
         await exitFullscreen();
      }
   };

   return (
      <FullScreenContext.Provider value={{setFullscreen: handleFullScreen, isFullscreen}}>
         {children}
      </FullScreenContext.Provider>
   );
}
