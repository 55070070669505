import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';

export default function PrivilegeRoute({hasPrivilege, children, ...rest}) {
   if (hasPrivilege) {
      return <Route {...rest}>{children}</Route>
   }
   return <Redirect to='/'/>
}

PrivilegeRoute.propTypes = {
   hasPrivilege: PropTypes.bool.isRequired,  //Function that returns true if the user can access the route.
};
