import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useRecoilState} from 'recoil';
import {downLoadForOfflineStatus} from '../../../index';
import useOfflineData from './useOfflineData';

OfflineData.propTypes = {
   facilities: PropTypes.array.isRequired,         //The list of facilities to fetch offline data.
   wells: PropTypes.array.isRequired,              // The list of wells to fetch offline data.
   fields: PropTypes.array.isRequired,             // The list of fields to fetch offline data.
   wasteStorageList: PropTypes.array.isRequired,   // The list of waste storage to fetch offline data.
   skip: PropTypes.bool,                           // Indicates if the offline data queries should all be skipped.
};

/**
 * Fetch all the data for offline use.
 *
 * @param facilities The list of facilities to fetch offline data.
 * @param wells The list of wells to fetch offline data.
 * @param fields The list of fields to fetch offline data.
 * @param wasteStorageList The list of waste storage to fetch offline data.
 * @param skip Indicates if the offline data queries should all be skipped.
 * @return {null} This is not a viewable component.
 * @constructor
 */
export default function OfflineData({facilities, wells, fields, wasteStorageList, skip}) {
   const {loading, complete} = useOfflineData(
      {facilities, wells, fields, wasteStorageList, skip});
   const [downloadForOffline, setDownloadForOffline] = useRecoilState(downLoadForOfflineStatus);

   useEffect(() => {
       if (complete) {
          setDownloadForOffline({...downloadForOffline, shouldDownload: true, isDownloading: false, downloadComplete: true, userNotified: false});
       }
   }, [complete]);

   useEffect(() => {
       if (loading) {
          setDownloadForOffline({...downloadForOffline, shouldDownload: true, isDownloading: true, downloadComplete: false, userNotified: false});
       }
   }, [loading]);

   return null
}
