import {AccordionDetails} from '@material-ui/core';
import {Accordion} from '@material-ui/core';
import {IconButton} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Done} from '@material-ui/icons';
import {Edit} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import gql from 'graphql-tag';
import {useEffect} from 'react';
import React, {useState} from 'react';
import {v4 as uuid} from 'uuid';
import AutocompleteFHG from '../../../components/AutocompleteFHG';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import {MAX_RATE_UPDATE} from '../../../data/QueriesGL';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useMutationFHG, {CREATE_UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import Form from '../../../fhg/components/edit/Form';
import Prompt from '../../../fhg/components/edit/Prompt';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import ProgressButton from '../../../fhg/components/ProgressButton';
import TypographyFHG from '../../../fhg/components/Typography';
import {toNumber} from '../../../fhg/utils/Utils';
import {formatMessage} from '../../../fhg/utils/Utils';
import {FIELDS_BY_FACILITY_ID_QUERY} from '../../../prototype/ClientSetup';
import {FIELD_FRAGMENT} from './FragmentsGL';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import numberFormatter from 'number-formatter';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
   facilityFrameStyle: {
      // When the facilities wrap down, unset the height so the main frame scrolls.
      paddingRight: theme.spacing(2),
      // height: 'fit-content',
      [theme.breakpoints.down('xs')]: {
         paddingRight: theme.spacing(0),
         // height: 'unset',
      },
   },
   textFieldStyle: {
      marginBottom: 14,
      fontWeight: 400,
      fontSize: 14,
      color: 'black !important',
   },
   fadeArea: {
      '&:hover $fadeIn': {
         opacity: 1,
         transition: '1s',
         transitionDelay: '0.3s',
      },
   },
   fadeIn: {
      opacity: 0,
      marginTop: 'auto',
      marginBottom: 'auto',
   },
   editStyle: {
      marginTop: 16,
   },
   primaryStyle: {
      paddingRight: theme.spacing(1),
      userSelect: 'none',
   },
   secondaryStyle: {
      cursor: 'pointer',
      textDecoration: 'underline',
   },
}));

// The client with the given client ID and property ID.
export const FIELD_COVER_QUERY = gql`
   query getFieldCover {
      fieldCover: fieldCover_All {
         id
         name
         isDeleted
         uuid
      }
   }
`;

// Update the fields with the given properties.
const FIELD_CREATE_UPDATE = {
   mutation: gql`
      mutation FieldCreateUpdate(
         $uuid: String!
         $name: String
         $acreage: Float
         $facilityId: Int!
         $fieldCover: String
         $fieldCoverId: Int
         $latitude: Float
         $longitude: Float
         $nmpMaxRate: Float
         $boundaryData: String
         $isNmpMaxRateLiquid: Boolean
      ) {
         field: field_CreateUpdate(
            field: {
               uuid: $uuid
               name: $name
               acreage: $acreage
               facilityId: $facilityId
               fieldCover: $fieldCover
               fieldCoverId: $fieldCoverId
               latitude: $latitude
               longitude: $longitude
               nmpMaxRate: $nmpMaxRate
               boundaryData: $boundaryData
               isNmpMaxRateLiquid: $isNmpMaxRateLiquid
            }
         ) {
            ...fieldInfo
         }
      }
      ${FIELD_FRAGMENT}
   `,
   actionKey: CREATE_UPDATE_ACTION,
   typeKey: 'field.type',
};

export const FIELD_DELETE = {
   mutation: gql`
      mutation FieldDelete($id: Int!) {
         field_Delete(fieldId: $id)
      }
   `,
   actionKey: DELETE_ACTION,
   typeKey: 'field.type',
};

export default function FieldEditCard({field = {}, facilityIds = [], onClose}) {
   const classes = useStyles();
   const intl = useIntl();

   const [fieldCreateUpdate] = useMutationFHG(FIELD_CREATE_UPDATE);
   const [fieldDelete] = useMutationFHG(FIELD_DELETE);

   const {data: coverData} = useQueryFHG(FIELD_COVER_QUERY, undefined, 'cover.type');

   const [editValues, handleChange, {getValue, isChanged, setIsChanged, defaultValues}] = useEditData(
      {uuid: uuid(), ...field},
      ['id', 'uuid', 'facilityId', 'latitude', 'longitude']
   );
   const [isSaving, setIsSaving] = useState(false);
   const [nmpEditIndex, setNmpEditIndex] = useState();
   const [nmpEditValue, setNmpEditValue] = useState();
   const [nmpRateUpdate, {data}] = useMutationFHG(MAX_RATE_UPDATE);

   /**
    * If the nmpRateUpdate successfully completes, the data will be updated triggering the close of the nmp max rate
    * edit.
    */
   useEffect(() => {
      if (data) {
         setNmpEditIndex(undefined);
         setNmpEditValue(undefined);
      }
   }, [data]);

   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);
            await fieldCreateUpdate(
               {variables: {...editValues}},
               false,
               (!field || !field.id) && [{query: FIELDS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}}]
            );
            setIsChanged(false);
            onClose && onClose();
         } finally {
            setIsSaving(false);
         }
      }
   };

   const handleDelete = () => {
      fieldDelete({variables: {id: field.id}}, true, [
         {query: FIELDS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}},
      ]);
      onClose && onClose();
   };

   let previousNmpRates = field?.nmpMaxRateHistory ? JSON.parse(field?.nmpMaxRateHistory) : {};

   /**
    * When a NMP Max Rate is selected for editing, save the index for edit.
    * @param index
    */
   function handleSelectNmpRate(index) {
      setNmpEditIndex(index);
   }

   /**
    * Store the change for the NMP Max Rate in the history.
    */
   const handleNmpRateChange = ({target}) => {
      setNmpEditValue(target.valueAsNumber);
   };

   /**
    * Cancel the edit for the NMP Max Rate.
    */
   const handleCancelNmpRateEdit = () => {
      setNmpEditIndex(undefined);
      setNmpEditValue(undefined);
   };

   /**
    * Submit the NMP Max Rate changes ot the history.
    */
   const handleSubmitNmpRate = () => {
      if (nmpEditValue) {
         try {
            setIsSaving(true);
            nmpRateUpdate({
               variables: {
                  fieldId: field?.id,
                  rate: nmpEditValue,
                  year: toNumber(Object.keys(previousNmpRates)[nmpEditIndex]),
               },
               refetchQueries: [{query: FIELDS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}}],
            });
         } finally {
            setIsSaving(false);
         }
      } else {
         handleCancelNmpRateEdit();
      }
   };

   /**
    * Listen for keydown to close for escape and submit for enter.
    * @param event The keydown event.
    */
   const handleKeydown = (event) => {
      if (event.key === 'Escape') {
         event.stopPropagation();
         event.preventDefault();

         handleCancelNmpRateEdit();
      } else if (event.key === 'Enter') {
         event.stopPropagation();
         event.preventDefault();
         handleSubmitNmpRate(nmpEditIndex);
      }
   };

   return (
      <TitleCardInner
         titleId={'clientField.title'}
         values={{name: defaultValues.name, hasBold: true}}
         onClose={onClose}
         headerAction={
            <ConfirmButton
               onConfirm={handleDelete}
               titleKey={'delete.confirm.title'}
               messageKey={'delete.confirm.message'}
               titleValues={{type: defaultValues.name}}
               values={{type: defaultValues.name}}
               color='secondary'
               buttonLabelKey={'delete.button'}
               disableRipple
               // className={classes.buttonStyle}
               buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
            />
         }
      >
         <Form onSubmit={handleSubmit}>
            <Prompt when={isChanged} />
            <Grid container spacing={2}>
               <Grid
                  container
                  item
                  className={classes.facilityFrameStyle}
                  fullWidth
                  spacing={2}
                  style={{height: 'fit-content'}}
               >
                  <Grid item fullWidth xs={6} sm={12} lg={8}>
                     <TextFieldFHG
                        name={'name'}
                        autoFocus
                        labelKey={'facility.fieldName.label'}
                        defaultValue={defaultValues.name}
                        value={editValues.name}
                        onChange={handleChange}
                        disabled={isSaving}
                        fullWidth
                        required
                     />
                  </Grid>
                  <Grid item fullWidth xs={6} sm={12} md={6} lg={4}>
                     <AutocompleteFHG
                        name={'fieldCoverId'}
                        labelKey={'fieldCover.label'}
                        autoSelect={false}
                        options={coverData && coverData.fieldCover}
                        defaultValue={defaultValues.fieldCoverId}
                        onChange={handleChange}
                        disableClearable
                        disabled={isSaving}
                        required
                     />
                  </Grid>
                  <Grid item fullWidth xs={6} sm={12} md={6} lg={6}>
                     <TextFieldFHG
                        name={'acreage'}
                        isFormattedNumber
                        labelKey={'facility.acreage.label'}
                        defaultValue={defaultValues.acreage}
                        value={editValues.acreage}
                        onChange={handleChange}
                        fullWidth
                        disabled={isSaving}
                        required
                     />
                  </Grid>
                  <Grid item fullWidth xs={6} sm={12} lg={6}>
                     <TextFieldFHG
                        name={'nmpMaxRate'}
                        isFormattedNumber
                        labelKey={'facility.nmpMaxRate.label'}
                        defaultValue={defaultValues.nmpMaxRate}
                        value={editValues.nmpMaxRate}
                        onChange={handleChange}
                        fullWidth
                        disabled={isSaving}
                        required
                     />
                  </Grid>
                  <Grid item xs={12} overflow={'visible'}>
                     <RadioGroup
                        key={'isNmpMaxRateLiquid' + defaultValues?.id}
                        name='isNmpMaxRateLiquid'
                        row={true}
                        inputProps={{'data-type': 'boolean'}}
                        value={getValue('isNmpMaxRateLiquid') ? 'liquid' : 'solid'}
                        onChange={(event) =>
                           handleChange({target: {name: event.target.name, value: event.target.value === 'liquid'}})
                        }
                     >
                        <FormControlLabel value={'liquid'} control={<Radio />} label='Liquid' />
                        <FormControlLabel value={'solid'} control={<Radio />} label='Solid' />
                     </RadioGroup>
                  </Grid>
                  <Grid item>
                     <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                           <TypographyFHG>Previous NMP Rates</TypographyFHG>
                        </AccordionSummary>
                        <AccordionDetails>
                           <List dense>
                              {Object.keys(previousNmpRates)?.map((year, index) => (
                                 <ListItem className={classes.fadeArea}>
                                    {nmpEditIndex !== index ? (
                                       <Grid key={'nmpRateHistory' + index} container spacing={2}>
                                          <Grid item>
                                             <ListItemText
                                                primary={year}
                                                classes={{
                                                   primary: classes.primaryStyle,
                                                   secondary: classes.secondaryStyle,
                                                }}
                                                primaryTypographyProps={{variant: 'h6', display: 'inline'}}
                                                secondary={numberFormatter('#,###,###,##0.##', previousNmpRates[year])}
                                                secondaryTypographyProps={{
                                                   variant: 'body1',
                                                   display: 'inline',
                                                   onClick: () => setNmpEditIndex(index),
                                                }}
                                                onDoubleClick={() => setNmpEditIndex(index)}
                                             />
                                          </Grid>
                                          <Grid item className={classes.fadeIn}>
                                             <IconButton
                                                onClick={() => handleSelectNmpRate(index)}
                                                size='small'
                                                color='primary'
                                             >
                                                <Edit fontSize={'small'} />
                                             </IconButton>
                                          </Grid>
                                       </Grid>
                                    ) : (
                                       <Grid
                                          key={'nmpRateHistoryEdit' + index}
                                          container
                                          spacing={2}
                                          alignItems={'center'}
                                       >
                                          <Grid item>
                                             <TextFieldFHG
                                                name={'nmpMaxRate'}
                                                isFormattedNumber
                                                label={formatMessage(
                                                   intl,
                                                   'facility.nmpMaxRateHistory.label',
                                                   undefined,
                                                   {year}
                                                )}
                                                defaultValue={previousNmpRates[year]}
                                                value={nmpEditValue}
                                                onChange={handleNmpRateChange}
                                                fullWidth
                                                disabled={isSaving}
                                                autoFocus
                                                onKeyDown={handleKeydown}
                                             />
                                          </Grid>
                                          <Grid item className={classes.editStyle}>
                                             <IconButton
                                                onClick={() => handleSubmitNmpRate(index)}
                                                size='small'
                                                color='primary'
                                             >
                                                <Done fontSize={'small'} />
                                             </IconButton>
                                          </Grid>
                                          <Grid item className={classes.editStyle}>
                                             <IconButton onClick={handleCancelNmpRateEdit} size='small' color='primary'>
                                                <CloseIcon fontSize={'small'} />
                                             </IconButton>
                                          </Grid>
                                       </Grid>
                                    )}
                                 </ListItem>
                              ))}
                           </List>
                        </AccordionDetails>
                     </Accordion>
                  </Grid>
               </Grid>
               <Grid item fullWidth>
                  <ProgressButton
                     isProgress={isSaving}
                     variant='contained'
                     color='primary'
                     type={'submit'}
                     size={'small'}
                     labelKey={'save.label'}
                     disabled={isSaving}
                  />
               </Grid>
            </Grid>
         </Form>
      </TitleCardInner>
   );
}
