import React from 'react';

/**
 * The component to be a target to validate. The title is used for the error messge and value is what is validated.
 *
 * Reviewed: 8/10/2020
 */
export default function ValidateTarget({name, top, value, title, left='50%', width=1}) {

   return (
      <input
         aria-invalid='false'
         id={name}
         title={title}
         onChange={() => {}}
         name='confirm'
         type='text'
         required
         value={value}
         style={{
            width,
            marginTop: -1,
            height: 1,
            padding: 0,
            position: 'relative',
            display: 'inherit',
            border: 'none',
            left,
            top,
            zIndex: -1,
            outline: 'unset',
         }}
      />
   );
}
