// Query for files in the given date range.
import gql from 'graphql-tag';
import {CREATE_UPDATE_ACTION} from '../fhg/components/data/useMutationFHG';
import {DELETE_ACTION} from '../fhg/components/data/useMutationFHG';
import {UPDATE_ACTION} from '../fhg/components/data/useMutationFHG';
import {CREATE_ACTION} from '../fhg/components/data/useMutationFHG';
import {FIELD_FULL_FRAGMENT} from '../pages/admin/client/FragmentsGL';
import {CLIENT_FRAGMENT, USER_CLIENT_FRAGMENT, USER_FRAGMENT} from '../pages/admin/client/FragmentsGL';
import {METER_READING_FRAGMENT} from '../pages/admin/client/FragmentsGL';
import {FIELDS_BY_FACILITY_ID_QUERY} from '../prototype/ClientSetup';

export const FILE_QUERY = gql`
   query getFiles($clientId: [Int]) {
      files: fileUpload_AllWhere(fileUploadSearch: {clientId: $clientId}) {
         id
         clientId
         createdDateTime
         fileData {
            fileFilename
            fileUpdateDateTime
            fileS3
            uuid: fileHash
         }
      }
   }
`;

// Query for all the waste exports for the facility.
export const METER_READING_BY_RANGE_QUERY = gql`
   query getMeterReadingByRange($wellId: [Int], $beginDate: String, $endDate: String) {
      meterReading: meterReading_AllWhere(
         meterReadingSearch: {wellId: $wellId, beginDate: $beginDate, endDate: $endDate}
      ) {
         ...meterReadingInfo
      }
   }
   ${METER_READING_FRAGMENT}
`;
// Query for all the waste exports for the facility.
export const LAST_METER_READING_BY_RANGE_QUERY = gql`
   query getLastMeterReadingByRange($wellId: [Int], $meterId: [Int], $beginDate: String, $endDate: String) {
      meterReading: meterReading_AllWhere(
         meterReadingSearch: {wellId: $wellId, meterId: $meterId, beginDate: $beginDate, endDate: $endDate}
      ) {
         ...meterReadingInfo
      }
   }
   ${METER_READING_FRAGMENT}
`;

// Query for all the pond Level records for the waste storage.
export const WELLS_FOR_FACILITY_QUERY = gql`
   query getWellsForFacility($facilityId: [Int]) {
      wells: well_AllWhere(wellSearch: {facilityId: $facilityId}) {
         id
         commonName
      }
   }
`;

// Query for the list of waste storage to select from.
export const WASTE_STORAGE_OPTIONS_QUERY = gql`
   query getWasteStorageOptions($facilityId: [Int]) {
      wasteStorageList: wasteStorage_AllWhere(wasteStorageSearch: {facilityId: $facilityId}) {
         id
         uuid
         name
         facilityId
         isDeleted
      }
   }
`;

// The client with the given client ID and property ID.
export const CLIENT_BY_ID_QUERY = gql`
   query getClientByIdForClientEdit($clientId: Int!) {
      client: client_ById(clientId: $clientId) {
         ...clientInfo
      }
   }
   ${CLIENT_FRAGMENT}
`;

// All the users for the dropdown.
export const USER_ALL_QUERY = gql`
   query getUsersAllAdmin {
      users: user_AllWhere(userSearch: {clientId: null}) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

// The user with the given user uuid.
export const USER_BY_UUID_QUERY = gql`
   query getUsersByUuid($uuid: String!) {
      user: user_ByUuid(userUuid: $uuid) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

export const ANNUAL_REPORT_FACILITY = gql`
   query getAnnualReportByFacility($facilityId: Int, $startYear: Int, $endYear: Int) {
      annualReport: annualReport_ByFacility(facilityId: $facilityId, startYear: $startYear, endYear: $endYear) {
         id
         facilityName
         fields {
            id
            name
            acreage
            isNmpMaxRateLiquid
            nmpMaxRate

            applicationMethods {
               id
               applicationMethodId
               name
               isSolid
               wasteTypeId
               currentYearBeforeHarvestSolids
               currentYearBeforeHarvestLiquids
               currentYearAfterHarvestLiquids
               currentYearAfterHarvestSolids
               previousYearAfterHarvestSolids
               previousYearAfterHarvestLiquids
            }
         }
         livestock {
            id
            max
            typeName
         }
         livestockWaste {
            id
            manureLitterGenerated
            wasteWaterGenerated
         }
         livestockWasteExported {
            id
            compostExported
            litterExported
            manureExported
            transferOccurred
            wasteWaterExported
         }
         livestockWasteLandApplied {
            id
            applied
            facilityAcres
            facilityAppliedAcres
            liquidsApplied
            planApproved
            solidsApplied
         }
         wasteApplications {
            id
            amount
            applicationMethod {
               id
               name
            }
            applicationMethodId
            createdByUserId
            createdDateTime
            date
            endDate
            fieldCover {
               id
               name
            }
            fieldCoverId
            fieldId
            isAfterHarvest
            isDeleted
            note
            soilCondition {
               id
               abbreviation
               name
            }
            soilConditionId
            startDate
            uuid
            wasteSource {
               id
               name
            }
            wasteSourceId
            wasteStorage {
               id
               name
               note
               facilityId
            }
            wasteStorageId
            wasteType {
               id
               name
            }
            wasteTypeId
         }
      }
   }
`;
// The user with the given user ID.
export const USER_BY_ID_QUERY = gql`
   query getUsersById($id: Int!) {
      user: user_ById(userId: $id) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

// Create a user with the given properties.
export const USER_CREATE = {
   mutation: gql`
      mutation UserCreate(
         $managerName: String
         $addressLineOne: String
         $addressLineTwo: String
         $city: String
         $cityId: Int
         $stateId: Int
         $zipCode: Int
         $contactName: String
         $phonePrimary: String
         $phoneSecondary: String
         $email: String!
         $username: String!
         $password: String!
         $isAdmin: Boolean
      ) {
         user: user_Create(
            user: {
               managerName: $managerName
               addressLineOne: $addressLineOne
               addressLineTwo: $addressLineTwo
               email: $email
               city: $city
               cityId: $cityId
               stateId: $stateId
               zipCode: $zipCode
               contactName: $contactName
               phonePrimary: $phonePrimary
               phoneSecondary: $phoneSecondary
               username: $username
               password: $password
               isAdmin: $isAdmin
            }
         ) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   updateQueries: [{query: USER_ALL_QUERY}],
   typeKey: 'user.type',
   actionKey: CREATE_ACTION,
};

// Update a user with the given properties.
export const USER_UPDATE = {
   mutation: gql`
      mutation UserUpdate(
         $id: Int!
         $managerName: String
         $addressLineOne: String
         $addressLineTwo: String
         $city: String
         $cityId: Int
         $stateId: Int
         $zipCode: Int
         $contactName: String
         $phonePrimary: String
         $phoneSecondary: String
         $email: String
         $username: String
         $password: String
         $isAdmin: Boolean
      ) {
         user: user_Update(
            userId: $id
            user: {
               managerName: $managerName
               addressLineOne: $addressLineOne
               addressLineTwo: $addressLineTwo
               email: $email
               city: $city
               cityId: $cityId
               stateId: $stateId
               zipCode: $zipCode
               contactName: $contactName
               phonePrimary: $phonePrimary
               phoneSecondary: $phoneSecondary
               username: $username
               password: $password
               isAdmin: $isAdmin
            }
         ) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   typeKey: 'user.type',
   actionKey: UPDATE_ACTION,
};

export const USER_DELETE = {
   mutation: gql`
      mutation UserDelete($id: Int!) {
         user_Delete(userId: $id)
      }
   `,
   updateQueries: [{query: USER_ALL_QUERY}],
   typeKey: 'user.type',
   actionKey: DELETE_ACTION,
};

// Create a user with the given properties.
export const USER_CREATE_CLIENT = {
   mutation: gql`
      mutation UserCreate(
         $clientId: Int
         $username: String!
         $password: String!
         $email: String!
         $facilityAccess: Boolean
         $fieldAccess: Boolean
         $wellAccess: Boolean
         $wasteStorageAccess: Boolean
         $isAdmin: Boolean
         $facilityIdList: [Int]
      ) {
         user: user_Create(
            user: {
               clientId: $clientId
               username: $username
               password: $password
               email: $email
               facilityAccess: $facilityAccess
               fieldAccess: $fieldAccess
               wellAccess: $wellAccess
               wasteStorageAccess: $wasteStorageAccess
               isAdmin: $isAdmin
               facilityIdList: $facilityIdList
            }
         ) {
            ...userClientInfo
         }
      }
      ${USER_CLIENT_FRAGMENT}
   `,
   typeKey: 'user.type',
   actionKey: CREATE_ACTION,
};

// All the users for the client for the dropdown.
export const USER_FOR_CLIENT_QUERY = gql`
   query getUsersForClient($clientId: Int!, $isAdmin: Boolean) {
      users: user_AllWhere(userSearch: {clientId: [$clientId], isAdmin: [$isAdmin]}) {
         ...userClientInfo
      }
      client: client_ById(clientId: $clientId) {
         id
         name
      }
   }
   ${USER_CLIENT_FRAGMENT}
`;

// Update a user with the given properties.
export const USER_UPDATE_CLIENT = {
   mutation: gql`
      mutation UserUpdate(
         $id: Int!
         $password: String
         $email: String!
         $facilityAccess: Boolean
         $fieldAccess: Boolean
         $wellAccess: Boolean
         $wasteStorageAccess: Boolean
         $isAdmin: Boolean
         $facilityIdList: [Int]
      ) {
         user: user_Update(
            userId: $id
            user: {
               password: $password
               email: $email
               facilityAccess: $facilityAccess
               fieldAccess: $fieldAccess
               wellAccess: $wellAccess
               wasteStorageAccess: $wasteStorageAccess
               isAdmin: $isAdmin
               facilityIdList: $facilityIdList
            }
         ) {
            ...userClientInfo
         }
      }
      ${USER_CLIENT_FRAGMENT}
   `,
   typeKey: 'user.type',
   actionKey: UPDATE_ACTION,
};

// Update a client with the given properties.
export const CLIENT_UPDATE = {
   mutation: gql`
      mutation ClientUpdate(
         $clientId: Int!
         $name: String
         $addressLineOne: String
         $addressLineTwo: String
         $city: String
         $cityId: Int
         $stateId: Int
         $zipCode: Int
         $contactName: String
         $phone: String
         $email: String
         $isManaged: Boolean
         $maximumUsers: Int
         $adminId: Int
         $eulaAcknowleged: Boolean
      ) {
         client: client_Update(
            clientId: $clientId
            client: {
               name: $name
               addressLineOne: $addressLineOne
               addressLineTwo: $addressLineTwo
               email: $email
               cityId: $cityId
               city: $city
               stateId: $stateId
               zipCode: $zipCode
               contactName: $contactName
               phone: $phone
               isManaged: $isManaged
               maximumUsers: $maximumUsers
               adminId: $adminId
               eulaAcknowleged: $eulaAcknowleged
            }
         ) {
            ...clientInfo
         }
      }
      ${CLIENT_FRAGMENT}
   `,
   typeKey: 'client.type',
   actionKey: UPDATE_ACTION,
};

// Update an NMP Max Rate.
export const MAX_RATE_UPDATE = {
   mutation: gql`
      mutation FieldUpdateMaxRate($fieldId: Int!, $rate: Float, $year: Int!) {
         field: field_UpdateMaxRateHistory(fieldId: $fieldId, rate: $rate, year: $year) {
            ...fieldFullInfo
         }
      }
      ${FIELD_FULL_FRAGMENT}
   `,
   typeKey: 'field.type',
   actionKey: UPDATE_ACTION,
};

export const maxRateRefetchQueries = () => {
   return [{query: FIELDS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}}];
};
