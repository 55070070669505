import 'leaflet-draw/dist/leaflet.draw.css';
import React, {Fragment, forwardRef} from 'react';
import {Popup, Tooltip, GeoJSON} from 'react-leaflet';
import {FIELD_GEO_JSON_COLOR} from '../../../Constants';

/**
 * The component to show a GeoJSON with color, tooltips, and popups.
 *
 * Reviewed: 8/21/2020
 *
 * @param name The name of the component.
 * @param color The color for the GeoJSON
 * @param content The content for the tooltips and popups.
 * @param onPopupClose Callback when the popup is closed.
 * @param onPopupOpen Callback when the popup is opened.
 * @param geoShapeProps The other props passed to the GeoJSON.
 * @return {JSX.Element}
 * @constructor
 */

const GeoJsonFHG = forwardRef(
function GeoJsonFHG({shape, name, color = FIELD_GEO_JSON_COLOR, content, onPopupClose, onPopupOpen, ...geoShapeProps}, ref) {

   const handlePopupClose = (event) => {
      onPopupClose && onPopupClose(geoShapeProps.uuid);
   };

   const handlePopupOpen = (event) => {
      onPopupOpen && onPopupOpen(geoShapeProps.uuid, true)
   };

   return (
      <GeoJSON key={`coloredGeo${name}`} style={{color}} {...geoShapeProps}>
         {content && <>
            <Tooltip key={`tooltip ${name}`}>
               {content.map((item, index) => (
                  <Fragment key={'tooltip internal' + index}>
                     <b>{item.label}</b>&nbsp;
                     {item.value}
                     <br/>
                  </Fragment>
               ))}
            </Tooltip>
            {(shape && shape.showPopup !== false) && (
               <Popup
                  key={`popup ${name}`}
                  onClose={handlePopupClose}
                  onOpen={handlePopupOpen}
               >
                  {content.map((item, index) => (
                     <Fragment key={'popup internal' + index}>
                        <b>{item.label}</b>&nbsp;
                        {item.value}
                        <br/>
                     </Fragment>
                  ))}
               </Popup>
            )}
         </>}
      </GeoJSON>
   );
});

export default GeoJsonFHG;

