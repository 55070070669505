import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import React, {forwardRef, useState} from 'react';
import Form from '../fhg/components/edit/Form';
import Grid from '../fhg/components/Grid';
import ProgressButton from '../fhg/components/ProgressButton';
import Typography from '../fhg/components/Typography';

const useStyles = makeStyles(
   (theme) => ({
      rootStyle: {
         // maxHeight: 'fit-content !important',
         padding: theme.spacing(0, 0),
      },
      actionGridStyle: {
         marginTop: theme.spacing(1),
         marginBottom: theme.spacing(1),
         padding: theme.spacing(0, 2),
         position: 'sticky',
         bottom: 0,
      },
      formStyle: {
         overflow: 'hidden',
         display: 'flex',
         flexDirection: 'column',
         width: '100%',
         maxHeight: '100%',
         position: 'relative',
      },
   }),
   {name: 'formCardStyles'}
);

/**
 * A Card that contains an input form. Contains the submit, optional cancel, and progress indication.
 *
 * Reviewed: 3/26/20
 */
const FormCard = forwardRef(function FormCard(
   {
      onSubmit,
      onCancel,
      disabled,
      isScrollable = false,
      noGrid = true,
      children,
      buttonLocation = 'left',
      submitKey,
      customActions,
      secondLevelActions,
      customValidity,
      validate,
      buttonSize,
      classes: classesProp,
   },
   ref
) {
   const classes = {...useStyles(), ...classesProp};

   const [isSaving, setIsSaving] = useState(false);

   /**
    * Handle the submit for the form. Stops propagation and prevents the default for the event so it doesn't add to the
    * URL.
    *
    * @param event The submit event.
    * @return {Promise<void>}
    */
   const handleSubmit = async (event) => {
      event && event.preventDefault();
      event && event.stopPropagation();
      try {
         setIsSaving(true);
         onSubmit && (await onSubmit(event));
      } catch (e) {
         console.log(e);
      } finally {
         setIsSaving(false);
      }
   };

   /**
    * Handle the cancel of the form.
    *
    * @param event The cancel event.
    */
   const handleCancelClick = (event) => {
      event && event.stopPropagation();
      event && event.preventDefault();

      onCancel && onCancel(event);
   };

   return (
      <Form
         ref={ref}
         onSubmit={handleSubmit}
         className={classes.formStyle}
         customValidity={customValidity}
         validate={validate}
      >
         {noGrid ? (
            children
         ) : (
            <Grid
               name={'FormCard-root notFHG'}
               item
               container
               className={classes.rootStyle}
               direction={'column'}
               resizable
               fullHeight
               isScrollable
               wrap={'nowrap'}
            >
               {/*<Grid name={'FormCard-content'} item container direction={'column'} resizable fullHeight wrap={'nowrap'} overflow={isScrollable ? 'auto' : 'hidden'}*/}
               {/*      style={{padding: 12, maxHeight: 'fit-content !important'}}>*/}
               {children}
               {/*</Grid>*/}
            </Grid>
         )}
         <Grid
            name={'FormCard-actions'}
            container
            item
            className={classes.actionGridStyle}
            resizable={false}
            fullWidth
            direction={buttonLocation === 'right' ? 'row-reverse' : 'row'}
            spacing={2}
         >
            {customActions &&
               customActions.length > 0 &&
               filter(customActions, {order: 'last'}).map((action) => (
                  <Grid
                     item
                     key={'formCard' + action.labelKey}
                     style={{display: action.order === 'last' ? undefined : 'none'}}
                  >
                     {action.component ? (
                        action.component
                     ) : (
                        <Button
                           color={action.color}
                           variant={action.variant}
                           onClick={action.onAction}
                           disabled={disabled}
                        >
                           <Typography color='inherit' variant={'subtitle1'} id={action.labelKey} />
                        </Button>
                     )}
                  </Grid>
               ))}
            {onSubmit && (
               <Grid item>
                  <ProgressButton
                     isProgress={isSaving}
                     variant='contained'
                     color='primary'
                     size={buttonSize}
                     type={'submit'}
                     disabled={disabled}
                     labelKey={submitKey || 'save.label'}
                  />
               </Grid>
            )}
            {customActions &&
               customActions.length > 0 &&
               filter(customActions, (action) => action.order !== 'last').map((action) => (
                  <Grid item key={'formCard' + action.labelKey}>
                     {action.component ? (
                        action.component
                     ) : (
                        <Button
                           name={action.name}
                           color={action.color}
                           variant={action.variant}
                           onClick={action.onAction}
                           disabled={disabled}
                        >
                           <Typography color='inherit' variant={'subtitle1'} id={action.labelKey} />
                        </Button>
                     )}
                  </Grid>
               ))}
            {onCancel && (
               <Grid item>
                  <Button color='default' onClick={handleCancelClick} disabled={disabled}>
                     <Typography variant={'subtitle1'} id={'cancel.button'} />
                  </Button>
               </Grid>
            )}
            {secondLevelActions &&
               secondLevelActions.length > 0 &&
               filter(secondLevelActions, (action) => action.order !== 'last').map((action) => (
                  <Grid item resizable key={'formCard' + action.labelKey}>
                     <Button
                        name={action.name}
                        color={action.color}
                        variant={action.variant}
                        onClick={action.onAction}
                        disabled={disabled}
                     >
                        <Typography color='inherit' variant={'subtitle1'} id={action.labelKey} />
                     </Button>
                  </Grid>
               ))}
         </Grid>
      </Form>
   );
});

FormCard.propTypes = {
   onSubmit: PropTypes.func.isRequired, // The callback when the form is submitted.
   onCancel: PropTypes.func, // The callback when the form is canceled. If set the the cancel button is shown.
   disabled: PropTypes.bool, // If true, disables the buttons.
   children: PropTypes.any, //Children for the component.
};

export default FormCard;
