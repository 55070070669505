import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import TextField from '../../../components/TextField';

const useStyles = makeStyles({
   textFieldStyle: {
      '& label': {
         zIndex: 0,
      },
   },
}, {name: 'DateTextFieldStyles'});

/**
 * The TextField with preset formats.
 */
export default function DateTextFieldFHG({value, ...textFieldProps}) {
   const classes = useStyles();
   return (
      <TextField
         key={'DateText'+value}
         classses={classes}
         {...textFieldProps}
         value={value}
         // className={`${classes.textFieldStyle} ${className}`}
      />
   );
}

