import IconButton from '@material-ui/core/IconButton';
import {isWidthDown} from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import ModalDialog from '../fhg/components/dialog/ModalDialog';
import Grid from '../fhg/components/Grid';
import Typography from '../fhg/components/Typography';
import useKeyDown from '../fhg/components/useKeyDown';

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction='left' ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
   root: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
         padding: theme.spacing(1),
      },
      // minHeight: 300,
      // maxHeight: '100%',
   },
   titleStyle: {
      width: '100%',
      textTransform: 'capitalize',
   },
   subtitleStyle: {
      width: '100%',
      color: '#707070',
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
   },
   titleGridStyle: {
      // marginBottom: theme.spacing(2),
   },
   actionGridStyle: {
      marginTop: theme.spacing(3),
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
   },
   paperStyle: {
      margin: theme.spacing(2, 'auto'),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      [theme.breakpoints.down('sm')]: {
         margin: theme.spacing(1, 'auto'),
         width: `calc(100% - ${theme.spacing(2)}px)`,
      },
      maxWidth: 1400,
      display: 'flex',
      overflow: 'hidden',
      // height: 'calc(100% - 8px)',
      maxHeight: 'fit-content',
   },
}), {name: 'titleCardInnerStyles'});

/**
 * The component used as a placeholder for not implemented components.
 */
export default function TitleCardInner({
   classes: outsideClasses = {}, title, titleId, titleUrl, titleIcon, subtitle, subtitleId,
   values, subtitleValues, align, variant = 'h5', children, previous, next, style, onClose, smallDialog = false,
   titleGutterBottom = true, headerAction, color='textPrimary',
}) {
   const classes = useStyles();
   const isSmallDialog = smallDialog && isWidthDown('xs');

   useKeyDown(onClose);

   const LinkComponent = titleUrl ? Link : Fragment;
   const toProp = titleUrl ? {to: titleUrl} : {};

   const contents = (
      <Grid name={'TitleCard-root'} container item className={`${classes.root} ${outsideClasses.root}`} fullWidth direction={'column'}
            wrap={'nowrap'}>
         {!isSmallDialog && (
            <Grid name={'TitleCard-title'} item className={classes.titleGridStyle} resizable={false} fullWidth>
               <Grid container item direction={'row'} justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
                  <Grid container item fullWidth={false} alignItems={'center'} spacing={1} resizable>
                     <Grid container item fullWidth={false}>
                        {titleIcon && (
                           <Grid item>
                              {titleIcon}
                           </Grid>
                        )}
                        <Grid item>
                           <LinkComponent {...toProp}>
                              <Typography id={titleId} values={values} className={classes.titleStyle} variant={variant}
                                          gutterBottom={titleGutterBottom} color={color}
                              >
                                 {title}
                              </Typography>
                           </LinkComponent>
                        </Grid>
                     </Grid>
                     {headerAction && (
                        <Grid item>
                           {headerAction}
                        </Grid>
                     )}
                  </Grid>
                  <Grid item style={{display: onClose ? undefined : 'none', alignSelf: 'flex-start'}} resizable={false}>
                     <IconButton onClick={onClose}>
                        <Close/>
                     </IconButton>
                  </Grid>
               </Grid>
               {(subtitleId || subtitle) && (
                  <Typography id={subtitleId} className={`${classes.subtitleStyle} ${outsideClasses.subtitleStyle}`} variant={'subtitle1'} noWrap
                              align={align} values={subtitleValues}>
                     {subtitle}
                  </Typography>
               )}
            </Grid>
         )}
         {/*<Grid name={'TitleCard-children'} container item fullWidth>*/}
         {children}
         {/*</Grid>*/}
         {(previous || next) && (
            <Grid name={'TitleCard-actions'} container item className={classes.actionGridStyle} resizable={false}
                  fullWidth direction={'row'} justify={'space-between'}>
               {previous && (
                  <Button className={classes.buttonStyle} style={{visibility: !!previous ? 'visible' : 'hidden'}}
                          variant='contained' color='primary' component={Link} to={previous}>
                     <KeyboardArrowLeft/>
                     <Typography id={'previous.button'} variant={'subtitle1'}/>
                  </Button>
               )}
               {next && (
                  <Button className={classes.buttonStyle} variant='contained' color='primary' type={'submit'}>
                     <Typography id={'next.button'} variant={'subtitle1'}/>
                     <KeyboardArrowRight/>
                  </Button>
               )}
            </Grid>
         )}
      </Grid>
   );
   if (isSmallDialog) {
      return (
         <ModalDialog
            titleKey={titleId}
            title={title}
            cancelKey={'close.button'}
            onClose={onClose}
            maxWidth={'lg'}
            fullWidth
            fullHeight
            fullScreen
            TransitionComponent={Transition}
         >
            {contents}
         </ModalDialog>
      )
   }
   return (
      <Paper square className={`${classes.paperStyle} ${outsideClasses.paperStyle}`} style={style}>
         {contents}
      </Paper>
   );
}

TitleCardInner.propTypes = {
   title: PropTypes.string,                  //The title to display for the card.
   titleId: PropTypes.string,                //The titleId for the title to display for the card.
   subtitle: PropTypes.string,               //The subtitle to display for the card.
   subtitleId: PropTypes.string,             //The subtitleId for the subtitle to display for the card.
   children: PropTypes.any,                  //Children for the component.
   next: PropTypes.string,                   //The path for the next card.
   previous: PropTypes.string,               //The path for the previous card.
};
