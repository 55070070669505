import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {formatMessage} from '../utils/Utils';

/**
 * The component used as a placeholder for not implemented components.
 *
 * Reviewed: 3/26/20
 */
export default function PageTitle({appTitleKey='application.title', title, titleKey, values, children}) {
   const intl = useIntl();

   useEffect(() => {
       const appTitle = appTitleKey ? formatMessage(intl, appTitleKey) : '';
       const titleLabel = titleKey ? formatMessage(intl, titleKey, title, values) : title || '';
       document.title = appTitle ? `${appTitle} - ${titleLabel}` : titleLabel;
   }, [appTitleKey, title, titleKey, values, intl]);

   return children ? React.Children.only(children) : null
}

PageTitle.propTypes = {
   appTitleKey: PropTypes.string,
   title: PropTypes.string,
   titleKey: PropTypes.string,
   values: PropTypes.any,
   children: PropTypes.any,
}

PageTitle.defaultTypes = {
   title: '',
}