import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Grid from '../fhg/components/Grid';
import PageTitle from '../fhg/components/PageTitle';
import Typography from '../fhg/components/Typography';

const useStyles = makeStyles(theme => ({
   root: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
         padding: theme.spacing(1),
      }
   },
   titleStyle: {
      width: '100%',
   },
   subtitleStyle: {
      width: '100%',
      color: '#707070',
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
   },
   titleGridStyle: {
      marginBottom: theme.spacing(3),
   },
   actionGridStyle: {
      marginTop: theme.spacing(3),
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
   },
   paperStyle: {
      margin: theme.spacing(2, 'auto'),
      // marginBottom: theme.spacing(2),
      // marginLeft: 'auto',
      // marginRight: 'auto',
      // padding: theme.spacing(1),
      maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
      width: `calc(100% - ${theme.spacing(4)}px)`,
      [theme.breakpoints.down('sm')]: {
         margin: theme.spacing(1, 'auto'),
         width: `calc(100% - ${theme.spacing(2)}px)`,
         maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
      },
      maxWidth: 1000,
      display: 'flex',
      overflow: 'hidden',
   },
}), {name: 'titleCardWebStyles'});

/**
 * The component used as a placeholder for not implemented components.
 */
export default function TitleCardWeb({title, titleId, subtitle, subtitleId, values, children, actions, previous, next, noGrid=false, classes:classesProp}) {
   const classes = {...useStyles(), ...classesProp};
   const theme = useTheme();

   return (
      <PageTitle titleKey={titleId} title={title} values={values}>
         <Paper square className={classes.paperStyle}>
            <Grid name={'TitleCardWeb-root'} container item className={classes.root} fullWidth direction={'column'}
                  wrap={'nowrap'}>
               <Grid name={'TitleCardWeb-title2'} item container className={classes.titleGridStyle} direction={'row'}
                     justify={'space-between'} resizable={false}
               >
                  <Grid name={'TitleCardWeb-title'} item resizable={false} fullWidth={false} style={{maxWidth: '100%'}}>
                     <Typography id={titleId} values={values} className={classes.titleStyle} variant={'h5'} >
                        {title}
                     </Typography>
                     {subtitle && (
                        <Typography id={subtitleId} className={classes.subtitleStyle} variant={'subtitle1'} noWrap
                                    align={'center'}>
                           {subtitle}
                        </Typography>
                     )}
                  </Grid>
                  {(actions && actions.length > 0) && (
                     <Grid container resizable={false} fullWidth={false} spacing={1}>
                        {actions.map((action, index) => (
                           <Fragment key={index}>
                              <Grid item key={index}>
                                 {action}
                              </Grid>
                              {((index + 1) < actions.length) && (
                                 <Grid item>
                                    <Divider orientation={'vertical'} style={{color: theme.palette.primary.main}}/>
                                 </Grid>
                              )}
                           </Fragment>
                        ))}
                     </Grid>
                  )}
               </Grid>
               {noGrid ? children : (
                  <Grid name={'TitleCardWeb-children'} container item resizable fullWidth>
                     {children}
                  </Grid>
               )}
               {(previous || next) && (
                  <Grid name={'TitleCardWeb-actions'} container item className={classes.actionGridStyle} resizable={false}
                        fullWidth direction={'row'} justify={'space-between'}>
                     {previous && (
                        <Button className={classes.buttonStyle} style={{visibility: !!previous ? 'visible' : 'hidden'}}
                                variant='contained' color='primary' component={Link} to={previous}>
                           <KeyboardArrowLeft/>
                           <Typography id={'previous.button'} variant={'subtitle1'}/>
                        </Button>
                     )}
                     {next && (
                        <Button className={classes.buttonStyle} variant='contained' color='primary' type={'submit'}>
                           <Typography id={'next.button'} variant={'subtitle1'}/>
                           <KeyboardArrowRight/>
                        </Button>
                     )}
                  </Grid>
               )}
            </Grid>
         </Paper>
      </PageTitle>
   );
}

TitleCardWeb.propTypes = {
   title: PropTypes.string,                  //The title to display for the card.
   titleId: PropTypes.string.isRequired,     //The titleId for the title to display for the card.
   subtitle: PropTypes.string,               //The subtitle to display for the card.
   subtitleId: PropTypes.string,             //The subtitleId for the subtitle to display for the card.
   children: PropTypes.any,                  //Children for the component.
   next: PropTypes.string,                   //The path for the next card.
   previous: PropTypes.string,               //The path for the previous card.
};
