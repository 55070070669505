import {useMutation} from '@apollo/react-hooks';
import {useContext, useEffect, useState} from 'react';
import StatusContext from '../StatusContext';

// export const UPDATE_ACTION = 'update';
// export const CREATE_UPDATE_ACTION = 'createUpdate';
// export const CREATE_ACTION = 'create';
// export const DELETE_ACTION = 'delete';
// export const UNDELETE_ACTION = 'undelete';

/**
 * Hook for useMutation that updates the cache for add and delete queries. Update mutations should automatically update
 * cache without this update.
 *
 * NOTE:
 * 1) Assumes that the result of the mutation only has a single property. (e.g. {data: {operators: {...}}})
 * 2) Updates ONLY the FIRST property in an updateQuery. The first property is assumed to be a list and adds the result
 *    property to the list. Other properties in the original query are copied to the updated cache item.
 *
 * Reviewed: 3/26/20
 *
 * @param mutation The graphql mutation.
 * @param options The options for the mutation.
 * @param updateQueries - Queries that need to be updated in the cache to be in sync with the server.
 *          Example: {query: ITEM_CREATE, variables: {id: 1}}
 *    typeKey - The localization key for the type of the object
 *    errorKey - The localization key for the error message.
 *    actionType - The localization key for the action type (e.g. create, update, delete).
 * @return
 */
export default function useMutationFHG2(mutation, options, updateQueries) {

   const {setError, setProgress, setErrorGeneral} = useContext(StatusContext);
   const [lastMessage, setLastMessage] = useState('');

   const useOptions = {typeKey: mutation.typeKey || 'Unknown', actionKey: mutation.actionKey};

   useEffect(() => {
      return () => {
         setProgress(false);
      }
   }, []);

   const [mutationFunction, {loading, error, data}] = useMutation(mutation.mutation, options);

   useEffect(() => {
      if (error) {
         if (error.message !== lastMessage) {
            console.log(error, error.stackTrace);
            setLastMessage(error.message);
            if (useOptions.typeKey) {
               setErrorGeneral({...useOptions, error});
            } else {
               setError(useOptions.errorKey, undefined, error);
            }
         }
      } else if (lastMessage !== undefined){
         setLastMessage(undefined);
      }
      // eslint-disable-next-line
   }, [setError, setErrorGeneral, error, useOptions, lastMessage]);

   useEffect(() => {
      if (useOptions.showLoading || useOptions.showLoading === undefined) {
         setProgress(loading)
      }
   }, [setProgress, useOptions.showLoading, loading]);

   return [mutationFunction, {loading, error, data}];
}
