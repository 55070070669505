import Amplify, {Hub} from '@aws-amplify/core';
import {I18n} from 'aws-amplify';
import {Authenticator, SignIn, VerifyContact, ForgotPassword} from 'aws-amplify-react';
import React, {Component} from 'react';
import {BUCKET_NAME} from '../Constants';
import withRoot from '../withRoot';
import Main from './Main';

const authScreenLabels = {
   en: {
      // 'Sign Up': 'Create new account',
      // 'Sign Up Account': 'Create a new account',
      'Sign in to your account': 'KLA - Sign in to your account',
      // 'No account? ': 'Don\'t have an account? ',
      // 'Create account': 'Sign Up!',
      'Account recovery requires verified contact information': 'Verify Email (click email and verify)',
      // 'Username': ' ',
      // 'Password': ' ',
      // 'Enter your password': 'Password',
      // 'Enter your username': 'Username',
   },
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

// Material UI default theme for the application.
/*
Main blue (light) - #1E528F
Accent blue (dark) - #002D62
Red - #CD3B49
Green - #0E8D0A
Font & Form element gray - #555555
Main Menu button background - #EFEFEF
Main Menu button border - #D8D8D8
*/

let Auth;
if (process.env.REACT_APP_POOL === 'production') {
   Auth = {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-2:ebb30afc-1b4d-43c9-87af-080c467c34db',
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_DdyqrSbk5',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '3f9a0kp0slvnuttn6dru5fk7oa',
   };
} else {
   Auth = {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-2:fc2ef06e-cf67-4b73-9da8-090e59828afe',
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_juU9d1lC5',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '730rts1ddvhja0k0nv09hvthm2',
   };
}

Amplify.configure({
   Auth,
   Storage: {
      bucket: BUCKET_NAME, //REQUIRED -  Amazon S3 bucket
      region: 'us-east-2',
      customPrefix: {
         public: '',
         protected: '',
         private: '',
      },
   },
   Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: true,
   },
});

export const MyTheme = {
   button: {
      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: 64,
      boxSizing: 'border-box',
      transition:
         'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      lineHeight: 1.75,
      fontFamily: '"prometo", "Montserrat", "Arial", "Helvetica Neue", "Helvetica", sans-serif',
      fontWeight: 500,
      borderRadius: 4,
      textTransform: 'uppercase',
      color: '#fff',
      backgroundColor: '#194056',
   },
   a: {
      color: '#194056',
      // cursor: 'pointer',
   },
   hint: {
      color: '#52839F',
      fontSize: '14px',
   },
   sectionFooter: {
      fontSize: '16px',
   },
};

/**
 * App component that handles security for the application.
 *
 * Reviewed: 2/24/20
 */
class App extends Component {
   constructor(props, context) {
      super(props, context);

      this.state = {};
      Hub.listen('auth', this.listener);
   }

   listener = (data) => {
      if (data.payload.event === 'signOut') {
         this.forceUpdate();
      }
   };

   render() {
      return (
         <Authenticator hideDefault={true} theme={MyTheme}>
            <SignIn />
            <VerifyContact />
            <ForgotPassword />
            <Main />
         </Authenticator>
      );
   }
}

//Setup the localization HOC and the Material-UI HOC with a theme for the application.
export default withRoot(App);
