import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import React, {useState, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {formatMessage} from '../utils/Utils';
import DisplayError from './DisplayError';
import Loading from './Loading';
import StatusContext from './StatusContext';

/**
 * The ErrorProvider component.
 *
 * Reviewed:
 */
export default function StatusProvider({children}) {
   const intl = useIntl();

   const [errorKey, setErrorKey] = useState();
   const [error, setError] = useState();
   const [enableRefresh, setEnableRefresh] = useState(true);
   const [errorMessage, setErrorMessage] = useState();
   const [values, setValues] = useState({});
   const [progress, setProgress] = useState(false);
   const [showError, setShowError] = useState(false);

   const setErrorData = useCallback((errorKey, errorMessage, error, values, enableRefresh) => {
      // setErrorKey(errorKey);
      // setErrorMessage(errorMessage);
      // setError(error);
      // setValues(values);
      // setEnableRefresh(enableRefresh);
   }, []);

   // const setGeneralErrorData = useCallback(({errorKey, typeKey, actionKey, error, values, enableRefresh}) => {
   const setGeneralErrorData = useCallback((props) => {
      setErrorKey(props.errorKey  ? props.errorKey : 'action.error');
      setShowError(true);
      if (!isEqual(get(error, 'message'), get(props, 'error.message'))) {
         setError(props.error);
      }
      const type = props.typeKey ? formatMessage(intl,props.typeKey) : undefined;
      const action = props.actionKey ? formatMessage(intl,props.actionKey) : undefined;
      if (type !== values.type || action !== values.action) {
         setValues({...values, type, action});
      }
      if (enableRefresh !== props.enableRefresh) {
         setEnableRefresh(props.enableRefresh);
      }
      //intl causes constant changes.
      // eslint-disable-next-line
   }, [values, error, errorKey, enableRefresh]);

   const handleSetProgress = useCallback(newProgress => {
      setProgress(newProgress);
   }, []);

   const handleClose = () => {
      setShowError(false);
   };

   return (
      <StatusContext.Provider value={{errorKey, errorMessage, error, values, enableRefresh, setError: setErrorData, setErrorGeneral: setGeneralErrorData, progress, setProgress: handleSetProgress}}>
         <Loading isLoading={progress}/>
         {(showError && (error || errorKey)) && (
            <DisplayError error={error || {}} errorId={errorKey} onClose={handleClose} enableRefresh={enableRefresh}
                          values={{...values, ...{message: errorMessage}, ...{message: get(error, 'message')}}}/>
         )}
         {children}
      </StatusContext.Provider>
   );
}
