import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
import React, {forwardRef, useImperativeHandle} from 'react';
import {useParams} from 'react-router-dom';
import AutocompleteFHG from '../../../components/AutocompleteFHG';
import TextFieldFHG from '../../../components/TextField';
import {DELETE_STATUS, EDIT_STATUS} from '../../../Constants';
import useMutationFHG, {CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import Grid from '../../../fhg/components/Grid';
import {LIVESTOCK_HEADCOUNT_QUERY} from '../../client/record/HeadcountRecord';
import {LIVESTOCK_QUERY} from './LivestockListCard';

const useStyles = makeStyles(theme => ({
   livestockStyle: {
      marginTop: theme.spacing(1),
   },
   textFieldStyle: {
      marginBottom: theme.spacing(0.5),
   }
}), {name: 'livestockEditInfoStyles'});

// Update the livestock with the given properties.
export const LIVESTOCK_CREATE = {
   mutation: gql`
      mutation LivestockCreate($facilityId: Int!, $livestockTypeId: Int, $max: Int) {
         livestock: livestock_Create(livestock: { facilityId: $facilityId, livestockTypeId: $livestockTypeId, max: $max}) {
            id
            uuid
            facilityId
            max
            livestockTypeId
         }
      }
   `,
   typeKey: 'livestock.type',
   actionKey: CREATE_ACTION
};

export const LIVESTOCK_DELETE = {
   mutation: gql`
      mutation LivestockDelete($id: Int!) {
         livestock_Delete(livestockId: $id)
      }
   `,
   typeKey: 'livestock.type',
   actionKey: DELETE_ACTION,
};

// Update the livestock with the given properties.
const LIVESTOCK_UPDATE = {
   mutation: gql`
      mutation LivestockUpdate($id: Int!, $facilityId: Int, $livestockTypeId: Int, $max: Int) {
         livestock: livestock_Update(livestockId: $id, livestock: { facilityId: $facilityId, livestockTypeId: $livestockTypeId, max: $max}) {
            id
            uuid
            facilityId
            max
            livestockTypeId
         }
      }
   `,
   typeKey: 'livestock.type',
   actionKey: UPDATE_ACTION,
};

const LivestockEditInfo = forwardRef(
   function LivestockEditInfo({editItem, defaultItem, isSelected, livestockTypes, onChange, onEditChange, parentId},
      ref) {
      const classes = useStyles();

      const [livestockCreate] = useMutationFHG(LIVESTOCK_CREATE);
      const [livestockUpdate] = useMutationFHG(LIVESTOCK_UPDATE)
      const [livestockDelete] = useMutationFHG(LIVESTOCK_DELETE, undefined, {query: LIVESTOCK_QUERY, variables: {facilityId: parentId}});

      useImperativeHandle(ref, () => ({
         async submit(saveItem, parentId) {
            function getRefetchQueries() {
               return [
                  {query: LIVESTOCK_QUERY, variables: {facilityId: parentId}},
                  {query: LIVESTOCK_HEADCOUNT_QUERY, variables: {facilityId: parentId}},
               ];
            }

            if (saveItem.id) {
               if (saveItem.__state === EDIT_STATUS) {
                  await livestockUpdate({variables: {...saveItem}, refetchQueries: getRefetchQueries});
               } else if (saveItem.__state === DELETE_STATUS) {
                  await livestockDelete({variables: {id: saveItem.id}, refetchQueries: getRefetchQueries()});
               }
            } else if (saveItem.__state === EDIT_STATUS) {
               await livestockCreate({variables: {...saveItem, facilityId: parentId}, refetchQueries: getRefetchQueries});
            }
         }
      }), [livestockCreate, livestockDelete, livestockUpdate]);

      /**
       * Handle onChange events for the inputs.
       *
       * NOTE:
       * Input components MUST have their name set to be set in the editValues.
       *
       * @param event The event that changed the input.
       * @param value The value if the component is an Autocomplete
       * @param reason The reason of the value change if Autocomplete
       * @param newValue The new value from the component
       * @param name The name of the component.
       */
      const handleChange = (event, value, reason, newValue, name) => {
         onEditChange && onEditChange(editItem, event, value, reason, newValue, name);
      };

      return (
         <Grid container spacing={2} fullHeight >
            <Grid item fullWidth xs={12} md={6} lg={8} className={classes.livestockStyle}>
               <AutocompleteFHG
                  name={'livestockTypeId'}
                  labelKey={'facility.livestockType.label'}
                  options={get(livestockTypes, 'livestockTypes')}
                  defaultValue={get(defaultItem, 'livestockTypeId')}
                  autoFocus={isSelected}
                  disableClearable
                  onChange={handleChange}
               />
            </Grid>
            <Grid item fullWidth xs={12} md={6} lg={4} className={classes.livestockStyle}>
               <TextFieldFHG
                  name={'max'}
                  isFormattedNumber
                  className={classes.textFieldStyle}
                  labelKey={'facility.livestockMax.label'}
                  defaultValue={get(defaultItem, 'max')}
                  value={editItem.max}
                  onChange={handleChange}
                  fullWidth
               />
            </Grid>
         </Grid>
      )
   });

LivestockEditInfo.propTypes = {
   editItem: PropTypes.any,
   defaultItem: PropTypes.any,
   livestockTypes: PropTypes.any,
   onChange: PropTypes.func,
};

export default LivestockEditInfo;
