import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import defer from 'lodash/defer';
import get from 'lodash/get';
import React, {useState, useEffect, useRef, useContext} from 'react';
import {useParams} from 'react-router-dom';
import AuthContext from '../../components/AuthContext';
import TitleCardInner from '../../components/TitleCardInner';
import useQueryOfflineFHG from '../../fhg/components/data/useQueryOfflineFHG';
import Grid from '../../fhg/components/Grid';
import Loading from '../../fhg/components/Loading';
import SessionContext from '../../fhg/components/security/SessionContext';
import TypographyFHG from '../../fhg/components/Typography';
import useWidthRule from '../../fhg/components/useWidthRule';
import PondLevelRecord from './record/PondLevelRecord';
import WasteTransferRecord from './record/WasteTransferRecord';

const POND_LEVEL_RECORD = 'pondlevel record';
const WASTE_TRANSFER_RECORD = 'waste transfer record';
const NO_RECORD = 'no record';

const useStyles = makeStyles(theme => ({
   addButtonStyle: {
      color: theme.palette.text.secondary,
   },
   desktopFrameStyle: {

   },
   mobileFrameStyle: {

   },
   subtitleStyle: {
      fontWeight: 450,
   },
}), {name: 'WasteStorageClientStyles'});

export const WASTE_STORAGE_BY_UUID_QUERY = gql`
   query getWasteStorageByUuidForWasteStorageEdit ($uuid: String!) {
      wasteStorage: wasteStorage_ByUuid(wasteStorageUuid: $uuid) {
         id
         uuid
         facilityId
         operatingLevel
         dec1OperatingLevel
         name
         latitude
         longitude
      }
   }
`;

export default function WasteStorageClient({defaultItem, onClose, onActionChange}) {
   const classes = useStyles();
   const {uuid} = useParams();
   const ref = useRef();
   const isDesktopWidth = useWidthRule('up', 'sm');
   const {wasteStorageAccess} = useContext(SessionContext);
   const {isAdmin} = useContext(AuthContext);

   const {data, loading} = useQueryOfflineFHG(WASTE_STORAGE_BY_UUID_QUERY, {variables: {uuid}, skip: !uuid}, 'wasteStorage.type');

   const [actionType, setActionType] = useState(NO_RECORD);
   const [wasteStorage, setWasteStorage] = useState({});

   useEffect(() => {
      if (!!data) {
         setWasteStorage(data.wasteStorage);
      }
   }, [data]);

   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }

   }, [ref]);

   const handleClose = () => {
      setActionType(NO_RECORD)
   };

   const handleChangeAction = (action) => {
       setActionType(action);
      onActionChange && onActionChange(action);
   };

   if (loading && !data) {
      return <Loading isLoading={loading}/>
   }
   return (
      <Grid ref={ref} container direction={'column'} wrap={'nowrap'} resizable>
         <Grid item resizable={isDesktopWidth ? false : undefined} fullHeight={isDesktopWidth ? false : undefined} className={isDesktopWidth ? classes.desktopFrameStyle: classes.mobileFrameStyle}>
            <TitleCardInner
               titleId={'clientWasteStorage.title'}
               values={{...defaultItem, hasBold:true}}
               onClose={onClose}
            >
               {(actionType === NO_RECORD || isDesktopWidth) && (
                  <Grid name={'wasteStorage frame'} container direction={'column'} >
                     <Grid name={'wasteStorage details'} container item direction={'column'}>
                        <TypographyFHG variant={'subtitle1'} className={classes.subtitleStyle} hasBold id={'clientWasteStorage.operatingLevel'} values={{operatingLevel: get(wasteStorage, 'operatingLevel') || 0}}/>
                        <TypographyFHG variant={'subtitle1'} className={classes.subtitleStyle} hasBold id={'clientWasteStorage.decOperatingLevel'} values={{dec1OperatingLevel: get(wasteStorage, 'dec1OperatingLevel') || 0}}/>
                     </Grid>
                     <Grid container item name={'wasteStorage actions'} direction={'row'}>
                        <Grid item xs={6} sm={12} md={6}>
                           <Button className={classes.addButtonStyle} size={'small'} disabled={!isAdmin && !wasteStorageAccess}
                                   onClick={() => setActionType(POND_LEVEL_RECORD)}>
                              <TypographyFHG variant='button' color={'inherit'}
                                             id={'clientWasteStorage.pondRecord.button'}
                                             style={{textDecoration: 'underline'}}/>
                           </Button>
                        </Grid>
                        <Grid item xs={6} sm={12} md={6}>
                           <Button className={classes.addButtonStyle} disableRipple size={'small'} disabled={!isAdmin && !wasteStorageAccess}
                                   onClick={() => handleChangeAction(WASTE_TRANSFER_RECORD)}>
                              <TypographyFHG variant='button' color={'inherit'}
                                             id={'clientWasteStorage.wasteTransferRecord.button'}
                                             style={{textDecoration: 'underline'}}/>
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
            </TitleCardInner>
         </Grid>
         <Grid item resizable={isDesktopWidth} style={{position: 'relative', minHeight: actionType === NO_RECORD ? 0 : 190}}>
            {{
               [NO_RECORD]: undefined,
               [POND_LEVEL_RECORD]: <PondLevelRecord parentItem={wasteStorage} onClose={handleClose}/>,
               [WASTE_TRANSFER_RECORD]: <WasteTransferRecord parentItem={wasteStorage} onClose={handleClose}/>,
            }[actionType]}
         </Grid>
      </Grid>
   );
}
