import Badge from '@material-ui/core/Badge';
import {DatePicker} from '@material-ui/pickers';
import {indexOf} from 'lodash';
import moment from 'moment';
import React, {useState, useContext, useRef, useEffect} from 'react'
import { MuiPickersContext } from 'material-ui-pickers'
import withStyles from '@material-ui/core/styles/withStyles'
import { styles as dayStyles } from 'material-ui-pickers/DatePicker/components/Day'
import classnames from 'classnames'
import {DATE_DB_FORMAT} from '../../../Constants';
import {sortDate} from '../../utils/Utils';
// import DateTextField from '../../../components/TextField';
import DateTextField from './DateTextField';


function DateRangePicker({
   classes,
   name,
   value,
   onChange,
   labelFunc,
   format,
   emptyLabel,
   autoOk,
   onClose,
   className,
   InputLabelProps, InputProps,
   entryDays,
   ...props
}) {

   const [begin, setBegin] = useState(value && value[0]);
   const [end, setEnd] = useState(value && value[1]);
   const [hover, setHover] = useState(undefined);
   const picker = useRef();
   const utils = useContext(MuiPickersContext);
   const cache = (value && value.length > 0 && value.sort(sortDate)) || [];

   useEffect(() => {
      if (cache.length > 0) {
         setBegin(cache[0]);
         setEnd(cache[1] || cache[0]);
      }
      // eslint-disable-next-line
   }, [cache]);

   function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
      // const dayMillis = day.valueOf();

      const min = Math.min(begin, end || hover || begin);
      const max = Math.max(begin, end || hover || begin);

      const newDayComponent = React.cloneElement(dayComponent, {
         onClick: e => {
            e.stopPropagation();
            if (!begin) setBegin(day);
            else if (!end) {
               setEnd(day);
               if (autoOk) {
                  onChange({target: {name, type: 'date-range', date:[begin, day].sort(sortDate)}});
                  onClose?.()
               }
            } else {
               setBegin(day);
               setEnd(undefined)
            }
         },
         // Only handle hover for devices that are not mobile.
         onMouseEnter: () => !('ontouchstart' in document.documentElement) && setHover(day),
         onMouseLeave: () => !('ontouchstart' in document.documentElement) && setHover(undefined),
         className: classnames(classes.day, {
            [classes.hidden]: dayComponent.props.hidden,
            [classes.current]: dayComponent.props.current,
            [classes.isDisabled]: dayComponent.props.disabled,
            [classes.isSelected]: day >= min && day <= max,
            [classes.selected]: day > min && day < max,
            [classes.beginCap]: max !== min && utils.isSameDay(day, min),
            [classes.endCap]: max !== min && utils.isSameDay(day, max),
            [classes.bothCap]: max === min && utils.isSameDay(day, min),
         })
      });
      if (entryDays) {
         const isSelected = indexOf(entryDays, day.format(DATE_DB_FORMAT)) >= 0;
         return <Badge invisible={!isSelected} variant={'dot'}
                       color={'secondary'}>{newDayComponent}</Badge>;
      } else {
         return newDayComponent;
      }
   }

   const formatDate = date => {
      let useDate = typeof date === "number" ? moment(date) : date;
      return utils.format(useDate, format || utils.dateFormat)};

   return (
      <DatePicker
         {...props}
         value={begin}
         renderDay={renderDay}
         onClose={() => {
            if (begin && !end) {
               setEnd(begin);
               const date = [begin, begin];
               onChange && onChange({target: {name, type: 'date-range', date}}, date, 'date-range');
            }
            onClose && onClose();
         }}
         onDismiss={() => {
            setBegin(cache[0]);
            setEnd(cache[1]);
            onClose && onClose();
         }}
         onChange={() => {
            const date = [begin, end].sort(sortDate);
            onChange && onChange({target: {name, type: 'date-range', date}}, date, 'date-range');
         }}
         onClear={() => {
            setBegin(undefined);
            setEnd(undefined);
            setHover(undefined);
            onChange({target: {name, type: 'date-range', date:[]}});
         }}
         ref={picker}
         labelFunc={(date, invalid) => {
            const min = Math.min(begin, end || hover || begin);
            const max = Math.max(begin, end || hover || begin);
            return labelFunc
               ? labelFunc([min, max], invalid)
               : date && min && max
                  ? `${formatDate(min)} - ${formatDate(max)}`
                  : emptyLabel || ''
         }}
         inputVariant={'outlined'}
         TextFieldComponent={DateTextField}
      />
   )
}

export const styles = theme => {
   const base = dayStyles(theme);
   return {
      ...base,
      day: {
         ...base.day,
         margin: 0,
         width: '40px',
         borderRadius: '0',
      },
      beginCap: {
         opacity: '100%',
         borderRadius: 0,
      },
      endCap: {
         opacity: '100%',
         borderRadius: 0
      },
      bothCap: {
         opacity: '100%',
         borderRadius: 0
      },
      selected: {
         opacity: '70%',
      }
   }
};

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker)
