import makeStyles from '@material-ui/core/styles/makeStyles';
import {DatePicker as DatePickerOriginal} from '@material-ui/pickers';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {DATE_FORMAT} from '../Constants';
import {formatMessage} from '../fhg/utils/Utils';
import TextFieldFHG from './TextField';

const useStyles = makeStyles(theme => ({
   buttonPadding: {
      '& button': {
         padding: theme.spacing(0.5),
      },
      '& > div': {
         paddingRight: 0,
      },
      '& > div > div': {
         marginLeft: 0,
      },
      '& input > div': {
         marginLeft: 0,
      }
   }
}));

/**
 * The TextField with preset formats.
 */
export default function DatePickerFHG ({ name, className, label, labelKey, disableToolbar=false, format=DATE_FORMAT, autoOk=true, onChange, value, defaultValue, required, ...datePickerProps }) {
   const classes = useStyles();
   const intl = useIntl();
   const currentLabel = label || (labelKey && formatMessage(intl, labelKey)) || undefined;
   const [isSet, setIsSet] = useState(value !== undefined && value !== null && value !== '');

   const handleChange = (moment, date) => {
      setIsSet(true);
      onChange && onChange({target: {name}}, moment, 'date-picker');
   };

   return (
      <DatePickerOriginal
         name={name}
         className={`${classes.buttonPadding} ${className}`}
         disableToolbar={disableToolbar}
         format={format}
         autoOk={autoOk}
         disableFuture={true}
         label={currentLabel}
         onChange={handleChange}
         value={isSet ? value : defaultValue}
         TextFieldComponent={params => (
            <TextFieldFHG name={'date'} required={required} fullWidth {...params} labelKey={labelKey}/>
         )}
         {...datePickerProps}
      />
   );
}
