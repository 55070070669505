import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
import React, {Fragment, forwardRef} from 'react';
import DatePickerFHG from '../../../components/DatePickerFHG';
import TextFieldFHG from '../../../components/TextField';
import {YEAR_FORMAT, DISABLED_STATUS} from '../../../Constants';
import Grid from '../../../fhg/components/Grid';
import TypographyFHG from '../../../fhg/components/Typography';

const useStyles = makeStyles({
   deleteStyle: {
      marginTop: 12, //Move the top of delete button down to align with the text boxes.
   }
}, {name: 'AllotmentStyles'});

/**
 * The component to edit an allotment.
 *
 * Reviewed: 7/7/2020
 */
const Allotment = forwardRef(
function Allotment({editItem, defaultItem = {}, isSelected, onEditChange, isMultiYear = false, onRemoveAllotment, onAddAllotment, hasAllotment, canDelete}, ref) {
   const classes = useStyles();
   const theme = useTheme();

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param reason The reason of the value change if Autocomplete
    */
   const handleChange = (event, value, reason) => {
      onEditChange && onEditChange(editItem, event, value, reason);
   };

   if (hasAllotment) {

   return (
      <Grid name={'Allotment Frame'} container item fullWidth alignItems={'center'} spacing={2} style={{width: 'calc(100% + 16px'}}>
         <Grid item fullWidth xs={6}  sm={12} md={6} lg={4}>
            <TextFieldFHG
               key={`fileNumbers ${get(defaultItem, 'uuid')}`}
               name={'fileNumbers'}
               autoFocus={isSelected}
               labelKey={'facility.fileNumber.label'}
               defaultValue={get(defaultItem, 'fileNumbers')}
               value={editItem.fileNumbers}
               onChange={handleChange}
               disabled={editItem.__state === DISABLED_STATUS}
               fullWidth
            />
         </Grid>
         {isMultiYear && (
            <Fragment>
               <Grid item fullWidth xs={6} sm={12} md={6} lg={2}>
                  <TextFieldFHG
                     key={`years ${get(defaultItem, 'uuid')}`}
                     name={'years'}
                     type={'number'}
                     labelKey={'allotment.years.label'}
                     defaultValue={get(defaultItem, 'years')}
                     value={editItem.years}
                     onChange={handleChange}
                     disabled={editItem.__state === DISABLED_STATUS}
                     fullWidth
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6} lg={3}>
                  <DatePickerFHG
                     name={'startYear'}
                     views={['year']}
                     format={YEAR_FORMAT}
                     labelKey={'allotment.startYear.label'}
                     defaultValue={defaultItem.startYear}
                     value={editItem.startYear}
                     onChange={handleChange}
                     required
                     disabled = {editItem.__state === DISABLED_STATUS || (editItem.years ? editItem.years === 1 : defaultItem.years === 1)}
                  />
               </Grid>
            </Fragment>
         )}
         <Grid item fullWidth xs={6} sm={12} md={6} lg={3}>
            <TextFieldFHG
               key={`allotment ${get(defaultItem, 'uuid')}`}
               name={'allotment'}
               isFormattedNumber
               labelKey={'facility.allotment.label'}
               defaultValue={get(defaultItem, 'allotment') || ''}
               value={editItem.allotment}
               onChange={handleChange}
               disabled={editItem.__state === DISABLED_STATUS}
               fullWidth
            />
         </Grid>
         {canDelete && (
            <Grid name={'Allotment Button Frame'} item resizable={false} style={{minWidth: 86}}>
               <Button className={classes.deleteStyle} onClick={onRemoveAllotment} disableRipple>
                  <TypographyFHG variant='button' color='secondary' style={{textDecoration: 'underline'}}
                                 id={'allotment.delete.button'}/>
               </Button>
            </Grid>
         )}
      </Grid>
   );
   } else {
      return (
         <Grid name={'Allotment Frame'} item fullWidth>
            <Button onClick={onAddAllotment} disableRipple>
               <TypographyFHG variant='button' style={{color: theme.palette.primary.light, textDecoration: 'underline'}}
                              id={'allotment.add.button'}/>
            </Button>
         </Grid>
      )
   }
});

export default Allotment;

Allotment.propTypes = {
   editItem: PropTypes.object,               // The allotment with the edit values.
   defaultItem: PropTypes.object,            // The allotment with the default values.
   isSelected: PropTypes.bool,               // Indicates if the item is selected.
   onEditChange: PropTypes.func,             // Callback when an allotment has been edited.
   isMultiYear: PropTypes.bool,              //Indicates if the allotment can be for multiple years.
   onRemoveAllotment: PropTypes.func,        // Callback when an allotment has been removed.
   onAddAllotment: PropTypes.func,           // Callback when an allotment has been added.
   hasAllotment: PropTypes.bool.isRequired,  // Indicates if the allotment exists.
   canDelete: PropTypes.bool.isRequired,     // Indicates if existing allotments can be deleted.
};

Allotment.defaultProps = {
   isMultiYear: false
};
