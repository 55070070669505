import {useMutation} from '@apollo/react-hooks';
import differenceBy from 'lodash/differenceBy';
import castArray from 'lodash/castArray';
import get from 'lodash/get';
import set from 'lodash/set';

import findIndex from 'lodash/findIndex';
import {useContext, useEffect, useState, useCallback, useRef} from 'react';
import StatusContext from '../StatusContext';

export const UPDATE_ACTION = 'update';
export const CREATE_UPDATE_ACTION = 'createUpdate';
export const CREATE_ACTION = 'create';
export const DELETE_ACTION = 'delete';
export const UNDELETE_ACTION = 'undelete';

/**
 * Hook for useMutation that updates the cache for add and delete queries. Update mutations should automatically update
 * cache without this update.
 *
 * NOTE:
 * 1) Assumes that the result of the mutation only has a single property. (e.g. {data: {operators: {...}}})
 * 2) Updates ONLY the FIRST property in an updateQuery. The first property is assumed to be a list and adds the result
 *    property to the list. Other properties in the original query are copied to the updated cache item.
 *
 * Reviewed: 3/26/20
 *
 * @param mutation The graphql mutation.
 * @param options The options for the mutation.
 * @param updateQueries - Queries that need to be updated in the cache to be in sync with the server.
 *          Example: {query: ITEM_CREATE, variables: {id: 1}}
 *    typeKey - The localization key for the type of the object
 *    errorKey - The localization key for the error message.
 *    actionType - The localization key for the action type (e.g. create, update, delete).
 * @return
 */
export default function useMutationFHG(mutation, options, updateQueries) {

   const {setError, setProgress, setErrorGeneral} = useContext(StatusContext);
   const [lastMessage, setLastMessage] = useState('');

   const ref = useRef({updateQueries: updateQueries || mutation.updateQueries}).current;

   const useOptions = {typeKey: mutation.typeKey || 'Unknown', actionKey: mutation.actionKey};

   useEffect(() => {
      return () => {
         setProgress(false);
      }
   }, []);

   const updateCache = useCallback((cache, {data}) => {

      //Handle one or more queries to update.
      const updateQueryList = castArray(ref.updateQueries);
      //Works only on a single update in the results
      const resultDataItem = Object.values(data)[0];
      for (const query of updateQueryList) {
         //get the cached data for the update query.
         try {
            const queryData = cache.readQuery(query);
            //get the property of the first item in the update query results.
            const queryItemToUpdateProperty = query.path || Object.keys(queryData)[0];

            //get the value of the first item in the update query results.
            const queryItemToUpdate = query.path ? get(queryData, query.path) : Object.values(queryData)[0];

            if ((mutation.actionKey === DELETE_ACTION || mutation.actionKey === UNDELETE_ACTION) &&
               ref.currentDeleteOptions && queryItemToUpdate && queryItemToUpdate.length > 0) {
               let items;

               if (mutation.actionKey === DELETE_ACTION) {
                  items = differenceBy(queryItemToUpdate, [ref.currentDeleteOptions.variables],
                     Object.keys(ref.currentDeleteOptions.variables)[0]);
               }
               const data = {...queryData};
               //Add the new result data item to the list in the update query.
               set(data, query.path || queryItemToUpdateProperty, items);

               cache.writeQuery({...query, data});
            } else if (queryItemToUpdate &&
               (queryItemToUpdate.length === 0 || (resultDataItem && queryItemToUpdate.length > 0 && resultDataItem.__typename === queryItemToUpdate[0].__typename))) {
               const data = {...queryData};
               let copyItemToUpdate = [];
               //Add the new result data item to the list in the update query.
               if (queryItemToUpdate.length > 0 && resultDataItem?.id) {
                  const itemIndex = findIndex(queryItemToUpdate, {id: resultDataItem.id});

                  if (itemIndex >= 0) {
                     copyItemToUpdate = [...queryItemToUpdate];
                     copyItemToUpdate[itemIndex] = resultDataItem;
                  } else {
                     copyItemToUpdate = [...queryItemToUpdate, resultDataItem];
                  }
               } else {
                  copyItemToUpdate = [...queryItemToUpdate, resultDataItem];
               }
               set(data, query.path || queryItemToUpdateProperty, copyItemToUpdate);
               cache.writeQuery({...query, data});
            } else if ((mutation.actionKey === DELETE_ACTION || mutation.actionKey === UNDELETE_ACTION) &&
               queryItemToUpdate.length === undefined) {
               queryItemToUpdate.isDeleted = mutation.actionKey === DELETE_ACTION;
            } else {
               console.log('Update did not update cache for ', resultDataItem,);
            }
         } catch (error) {
            //The query may not be in the cache yet.
            console.log(error);
         }
      }
      // }, [mutation.actionKey, mutation.updateQueries]);
   }, [mutation.actionKey, ref.currentDeleteOptions, ref.updateQueries]);

   const [mutationFunction, {loading, error, data}] = useMutation(
      mutation.mutation,
      {
         update: ref && ref.updateQueries ? updateCache : undefined,
         ...options,
      }
   );

   const theMutation = useCallback((options, isDeleteOrUndelete, updateQueriesArg) => {
      ref.currentDeleteOptions = isDeleteOrUndelete || updateQueriesArg ? {...options, updateQueries: updateQueriesArg} : undefined;

      if (updateQueriesArg) {
         ref.updateQueries = updateQueriesArg;
      } else if (!mutation.updateQueries && !updateQueries) {
         ref.updateQueries = undefined;
      }

      return mutationFunction(
         {
            update: ref && ref.updateQueries ? updateCache : undefined,
            ...options,
         });
   }, [mutationFunction, updateCache, ref, mutation.updateQueries, updateQueries]);

   useEffect(() => {
      if (error) {
         if (error.message !== lastMessage) {
            console.log(error, error.stackTrace);
            setLastMessage(error.message);
            if (useOptions.typeKey) {
               setErrorGeneral({...useOptions, error});
            } else {
               setError(useOptions.errorKey, undefined, error);
            }
         }
      } else if (lastMessage !== undefined){
         setLastMessage(undefined);
      }
      // eslint-disable-next-line
   }, [setError, setErrorGeneral, error, useOptions, lastMessage]);

   useEffect(() => {
      if (useOptions.showLoading || useOptions.showLoading === undefined) {
         setProgress(loading)
      }
   }, [setProgress, useOptions.showLoading, loading]);

   return [theMutation, {loading, error, data}];
}
