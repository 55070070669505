import AccordionDetails from '@material-ui/core/AccordionDetails';
import useTheme from '@material-ui/core/styles/useTheme';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import find from 'lodash/find';
import React, {
   useState, Fragment, useEffect, forwardRef, useImperativeHandle, useRef, useCallback, useMemo
} from 'react';
import {DELETE_STATUS, EDIT_STATUS, NEW_STATUS, NEW_AUTO_STATUS} from '../Constants';
import ConfirmButton from '../fhg/components/ConfirmButton';
import Grid from '../fhg/components/Grid';
import TypographyFHG from '../fhg/components/Typography';
import {editChange, hasValue} from '../fhg/utils/Utils';

const lookup = (collection, predicate, propName) => {
   const item = find(collection, predicate);
   if (item && item[propName] !== undefined) {
      return item[propName];
   }
   return '';
};
const useStyles = makeStyles(theme => ({
   facilityFrameStyle: {
      // When the facilities wrap down, unset the height so the main frame scrolls.
      paddingRight: theme.spacing(2),
      // height: 'fit-content',
      [theme.breakpoints.down('xs')]: {
         paddingRight: theme.spacing(0),
         // height: 'unset',
      },
   },
   expansionStyle: {
      border: '1px solid lightgrey',
      width: '100%',
   },
   contentStyle: {
      margin: theme.spacing(.75, 0),
      '&.Mui-expanded': {
         margin: `${theme.spacing(1, 0)}!important`,
      }
   },
   rootStyle: {
      '&.Mui-expanded': {
         minHeight: 52,
      }
   },
}));


const ListEditCard = forwardRef(
   function ListEditCard({listDefaults = [], onChange, onListChange, createItem, addKey, deleteKey, nameProp='name', lookupData, secondaryProps, secondaryProp, secondaryLabel, getName, sortKeys = [], allowExpand = true, createIfEmpty = true, allowDeleteLast = false, onInit, children, confirmDeletes =false}, ref) {
      const theme = useTheme();
      const classes = useStyles();

      const [current, setCurrent] = useState();

      const [listEditValues, setListEditValues] = useState([]);
      const childRef = useRef();
      const [isChanged, setIsChanged] = useState(false);

      useEffect(() => {
          return () => {
             setListEditValues(listDefaults);
          }

      }, [listDefaults]);

      useEffect(() => {
         if (createIfEmpty && listEditValues.length <= 0) {
            setListEditValues([
               createItem(true)
            ]);
         }
         // setCurrent(0);
      }, [listEditValues.length, createItem, createIfEmpty]);

      useEffect(() => {
         if (!isChanged && listDefaults && listDefaults.length > 0) {
            setListEditValues(cloneDeep(listDefaults));
         }
      }, [isChanged, listDefaults]);

      useImperativeHandle(ref, () => ({
         async submit(parentId) {
            if (isChanged) {
               for (const editItem of listEditValues) {
                  if (editItem.__state === EDIT_STATUS || editItem.__state === DELETE_STATUS) {
                     await childRef.current.submit(editItem, parentId);
                     editItem.__state = undefined;
                  }
               }
               setIsChanged(false);
            }
         },
         getList() {
            return listEditValues;
         }
      }));

      const handleAdd = (event) => {
         event.stopPropagation();
         event.preventDefault();
         // noinspection JSCheckFunctionSignatures
         setCurrent(listEditValues.length);
         const newList = [
            ...listEditValues,
            createItem()
         ];
         setListEditValues(newList);
         onListChange && onListChange(newList);
         setIsChanged(true);
      };

      const handleAddItem = (item) => {
         // noinspection JSCheckFunctionSignatures
         setCurrent(listEditValues.length);
         const newList = [
            ...listEditValues,
            item
         ];
         setListEditValues(newList);
         onListChange && onListChange(newList);
         setIsChanged(true);
      };

      const handleDelete = (editItem, index) => (event) => {
         if (event) {
            event.stopPropagation();
            event.preventDefault();
         }
         editItem.__state = DELETE_STATUS;
         const newList = [...listEditValues];
         setListEditValues(newList);

         if (index <= current) {
            // noinspection JSCheckFunctionSignatures
            setCurrent(current - 1);
         }
         setIsChanged(true);
         onListChange && onListChange(newList);
      };

      const handleChange = useCallback((editItem) => {
         const cacheState = editItem.__state;
         editItem.__state = EDIT_STATUS;
         const newList = [...listEditValues];
         setListEditValues(newList);
         setIsChanged(true);
         if (cacheState === NEW_STATUS || cacheState === NEW_AUTO_STATUS) {
            onListChange && onListChange(newList);
         } else {
            onChange && onChange(editItem, newList);
         }
      }, [onListChange, onChange, listEditValues]);

      const handleEditChange = useCallback((editItem, event, value, reason, theNewValue, name) => {
         const cacheState = editItem.__state;
         const newValue = editChange(event, value, reason, true, theNewValue, name);

         for (const key of Object.keys(newValue)) {
            editItem[key] = newValue[key];
         }

         editItem.__state = EDIT_STATUS;
         const newList = [...listEditValues];
         setListEditValues(newList);
         setIsChanged(true);

         if (cacheState === NEW_STATUS || cacheState === NEW_AUTO_STATUS) {
            onListChange && onListChange(newList);
         } else {
            onChange && onChange(newList);
         }
      }, [onListChange, listEditValues, onChange]);

      const handleSelect = index => () => {
         setCurrent(index);
      };

      const renderChildren = (editItem, index) => {
         if (typeof children === 'function') {
            return children(editItem, current === index);
         } else if (typeof children === 'object') {
            return React.Children.map(children, child => {
               const isSelected = current === index;
               // const defaultItem = listDefaults && listDefaults.length > index && listDefaults[index];
               return React.cloneElement(child, {
                  key: editItem && editItem.uuid,
                  editItem: editItem,
                  defaultItem: editItem,
                  ref: childRef,
                  isSelected,
                  onSelect: handleSelect(index),
                  onChange: handleChange,
                  onEditChange: handleEditChange,
                  onAdd: handleAddItem,
                  index,
                  ...child.props
               });
            });
         }
      };

      const handleExpand = index => () => {
         setCurrent(current === index ? undefined : index);
      };

      const filteredItems = useMemo(() => (listEditValues || []).filter(item => item && (item.__state !== DELETE_STATUS && !item.isDeleted)), [listEditValues]);
      return (
         <Grid container item fullWidth style={{height: 'fit-content'}}>
            {filteredItems.map((editItem, index) => {
               if (editItem.__state !== DELETE_STATUS && !editItem.isDeleted) {
                  const expanded = current === index;
                  return (
                     <Fragment key={'listEdit'+(editItem.uuid || index)}>
                        {(allowExpand && filteredItems.length > 1) ? (
                           <Accordion key={'expansionPanel'+(editItem && editItem.uuid) || index}  name={index} classes={{root: classes.expansionStyle}} expanded={expanded}
                                           onChange={handleExpand(index)}>
                              <AccordionSummary
                                 expandIcon={<ExpandMore/>}
                                 aria-controls='panel1bh-content'
                                 id='panel1bh-header'
                                 classes={{root: classes.rootStyle, content: classes.contentStyle}}
                              >
                                 <Grid container direction={'row'} justify={'space-between'}>
                                    <Grid container item fullWidth={false} alignItems={'center'}>
                                       <Grid item>
                                          <TypographyFHG variant={'body1'} color={'primary'} style={{fontWeight: 500}}>{
                                             (getName && getName(editItem, index)) ||
                                             (lookupData && lookup(lookupData, {id: editItem[nameProp]}, 'name')) ||
                                             ((nameProp && editItem) && editItem[nameProp]) ||
                                             ''
                                          }
                                          </TypographyFHG>
                                       </Grid>
                                       {(secondaryProp && editItem && editItem[secondaryProp]) &&  (
                                          <Grid item>
                                             &nbsp;&nbsp;<TypographyFHG><b>{secondaryLabel}</b></TypographyFHG>
                                             &nbsp;<TypographyFHG>{editItem[secondaryProp] || 'N/A'}</TypographyFHG>
                                          </Grid>
                                       )}
                                       {(secondaryProps && secondaryProps.length > 0 && editItem) && secondaryProps.map((secondaryProperty, index) => (
                                             <Grid key={'secondaryProp'+index} item style={{
                                                display: hasValue(get(editItem, secondaryProperty.prop)) ? undefined : 'none'
                                             }}>
                                                &nbsp;&nbsp;<b><TypographyFHG id={secondaryProperty.labelKey}/></b>
                                                &nbsp;<TypographyFHG>{secondaryProperty.lookupData ?
                                                lookup(secondaryProperty.lookupData,
                                                   {id: editItem[secondaryProperty.prop]}, 'name') :
                                                get(editItem, secondaryProperty.prop, 'N/A')}</TypographyFHG>
                                             </Grid>
                                          ))}
                                    </Grid>
                                    <Grid item>
                                       {confirmDeletes ? (
                                          <ConfirmButton
                                             onConfirm={handleDelete(editItem, index)}
                                             style={{
                                                color: theme.palette.secondary.main,
                                                display: filteredItems.length > 1 ? undefined : 'none',
                                             }}
                                             titleKey={'delete.confirm.title'}
                                             messageKey={'delete.confirmName.message'}
                                             titleValues={{type: (editItem[nameProp]) || (getName && getName(editItem, index))}}
                                             values={{name: (editItem[nameProp]) || (getName && getName(editItem, index))}}
                                             color='secondary'
                                             buttonLabelKey={'delete.button'}
                                             disableRipple
                                             className={classes.buttonStyle}
                                             buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
                                          />
                                       ) : (
                                          <Button className={classes.linkStyle}  onClick={handleDelete(editItem, index)} style={{
                                             color: theme.palette.secondary.main,
                                             display: filteredItems.length > 1 ? undefined : 'none',
                                          }} >
                                             <TypographyFHG variant='button' color='inherit' style={{textDecoration: 'underline'}} id={deleteKey}/>
                                          </Button>
                                       )}
                                    </Grid>
                                 </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                 {expanded && renderChildren(editItem, index)}
                              </AccordionDetails>
                           </Accordion>
                        ) : (
                           <Fragment>
                              {renderChildren(editItem, index)}
                           </Fragment>
                        )}
                        {((!allowExpand || (allowDeleteLast && filteredItems.length === 1)) && deleteKey) && (
                           <Grid item fullWidth xs={12} >
                              <Button className={classes.linkStyle}  onClick={handleDelete(editItem, index)} style={{
                                 display: (allowDeleteLast || filteredItems.length > 1) ? undefined : 'none',
                                 color: theme.palette.secondary.main,
                              }} >
                                 <TypographyFHG variant='button' color='inherit' style={{textDecoration: 'underline'}} id={deleteKey}/>
                              </Button>
                           </Grid>
                        )}
                     </Fragment>
                  )
               }
               // Component must be in the DOM to have the ref for delete.
               return (
                  <div key={'listEditSingle'+((editItem && editItem.uuid) || index)}
                       style={{visibility: 'hidden', height: 0}}
                  >
                     {renderChildren(editItem, index)}
                  </div>
               );
            })}
            <Grid item fullWidth xs={12} style={{marginTop: 8}}>
               <Button className={classes.linkStyle}  onClick={handleAdd} style={{color: theme.palette.primary.light}} >
                  <TypographyFHG variant='button' color='inherit' style={{textDecoration: 'underline'}} id={addKey}/>
               </Button>
            </Grid>
         </Grid>
      );
   });

export default ListEditCard;
