import memoize from 'fast-memoize';
import {camelCase} from 'lodash';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import values from 'lodash/values';
import castArray from 'lodash/castArray';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import {removeOne} from './Utils';

/**
 * Update the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to update. (e.g. {query, variables, queryPath})
 * @param uuid of the item to update.
 * @param mutationPathProp Property name resulting object being updated.
 * @return {function(*, {data: {*}}): void}
 */
export const cacheUpdate = (queryList, uuid, mutationPathProp) => {
   const useQueryList = castArray(queryList);

   if (uuid !== undefined) {
      return (proxy, {data}) => {
         for (const queryItem of useQueryList) {
            const {query, variables, queryPath = mutationPathProp, mutationPath = mutationPathProp} = queryItem;
            const resultData = get(data, mutationPath);
            const cachedData = proxy.readQuery({query, variables});
            const itemIndex = findIndex(cachedData[queryPath], {uuid});
            let arr;

            if (itemIndex >= 0) {
               arr = [...cachedData[queryPath]];
               arr[itemIndex] = resultData;
            } else {
               arr = [...(cachedData[queryPath] || []), resultData];
            }
            proxy.writeQuery({query, variables, data: {...cachedData, [queryPath]: arr}});
         }
      };
   } else {
      return cacheAdd(useQueryList, mutationPathProp);
   }
};

/**
 * Add the new item to the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to add the result item. (e.g. {query, variables, queryPath})
 * @param mutationPath Property name resulting object being updated.
 * @return {function(*, {data: {*}}): void}
 */
export const cacheAdd = (queryList, mutationPath, isArray) => {
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = mutationPath} = queryItem;
         let newArray;

         const resultData = get(data, mutationPath);
         // Read the data from our cache for this query.
         const cachedData = proxy.readQuery({query, variables});
         // Write our data back to the cache with the new comment in it
         if (isArray) {
            newArray = [...(cachedData[queryPath] || []), ...resultData];
         } else {
            newArray = [...(cachedData[queryPath] || []), resultData];
         }
         const newData = {...cachedData, [queryPath]: newArray};
         proxy.writeQuery({query, variables, data: newData});
      }
   };
};

/**
 * Delete the item add the uuid from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param queryList the list of queries to delete the item at uuid. (e.g. {query, variables, queryPath})
 * @param path Property name resulting object being updated.
 * @return {function(*, {data: {*}}): void}
 */
export const cacheDelete = (queryList, uuid, path) => {
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = path} = queryItem;

         const cachedData = proxy.readQuery({query, variables});
         console.log('queryResult', cachedData);
         const itemIndex = findIndex(cachedData[queryPath], {uuid});
         if (itemIndex >= 0) {
            const modifiedList = removeOne([...cachedData[queryPath]], itemIndex);
            proxy.writeQuery({
               query,
               variables,
               data: {...cachedData, [queryPath]: modifiedList.length > 0 ? modifiedList : null},
            });
         }
      }
   };
};

export const updateAdd =
   (mutationKey, options) =>
   (proxy, {data}) => {
      let currentOptions = Array.isArray(options) ? options : [options];
      for (const option of currentOptions) {
         updateAddChange(mutationKey, option, proxy, data);
      }
   };

export const updateAddChange = (mutationKey, options, proxy, data) => {
   const {query, key} = options;
   let queryData = proxy.readQuery(options);
   let usingDataKey = queryData[mutationKey];
   if (usingDataKey) {
      usingDataKey.push(data[key]);
   } else {
      get(queryData, key, []).push(data[mutationKey]);
   }
   proxy.writeQuery({query, data: queryData});
};

export const updateEdit =
   (mutationKey, options) =>
   (proxy, {data}) => {
      let currentOptions = Array.isArray(options) ? options : [options];
      for (const option of currentOptions) {
         updateEditChange(mutationKey, option, proxy, data);
      }
   };

function copyMatchingProperties(originalObject, changedObject) {
   let matchingObject = {};
   forOwn(originalObject, (value, key) => {
      matchingObject[key] = changedObject[key] || value;
   });
   return matchingObject;
}

export const updateEditChange = (mutationKey, options, proxy, data) => {
   const {query, key, variables} = options;
   const queryData = proxy.readQuery({query, variables});
   const usingDataKey = queryData[mutationKey];
   if (usingDataKey) {
      queryData[mutationKey] = data[key];
   } else {
      const selectedIndex = get(queryData, key).findIndex((item) => item.id === data[mutationKey].id);
      get(queryData, key)[selectedIndex] = copyMatchingProperties(queryData[key][selectedIndex], data[mutationKey]);
   }
   proxy.writeQuery({query, data: queryData});
};

export const updateDelete =
   (id, options) =>
   (proxy, {data}) => {
      if (options) {
         let currentOptions = Array.isArray(options) ? options : [options];
         for (const option of currentOptions) {
            updateDeleteChange(id, option, proxy, data);
         }
      }
   };

export const updateDeleteChange = (id, options, proxy, data) => {
   const queryData = proxy.readQuery(options);
   const selectedIndex = queryData[options.key].findIndex((item) => item.id === id);
   if (selectedIndex >= 0) {
      removeOne(queryData[options.key], selectedIndex);
      proxy.writeQuery({query: options.query, data: queryData});
   } else {
      console.log('Could not find item to remove from cache on delete.');
   }
};

/**
 * Create a cell in Excel spreadsheet.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 * @param font The font style for the cell.
 * @param hasBottomBorder Indicates if the cell has a bottom border.
 * @return {Cell} The Excel spreadsheet cell.
 */
const createCell = (worksheet, text, location, font, hasBottomBorder) => {
   const currentCell = worksheet.getCell(location);
   currentCell.value = text;
   currentCell.font = font;

   if (hasBottomBorder) {
      currentCell.border = {
         bottom: {style: 'thin'},
      };
   }
   return currentCell;
};

/**
 * Create a label for the Excel spreadsheet. Label is bold and can be aligned right.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 * @param alignRight Indicates if the text should be aligned right.
 */
export const createLabel = (worksheet, text, location, alignRight = false) => {
   const LABEL_FONT = {
      name: 'Arial',
      size: 10,
      bold: true,
   };
   const currentCell = createCell(worksheet, text, location, LABEL_FONT);

   if (alignRight) {
      currentCell.alignment = {horizontal: 'right'};
   }
};

/**
 * Create a notes area for the Excel spreadsheeet. A note is any large text area.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 * @param wrap Indicates if the cell should wrap.
 */
export const createNotes = (worksheet, text, location, wrap = false) => {
   const NOTE_FONT = {
      name: 'Arial',
      size: 10,
   };
   const currentCell = createCell(worksheet, text, location, NOTE_FONT);

   if (wrap) {
      currentCell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'left'};
   }
};

/**
 * A value cell in an Excel spreadsheet. The value will be underlined.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 */
export const createValue = (worksheet, text, location) => {
   const VALUE_FONT = {
      name: 'Arial',
      size: 12,
   };
   createCell(worksheet, text, location, VALUE_FONT, true);
};

const mapRows = memoize((data) => {
   return map(data, (item) => {
      const array = values(item);
      for (let i = 0; i < array.length; i++) {
         if (!array[i]) {
            array[i] = '';
         }
      }
      return array;
   });
});

/**
 * A table for an Excel spreadsheet.
 *
 * @param name The name of the table. The name will be converted to camel case to avoid spaces which aren't allowed.
 * @param worksheet The worksheet of the spreadsheet.
 * @param columns The columns for the table.
 * @param data The data properties must match the columns in order. For example the first column will be the first
 *        property in the object
 * @param location The location of the cell (e.g. 'A1').
 * @param headerRow Indicates if header row should be shown.
 * @param totalsRow Indicates if there is a totals row.
 * @param firstRow Number of the first row in the table only required when headerRow is false. Otherwise it is not used.
 */
export const createTable = (
   name,
   worksheet,
   columns,
   data = [],
   location,
   headerRow = true,
   totalsRow = true,
   firstRow
) => {
   const rows = mapRows(data);
   if (!headerRow && firstRow) {
      const row = worksheet.getRow(firstRow);
      row.hidden = true;
   }

   worksheet.addTable({
      name: camelCase(name),
      ref: location,
      headerRow: true,
      totalsRow,
      style: {
         theme: 'TableStyleLight15',
      },
      columns,
      rows,
   });
};
