import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WarningRounded from '@material-ui/icons/WarningRounded';
import {useIsConnected} from '@wora/detect-network';
import gql from 'graphql-tag';
import get from 'lodash/get';
import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import TitleCardInner from '../../../components/TitleCardInner';
import {SUPPORT_PHONE_NUMBER} from '../../../Constants';
import useMutationFHG, {CREATE_UPDATE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import Grid from '../../../fhg/components/Grid';
import Typography from '../../../fhg/components/Typography';
import TypographyFHG from '../../../fhg/components/Typography';
import useMessage from '../../../fhg/utils/useMessage';
import {toNumber} from '../../../fhg/utils/Utils';

const useStyles = makeStyles(theme => ({
   linkStyle: {
      '&:hover': {
         backgroundColor: 'transparent',
      },
      '&:disabled': {
         color: `${theme.palette.text.disabled} !important`,
      },
   },
}), {name: 'ReportDischargeStyles'});

// Default properties needed for the waste application record.
export const ALERT_FRAGMENT = gql`
   fragment alertInfo on Alert {
      id
      uuid
      sent
      forAdmins
      isDeleted
      createdDateTime
      messageData {
         text
      }
      alertData {
         id
         alertType

      }
   }
`;

// Query for all the discharge alerts in the give date range.
export const ALERT_QUERY = gql`
   query getAlert($clientId: [Int])
   {
      alerts:alert_AllWhere( alertSearch: {dismissed: false, clientId: $clientId, forAdmins: true}) {
         ...alertInfo
      }
   }
   ${ALERT_FRAGMENT}
`;

// Create or Update the alert with the given properties.
export const ALERT_CREATE_UPDATE = {
   mutation: gql`
      mutation AlertCreateUpdate(
         $uuid: String!
         $text: String
         $sent: Boolean
         $clientId: Int
      ) {
         alert: alert_CreateUpdate(alert: {
            uuid: $uuid
            messageData: {text: $text}
            clientId: $clientId
            forAdmins: true
            sent: $sent
            dismissed: false
            alertData: {alertType: "dischargeAlert"}
         }) {
            ...alertInfo
         }
      }
      ${ALERT_FRAGMENT}
   `,
   typeKey: 'alert.type',
   actionKey: CREATE_UPDATE_ACTION,
};

/**
 * Component for user to either call KLA or notify them of a discharge.
 *
 * @param defaultItem
 * @param onClose Callback when the panel is closed.
 *
 * Reviewed: 8/10/2020
 *
 * @param clientName The name of the client.
 * @return {JSX.Element}
 * @constructor
 */
function ReportDischarge({defaultItem, onClose, clientName}) {
   const {clientId} = useParams();
   const classes = useStyles();
   const text = useMessage('alert.discharge.text', undefined, {clientName, facilityName: defaultItem.name});
   const [uuidValue, setUuidValue] = useState(uuid());
   const isConnected = useIsConnected();

   const [messageSent, setMessageSent] = useState(false);

   const {data} = useQueryOfflineFHG(ALERT_QUERY, {variables: {clientId: toNumber(clientId)}}, 'alert.type');
   const [alertCreateUpdate, {data: createUpdateData}] = useMutationFHG(ALERT_CREATE_UPDATE);

   /**
    * Set the uuid to the most recent alert
    */
   useEffect(() => {
      const alerts = get(data, 'alerts') || [];
      if (alerts.length > 0) {
         const latestAlert = get(alerts, `[${alerts.length - 1}]`);

         const createdDateTime = moment(latestAlert.createdDateTime);
         const isSameHour = createdDateTime.isSame(moment(), 'hour');

         // Was the discharge notification sent in last hour?
         if (isSameHour) {
            setUuidValue(latestAlert.uuid);
            setMessageSent(latestAlert.sent);
         }
      }
   }, [data]);

   useEffect(() => {
      const alertUuid = get(createUpdateData, 'alert.uuid');
      if (alertUuid) {
         setUuidValue(alertUuid);
      }
   }, [createUpdateData]);

   const handleNotify = () => {
      alertCreateUpdate({variables: {uuid: uuidValue, sent: true, text, clientId: toNumber(clientId)}});
      setMessageSent(true);
   };

   const handleUndoNotify = () => {
      if (uuidValue) {
         alertCreateUpdate({variables: {uuid: uuidValue, sent: false}});
         setMessageSent(false);
      } else {
         console.log('Could not undo send discharge.');
      }
   };

   return (
      <TitleCardInner titleId={'clientFacility.reportDischarge.button'} color='secondary' variant={'subtitle1'} onClose={onClose}
                      titleIcon={<WarningRounded color={'secondary'} style={{marginRight: 8}}/>}
      >
         <Grid container direction={'row'}>
            <Grid item>
               <Typography variant={'subtitle1'} id={'discharge.notify.text1'} display='inline'/>
               <Typography variant={'subtitle1'} id={'discharge.notify.text2'} display='inline' nowrap/>
               {messageSent ? (
                  <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                     <Grid item>
                        <TypographyFHG variant='subtitle1' color={'textSecondary'}
                                       id={'alert.sent.text'}/>
                     </Grid>
                     <Grid item>
                        <Button className={classes.linkStyle} size={'small'}
                                onClick={handleUndoNotify}>
                           <TypographyFHG variant='subtitle1' color={'textSecondary'}
                                          id={'discharge.undoNotify.button'}
                                          style={{textDecoration: 'underline'}}/>
                        </Button>
                     </Grid>
                  </Grid>
               ) : (
                  <Button className={classes.linkStyle} size={'small'}
                          onClick={handleNotify} disabled={!isConnected}>
                     <TypographyFHG variant='subtitle1' color={isConnected ? 'secondary' : 'disabled'}
                                    id={'discharge.notify.button'}
                                    style={{textDecoration: 'underline'}}/>
                  </Button>
               )}
            </Grid>
            <Grid item>
               <Typography variant={'subtitle1'} id={'discharge.phone.text'} display={'inline'}/>
               <span> </span>
               <Typography display={'inline'} color={'secondary'} variant={'subtitle1'}>
                  <Link underline={'always'} href={`tel:${SUPPORT_PHONE_NUMBER}`} color='inherit'>
                     {SUPPORT_PHONE_NUMBER}
                  </Link>
               </Typography>
            </Grid>
         </Grid>
      </TitleCardInner>
   );
}

export default ReportDischarge;
