import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import * as PropTypes from 'prop-types';
import React, {useState} from 'react';
import {EditControl} from 'react-leaflet-draw';
import {FIELD_GEO_JSON_COLOR} from '../../../Constants';

// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
   iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
   iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

L.DrawToolbar.include({
   getModeHandlers: function (map) {
      const options = {
         lineOptions: {
            color: FIELD_GEO_JSON_COLOR,
            weight: 3,
         },
         shapeOptions: {
            color: FIELD_GEO_JSON_COLOR,
         }
      };
      return [
         {
            enabled: this.options.polyline,
            handler: new L.Draw.Polyline(map, this.options.polyline),
            title: L.drawLocal.draw.toolbar.buttons.polyline
         },
         {
            enabled: this.options.polygon,
            handler: new L.Draw.Polygon(map, this.options.polygon),
            title: L.drawLocal.draw.toolbar.buttons.polygon
         },
         {
            enabled: this.options.rectangle,
            handler: new L.Draw.Rectangle(map, this.options.rectangle),
            title: L.drawLocal.draw.toolbar.buttons.rectangle
         },
         {
            enabled: this.options.circle,
            handler: new L.Draw.Circle(map, this.options.circle),
            title: L.drawLocal.draw.toolbar.buttons.circle
         },
         {
            enabled: this.options.marker,
            handler: new L.Draw.Marker(map, this.options.marker),
            title: L.drawLocal.draw.toolbar.buttons.marker
         },
         {
            enabled: this.options.semicircle,
            handler: new L.Draw.Sector(map, this.options.semicircle),
            title: "Draw Semicircle"
         },
      ];
   }
});
//
//
// see http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event for leaflet-draw events doc

EditControlFHG.propTypes = {
   allowEdit: PropTypes.bool,
   limitShapes: PropTypes.number,
   count: PropTypes.number,
   onChange: PropTypes.func,
   onDelete: PropTypes.func,
}

/**
 * Edit control to allow for deleting, editing and drawing circles, rectangles and polygons. Only one shape is allowed,
 * but could be easily expanded to handle more.
 *
 * @param allowEdit
 * @param limitShapes
 * @param count
 * @param onChange
 * @return {JSX.Element|null}
 * @constructor
 */
export default function EditControlFHG({allowEdit = false, limitShapes = 1, count = 0, onChange, onDelete}) {
   const [shapeCount, setShapeCount] = useState(count);

   /**
    * Get the JSON from the layer in the event. Only one layer is allowed per GeoJSON.
    * @param e leaflet event.
    * @return {undefined|*} The JSON for the layer.
    */
   let getLayerJson = function (e) {
      if (e) {
         const layers = e.layer ? [e.layer] : e.layers.getLayers();
         const json = e.layer ? {features: [e.layer.toGeoJSON()]} : e.layers.toGeoJSON();
         for (let i = 0; i < layers.length; i++) {
            const layer = layers[i];
            if (layer instanceof L.Circle) {
               json.features[i].properties.radius = layer.getRadius();
               const {lat, lng} = layer.getLatLng();
               json.features[i].properties.latitude = lat;
               json.features[i].properties.longitude = lng;
            } else if (layer instanceof L.Sector) {
               json.features[i].properties.type = 'Sector';
               json.features[i].geometry.type = 'Point';

               const {lat, lng} = layer.getCenter();
               json.features[i].geometry.coordinates =  [lat, lng];
               json.features[i].properties.center = layer.getCenter();
               json.features[i].properties.innerRadius = layer.getInnerRadius();
               json.features[i].properties.numberOfPoints = layer.getNumberOfPoints();
               json.features[i].properties.outerRadius = layer.getOuterRadius();
               json.features[i].properties.startBearing = layer.getStartBearing();
               json.features[i].properties.endBearing = layer.getEndBearing();
               json.features[i].properties.latitude = lat;
               json.features[i].properties.longitude = lng;
            } else if (layer instanceof L.Marker) {
               const {lat, lng} = layer.getLatLng();
               json.features[i].properties.latitude = lat;
               json.features[i].properties.longitude = lng;
            } else if (layer.getCenter) {
               try {
                  const {lat, lng} = layer.getCenter();
                  json.features[i].properties.latitude = lat;
                  json.features[i].properties.longitude = lng;
               } catch (e) {
                  console.log('Add to map first.');
                  console.trace();
               }
            }

         }
         return json;
      } else {
         return undefined;
      }
   };

   /**
    * Callback when a shape is edited.
    * @param e the leaflet event for the edit.
    */
   const onEdited = (e) => {
      handleChange(e);
   };

   /**
    * Callback when a shape is created.
    * @param e the leaflet event for the create.
    */
   const onCreated = (e) => {
      setShapeCount(shapeCount => shapeCount + 1);
      handleChange(e);
   };

   /**
    * Callback when a shape is edited.
    * @param e the leaflet event for the delete.
    */
   const onDeleted = (e) => {
      let numDeleted = 0;
      e.layers.eachLayer(() => {
         numDeleted += 1;
      });
      setShapeCount(shapeCount => shapeCount - numDeleted);
      if (onDelete) {
         onDelete(e.layers.toGeoJSON(), e);
      } else {
         onChange && onChange(undefined, undefined);
      }
   };

   /**
    * Common handling for create and edit events. Find the layer for the shape in the event. Find the center of the
    * shape and callback onChange.
    * @param e the leaflet event for create or edit.
    */
   const handleChange = (e) => {
      onChange && onChange(getLayerJson(e), e);
   };

   //The controls are not visible unless allowEdit is true. If the limit of shapes as been created the draw controls
   // aren't shown.
   if (allowEdit) {
      return (
         <EditControl
            position='topright'
            onEdited={onEdited}
            onCreated={onCreated}
            onDeleted={onDeleted}
            draw={{
               polygon: shapeCount >= limitShapes ? false : {
                  allowIntersection: false, // Restricts shapes to simple polygons
                  drawError: {
                     color: 'red', // Color the shape will turn when intersects
                     message: '<strong>The line cannot cross an existing boundary<strong>' // Message that will
                                                                                           // show when intersect
                  },
                  shapeOptions: {
                     color: FIELD_GEO_JSON_COLOR
                  }
               },
               circle: shapeCount >= limitShapes ? false : {
                  shapeOptions: {
                     color: FIELD_GEO_JSON_COLOR
                  }
               },
               rectangle: shapeCount >= limitShapes ? false : {
                  shapeOptions: {
                     color: FIELD_GEO_JSON_COLOR
                  }
               },
               marker: false,
               circlemarker: false,
               polyline: false,
               semicircle: {
                  lineOptions: {
                     color: FIELD_GEO_JSON_COLOR,
                     weight: 3,
                  },
                  shapeOptions: {
                     color: FIELD_GEO_JSON_COLOR,
                     useOuterRadius: false
                  }
               },
            }}
         />
      );
   } else {
      return null;
   }
}
