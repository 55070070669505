import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import React, {useContext} from 'react';
import {ERROR_COLOR, WARNING_COLOR, SUCCESS_COLOR} from './Constants';
import AppearanceContext from './fhg/components/AppearanceContext';

/**
 * An HOC component to handle setting up the Material-UI theme and provider.
 * @param Component The component to apply the theme to.
 * @return {function(*): *}
 */
function withRoot(Component) {

   function WithRoot(props) {
      const {isDarkMode} = useContext(AppearanceContext);

      let useTheme = React.useMemo(
         () => {
            let materialTheme;

            if (isDarkMode) {
               materialTheme = {
                  palette: {
                     type: 'dark',
                     primary: {
                        main: '#52839F',
                     },
                     secondary: {
                        main: '#DD3E39',
                     },
                     error: {
                        main: ERROR_COLOR,
                     },
                     warning: {
                        main: WARNING_COLOR,
                     },
                     success: {
                        main: SUCCESS_COLOR,
                     },
                  },
                  typography: {
                     button: {
                        textTransform: 'none',
                     }
                  },
                  shape: {
                     borderRadius: 4,
                  }
               }
            } else {
               materialTheme = {
                  palette: {
                     primary: {
                        light: '#52839F',
                        main: '#194056',
                        dark: '#002D62',
                     },
                     secondary: {
                        main: '#AA0B06',
                     },
                     background: {
                        default: '#F8FAFB',
                     },
                     text: {
                        primary: '#707070',
                        secondary: '#52839F',
                     },
                     error: {
                        main: ERROR_COLOR,
                     },
                     warning: {
                        main: WARNING_COLOR,
                     },
                     success: {
                        main: SUCCESS_COLOR,
                     }
                  },
                  typography: {
                     button: {
                        textTransform: 'none',
                     }
                  },
                  shape: {
                     borderRadius: 4,
                  }
               };
            }
            return createMuiTheme(materialTheme);
         },
         [isDarkMode]);
      useTheme = responsiveFontSizes(useTheme);

      return (
         <MuiThemeProvider theme={useTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
               <CssBaseline/>
               <Component {...props} />
            </MuiPickersUtilsProvider>
         </MuiThemeProvider>
      );
   }

   return WithRoot;
}

export default withRoot;
