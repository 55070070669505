import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import ExpandMore from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import defer from 'lodash/defer';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect, useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import TitleCardInner from '../../../components/TitleCardInner';
import {DATE_DB_FORMAT} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useLazyQueryOfflineFHG from '../../../fhg/components/data/useLazyQueryOfflineFHG';
import useMutationFHG, {DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import DateRangePicker from '../../../fhg/components/edit/DateRangePicker';
import Grid from '../../../fhg/components/Grid';
import TypographyFHG from '../../../fhg/components/Typography';
import {cacheDelete} from '../../../fhg/utils/DataUtil';
import useMessage from '../../../fhg/utils/useMessage';
import {getDays, areDatesValid, removeOne} from '../../../fhg/utils/Utils';
import WasteTransferRecordEdit from './WasteTransferRecordEdit';
import {WASTE_STORAGE_OPTIONS_QUERY} from '../../../data/QueriesGL'
import isArray from 'lodash/isArray';

const useStyles = makeStyles(theme => ({
   rootStyle: {
      '&.Mui-expanded': {
         minHeight: 52,
      }
   },
   expansionStyle: {
      border: '1px solid lightgrey',
      width: '100%',
   },
   contentStyle: {
      margin: theme.spacing(.75, 0),
      '&.Mui-expanded': {
         margin: `${theme.spacing(1, 0)}!important`,
      }
   },
   paperStyle: {
      height: 'fit-content',
   },
   buttonStyle: {
      marginBottom: '0.35em',
   },
}), {name: 'WasteTransferRecordStyles'});

// Default properties needed for the waste transfer record.
const WASTE_TRANSFER_FRAGMENT = gql`
   fragment wasteTransferRecordInfo on WasteTransfer {
      id
      uuid
      startDate
      endDate
      fromWasteStorageId
      toWasteStorageId
      isDeleted
   }
`;

// Query for all the waste transfer records for the waste storage.
export const WASTE_TRANSFER_BY_RANGE_QUERY = gql`
   query getWasteTransferByRange($toWasteStorageId: [Int], $beginDate: String, $endDate: String)
   {
      wasteTransfer:wasteTransfer_AllWhere(wasteTransferSearch: {toWasteStorageId: $toWasteStorageId, beginDate: $beginDate, endDate: $endDate}) {
         ...wasteTransferRecordInfo
      }
   }
   ${WASTE_TRANSFER_FRAGMENT}
`;

// Delete the waste transfer on the server.
const WASTE_TRANSFER_DELETE = {
   mutation: gql`
      mutation WasteTransferDelete($id: Int!) {
         wasteTransfer_Delete(wasteTransferId: $id)
      }
   `,
   typeKey: 'wasteTransfer.type',
   actionKey: DELETE_ACTION,
};

WasteTransferRecord.propTypes = {
   parentItem: PropTypes.object.isRequired,
   onClose: PropTypes.func.isRequired,
};

/**
 * The component to record waste transfers.
 *
 * @param parentItem The waste storage
 * @param onClose Callback when the panel is closed.
 */
export default function WasteTransferRecord({parentItem = {}, onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const wasteTransferType = useMessage('wasteTransfer.type');

   const {data} = useQueryOfflineFHG(WASTE_STORAGE_OPTIONS_QUERY,
      {variables: {facilityId: parentItem.facilityId}}, 'wasteStorage.type');
   const options = get(data, 'wasteStorageList') || [];

   const [loadWasteTransferRange, {data: wasteTransferRangeData}] = useLazyQueryOfflineFHG(WASTE_TRANSFER_BY_RANGE_QUERY,
      undefined, 'wasteTransfer.type');

   const [wasteTransferDelete] = useMutationFHG(WASTE_TRANSFER_DELETE);

   const [wasteTransferList, setWasteTransferList] = useState([]);

   const [entryDays, setEntryDays] = useState([]);
   const [daySelected, setDaySelected] = useState([moment().startOf('day'), moment().endOf('day')]);
   const [monthRange, setMonthRange] = useState(
      {beginDate: moment().startOf('month'), endDate: moment().endOf('month')});
   const [isPickerOpen, setIsPickerOpen] = useState(false);
   const [expanded, setExpanded] = useState();

   /**
    * Scroll to the top of the panel when the panel is opened.
    */
   useEffect(() => {
      defer(() => {
         const titleElement = document.getElementById('wasteTransfer.add.button');
         if (titleElement) {
            titleElement.scrollIntoView(false);
         }
      });
   }, []);

   /**
    * Query for the waste transfer records for the waste storage when the waste storage selected changes.
    */
   useEffect(() => {
      if (parentItem) {
         loadWasteTransferRange({
            variables: {
               toWasteStorageId: parentItem && parentItem.id,
               beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
               endDate: monthRange.endDate.format(DATE_DB_FORMAT),
            }
         });
      }
      //Don't change when monthRange changes.
      // eslint-disable-next-line
   }, [parentItem]);

   /**
    * Create a new waste transfer record and set the defaults.
    *
    * @return {{toWasteStorageId: any, uuid: (*|string)}}
    */
   const createNew = () => {
      return {
         uuid: uuid(),
         toWasteStorageId: get(parentItem, 'id'),
      }
   };

   /**
    * Set the initial values when new waste transfer records are returned from the server or the selected date changes.
    * @type {function(*=, *=): void}
    */
   const setInitialValues = useCallback((isSetEntryDays = false, dates = daySelected) => {
      const wasteTransfer = get(wasteTransferRangeData, 'wasteTransfer') || [];

      if (wasteTransfer.length > 0) {
         if (isSetEntryDays) {
            setEntryDays(getDays(wasteTransfer));
         }
         const filteredDays = filter(wasteTransfer, item => {
            const isStartSame =  moment(item.startDate, DATE_DB_FORMAT).isSame(dates[0], 'day');
            const isEndDSame = moment(item.endDate, DATE_DB_FORMAT).isSame(dates[1] || dates[0], 'day');
            return isStartSame && isEndDSame;
         }) || [];

         if (filteredDays.length > 0) {
            setWasteTransferList(filteredDays);
         } else {
            setWasteTransferList([
               createNew()
            ]);
         }
      } else {
         if (isSetEntryDays) {
            setEntryDays([]);
         }
         setWasteTransferList([
            createNew()
         ]);
      }
   }, [parentItem, wasteTransferRangeData]);

   /**
    * Set the initial values when the waste transfer records are returned from the server. Update the calendar with the
    * days that have a record.
    */
   useEffect(() => {
      if (wasteTransferRangeData) {
         setInitialValues(true, daySelected);
      }
      //Don't change on daySelected
      // eslint-disable-next-line
   }, [setInitialValues, wasteTransferRangeData]);

   /**
    * Refetch the given queries when a mutation is done.
    * @return {[{variables: {beginDate: *, toWasteStorageId, endDate: *}, query: DocumentNode}]}
    */
   const getCacheQueries = () => {
      const variables = {
         toWasteStorageId: parentItem && parentItem.id,
         beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
         endDate: monthRange.endDate.format(DATE_DB_FORMAT)
      };
      return [{query: WASTE_TRANSFER_BY_RANGE_QUERY, variables}];
   };

   /**
    * Callback when the date changes. If a new month is selected, fetch the waste transfer records.
    * @param event The date selected event.
    * @param dates The dates selected.
    * @return {Promise<void>}
    */
   const handleDateChange = async (event, dates) => {

      if (areDatesValid(dates)) {
         setDaySelected(dates);
         if (!dates[0].isBetween(monthRange.beginDate, monthRange.endDate, 'day', '[]')) {
            await handleMonthChange(dates);
         } else {
            setInitialValues(false, dates);
         }
      }
   };

   /**
    * Callback when the month selected on the calendar changes. Fetch the waste transfer records for the new month range.
    * @param dates The dates selected.
    * @return {*} The promise for the completion of the fetch.
    */
   const handleMonthChange = (dates) => {
      let beginDate, endDate;

      if (isArray(dates)) {
         beginDate = moment(dates[0]).startOf('month');
         if (dates.length > 1) {
            endDate = moment(dates.length > 1 ? dates[1] : dates[0]).endOf('month');
         }
      } else {
         beginDate = moment(dates).startOf('month');
         endDate = moment(dates).endOf('month');
      }
      setMonthRange({beginDate, endDate});
      return loadWasteTransferRange({
         variables: {
            toWasteStorageId: parentItem && parentItem.id,
            beginDate: beginDate.format(DATE_DB_FORMAT),
            endDate: endDate.format(DATE_DB_FORMAT),
         }
      });
   };

   /**
    * Delete the selected waste transfer record. If the waste transfer record doesn't exist on the server, remove it
    * from the list.
    *
    * @param wasteTransfer The waste transfer record to delete.
    * @param index The index of the waste transfer record to delete in the list.
    * @return {function(): Promise<void>} The promise for the completion of the delete on the server.
    */
   const handleDelete = (wasteTransfer, index) => async () => {
      if (wasteTransfer && wasteTransfer.id) {
         await wasteTransferDelete({
            variables: {id: wasteTransfer.id},
            optimisticResponse: {wasteTransfer_Delete: 1},
            update: cacheDelete(getCacheQueries(), wasteTransfer.uuid, 'wasteTransfer'),
         });
      } else {
         const updatedList = removeOne([...wasteTransferList], index);
         setWasteTransferList(updatedList);
      }
   };

   /**
    * Add a new waste transfer record to the list and expand the accordion for the new record.
    *
    * @param event The add button event.
    */
   const handleAdd = (event) => {
      event.stopPropagation();
      event.preventDefault();

      setExpanded(wasteTransferList.length);

      setWasteTransferList([
         ...wasteTransferList,
         createNew(),
      ]);
   };

   /**
    * Close the edit panel. If there is a list make the accordion panel not expanded. If there isn't a list close the
    * waste transfer panel
    */
   const handleCloseEdit = () => {
      if (wasteTransferList && wasteTransferList.length <= 1) {
         onClose && onClose();
      } else {
         setExpanded(undefined);
      }
   };

   /**
    * Expand the Accordion for the index to show the edit panel.
    *
    * @param index The index at which to expand.
    */
   const handleExpand = index => (event) => {
      if (!event.isDefaultPrevented()) {
         setExpanded(expanded === index ? undefined : index);
      }
   };

   /**
    * Get the waste storage name from the options based on the id.
    *
    * @param wasteStorageId The id of the waste storage to get the name for.
    * @return {any|string} The name or Untitled if not found.
    */
   const getWasteStorageName = (wasteStorageId) => {
       return get(find(options, {id: wasteStorageId}), 'name') || 'Untitled';
   };

   return (
      <TitleCardInner
         titleId={'clientWasteStorage.wasteTransferRecord.button'}
         variant={'subtitle1'}
         onClose={isPickerOpen ? undefined : onClose}
         classes={{paperStyle: classes.paperStyle}}
         headerAction={(
            <Grid container>
               {((wasteTransferList.length > 1) || (wasteTransferList.length === 1 && get(wasteTransferList, '[0].id') !== undefined)) && (
                  <Button className={classes.buttonStyle} onClick={handleAdd}
                          style={{color: theme.palette.primary.light}}>
                     <TypographyFHG variant='button' color='inherit' style={{textDecoration: 'underline'}}
                                    id={'wasteTransfer.add.button'}/>
                  </Button>
               )}
               <ConfirmButton
                  onConfirm={handleDelete(get(wasteTransferList, '[0]'), 0)}
                  style={{display: wasteTransferList.length !== 1 || !wasteTransferList[0].id ? 'none' : undefined}}
                  titleKey={'delete.confirm.title'}
                  messageKey={'delete.confirm.message'}
                  titleValues={{type: wasteTransferType}}
                  values={{type: wasteTransferType}}
                  color='secondary'
                  buttonLabelKey={'delete.button'}
                  disableRipple
                  className={classes.buttonStyle}
                  buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
               />
            </Grid>
         )}
      >
         <Grid item container direction={'row'} spacing={1} alignItems={'center'} overflow={'visible'}
               style={{padding: 0, height: 'fit-content'}}>
            <Grid item xs={12}>
               <DateRangePicker
                  name={'date'}
                  open={isPickerOpen}
                  labelKey={'field.date.label'}
                  value={daySelected}
                  autoOk={false}
                  disableFuture={true}
                  onChange={handleDateChange}
                  onMonthChange={handleMonthChange}
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  entryDays={entryDays}
                  autoFocus
                  required
               />
            </Grid>

            {wasteTransferList.length > 1 ? wasteTransferList.map((wasteTransfer, index) => (
               <Accordion key={'expansionPanel' + (wasteTransfer && wasteTransfer.uuid) || index} name={index}
                          classes={{root: classes.expansionStyle}} expanded={expanded === index}
                          onChange={handleExpand(index)}>
                  <AccordionSummary
                     expandIcon={<ExpandMore/>}
                     aria-controls='panel1bh-content'
                     id='panel1bh-header'
                     classes={{root: classes.rootStyle, content: classes.contentStyle}}
                  >
                     <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
                        <Grid item>
                           <TypographyFHG variant={'body1'} color={'primary'} style={{fontWeight: 500}}>
                              {`From ${getWasteStorageName(wasteTransfer.fromWasteStorageId)}`}
                           </TypographyFHG>
                        </Grid>
                        <Grid item>
                           <ConfirmButton
                              onConfirm={handleDelete(wasteTransfer, index)}
                              titleKey={'delete.confirm.title'}
                              messageKey={'delete.confirm.message'}
                              titleValues={{type: wasteTransferType}}
                              values={{type: wasteTransferType}}
                              color='secondary'
                              buttonLabelKey={'delete.button'}
                              disableRipple
                              className={classes.buttonStyle}
                              buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
                           />
                        </Grid>
                     </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                     <WasteTransferRecordEdit wasteStorage={parentItem} wasteTransfer={wasteTransfer} monthRange={monthRange}
                                              daySelected={daySelected} onClose={handleCloseEdit}
                                              onUpdateCache={getCacheQueries} options={options}/>
                  </AccordionDetails>
               </Accordion>
            )) : (
               <WasteTransferRecordEdit wasteStorage={parentItem} wasteTransfer={get(wasteTransferList,
                  '[0]')} daySelected={daySelected} onClose={handleCloseEdit} onUpdateCache={getCacheQueries}
                                        options={options}
               />
            )}
         </Grid>
      </TitleCardInner>
   );
}
