import gql from 'graphql-tag';
import moment from 'moment';
import React, {useCallback, forwardRef, useEffect, useState} from 'react';
import ListEditCard from '../../../components/ListEditCard';
import {NEW_STATUS, NEW_AUTO_STATUS, EDIT_STATUS, DATE_DB_FORMAT} from '../../../Constants';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import {METER_FRAGMENT} from './FragmentsGL';
import MeterEditInfo from './MeterEditInfo';

// Query for all the meters for the facility.
const METER_QUERY = gql`
   query getMeterForEdit($wellId: [Int], $beginActiveDate: String, $endActiveDate: String){
      meters:meter_AllWhere(meterSearch: {wellId: $wellId, beginActiveDate: $beginActiveDate, endActiveDate: $endActiveDate}) {
         ...meterInfo
      }
   }
   ${METER_FRAGMENT}
`;

const MetersEditCard = forwardRef(
   function MetersEditCard({well, onChange, onListChange, onInit}, ref) {

      const today = moment();
      const variables = {
         wellId: well && well.id,
         beginActiveDate: today.startOf('year').format(DATE_DB_FORMAT),
         endActiveDate: today.endOf('year').format(DATE_DB_FORMAT),
      };
      const {data} = useQueryFHG(METER_QUERY, {variables, skip: !(well && well.id && well.__state !== EDIT_STATUS), fetchPolicy: 'cache-and-network'}, 'meter.type');

      const [meters, setMeters] = useState([]);

      useEffect(() => {
          if (well) {
             if (well.meters && well.meters.length > 0) {
                setMeters(well.meters || []);
             } else {
                setMeters([]);
             }
          } else {
             setMeters([]);
          }
      }, [well]);

      useEffect(() => {
         // Don't update from the db if the well has had changes.
          if (!!data && well.__state !== EDIT_STATUS) {
             // Meters are queried separately, so add the meters to the well to handle submits.
             well.meters = data.meters || [];
             setMeters(well.meters);
          }
      }, [well.__state, well.meters, data]);

      const handleCreateItem = useCallback((isAuto = false) => {
         return {
                  __state: isAuto? NEW_AUTO_STATUS : NEW_STATUS,
                  acreFeetMultiplier: undefined,
                  acreInchesMultiplier: undefined,
                  beginningMeter: undefined,
                  date: new Date(),
                  gallonsUnitMultiplier: undefined,
                  wellId: well && well.id,
                  useTypeId: 1,
         };
      }, [well]);

      return (
         <ListEditCard
            key={'Meters' + (well && well.uuid)}
            ref={ref}
            createItem={handleCreateItem}
            onChange={onChange}
            onInit={onInit}
            onListChange={onListChange}
            listDefaults={meters}
            addKey={'facility.meterAdd.button'} deleteKey={'facility.meterDelete.button'}
               secondaryProps={[
                  {
                     labelKey: 'facility.beginningMeterShort.label', prop: 'beginningMeter'
                  }, {
                     labelKey: 'meter.useType.label', prop: 'useType.name'
                  }, {
                     labelKey: 'facility.acreage.label', prop: 'acreage'
                  },
               ]}
            confirmDeletes
         >
            <MeterEditInfo/>
         </ListEditCard>
      );
   });

export default MetersEditCard;
