import {useApolloClient} from '@apollo/client';
import {useLazyQuery} from '@apollo/react-hooks';
import {useIsConnected} from '@wora/detect-network';
import {useContext, useEffect, useRef, useState} from 'react';
import StatusContext from '../StatusContext';

export default function useLazyQueryOfflineFHG(query, options, typeKey, showLoading) {
   const client = useApolloClient();
   const isConnected = useIsConnected();

   const {setError, setErrorGeneral, setProgress} = useContext(StatusContext);
   const [queryFunction, {loading, error, data}] = useLazyQuery(query, {fetchPolicy: isConnected !== false ? 'cache-and-network' : undefined, ...options});

   const [, forceUpdate] = useState(null);


   const ref = useRef({
      hydrate: client.isRehydrated(),
      data: null,
   });

   ref.current.data = data;

   useEffect(() => {
      return () => {
         if (showLoading) {
            setProgress(false);
         }
      }
   }, [setProgress, showLoading]);

   useEffect(() => {
      if (showLoading) {
         setProgress(loading)
      }
   }, [loading, showLoading, setProgress]);

   useEffect(() => {
      if (error &&  typeKey) {
         console.log('Error type', typeKey);
         console.log(error, error.stackTrace);
         if (typeKey) {
            setErrorGeneral({typeKey, error, actionKey: 'load'});
         }
      }
   }, [error, setErrorGeneral, setError, typeKey]);

   if (!ref.current.hydrate) {
      ref.current.hydrate = true;
      client.hydrate().then(() => {
         const { data } = ref.current;
         if (!data) forceUpdate(client);
      });
   }

   return [queryFunction, {loading, error, data}];
}
