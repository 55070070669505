import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useIsConnected} from '@wora/detect-network';
import gql from 'graphql-tag';
import defer from 'lodash/defer';
import get from 'lodash/get';
import find from 'lodash/find';
import React, {useState, useEffect, useRef, useContext} from 'react';
import {useParams} from 'react-router-dom';
import AuthContext from '../../components/AuthContext';
import TitleCardInner from '../../components/TitleCardInner';
import useQueryOfflineFHG from '../../fhg/components/data/useQueryOfflineFHG';
import Grid from '../../fhg/components/Grid';
import Online from '../../fhg/components/Online';
import SessionContext from '../../fhg/components/security/SessionContext';
import TypographyFHG from '../../fhg/components/Typography';
import useWidthRule from '../../fhg/components/useWidthRule';
import HeadcountRecord from './record/HeadcountRecord';
import PrecipitationRecord from './record/PrecipitationRecord';
import ReportDischarge from './record/ReportDischarge';
import WasteExport from './record/WasteExport';

const PRECIPITATION_RECORD = 'precipitation record';
const HEADCOUNT_RECORD = 'headcount record';
const WASTE_EXPORT = 'waste export';
const REPORT_DISCHARGE = 'report discharge';
const NO_RECORD = 'no record';

const useStyles = makeStyles(theme => ({
   addButtonStyle: {
      color: theme.palette.text.secondary,
   },
   desktopFrameStyle: {},
   mobileFrameStyle: {},
}), {name: 'FacilityEditClientStyles'});

FacilityClient.propTypes = {};

export const FACILITY_BY_UUID_FOR_CLIENT_QUERY = gql`
    query getFacilityByUuidForFacilityEdit ($uuid: String!) {
        facility: facility_ByUuid(facilityUuid: $uuid) {
            id
            uuid
            name
            latitude
            longitude
            gallonsPerHeadPerDay
            spccLink
            livestock {
                id
                livestockTypeId
                facilityId
                max
                uuid
                isDeleted
            }
            isDeleted
        }
        livestockTypes: livestockType_All {
            id
            uuid
            name
            isDeleted
        }
    }
`;

function FacilityClient({defaultItem, clientName, onClose}) {
   const classes = useStyles();
   const {uuid} = useParams();
   const ref = useRef();
   const isDesktopWidth = useWidthRule('up', 'sm');
   const {facilityAccess} = useContext(SessionContext);
   const {isAdmin} = useContext(AuthContext);
   const isConnected = useIsConnected();

   const {data} = useQueryOfflineFHG(FACILITY_BY_UUID_FOR_CLIENT_QUERY,
      {variables: {uuid}, skip: !uuid, errorPolicy: 'all'}, 'facility.type');

   const [actionType, setActionType] = useState(NO_RECORD);
   const [facility, setFacility] = useState();

   useEffect(() => {
      if (!!data) {
         setFacility(data.facility);
      }
   }, [data]);

   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }

   }, [ref]);

   const handleClose = () => {
      setActionType(NO_RECORD)
   };

   const getLivestockType = (livestock) => {
      const livestockTypes = get(data, 'livestockTypes') || [];
      return get(find(livestockTypes, {id: livestock.livestockTypeId}), 'name') || 'N/A';
   };

   return (
      <Grid ref={ref} container direction={'column'} wrap={'nowrap'} resizable>
         <Grid item resizable={isDesktopWidth ? false : undefined} fullHeight={isDesktopWidth ? false : undefined}
               className={isDesktopWidth ? classes.desktopFrameStyle : classes.mobileFrameStyle}>
            <TitleCardInner
               titleId={'clientFacility.title'}
               values={{...defaultItem, hasBold: true}}
               onClose={onClose}
            >
               {(actionType === NO_RECORD || isDesktopWidth) && (
                  <Grid name={'facility frame'} container direction={'column'} style={{position: 'relative'}}>
                     <Online marginTop={0}>
                        <Grid name={'facility details'} container item direction={'column'}>
                           <TypographyFHG variant={'subtitle1'} id={'clientWell.galHeadDay.label'} hasBold
                                          values={{gal: facility ? facility.gallonsPerHeadPerDay : 0}}/>
                           {(facility && facility.livestock && facility.livestock.length > 0) &&
                           facility.livestock.map(livestock => (
                              <TypographyFHG key={livestock.uuid} variant={'subtitle1'}
                                             id={'clientFacility.headcount.title'} hasBold values={{
                                 livestockType: getLivestockType(livestock),
                                 maximumAllowed: livestock.max
                              }}/>
                           ))}
                        </Grid>
                     </Online>
                     <Grid container item name={'facility actions'} direction={'row'}>
                        <Grid item xs={6} sm={12} md={6}>
                           <Button className={`${classes.addButtonStyle} ${classes.linkStyle}`} size={'small'}
                                   onClick={() => setActionType(HEADCOUNT_RECORD)}
                                   disabled={!isAdmin && !facilityAccess}
                           >
                              <TypographyFHG variant='button' color={'inherit'}
                                             id={'clientFacility.headcountRecord.button'}
                                             style={{textDecoration: 'underline'}}/>
                           </Button>
                        </Grid>
                        <Grid item xs={6} sm={12} md={6}>
                           <Button className={classes.addButtonStyle} disableRipple size={'small'}
                                   disabled={!isAdmin && !facilityAccess}
                                   onClick={() => setActionType(PRECIPITATION_RECORD)}>
                              <TypographyFHG variant='button' color={'inherit'}
                                             id={'clientFacility.precipitation.button'}
                                             style={{textDecoration: 'underline'}}/>
                           </Button>
                        </Grid>
                        <Grid item xs={6} sm={12} md={6}>
                           <Button className={`${classes.addButtonStyle} ${classes.linkStyle}`} size={'small'}
                                   onClick={() => setActionType(WASTE_EXPORT)} disabled={!isAdmin && !facilityAccess}>
                              <TypographyFHG variant='button' color={'inherit'}
                                             id={'clientFacility.wasteExport.button'}
                                             style={{textDecoration: 'underline'}}/>
                           </Button>
                        </Grid>
                        {(facility && facility.spccLink) && (
                           <Grid item xs={6} sm={12} md={6}>
                              <Button color='secondary' className={classes.addButtonStyle} size={'small'}
                                      component={Link} href={facility.spccLink}
                                      disabled={!isConnected || (!isAdmin && !facilityAccess)}
                                      rel='noreferrer' target='_blank'
                              >
                                 <TypographyFHG variant='button' color={'inherit'}
                                                id={'clientFacility.spccLink.button'}
                                                style={{textDecoration: 'underline'}}/>
                              </Button>
                           </Grid>
                        )}
                        <Grid item xs={6} sm={12} md={6}>
                           <Button color={'secondary'} className={classes.addButtonStyle} size={'small'}
                                   onClick={() => setActionType(REPORT_DISCHARGE)}
                                   disabled={!isAdmin && !facilityAccess}>
                              <TypographyFHG variant='subtitle1' color={'inherit'}
                                             id={'clientFacility.reportDischarge.button'}
                                             style={{textDecoration: 'underline'}}/>
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
            </TitleCardInner>
         </Grid>
         <Grid item resizable={isDesktopWidth} style={{minHeight: actionType === NO_RECORD ? 0 : 190}}>
            {{
               [NO_RECORD]: undefined,
               [PRECIPITATION_RECORD]: <PrecipitationRecord facility={facility} onClose={handleClose}/>,
               [HEADCOUNT_RECORD]: <HeadcountRecord facility={facility} livestockTypes={get(data, 'livestockTypes')}
                                                    onClose={handleClose}/>,
               [WASTE_EXPORT]: <WasteExport facility={facility} onClose={handleClose}/>,
               [REPORT_DISCHARGE]: <ReportDischarge defaultItem={facility} onClose={handleClose}
                                                    clientName={clientName}/>
            }[actionType]}
         </Grid>
      </Grid>
   );
}

export default FacilityClient;
