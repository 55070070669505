import gql from 'graphql-tag';
import find from 'lodash/find';
import map from 'lodash/map';
import React, {useCallback, forwardRef, useMemo} from 'react';
import ListEditCard from '../../../components/ListEditCard';
import {NEW_STATUS, NEW_AUTO_STATUS} from '../../../Constants';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import {LIVESTOCK_FRAGMENT} from './FragmentsGL';
import LivestockEditInfo from './LivestockEditInfo';
import {v4 as uuid} from 'uuid';

// Query for all the waste exports for the facility on the date.
export const LIVESTOCK_QUERY = gql`
   query getLivestockForEdit($facilityId: [Int])
   {
      livestockList:livestock_AllWhere(livestockSearch: {facilityId: $facilityId}) {
         ...livestockInfoForEdit
      }
   }
   ${LIVESTOCK_FRAGMENT}
`;

// The client with the given client ID and property ID.
export const LIVESTOCK_TYPES_QUERY = gql`
   query getLivestockTypes{
      livestockTypes:livestockType_All {
         id
         name
         isDeleted
      }
   }
`;

const LivestockListCard = forwardRef(
function LivestockListCard({parentId}, ref) {

   const {data} = useQueryFHG(LIVESTOCK_QUERY, {variables: {facilityId: parentId}, skip: !parentId}, 'livestock.type');
   const {data: livestockTypes} = useQueryFHG(LIVESTOCK_TYPES_QUERY, undefined, 'livestockTypes.type');

   const livestock = useMemo(() => {
      if (data) {
         return map(data.livestockList, livestock => ({...livestock}));
      }
      return [];
   }, [data]);

   const handleCreateItem = useCallback((isAuto = false) => {
      return {
         __state: isAuto? NEW_AUTO_STATUS : NEW_STATUS,
         uuid: uuid(),
         livestockTypeId: undefined,
         facilityId: parentId,
         max: undefined,
      };
   }, [parentId]);

   const handleGetName = editItem => {
      if (livestockTypes && editItem) {
         const option = find(livestockTypes.livestockTypes, {id: editItem.livestockTypeId});
         if (option) {
            return option.name;
         }
      }
      return '';
   };

   return (
      <ListEditCard
         key={'livestock'}
         ref={ref}
         allowExpand={false}
         createItem={handleCreateItem}
         listDefaults={livestock} getName={handleGetName}
                    addKey={'facility.addLivestock.button'} deleteKey={'facility.deleteLivestock.button'}
                    secondaryProp={'max'} secondaryLabel={'Max'} nameProp={'livestockTypeId'}
      >
         <LivestockEditInfo livestockTypes={livestockTypes} parentId={parentId}/>
      </ListEditCard>
   );
});

export default LivestockListCard;
