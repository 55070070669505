import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {v4 as uuid} from 'uuid';
import AutocompleteFHG from '../../../components/AutocompleteFHG';
import {DATE_DB_FORMAT} from '../../../Constants';
import useMutationFHG, {CREATE_UPDATE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import Form from '../../../fhg/components/edit/Form';
import Prompt from '../../../fhg/components/edit/Prompt';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import ProgressButton from '../../../fhg/components/ProgressButton';
import {cacheUpdate} from '../../../fhg/utils/DataUtil';

const useStyles = makeStyles({
   formStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
      position: 'relative',
   },
   paperStyle: {
      height: 'fit-content',
   },
   buttonStyle: {
      marginBottom: '0.35em',
   },
}, {name: 'WasteTransferEditRecordStyles'});

// Default properties needed for the waste transfer.
const WASTE_TRANSFER_FRAGMENT = gql`
   fragment wasteTransferRecordInfo on WasteTransfer {
      id
      uuid
      startDate
      endDate
      fromWasteStorageId
      toWasteStorageId
      isDeleted
   }
`;

const defaultWasteTransfer = {
   id: 0,
   isDeleted: false,
};

// Create or Update the waste transfer with the given properties.
const WASTE_TRANSFER_CREATE_UPDATE = {
   mutation: gql`
      mutation WasteTransferCreateUpdate(
         $toWasteStorageId: Int
         $fromWasteStorageId: Int
         $startDate: String
         $endDate: String
         $uuid: String!
      ) {
         wasteTransfer: wasteTransfer_CreateUpdate(wasteTransfer: {
            toWasteStorageId: $toWasteStorageId
            fromWasteStorageId: $fromWasteStorageId
            startDate: $startDate
            endDate: $endDate
            uuid: $uuid
         }) {
            ...wasteTransferRecordInfo
         }
      }
      ${WASTE_TRANSFER_FRAGMENT}
   `,
   typeKey: 'wasteTransfer.type',
   actionKey: CREATE_UPDATE_ACTION,
};

WasteTransferRecordEdit.propTypes = {
   wasteStorage: PropTypes.object.isRequired,   //The selected waste storage for the transfer.
   wasteTransfer: PropTypes.object.isRequired,  // The waste transfer record selected.
   daySelected: PropTypes.any.isRequired,       // The selected day for the transfer.
   onUpdateCache: PropTypes.func.isRequired,    // The queries to execute for a mutation.
   onClose: PropTypes.func.isRequired,          // Callback when the panel is closed.
   options: PropTypes.array.isRequired,         // The list of waste storage to select from.
};

/**
 * The component to edit waste transfers records.
 *
 * @param wasteStorage The waste storage
 * @param wasteTransfer The waste transfer record being edited.
 * @param daySelected The day selected for the waste transfer record.
 * @param onUpdateCache The queries to execute when a mutation is made.
 * @param onClose Callback when the panel is closed.
 * @param options The list of waste storage to select from.
 */
export default function WasteTransferRecordEdit({wasteStorage = {}, wasteTransfer, daySelected, onUpdateCache, onClose, options}) {
   const classes = useStyles();
   const theme = useTheme();

   const [wasteTransferCreateUpdate, {loading}] = useMutationFHG(WASTE_TRANSFER_CREATE_UPDATE);

   const [editValues, handleChange, {isChanged, defaultValues, setDefaultValues, setIsChanged}] = useEditData(
      {uuid: uuid(), toWasteStorageId: get(wasteStorage, 'id')}, ['uuid', 'toWasteStorageId']);

   const [isSaving, setIsSaving] = useState(false);

   /**
    * Initialize the default values for all the edit components with the selected waste transfer.
    */
   useEffect(() => {
      if (wasteTransfer) {
         setDefaultValues(wasteTransfer);
      }
   }, [wasteTransfer, setDefaultValues]);

   /**
    * Submit the waste transfer to the server.
    * @return {Promise<void>}
    */
   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);
            const variables = {
               ...editValues,
               startDate: daySelected[0].format(DATE_DB_FORMAT),
               endDate: (daySelected[1] || daySelected[0]).format(DATE_DB_FORMAT)
            };
            await wasteTransferCreateUpdate({
               variables,
               optimisticResponse: {
                  __typename: 'Mutation',
                  wasteTransfer: {
                     __typename: 'WasteTransfer',
                     ...defaultWasteTransfer,
                     ...defaultValues,
                     ...variables,
                  }
               },
               update: cacheUpdate(onUpdateCache(), editValues?.uuid, 'wasteTransfer'),
            });
            setIsChanged(false);
            onClose && onClose();
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         onClose && onClose();
      }
   };

   return (
      <Form className={classes.formStyle} onSubmit={handleSubmit}>
         <Prompt when={isChanged}/>
         <Grid container direction={'row'} spacing={1} alignItems={'center'}
               style={{padding: 0, height: 'fit-content'}}>
            <Grid item xs={6} sm={12} md={6}>
               <AutocompleteFHG
                  name={'fromWasteStorageId'}
                  labelKey={'wasteTransfer.from.label'}
                  options={options}
                  autoFocus
                  defaultValue={defaultValues.fromWasteStorageId}
                  value={editValues.fromWasteStorageId}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item xs={6} sm={12} md={6}>
               <AutocompleteFHG
                  name={'toWasteStorageId'}
                  labelKey={'wasteTransfer.to.label'}
                  options={options}
                  defaultValue={defaultValues.toWasteStorageId}
                  value={editValues.toWasteStorageId}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
               />
            </Grid>
         </Grid>
         <Grid item
               style={{
                  marginTop: theme.spacing(1),
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: theme.palette.background.paper
               }}
               fullWidth>
            <ProgressButton isProgress={loading} variant='contained' color='primary' type={'submit'}
                            size={'small'} labelKey={'save.label'} disabled={isSaving}/>
         </Grid>
      </Form>
   );
}
