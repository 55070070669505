import PropTypes from 'prop-types';
import React, {Fragment, useState} from 'react';
import ModalDialog from './dialog/ModalDialog';
import ProgressButton from './ProgressButton';
import Typography from './Typography';

/**
 * Button Component to show confirmation.
 *
 * Reviewed:
 */
ConfirmButton.propTypes = {
   titleKey: PropTypes.string.isRequired,       // Localization key for the title.
   messageKey: PropTypes.string.isRequired,     // Localization key for the messages.
   buttonLabelKey: PropTypes.string,            // Localization key for the button label.
   onConfirm: PropTypes.func.isRequired,        // The callback when the action is confirmed.
   onCancel: PropTypes.func,                    // The callback when the action is canceled.
   component: PropTypes.any,                    // The Button component. Defaults to Button.
   isProgress: PropTypes.bool,                  // Indicates if the confirmed action is in progress.
   buttonProperties: PropTypes.any              // The properties for the button component.
};

export default function ConfirmButton({titleKey, messageKey, buttonLabelKey, onConfirm, onCancel, confirmProps,
   children, color, component, values, titleValues, isProgress=false, buttonTypographyProps, ...buttonProperties}) {

   const [isModalOpen, setIsModalOpen] = useState(false);

   const handleClick = event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      if (buttonProperties.type !== 'submit') {
         setIsModalOpen(true);
      }

      buttonProperties && buttonProperties.onClick && buttonProperties.onClick(event);
   };

   const handleConfirm = (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      setIsModalOpen(false);
      onConfirm && onConfirm();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      onCancel && onCancel();
   };

   return (
      <Fragment>
         {isModalOpen && (
            <ModalDialog submitKey={buttonLabelKey} {...confirmProps} titleKey={titleKey} messageKey={messageKey} onSubmit={handleConfirm}
                         onClose={handleCancel} hideBackdrop submitColor={color} messageValues={values} titleValues={titleValues}
            />
         )}
         <ProgressButton isProgress={isProgress} onClick={handleClick} color={color} {...buttonProperties}>
            {(!component && buttonLabelKey) && (
               <Typography variant={'button'} id={buttonLabelKey} {...buttonTypographyProps}/>
            )}
            {children}
         </ProgressButton>
      </Fragment>
   );
}

