import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import {Storage} from 'aws-amplify';
import gql from 'graphql-tag';
import isArray from 'lodash/isArray';
import round from 'lodash/round';
import defer from 'lodash/defer';
import filter from 'lodash/filter';
import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import CheckboxFHG from '../../../components/CheckboxFHG';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import {DATE_DB_FORMAT, BUCKET_NAME, CLIENT_DASHBOARD_PATH} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useLazyQueryOfflineFHG from '../../../fhg/components/data/useLazyQueryOfflineFHG';
import useMutationFHG, {CREATE_UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import ConfirmDialog from '../../../fhg/components/dialog/ConfirmDialog';
import DateRangePicker from '../../../fhg/components/edit/DateRangePicker';
import Form from '../../../fhg/components/edit/Form';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import Typography from '../../../fhg/components/Typography';
import Video from '../../../fhg/components/video/Video';
import {cacheAdd} from '../../../fhg/utils/DataUtil';
import {cacheUpdate, cacheDelete} from '../../../fhg/utils/DataUtil';
import useMessage from '../../../fhg/utils/useMessage';
import {areDatesValid} from '../../../fhg/utils/Utils';
import {toNumber} from '../../../fhg/utils/Utils';
import {CLIENT_BY_ID_DASHBOARD_QUERY} from '../ClientDashboard';
import localForage from 'localforage';
import {useIsConnected} from '@wora/detect-network';

const useStyles = makeStyles(theme => ({
   paperStyle: {
      height: 'fit-content',
   },
   buttonStyle: {
      marginBottom: '0.35em',
   },
   addPhotoStyle: {
      marginLeft: theme.spacing(1),
   },
   backdrop: {
      position: 'relative',
      zIndex: theme.zIndex.drawer + 1,
      opacity: '0.5 !important',
   },
}), {name: 'PondLevelRecordStyles'});

// Default properties needed for the pond Level record.
export const POND_LEVEL_FRAGMENT = gql`
    fragment pondLevelRecordInfo on PondLevel {
        id
       date
        currentWaterLevel
        wasteStorageId
        isDeleted
        visualInspection
        uuid
        imageData {
            uuid:imageHash
            id: imageKey
            imageS3
        }
        note
    }
`;

const defaultPondLevelRecord = {
   id: 0,
   isDeleted: false,
   imageData: null,
   note: '',
   wasteStorageId: 0,
};

// Query for all the pond Level records for the waste storage.
export const POND_LEVEL_BY_RANGE_QUERY = gql`
    query getPondLevelByRange($wasteStorageId: [Int], $beginDate: String, $endDate: String)
    {
        pondLevel:pondLevel_AllWhere( pondLevelSearch: {wasteStorageId: $wasteStorageId, beginDate: $beginDate, endDate: $endDate}) {
            ...pondLevelRecordInfo
        }
    }
    ${POND_LEVEL_FRAGMENT}
`;

// Create or Update the pond Level with the given properties.
const POND_LEVEL_CREATE_UPDATE = {
   mutation: gql`
       mutation PondLevelCreateUpdate(
           $parentItemId: Int
           $date: String
           $note: String
           $currentWaterLevel: Float
           $visualInspection: Boolean
           $imageData: ImageS3Data
           $uuid: String!
       ) {
           pondLevel: pondLevel_CreateUpdate(pondLevel: {
               wasteStorageId: $parentItemId
               currentWaterLevel: $currentWaterLevel
               visualInspection: $visualInspection
               imageS3Data: $imageData
               note: $note
               date: $date
               uuid: $uuid
           }) {
               ...pondLevelRecordInfo
           }
       }
       ${POND_LEVEL_FRAGMENT}
   `,
   typeKey: 'pondLevel.type',
   actionKey: CREATE_UPDATE_ACTION,
};

// Bulk Create or Update the pond Level with the given properties.
const POND_LEVEL_BULK_CREATE_UPDATE = {
   mutation: gql`
      mutation PondLevelCreateUpdateBulk(
         $parentItemId: Int!
         $startDate: String!
         $endDate: String!
         $note: String
         $currentWaterLevel: Float!
         $visualInspection: Boolean
         $imageData: ImageS3Data
         $overwrite: Boolean
      ) {
         pondLevels: pondLevel_CreateUpdateBulk(overwrite: $overwrite, pondLevel: {
            wasteStorageId: $parentItemId
            currentWaterLevel: $currentWaterLevel
            visualInspection: $visualInspection
            imageS3Data: $imageData
            note: $note
            startDate: $startDate
            endDate: $endDate
         }) {
            ...pondLevelRecordInfo
         }
      }
      ${POND_LEVEL_FRAGMENT}
   `,
   typeKey: 'pondLevel.type',
   actionKey: CREATE_UPDATE_ACTION,
};

// Delete the waste export on the server.
const POND_LEVEL_DELETE = {
   mutation: gql`
       mutation PondLevelDelete($id: Int!) {
           pondLevel_Delete(pondLevelId: $id)
       }
   `,
   typeKey: 'pondLevel.type',
   actionKey: DELETE_ACTION,
};

PondLevelRecord.propTypes = {
   parentItem: PropTypes.object.isRequired,
   onClose: PropTypes.func.isRequired,
};

/**
 * The component to record the pond (i.e. waste storage) level.
 *
 * @param parentItem The admin waste storage preferences record.
 * @param onClose Callback when the pane is closed.
 */
export default function PondLevelRecord({parentItem = {}, onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const isConnected = useIsConnected();

   let messageMatch = useRouteMatch({path: CLIENT_DASHBOARD_PATH, strict: false, sensitive: false});
   const pondLevelType = useMessage('pondLevel.type');
   const ref = useRef();
   const [useCamera, setUseCamera] = useState(false);
   const [photoUri, setPhotoUri] = useState();
   const [showNote, setShowNote] = useState(false);
   const [image, setImage] = useState();

   const [loadPondLevelRange, {data: pondLevelRangeData}] = useLazyQueryOfflineFHG(POND_LEVEL_BY_RANGE_QUERY,
      undefined, 'pondLevel.type');

   const [pondLevelCreateUpdate] = useMutationFHG(POND_LEVEL_BULK_CREATE_UPDATE);
   const [pondLevelDelete] = useMutationFHG(POND_LEVEL_DELETE);

   const [editValues, handleChange, {isChanged, defaultValues, setDefaultValues, setIsChanged}] = useEditData(
      {visualInspection: true, uuid: uuid(), parentItemId: get(parentItem, 'id')},
      ['visualInspection', 'id', 'uuid', 'parentItemId']);

   const [aboveOperatingLevel, setAboveOperatingLevel] = useState(false);

   const [entryDays, setEntryDays] = useState([]);
   const [monthRange, setMonthRange] = useState(
      {beginDate: moment().startOf('month'), endDate: moment().endOf('month')});
   const [daySelected, setDaySelected] = useState([moment(), moment()]);
   const [isPickerOpen, setIsPickerOpen] = useState(false);
   const [isSaving, setIsSaving] = useState(false);

   /**
    * When the component has been displayed, scroll it into view.
    */
   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }
      if (parentItem) {
         loadPondLevelRange({
            variables: {
               wasteStorageId: parentItem && parentItem.id,
               beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
               endDate: monthRange.endDate.format(DATE_DB_FORMAT),
            }
         });
      }
      // eslint-disable-next-line
   }, [ref, parentItem]);

   const setInitialValues = useCallback((isSetEntryDays = false, dates = daysSelected) => {
      const pondLevel = get(pondLevelRangeData, 'pondLevel') || [];

      if (pondLevel.length > 0) {
         if (isSetEntryDays) {
            setEntryDays(pondLevel.map(item => item.date));
         }
         const filteredDays = filter(pondLevel, item => {
            return moment(item.date, DATE_DB_FORMAT).isBetween(dates[0], dates[1] || dates[0], 'day', '[]');
         }) || [];

         if (filteredDays.length > 0) {
            setDefaultValues({...filteredDays[0]});
            setShowNote(!!get(filteredDays, '[0].note'));
         } else {
            setDefaultValues({visualInspection: true, uuid: uuid(), parentItemId: get(parentItem, 'id')});
            setShowNote(false);
         }
      } else {
         if (isSetEntryDays) {
            setEntryDays([]);
         }
         setDefaultValues({visualInspection: true, uuid: uuid(), parentItemId: get(parentItem, 'id')});
         setShowNote(false);
      }
   }, [parentItem, pondLevelRangeData, setDefaultValues]);

   useEffect(() => {
      if (pondLevelRangeData) {
         setInitialValues(true, daySelected);
      }
      //Don't update on daySelected changes.
      // eslint-disable-next-line
   }, [
      pondLevelRangeData,
      setInitialValues,
   ]);

   const getCacheQueries = () => {
      const variables = {
         wasteStorageId: parentItem && parentItem.id,
         beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
         endDate: monthRange.endDate.format(DATE_DB_FORMAT)
      };
      return [
         {query: POND_LEVEL_BY_RANGE_QUERY, variables, queryPath: 'pondLevel'}
      ];
   };

   const getRefetchQueries = () => {
      return [
         {query: CLIENT_BY_ID_DASHBOARD_QUERY, variables: {clientId: toNumber(get(messageMatch, 'params.clientId'))}},
      ];
   };

   /**
    * Submit the pond Level.
    * @return {Promise<void>}
    */
   const handleSubmit = async () => {
      let useImageData = {};

      if (isChanged) {
         try {
            setIsSaving(true);
            const startDate = moment(daySelected[0]);
            const endDate = moment((daySelected[1] || daySelected[0]));
            const imageData = photoUri ? await handleCameraSubmit() : photoUri;
            const variables = {
               ...editValues,
               startDate: startDate.format(DATE_DB_FORMAT),
               endDate: endDate.format(DATE_DB_FORMAT),
               overwrite: true,
            };

            if (imageData) {
               variables.imageData = imageData;
               const imageFilename = imageData.imageLocation.substring((BUCKET_NAME+'/upload/').length);
               //Set the values for the optimistic response. The values sent to the server for imageData is different
               // than those coming back.

               useImageData = {
                  imageData: {
                     imageS3: imageData.imageLocation,
                     __typename: 'ImageData',
                     id: `pondLevel/${variables.uuid}-${imageFilename}`,
                     uuid: uuid(),
                  }
               };
            }
            let days = moment(endDate).diff(startDate, 'day') + 1;
            let pondLevels = Array.apply(0, new Array(days)).map(i => ({
               __typename: 'PondLevel',
            ...defaultPondLevelRecord,
               id: uuid(),
               date: moment(startDate).add(1, 'day').format(DATE_DB_FORMAT),
            ...defaultValues,
            ...variables,
               startDate: undefined,
               endDate: undefined,
            }));
            pondLevels[0] = {...pondLevels[0], ...useImageData};

            await pondLevelCreateUpdate({
               variables,
               optimisticResponse: {
                  __typename: 'Mutation',
                  refetchQueries: getRefetchQueries,
                  pondLevels,
               },
               update: cacheAdd(getCacheQueries(), 'pondLevels', true),
               refetchQueries: getRefetchQueries
            });
            setIsChanged(false);
            onClose && onClose();
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         onClose && onClose();
      }
   };

   const handleDateChange = async (event, dates) => {

      if (areDatesValid(dates)) {
         setDaySelected(dates);
         if (!dates[0].isBetween(monthRange.beginDate, monthRange.endDate, 'day', '[]')) {
            await handleMonthChange(dates);
         } else {
            setInitialValues(false, dates);
         }
      }
   };

   const handleMonthChange = (dates) => {
      let beginDate, endDate;

      if (isArray(dates)) {
         beginDate = moment(dates[0]).startOf('month');
         if (dates.length > 1) {
            endDate = moment(dates.length > 1 ? dates[1] : dates[0]).endOf('month');
         }
      } else {
         beginDate = moment(dates).startOf('month');
         endDate = moment(dates).endOf('month');
      }
      setMonthRange({beginDate, endDate});

      return loadPondLevelRange({
         variables: {
            wasteStorageId: parentItem && parentItem.id,
            beginDate: beginDate.format(DATE_DB_FORMAT),
            endDate: endDate.format(DATE_DB_FORMAT),
         }
      });
   };

   /**
    * Delete the pond level record.
    * @return {Promise<void>}
    */
   const handleDelete = async () => {
      const storedImage = get(defaultValues, 'imageData.imageS3');
      if (storedImage?.indexOf(BUCKET_NAME) === 0) {
         const imageKey = storedImage.substring(BUCKET_NAME.length + 1);
         await localForage.removeItem(imageKey);
      }
      handleDeletePhoto();
      await pondLevelDelete({
         variables: {id: defaultValues.id},
         optimisticResponse: {pondLevel_Delete: 1, refetchQueries: getRefetchQueries},
         update: cacheDelete(getCacheQueries(), defaultValues.uuid, 'pondLevel'),
         refetchQueries: getRefetchQueries
      });
      setDefaultValues({visualInspection: true, uuid: uuid(), wasteStorageId: get(parentItem, 'id')});
   };

   const handleCheckOperatingLevels = () => {
      let aboveOperatingLevel = editValues.currentWaterLevel < parentItem.operatingLevel;

      setAboveOperatingLevel(aboveOperatingLevel);

      if (!aboveOperatingLevel) {
         handleSubmit();
      }
   };

   /**
    * When a photo is taken, save the new photo.
    * @param photoUri The URI of the new photo.
    */
   const handleTakePhoto = (photoUri) => {
      setPhotoUri(photoUri);
      setIsChanged(true);
   };

   /**
    * When submitting, put the photo in S3 storage and return the location to be added to the PondLevelRecord. If
    * offline add to the localForage.
    *
    * @return {Promise<undefined|{imageLocation: string}>}
    */
   const handleCameraSubmit = async () => {
      if (photoUri) {
         const imagePath = `upload/Photo_${Date.now()}.jpg`;

         if (isConnected) {
            await Storage.put(imagePath, photoUri.blob,
               {level: 'public', contentType: get(photoUri.blob, 'blob.type', 'image/jpeg')});
         } else {
            await localForage.setItem(imagePath, photoUri);
         }
         return {imageLocation: `${BUCKET_NAME}/${imagePath}`};
      } else {
         return undefined;
      }
   };

   /**
    * When the photo is deleted, remove the photo URI and show the take photo button.
    */
   const handleDeletePhoto = async () => {
      setPhotoUri(null);
      setUseCamera(false);
      set(defaultValues, 'imageData.imageS3', null);

      setIsChanged(true);
      setImage(undefined);
   };

   /**
    * Handle the error from the camera. Usually it is because the user doesn't have permission. Video displays the
    * error.
    */
   const handleCameraError = () => {
      setUseCamera(false);
   };

   useEffect(() => {
      const getImage = async () => {
         let image = get(defaultValues, 'imageData.imageS3');
         if (image) {
            if (image?.indexOf(BUCKET_NAME) === 0) {
               const imageKey = image.substring(BUCKET_NAME.length + 1);
               const image2 = await localForage.getItem(imageKey);
               // The cached PondLevelRecord imageData may still point to offline storage but not exist because it has
               // not been uploaded. This will cause the missing image graphic to display. If online it will be replaced
               // with the actual photo soon and if not it will show the offline message.
               setImage(image2 ? image2?.src : 'http://' + imageKey);
               setUseCamera(true);
            } else {
               setImage(image);
               setUseCamera(true);
            }
         } else {
            setImage(undefined);
            setUseCamera(false);
         }
      }
      if (!photoUri) {
         getImage();
      }
   }, [defaultValues]);
   return (
      <Form className={classes.formStyle} onSubmit={handleCheckOperatingLevels}>
         <TitleCardInner
            titleId={'clientWasteStorage.pondRecord.button'}
            variant={'subtitle1'}
            onClose={isPickerOpen ? undefined : onClose}
            classes={{paperStyle: classes.paperStyle}}
            headerAction={(
               <ConfirmButton
                  onConfirm={handleDelete}
                  style={{display: !defaultValues || !defaultValues.id ? 'none' : undefined}}
                  titleKey={'delete.confirm.title'}
                  messageKey={'delete.confirm.message'}
                  titleValues={{type: pondLevelType}}
                  values={{type: pondLevelType}}
                  color='secondary'
                  buttonLabelKey={'delete.button'}
                  disableRipple
                  disabled={!defaultValues || !defaultValues.id}
                  className={classes.buttonStyle}
                  buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
               />
            )}
         >
            {aboveOperatingLevel && (
               <ConfirmDialog
                  titleKey={'pond.confirmSave.title'}
                  messageKey={'pond.confirmSaveOperatingLevel.message'}
                  onConfirm={handleSubmit}
                  onClose={() => setAboveOperatingLevel(false)}
                  confirmKey={'confirmSave.label'}
                  confirmColor={'secondary'}
               >
                  <b><Typography hasBold id={'pond.confirmSaveOperatingLevel'} values={parentItem}/></b>
                  <b><Typography hasBold id={'pond.confirmSaveWaterLevel'} values={editValues}/></b>
                  <Typography variant={'h6'} id={'pond.confirmSaveOperatingLevelFooter.message'}
                              style={{fontSize: 18, marginTop: theme.spacing(2)}}/>
               </ConfirmDialog>
            )}
            <Grid ref={ref} container isScrollable>
               <Grid item container direction={'row'} spacing={1} alignItems={'center'}
                     style={{padding: 0, height: 'fit-content'}} fullWidth>
                  <Grid item xs={12}>
                     <DateRangePicker
                        name={'date'}
                        open={isPickerOpen}
                        labelKey={'field.date.label'}
                        value={daySelected}
                        autoOk={false}
                        disableFuture={true}
                        onChange={handleDateChange}
                        onMonthChange={handleMonthChange}
                        onOpen={() => setIsPickerOpen(true)}
                        onClose={() => setIsPickerOpen(false)}
                        entryDays={entryDays}
                        autoFocus
                        disabled={isSaving}
                        required
                     />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6}>
                     <TextFieldFHG
                        name={'currentWaterLevel'}
                        labelKey={'pond.waterLevel.label'}
                        type={'number'}
                        inputProps={{step: 0.1}}
                        autoFocus
                        defaultValue={defaultValues.currentWaterLevel && round(defaultValues.currentWaterLevel, 1)}
                        value={editValues.currentWaterLevel && round(editValues.currentWaterLevel, 1)}
                        onChange={handleChange}
                        disabled={isSaving}
                        required
                     />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6}>
                     <CheckboxFHG
                        name={'visualInspection'}
                        labelKey={'pond.visualInspection.label'}
                        color={'default'}
                        defaultValue={defaultValues.currentWaterLevel}
                        defaultChecked={defaultValues.visualInspection}
                        checked={editValues.visualInspection}
                        onChange={handleChange}
                        disabled={isSaving}
                        style={{marginLeft: 8}}
                     />
                  </Grid>
                  <Grid item container direction={'row'} spacing={1}>
                     {!showNote ? (
                        <Grid item xs={6} sm={12} md={6}>
                           <Button color='primary' size={'small'} onClick={() => {setShowNote(true)}}
                                   disabled={isSaving}>
                              <Typography variant={'inherit'} id={'pond.addNote.button'}/>
                           </Button>
                        </Grid>
                     ) : (
                        <Grid item fullWidth>
                           <TextFieldFHG
                              name={'note'}
                              labelKey={'pond.note.label'}
                              autoFocus
                              defaultValue={defaultValues.note}
                              value={editValues.note}
                              onChange={handleChange}
                              rows={2}
                              rowsMax={4}
                              fullWidth
                              multiline
                              disabled={isSaving}
                           />
                        </Grid>
                     )}
                     {!useCamera && (
                        <Grid item xs={6} sm={12} md={6}>
                           <Button color='primary' size={'small'} onClick={() => {setUseCamera(true)}}
                                   disabled={isSaving}>
                              <AddAPhoto/>
                              <Typography className={classes.addPhotoStyle} variant={'inherit'}
                                          id={'pond.addPhoto.button'}/>
                           </Button>
                        </Grid>
                     )}
                     {useCamera && (
                        <Video image={image} onTakePhoto={handleTakePhoto} onDeletePhoto={handleDeletePhoto}
                               onError={handleCameraError} isConnected={isConnected}/>
                     )}
                  </Grid>
                  <Grid item
                        style={{
                           marginTop: theme.spacing(1),
                           position: 'sticky',
                           bottom: 0,
                           backgroundColor: theme.palette.background.paper
                        }}
                        fullWidth>
                     <Button variant='contained' color='primary' type={'submit'} size={'small'} disabled={isSaving}>
                        <Typography variant={'inherit'} id={'save.label'}/>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </TitleCardInner>
      </Form>
   );
}
