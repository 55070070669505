import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import LinearProgressWithLabel from './progress/LinearProgressWithLabel';
import Typography from './Typography';

const useStyles = makeStyles(theme => ({
   messageStyle: {
      backgroundColor: `${theme.palette.background.default} !important`,
      color: `${theme.palette.text.secondary} !important`,
      paddingRight: theme.spacing(6),
   },
   closeButtonStyle: {
      position: 'absolute',
      right: 0,
      top: 0,
      marginLeft: 'auto',
      zIndex: 1001,
   },
   progressStyle: {
      marginRight: theme.spacing(1),
   }
}), {name: 'StatusSnackbarStyles'});

StatusSnackbar.propTypes = {
   open: PropTypes.bool,                     // Indicates if the snackbar should be shown to the user.
   onClose: PropTypes.func,                  // Callback when the snackbar is closed.
   messageKey: PropTypes.string,                // Intl ID for the error message.
   message: PropTypes.string,                // Text for the message.
   values: PropTypes.object,                 // Value object for the message.
};

/**
 * Component to show the status messages.
 *
 * Note:
 *    Message is the default property in values.
 *
 * Reviewed:
 */
export default function StatusSnackbar({
   open = true,
   autoHideDuration,
   allowClose,
   onClose,
   messageKey,
   values = {message: 'N/A'},
   message,
   total = 100,
   index
}) {
   const classes = useStyles();
   const [isOpen, setIsOpen] = useState(open || autoHideDuration);

   useEffect(() => {
      setIsOpen(open || autoHideDuration);
   }, [open, autoHideDuration]);

   const handleClose = () => {
      setIsOpen(false);

      onClose && onClose();
   };

   return (
      <Snackbar
         open={isOpen}
         autoHideDuration={autoHideDuration}
         onClose={handleClose}
         anchorOrigin={{vertical: 'top', horizontal: 'left'}}
         ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
               root: classes.messageStyle,
            }
         }}
         message={(
            <Grid container alignItems={'center'} spacing={1} direction={'column'}
                  style={{minHeight: 60, minWidth: 330}}>
               <Grid item>
                  <Typography id={messageKey} className={classes.progressStyle} values={values}
                              color={'inherit'}>{message}</Typography>
               </Grid>
               {index !== undefined && (
                  <Grid item>
                     <LinearProgressWithLabel value={(index / total) * 100}/>
                  </Grid>
               )}
            </Grid>
         )}
         action={[
            allowClose && (
               <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  size={'small'}
                  className={classes.closeButtonStyle}
                  onClick={handleClose}
               >
                  <CloseIcon fontSize='inherit'/>
               </IconButton>
            )
         ]}
      />
   );
}
