import gql from 'graphql-tag';
import React, {useState, useEffect, useContext} from 'react';
import {useRouteMatch} from 'react-router-dom';
import AuthContext from '../../components/AuthContext';
import {MAIL_POLL, ADMIN_TO_CLIENT, CLIENT_TO_ADMIN, CLIENT_DASHBOARD_PATH} from '../../Constants';
import {toNumber} from '../utils/Utils';
import useQueryFHG from './data/useQueryFHG';
import MailContext from './MailContext';
import SessionContext from './security/SessionContext';
import get from 'lodash/get';

const MESSAGE_FRAGMENT_FOR_COUNT = gql`
   fragment messageForCount on Message {
      id
      uuid
      direction
      fromClientId
      fromAdminId
      toAdminId
      toClientId
      read
      isDeleted
   }
`;

export const MESSAGE_COUNT = gql`
   query getMessagesForCount($adminId: [Int], $clientId: [Int], $direction: [Int], $adminDeleted: [Boolean], $clientDeleted: [Boolean]) {
      messages: message_AllWhere(messageSearch: {isDeleted: [false], read: false, adminId: $adminId, clientId: $clientId, direction: $direction, adminDeleted: $adminDeleted, clientDeleted: $clientDeleted}) {
         ...messageForCount
      }
   }
   ${MESSAGE_FRAGMENT_FOR_COUNT}
`;

/**
 * The MailProvider component. Watches for changes in the unread messages for clients or admin
 *
 * Reviewed: 8/21/2020
 */
export default function MailProvider({children}) {
   const [unreadCount, setUnreadCount] = useState(0);
   const {user} = useContext(SessionContext);
   const {isAdmin} = useContext(AuthContext);
   let messageMatch = useRouteMatch({path: CLIENT_DASHBOARD_PATH, strict: false, sensitive: false});
   const clientId = get(messageMatch, 'params.clientId');

   //If a client fetch messages for the client. If not fetch messages for the admin user.
   const variables = clientId ? {clientId: toNumber(clientId), clientDeleted: false}: {adminId: [user.id], adminDeleted: false};

   // For unread we only want messages to the client or admin user.
   variables.direction = clientId ? ADMIN_TO_CLIENT : CLIENT_TO_ADMIN;
   const {data} = useQueryFHG(MESSAGE_COUNT,
      {variables, skip: !clientId ? !isAdmin || (!user || !user.id) : !clientId, pollInterval: MAIL_POLL},
      'message.type');

   useEffect(() => {
       if (!!data) {
          setUnreadCount( get(data, 'messages.length') || 0);
       } else {
          setUnreadCount(0);
       }
   }, [data, ]);


   return (
      <MailContext.Provider value={{unreadCount}}>
         {children}
      </MailContext.Provider>
   );
}
