import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import defer from 'lodash/defer';
import indexOf from 'lodash/indexOf';
import sumBy from 'lodash/sumBy';
import filter from 'lodash/filter';
import round from 'lodash/round';
import get from 'lodash/get';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import CheckboxFHG from '../../../components/CheckboxFHG';
import DatePickerFHG from '../../../components/DatePickerFHG';
import KeyboardDatePickerFHG from '../../../components/KeyboardDatePickerFHG';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import {DATE_DB_FORMAT, MAXIMUM_PRECIPITATION} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useLazyQueryOfflineFHG from '../../../fhg/components/data/useLazyQueryOfflineFHG';
import useMutationFHG, {CREATE_UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import ConfirmDialog from '../../../fhg/components/dialog/ConfirmDialog';
import Form from '../../../fhg/components/edit/Form';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import Typography from '../../../fhg/components/Typography';
import TypographyFHG from '../../../fhg/components/Typography';
import {cacheUpdate, cacheDelete} from '../../../fhg/utils/DataUtil';
import useMessage from '../../../fhg/utils/useMessage';

const useStyles = makeStyles(theme => ({
   paperStyle: {
      height: 'fit-content',
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
      position: 'relative',
   },
   buttonStyle: {
      marginBottom: '0.35em',
   },
   subtitleStyle: {
      marginBottom: theme.spacing(1),
   },
}), {name: 'PrecipitationRecordStyles'});

// Default properties needed for the precipitation record.
const PRECIPITATION_FRAGMENT = gql`
   fragment precipitationRecordInfo on Precipitation {
      id
      amount
      date
      facilityId
      isDeleted
      isSnow
      uuid
   }
`;

const defaultPrecipitation = {
   id: 0,
   amount: 0,
   isDeleted: false,
   isSnow: false,
};

// Query for all the precipitation records for the facility.
export const PRECIPITATION_BY_RANGE_QUERY = gql`
   query getPrecipitationByRange($facilityId: [Int], $beginDate: String, $endDate: String)
   {
      precipitation:precipitation_AllWhere(precipitationSearch: {facilityId: $facilityId, beginDate: $beginDate, endDate: $endDate}) {
         ...precipitationRecordInfo
      }
   }
   ${PRECIPITATION_FRAGMENT}
`;

// Create or Update the precipitation with the given properties.
const PRECIPITATION_CREATE_UPDATE = {
   mutation: gql`
      mutation PrecipitationCreateUpdate(
         $amount: Float
         $date: String
         $facilityId: Int
         $isSnow: Boolean
         $uuid: String!
      ) {
         precipitation: precipitation_CreateUpdate(precipitation: {
            amount: $amount
            date: $date
            facilityId: $facilityId
            isSnow: $isSnow
            uuid: $uuid
         }) {
            ...precipitationRecordInfo
         }
      }
      ${PRECIPITATION_FRAGMENT}
   `,
   typeKey: 'precipitation.type',
   actionKey: CREATE_UPDATE_ACTION,
};

// Delete the waste export on the server.
const PRECIPITATION_DELETE = {
   mutation: gql`
      mutation PrecipitationDelete($id: Int!) {
         precipitation_Delete(precipitationId: $id)
      }
   `,
   typeKey: 'precipitation.type',
   actionKey: DELETE_ACTION,
};

PrecipitationRecord.propTypes = {
   facility: PropTypes.object,
   onClose: PropTypes.func.isRequired,
};

/**
 * The component to record precipitation.
 *
 * @param facility The facility to record for.
 * @param onClose Callback when the panel is closed.
 */
export default function PrecipitationRecord({facility, onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const precipitationType = useMessage('precipitation.type');
   const ref = useRef();

   const [isSaving, setIsSaving] = useState(false);

   const [loadPrecipitationRange, {data: precipitationRangeData}] = useLazyQueryOfflineFHG(PRECIPITATION_BY_RANGE_QUERY,
      undefined, 'precipitation.type');

   const [precipitationCreateUpdate] = useMutationFHG(PRECIPITATION_CREATE_UPDATE);
   const [precipitationDelete] = useMutationFHG(PRECIPITATION_DELETE);

   const [editValues, handleChange, {isChanged, defaultValues, setDefaultValues, setIsChanged}] = useEditData(
      {uuid: uuid(), facilityId: get(facility, 'id')}, ['id', 'uuid', 'facilityId']);

   const [entryDays, setEntryDays] = useState([]);
   const [monthRange, setMonthRange] = useState(
      {beginDate: moment().startOf('month'), endDate: moment().endOf('month')});
   const [daySelected, setDaySelected] = useState(moment());
   const [isPickerOpen, setIsPickerOpen] = useState(false);
   const [aboveMaximumLevel, setAboveMaximumLevel] = useState(false);

   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }
      loadPrecipitationRange({
         variables: {
            facilityId: facility && facility.id,
            beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
            endDate: monthRange.endDate.format(DATE_DB_FORMAT),
         }
      });
      // eslint-disable-next-line
   }, [ref, facility]);

   const setInitialValues = useCallback((isSetEntryDays, date) => {
      const precipitation = get(precipitationRangeData, 'precipitation') || [];

      if (precipitation.length > 0) {
         if (isSetEntryDays) {
            setEntryDays(precipitation.map(item => item.date));
         }
         const filteredDays = filter(precipitation, item => {
            const itemDate = moment(item.date, DATE_DB_FORMAT);
            return itemDate.isSame(date, 'day');
         }) || [];
         if (filteredDays.length > 0) {
            setDefaultValues({...filteredDays[0]});
         } else {
            setDefaultValues({uuid: uuid(), facilityId: get(facility, 'id')});
         }
      } else {
         if (isSetEntryDays) {
            setEntryDays([]);
         }
         setDefaultValues({uuid: uuid(), facilityId: get(facility, 'id')});
      }
   }, [facility, precipitationRangeData, setDefaultValues]);

   useEffect(() => {
      if (precipitationRangeData) {
         setInitialValues(true, daySelected);
      }
      //Don't change on daySelected. Will reset the entryDays needlessly.
      // eslint-disable-next-line
   }, [precipitationRangeData]);

   /**
    * Submit the precipitation.
    * @return {Promise<void>}
    */
   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);

            const cacheVariables = {
               facilityId: facility && facility.id,
               beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
               endDate: monthRange.endDate.format(DATE_DB_FORMAT)
            };
            const variables = {...editValues, date: daySelected.format(DATE_DB_FORMAT)};
            await precipitationCreateUpdate({
               variables,
               optimisticResponse: {
                  __typename: "Mutation",
                  precipitation: {
                     __typename: "Precipitation",
                     ...defaultPrecipitation,
                     ...defaultValues,
                     ...variables,
                  }
               },
               update: cacheUpdate({
                  query: PRECIPITATION_BY_RANGE_QUERY,
                  variables: cacheVariables,
               }, editValues?.uuid, 'precipitation')
            });
            setIsChanged(false);
            onClose && onClose();
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         onClose && onClose();
      }
   };

   const handleDateChange = async (event, date) => {
      if (date && date.isValid && date.isValid()) {
         setDaySelected(date);
         if (!date.isBetween(monthRange.beginDate, monthRange.endDate, 'day', '[]')) {
            await handleMonthChange(date);
         } else {
            setInitialValues(false, date);
         }
      }
   };

   const handleMonthChange = (date) => {
      const beginDate = moment(date).startOf('month');
      const endDate = moment(date).endOf('month');
      setMonthRange({beginDate, endDate});
      return loadPrecipitationRange({
         variables: {
            facilityId: facility && facility.id,
            beginDate: beginDate.format(DATE_DB_FORMAT),
            endDate: endDate.format(DATE_DB_FORMAT),
         },
      });
   };

   const handleDelete = async () => {
      const variables = {
         facilityId: facility && facility.id,
         beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
         endDate: monthRange.endDate.format(DATE_DB_FORMAT)
      }

      await precipitationDelete({
         variables: {id: defaultValues.id},
         optimisticResponse: {
            precipitation_Delete: 1,
         },
         update: cacheDelete(
            {query: PRECIPITATION_BY_RANGE_QUERY, variables}, defaultValues.uuid, 'precipitation')
      });
      setDefaultValues({uuid: uuid(), facilityId: get(facility, 'id')});
   };

   const handleCheckLevel = () => {
      if (editValues.amount > MAXIMUM_PRECIPITATION) {
         setAboveMaximumLevel(true);
      } else {
         handleSubmit();
      }
   };

   const total = sumBy(get(precipitationRangeData, 'precipitation'), 'amount');

   return (
      <Form className={classes.formStyle} onSubmit={handleCheckLevel}>
         <TitleCardInner
            titleId={'clientFacility.precipitation.button'}
            variant={'subtitle1'}
            onClose={isPickerOpen ? undefined : onClose}
            classes={{paperStyle: classes.paperStyle}}
            headerAction={(
               <ConfirmButton
                  onConfirm={handleDelete}
                  style={{display: !defaultValues || !defaultValues.id ? 'none' : undefined}}
                  titleKey={'delete.confirm.title'}
                  messageKey={'delete.confirm.message'}
                  titleValues={{type: precipitationType}}
                  values={{type: precipitationType}}
                  color='secondary'
                  buttonLabelKey={'delete.button'}
                  disableRipple
                  disabled={!defaultValues || !defaultValues.id}
                  className={classes.buttonStyle}
                  buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
               />
            )}
         >
            {aboveMaximumLevel && (
               <ConfirmDialog
                  titleKey={'clientFacility.confirmSave.title'}
                  messageKey={'clientFacility.confirmSave.message'}
                  messageVariant={'subtitle1'}
                  onConfirm={handleSubmit}
                  onClose={() => setAboveMaximumLevel(false)}
                  confirmKey={'confirmSave.label'}
                  confirmColor={'secondary'}
               />
            )}
            <TypographyFHG className={classes.subtitleStyle} variant={'subtitle1'}
                           id={'clientFacility.precipitation.label'} values={{total}}/>
            <Grid ref={ref} container>
               <Grid item container direction={'row'} spacing={1} alignItems={'center'}
                     style={{height: 'fit-content'}}>
                  <Grid item xs={12}>
                     <Hidden mdUp>
                        <DatePickerFHG
                           name={'date'}
                           labelKey={'date.label'}
                           value={daySelected}
                           onChange={handleDateChange}
                           onMonthChange={handleMonthChange}
                           disabled={isSaving}
                           onOpen={() => setIsPickerOpen(true)}
                           onClose={() => setIsPickerOpen(false)}
                           renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                              const isSelected = indexOf(entryDays, day.format(DATE_DB_FORMAT)) >= 0;
                              return <Badge invisible={!isSelected} variant={'dot'} overlap={'circle'}
                                            color={'secondary'}>{dayComponent}</Badge>;
                           }}
                           required
                        />
                     </Hidden>
                     <Hidden smDown>
                        <KeyboardDatePickerFHG
                           name={'date'}
                           labelKey={'date.label'}
                           value={daySelected}
                           onChange={handleDateChange}
                           onMonthChange={handleMonthChange}
                           disabled={isSaving}
                           onOpen={() => setIsPickerOpen(true)}
                           onClose={() => setIsPickerOpen(false)}
                           renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                              const isSelected = indexOf(entryDays, day.format(DATE_DB_FORMAT)) >= 0;
                              return <Badge invisible={!isSelected} variant={'dot'} overlap={'circle'}
                                            color={'secondary'}>{dayComponent}</Badge>;
                           }}
                           required
                        />
                     </Hidden>
                  </Grid>
                  <Grid item xs={6} sm={12} md={6}>
                     <TextFieldFHG
                        name={'amount'}
                        type={'number'}
                        inputProps={{step: 0.01}}
                        labelKey={'clientFacility.precipitationAmount.label'}
                        autoFocus
                        defaultValue={defaultValues.amount && round(defaultValues.amount, 2)}
                        value={editValues.amount && round(editValues.amount, 2)}
                        disabled={isSaving}
                        onChange={handleChange}
                        fullWidth
                        required
                     />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6}>
                     <CheckboxFHG
                        key={'isSnow' + editValues.uuid}
                        style={{marginLeft: theme.spacing(1)}}
                        name={'isSnow'}
                        onChange={handleChange}
                        color={'default'}
                        labelKey={'clientFacility.precipitationIsSnow.label'}
                        value={'isSnow'}
                        defaultChecked={defaultValues.isSnow}
                        disabled={isSaving}
                        checked={editValues.isSnow}
                     />
                  </Grid>
                  <Grid item
                        style={{
                           marginTop: theme.spacing(1),
                           position: 'sticky',
                           bottom: 0,
                           backgroundColor: theme.palette.background.paper
                        }}
                        fullWidth>
                     <Button variant='contained' color='primary' type={'submit'} size={'small'} disabled={isSaving}>
                        <Typography variant={'inherit'} id={'save.label'}/>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </TitleCardInner>
      </Form>
   );
}
