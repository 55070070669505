import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import get from 'lodash/get';
import React, {useState, useCallback, useRef, useEffect} from 'react';
import {v4 as uuid} from 'uuid';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useMutationFHG, {CREATE_UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import Form from '../../../fhg/components/edit/Form';
import Prompt from '../../../fhg/components/edit/Prompt';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import {PhoneNumberField} from '../../../fhg/components/input/TextMaskCustom';
import ProgressButton from '../../../fhg/components/ProgressButton';
import {FACILITY_BY_CLIENT_ID_QUERY} from '../../../prototype/ClientSetup';
import LivestockListCard from './LivestockListCard';

const useStyles = makeStyles(theme => ({
   paperStyle: {
      maxHeight: `calc(100% - 1px)`,
      margin: theme.spacing(0,0,0,2),
   },
   formStyle: {
      maxHeight: '100%',
      overflow: 'hidden',
   },
   infoRootStyle: {
      maxHeight: `calc(100% - ${theme.spacing(5)}px)`,
      overflow: 'auto',
   },
   buttonPanelStyle: {
      padding: theme.spacing(2, 0),
   },
}), {name: 'FacilityEditStyles'});

export const FACILITY_BY_ID_QUERY = gql`
   query getFacilityById ($facilityId: Int!) {
      facility: facility_ById(facilityId: $facilityId) {
         id
         uuid
         name
         latitude
         longitude
         kdhePermit
         contactName
         npdesPermit
         phone
         contactName
         email
         spccLink
      }
   }
`;

// Update a facility with the given properties.
export const FACILITY_CREATE_UPDATE = {
   mutation: gql`
      mutation FacilityCreateUpdate($uuid: String!, $clientId: Int, $name: String, $contactName: String, $phone: String, $email: String, $kdhePermit: String, $npdesPermit: String, $latitude: Float, $longitude: Float, $spccLink: String) {
         facility: facility_CreateUpdate(createChildren: false, facility: {uuid: $uuid, clientId: $clientId, name: $name, contactName: $contactName, phone: $phone, email: $email, kdhePermit: $kdhePermit, npdesPermit: $npdesPermit, latitude: $latitude, longitude: $longitude, spccLink: $spccLink}) {
            id
            uuid
            name
            latitude
            longitude
            kdhePermit
            contactName
            npdesPermit
            phone
            contactName
            email
            spccLink
         }
      }
   `,
   typeKey: 'facility.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const FACILITY_DELETE = {
   mutation: gql`
      mutation FacilityDelete($id: Int!) {
         facility_Delete(facilityId: $id)
      }
   `,
   typeKey: 'facility.type',
   actionKey: DELETE_ACTION,
};


export default function FacilityEditInfo({facility = {}, client, onClose}) {
   const classes = useStyles();
   const theme = useTheme();

   const livestockListRef = useRef();

   const {data} = useQueryFHG(FACILITY_BY_ID_QUERY, {variables: {facilityId: facility.id}, skip: !facility.id}, 'facility.type');

   const [facilityCreateUpdate] = useMutationFHG(FACILITY_CREATE_UPDATE);
   const [facilityDelete] = useMutationFHG(FACILITY_DELETE);

   const [isSaving, setIsSaving] = useState(false);

   const [editValues, handleChange, {isChanged, setIsChanged, defaultValues, setDefaultValues}] = useEditData({uuid: uuid(), ...facility});

   useEffect(() => {
       if (data) {
          setDefaultValues(data.facility);
       }
   }, [data]);

   const handleSubmit = useCallback(() => {
      const submit = async () => {

         setIsSaving(true);
         try {
            if (isChanged) {
               await facilityCreateUpdate({variables: {...editValues}});
            }

            // noinspection JSUnresolvedFunction
            await livestockListRef.current.submit(facility.id);
            setIsChanged(false);
            onClose && onClose();
         } catch (e) {
            //Intentionally left blank.
         } finally {
            setIsSaving(false);
         }
      };
      submit();
   }, [facilityCreateUpdate, isChanged, editValues, livestockListRef.current]);

   const handleDelete = () => {
      facilityDelete({variables: {id: facility.id}}, true, [
         {query: FACILITY_BY_CLIENT_ID_QUERY, variables: {clientId: client.id}}
      ]);
   };

   return (
      <Grid container fullWidth fullHeight>
      <TitleCardInner
         titleId={'client.facility'}
         onClose={onClose}
         values={{name: defaultValues.name, hasBold: true}}
         classes={{paperStyle: classes.paperStyle}}
         headerAction={(
            <ConfirmButton
               onConfirm={handleDelete}
               titleKey={'delete.confirm.title'}
               messageKey={'delete.confirm.message'}
               titleValues={{type: defaultValues.name}}
               values={{type: defaultValues.name}}
               color='secondary'
               buttonLabelKey={'delete.button'}
               disableRipple
               buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
            />
         )}
      >
         <Form onSubmit={handleSubmit} className={classes.formStyle}>
            <Prompt when={isChanged}/>
            <Grid name={'Facility Edit Info Root'} container item fullWidth spacing={1} className={classes.infoRootStyle}>
               <Grid item fullWidth xs={12}>
                  <TextFieldFHG
                     key={'FacilityName'}
                     name={'name'}
                     autoFocus
                     labelKey={'client.facilityName.label'}
                     placeholderKey={'client.facilityName.placeholder'}
                     defaultValue={defaultValues.name || 'Untitled'}
                     value={editValues.name}
                     onChange={handleChange}
                     fullWidth
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6}>
                  <TextFieldFHG
                     name={'kdhePermit'}
                     labelKey={'facility.kdhePermit.label'}
                     defaultValue={defaultValues.kdhePermit}
                     value={editValues.kdhePermit}
                     onChange={handleChange}
                     required
                     fullWidth
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6}>
                  <TextFieldFHG
                     name={'npdesPermit'}
                     labelKey={'facility.npdesPermit.label'}
                     defaultValue={defaultValues.npdesPermit}
                     value={editValues.npdesPermit}
                     onChange={handleChange}
                     required
                     fullWidth
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6}>
                  <TextFieldFHG
                     name={'contactName'}
                     labelKey={'facility.contactName.label'}
                     placeholderKey={'contact.placeholder'}
                     defaultValue={defaultValues.contactName || get(client, 'contactName')}
                     value={editValues.contactName}
                     onChange={handleChange}
                     fullWidth
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6}>
                  <PhoneNumberField
                     name='phone'
                     labelKey={'facility.phone.label'}
                     defaultValue={defaultValues.phone || get(client, 'phone')}
                     value={editValues.phone}
                     onChange={handleChange}
                     fullWidth
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6}>
                  <TextFieldFHG
                     name={'email'}
                     type='email'
                     labelKey={'facility.email.label'}
                     defaultValue={defaultValues.email || get(client, 'email')}
                     value={editValues.email}
                     onChange={handleChange}
                     fullWidth
                  />
               </Grid>
               <Grid item fullWidth xs={6} sm={12} md={6}>
                  <TextFieldFHG
                     name={'spccLink'}
                     labelKey={'facility.spccLink.label'}
                     defaultValue={defaultValues.spccLink}
                     value={editValues.spccLink}
                     onChange={handleChange}
                     fullWidth
                  />
               </Grid>
               <Grid item style={{padding: theme.spacing(2, 0, 1, .5)}} fullWidth>
                  {facility.id && (
                     <LivestockListCard ref={livestockListRef} parentId={facility.id}/>
                  )}
               </Grid>
            </Grid>
            <Grid item fullWidth className={classes.buttonPanelStyle}>
               <ProgressButton isProgress={isSaving} variant='contained' color='primary' type={'submit'}
                               size={'small'} labelKey={'save.label'} disabled={isSaving}/>
            </Grid>
         </Form>
      </TitleCardInner>
      </Grid>
   );
}
