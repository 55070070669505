import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Close from '@material-ui/icons/Close';
import gql from 'graphql-tag';
import get from 'lodash/get';
import moment from 'moment';
import React, {Fragment, useState, useEffect} from 'react';
import {v4 as uuidFunction} from 'uuid';
import KeyboardDatePickerFHG from '../../../components/KeyboardDatePickerFHG';
import TextFieldFHG from '../../../components/TextField';
import {YEAR_FORMAT} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useMutationFHG, {CREATE_UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import Form from '../../../fhg/components/edit/Form';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import TypographyFHG from '../../../fhg/components/Typography';
import {toNumber} from '../../../fhg/utils/Utils';
import {ALLOTMENTS_BY_FACILITY_ID_QUERY} from '../../../prototype/ClientSetup';
import {GAUGE_QUERY} from '../../client/WellClient';
import {ALLOTMENT_FRAGMENT} from './FragmentsGL';

const useStyles = makeStyles(theme => ({
   deleteStyle: {
      // marginTop: 12, //Move the top of delete button down to align with the text boxes.
   },
   paperStyle: {
      width: 460,
      maxWidth: 'calc(100% - 16px)',
      padding: theme.spacing(1),
   },
}), {name: 'AllotmentStyles'});

Allotment.propTypes = {};

const ALLOTMENT_CREATE_UPDATE = {
   mutation: gql`
      mutation AllotmentCreateUpdate($uuid: String!, $allotment: Float, $facilityId: Int!, $fileNumbers: String, $wellIdList: [Int], $years: Int, $startYear: Int) {
         allotment: allotment_CreateUpdate(allotment: {uuid: $uuid, allotment: $allotment, fileNumbers: $fileNumbers, facilityId: $facilityId, wellIdList: $wellIdList, years: $years, startYear: $startYear}) {
            ...allotmentInfo
         }
      }
      ${ALLOTMENT_FRAGMENT}
   `,
   typeKey: 'allotment.type',
   actionKey: CREATE_UPDATE_ACTION,
};

// Update the fields with the given properties.
export const ALLOTMENT_DELETE = {
   mutation: gql`
      mutation AllotmentDelete($id: Int!) {
         allotment_Delete(allotmentId: $id)
      }
   `,
   typeKey: 'allotment.type',
   actionKey: DELETE_ACTION,
};

/**
 * The component to edit an allotment.
 *
 * Reviewed: 7/7/2020
 */
export default function Allotment({well, allotment, facilityIds, anchorEl, onSubmit, onDelete, onClose}) {
   const classes = useStyles();
   const theme = useTheme();

   //TODO Convert this to allotments.
   const [allotmentCreateUpdate] = useMutationFHG(ALLOTMENT_CREATE_UPDATE);
   const [allotmentDelete] = useMutationFHG(ALLOTMENT_DELETE);

   const [editValues, handleChange, {defaultValues, setEditValues, setDefaultValues, resetValues}] = useEditData(
      allotment ? {years: 1, ...allotment, startYear: allotment.startYear ? moment(allotment.startYear, 'YYYY') : undefined} :
         {years: 1});

   const [showEdit, setShowEdit] = useState(false);

   useEffect(() => {
      if (allotment && anchorEl) {
         setShowEdit(anchorEl);
         setDefaultValues(
            {...allotment, startYear: allotment.startYear ? moment(allotment.startYear, 'YYYY') : undefined});
      }
   }, [allotment, anchorEl, setDefaultValues]);

   const onAddAllotment = (event) => {
      setShowEdit(event.currentTarget);
   };

   const handleDelete = () => {
      const updateQueries = [
         {query: ALLOTMENTS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}},
      ];
      allotmentDelete({variables: {id: allotment.id}}, true, updateQueries);
      onDelete && onDelete(allotment);
      handleClose();
   };

   //TODO Convert this to allotments.
   const onSaveAllotment = async () => {
      let updateQueries = [
         {query: ALLOTMENTS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}},
         {query: GAUGE_QUERY, variables: {wellId: well && well.id}, skip: !well},
      ];
      const startYear = typeof editValues.startYear === 'object' ? editValues.startYear.year() : toNumber(editValues.startYear);

      const result = await allotmentCreateUpdate(
         {
            variables: {
               uuid: (allotment && allotment.uuid) || uuidFunction(),
               wellIdList: allotment && allotment.wellIdList,
               ...editValues,
               facilityId: well && well.facilityId,
               startYear,
            }
         }, false, updateQueries);
      onSubmit && onSubmit(result.data.allotment, !allotment || !allotment.id);
      handleClose();
   };

   const handleClose = (event) => {
      resetValues();
      setDefaultValues({years: 1});
      setEditValues({});
      setShowEdit(undefined);
      onClose && onClose(event);
   };

   return (
      <Fragment>
         <Popover
            open={!!showEdit}
            // onClose={handleClose}
            anchorEl={showEdit}
            PaperProps={{
               className: classes.paperStyle
            }}
         >
            <Grid container direction={'column'}>
               <Grid item container direction={'row'} justify={'space-between'}>
                  <Grid item>
                     <TypographyFHG variant={'h6'} id={'facility.allotment.label'}/>
                  </Grid>
                  <Grid item>
                     <IconButton onClick={handleClose}>
                        <Close/>
                     </IconButton>
                  </Grid>
               </Grid>
               <Form onSubmit={onSaveAllotment}>
                  <Grid name={'Allotment Frame'} container item fullWidth alignItems={'center'} spacing={2}
                        style={{width: 'calc(100% + 16px'}}>
                     <Grid item fullWidth xs={12} sm={6}>
                        <TextFieldFHG
                           name={'fileNumbers'}
                           autoFocus
                           labelKey={'facility.fileNumber.label'}
                           defaultValue={get(defaultValues, 'fileNumbers')}
                           value={editValues.fileNumbers}
                           onChange={handleChange}
                           fullWidth
                           required
                        />
                     </Grid>
                     <Grid item fullWidth xs={12} sm={6}>
                        <TextFieldFHG
                           name={'allotment'}
                           isFormattedNumber
                           labelKey={'facility.allotmentWithUnit.label'}
                           defaultValue={get(defaultValues, 'allotment')}
                           value={editValues.allotment}
                           onChange={handleChange}
                           fullWidth
                           required
                        />
                     </Grid>
                     <Grid item fullWidth xs={12} sm={6}>
                        <TextFieldFHG
                           name={'years'}
                           type={'number'}
                           inputProps={{
                              max: 10,
                              min: 1,
                           }}
                           labelKey={'allotment.years.label'}
                           defaultValue={get(defaultValues, 'years') || 1}
                           value={editValues.years}
                           onChange={handleChange}
                           fullWidth
                           required
                        />
                     </Grid>
                     <Grid item fullWidth xs={12} sm={6}>
                        <KeyboardDatePickerFHG
                           name={'startYear'}
                           views={['year']}
                           format={YEAR_FORMAT}
                           labelKey={'allotment.startYear.label'}
                           defaultValue={defaultValues.startYear}
                           value={editValues.startYear}
                           onChange={handleChange}
                           required
                           disabled={(editValues.years ? editValues.years === 1 : defaultValues.years === 1)}
                        />
                     </Grid>
                     <Grid container item xs={12} spacing={1} direction={'row'}>
                        <Grid name={'Allotment Button Frame'} item style={{minWidth: 86}} fullHeight={false}
                              resizable={false}>
                           <Button className={classes.deleteStyle} disableRipple type='submit'>
                              <TypographyFHG variant='button' color='primary' style={{textDecoration: 'underline'}}
                                             id={'save.label'}/>
                           </Button>
                        </Grid>
                        {(allotment && allotment.id) && (
                           <Grid name={'Allotment Button Frame'} item style={{minWidth: 86}} fullHeight={false}
                                 resizable={false}>
                              <ConfirmButton
                                 onConfirm={handleDelete}
                                 titleKey={'delete.confirm.title'}
                                 messageKey={'delete.confirmNamed.message'}
                                 titleValues={{type: defaultValues.fileNumbers}}
                                 values={{type: 'Allotment', name: defaultValues.fileNumbers}}
                                 color='secondary'
                                 buttonLabelKey={'delete.button'}
                                 disableRipple
                                 buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
                              />
                           </Grid>
                        )}
                     </Grid>
                  </Grid>
               </Form>
            </Grid>
         </Popover>
         <Grid name={'Allotment Frame'} item fullWidth>
            <Button onClick={onAddAllotment} disableRipple>
               <TypographyFHG variant='button' style={{color: theme.palette.primary.light, textDecoration: 'underline'}}
                              id={'allotment.new.button'}/>
            </Button>
         </Grid>
      </Fragment>
   )
}

