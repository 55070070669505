import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import get from 'lodash/get';
import find from 'lodash/find';
import * as PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {v4 as uuid} from 'uuid';
import AutocompleteFHG from '../../../components/AutocompleteFHG';
import CheckboxFHG from '../../../components/CheckboxFHG';
import TextFieldFHG from '../../../components/TextField';
import {KANSAS_STATE_ID, DATE_DB_FORMAT} from '../../../Constants';
import useMutationFHG, {CREATE_UPDATE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import Form from '../../../fhg/components/edit/Form';
import Prompt from '../../../fhg/components/edit/Prompt';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import Typography from '../../../fhg/components/Typography';
import {cacheUpdate} from '../../../fhg/utils/DataUtil';
import {STATES_QUERY} from '../../admin/AdminEditCard';
import {WASTE_EXPORT_FRAGMENT} from '../../admin/client/FragmentsGL';

const useStyles = makeStyles({
   formStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
      position: 'relative',
   },
}, {name: 'WasteExportEditStyles'});

const defaultWasteExport = {
   id: 0,
   addressLineOne: "",
   addressLineTwo: "",
   isDeleted: false,
   city: {
      id: 1,
      name: undefined,
   },
   state: {
      id: KANSAS_STATE_ID,
      name: 'Kansas',
   },
   analysisProvided: true,
   zipCode:"",
}

// Get the waste types for the options.
export const WASTE_EXPORT_OPTIONS_QUERY = gql`
   query getWasteExportOptions{
      wasteType:wasteType_All {
         id
         name
         isDeleted
      }
   }
`;

// Create or Update the wasteExport with the given properties.
const WASTE_EXPORT_CREATE_UPDATE = {
   mutation: gql`
      mutation WasteExportCreateUpdate(
         $addressLineOne: String
         $addressLineTwo: String
         $amount: Float
         $analysisProvided: Boolean
         $city: String
         $cityId: Int
         $startDate: String
         $stopDate: String
         $facilityId: Int
         $recipientName: String
         $stateId: Int
         $wasteTypeId: Int
         $zipCode: Int
         $uuid: String!
      ) {
         wasteExport: wasteExport_CreateUpdate(wasteExport: {
            addressLineOne: $addressLineOne
            addressLineTwo: $addressLineTwo
            amount: $amount
            analysisProvided: $analysisProvided
            city: $city
            cityId: $cityId
            startDate: $startDate
            stopDate: $stopDate
            date: $startDate
            facilityId: $facilityId
            recipientName: $recipientName
            stateId: $stateId
            wasteTypeId: $wasteTypeId
            zipCode: $zipCode
            uuid: $uuid
         }) {
            ...wasteExportOnWasteExport
         }
      }
      ${WASTE_EXPORT_FRAGMENT}
   `,
   typeKey: 'wasteExport.type',
   actionKey: CREATE_UPDATE_ACTION,
};

WasteExportEdit.propTypes = {
   facility: PropTypes.object,   //Facility related to the waste export.
   onClose: PropTypes.func,      // Callback when the waste export is closed.
};

/**
 * Component to edit a waste export record.
 *
 * Reviewed:
 *
 * @param wasteExport The wastExport to edit.
 * @param daySelected The day selected for updating the cache.
 * @param onUpdateCache Callback to update the cache after an add. Returns the query to use to update cache.
 * @param onClose Callback when the waste export is closed.
 * @return {*}
 * @constructor
 */
export default function WasteExportEdit({wasteExport, daySelected, onUpdateCache, onClose}) {
   const classes = useStyles();
   const theme = useTheme();

   const {data: stateData} = useQueryOfflineFHG(STATES_QUERY, undefined, 'states.type');
   const {data: optionData} = useQueryOfflineFHG(WASTE_EXPORT_OPTIONS_QUERY, undefined, 'wasteType.type');

   const [wasteExportCreateUpdate] = useMutationFHG(WASTE_EXPORT_CREATE_UPDATE);

   //TODO remove zipcode
   const [editValues, handleChange, {isChanged, defaultValues, setDefaultValues, setIsChanged}] = useEditData(
      {uuid: uuid()}, ['id', 'uuid', 'facilityId', 'stateId', 'startDate', 'stopDate', 'zipcode', 'analysisProvided']);

   const [isSaving, setIsSaving] = useState(false);

   useEffect(() => {
      if (wasteExport) {
         setDefaultValues(wasteExport);
      }
   }, [wasteExport, setDefaultValues]);

   const handleUpdateCache = () => {
      const queries = (onUpdateCache && onUpdateCache()) || [];

      if (editValues.city && !editValues.cityId) {
         queries.push({query: STATES_QUERY, queryPath: 'cities', mutationPath: 'wasteExport.city'});
      }
      return queries;
   };

   const getCityOptimistic = () => {
      if (editValues.cityId || defaultValues.cityId) {
         return find(stateData.cities, {id: editValues.cityId || defaultValues.cityId}) || {};
      } else if (editValues.city) {
         return {
            id: 1,
            name: editValues.city,
            uuid: uuid(),
            __typename: 'City',
         }
      }
   };

   /**
    * Submit the waste export.
    * @return {Promise<void>}
    */
   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);
            const variables = {
               ...editValues,
               startDate: daySelected[0].format(DATE_DB_FORMAT),
               stopDate: (daySelected[1] || daySelected[0]).format(DATE_DB_FORMAT)
            };

            // Default to Kansas if no state is specified.
            await wasteExportCreateUpdate({
               variables,
               optimisticResponse: {
                  __typename: 'Mutation',
                  wasteExport: {
                     __typename: 'WasteExport',
                     ...defaultWasteExport,
                     ...defaultValues,
                     ...variables,
                     city: {
                        ...getCityOptimistic(),
                     },
                     state: {
                        ...(find(stateData.states, {id: editValues.stateId || defaultValues.stateId}) || {})
                     }
                  }
               },
               update: cacheUpdate(handleUpdateCache(), editValues?.uuid, 'wasteExport')
            });
            setIsChanged(false);
            onClose && onClose();
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         onClose && onClose();
      }
   };

   return (
      <Form className={classes.formStyle} onSubmit={handleSubmit}>
         <Prompt when={isChanged}/>
         <Grid container direction={'row'} spacing={1} alignItems={'center'} overflow={'visible'}>
            <Grid item xs={6} sm={12} md={6}>
               <AutocompleteFHG
                  key={'wasteTypeId' + editValues.uuid}
                  name={'wasteTypeId'}
                  labelKey='wasteExport.type.label'
                  options={optionData && optionData.wasteType}
                  defaultValue={defaultValues.wasteTypeId}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item xs={6} sm={12} md={6}>
               <TextFieldFHG
                  key={'amount' + editValues.uuid}
                  name={'amount'}
                  type={'number'}
                  labelKey={'clientFacility.precipitationAmount.label'}
                  defaultValue={defaultValues.amount}
                  value={editValues.amount}
                  onChange={handleChange}
                  fullWidth
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item fullWidth xs={12}>
               <TextFieldFHG
                  key={'recipientName' + editValues.uuid}
                  name={'recipientName'}
                  labelKey={'clientFacility.recipientName.label'}
                  defaultValue={defaultValues.recipientName}
                  value={editValues.recipientName}
                  onChange={handleChange}
                  fullWidth
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item fullWidth>
               <TextFieldFHG
                  key={'addressLineOne' + editValues.uuid}
                  name={'addressLineOne'}
                  labelKey={'client.address.label'}
                  placeholderKey={'address.placeholder'}
                  defaultValue={defaultValues.addressLineOne}
                  value={editValues.addressLineOne}
                  onChange={handleChange}
                  fullWidth
                  disabled={isSaving}
               />
            </Grid>
            <Grid item fullWidth>
               <TextFieldFHG
                  key={'addressLineTwo' + editValues.uuid}
                  name={'addressLineTwo'}
                  labelKey={'client.address2.label'}
                  placeholderKey={'address2.placeholder'}
                  defaultValue={defaultValues.addressLineTwo}
                  value={editValues.addressLineTwo}
                  onChange={handleChange}
                  fullWidth
                  disabled={isSaving}
               />
            </Grid>
            {/*<Grid item container fullWidth spacing={1}>*/}
            <Grid item fullWidth xs={12} xl={5}>
               <AutocompleteFHG
                  key={'cityId' + editValues.uuid}
                  name={'cityId'}
                  editName={'city'}
                  labelKey={'client.city.label'}
                  placeholderKey={'city.placeholder'}
                  autoSelect={false}
                  options={stateData && stateData.cities}
                  defaultValue={defaultValues.cityId}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item fullWidth xs={8} xl={4}>
               <AutocompleteFHG
                  key={'stateId' + editValues.uuid}
                  name={'stateId'}
                  labelKey={'client.state.label'}
                  placeholderKey={'state.placeholder'}
                  options={stateData && stateData.states}
                  defaultValue={get(defaultValues, 'stateId') || KANSAS_STATE_ID}
                  onChange={handleChange}
                  disabled={isSaving}
                  required
               />
            </Grid>
            <Grid item fullWidth xs={4} xl={3}>
               <TextFieldFHG
                  key={'zipCode' + editValues.uuid}
                  name={'zipCode'}
                  inputProps={{
                     'data-type': 'number',
                     maxLength: 5,
                     pattern: '[0-9]{5}',
                     title: 'Five digit zip code'
                  }}
                  labelKey={'client.zip.label'}
                  placeholderKey={'zip.placeholder'}
                  defaultValue={defaultValues.zipCode}
                  value={editValues.zipCode}
                  onChange={handleChange}
                  fullWidth
                  disabled={isSaving}
               />
            </Grid>
            <Grid item xs={12} overflow={'visible'}>
               <CheckboxFHG
                  key={'analysisProvided' + editValues.uuid}
                  name={'analysisProvided'}
                  onChange={handleChange}
                  color={'default'}
                  labelKey={'wasteExport.analysisProvided.label'}
                  value={'analysisProvided'}
                  defaultChecked={defaultValues.analysisProvided}
                  checked={editValues.analysisProvided}
                  disabled={isSaving}
               />
            </Grid>
         </Grid>
         <Grid item xs={4} style={{
            marginTop: theme.spacing(1),
            position: 'sticky',
            bottom: 0,
            backgroundColor: theme.palette.background.paper
         }}
               fullWidth>
            <Button id='submit.wasteExport.id' variant='contained' color='primary' type={'submit'} size={'small'}
                    disabled={isSaving}>
               <Typography variant={'inherit'} id={'save.label'}/>
            </Button>
         </Grid>
      </Form>
   );
}
