import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import EditLocation from '@material-ui/icons/EditLocation';
import gql from 'graphql-tag';
import escapeRegExp from 'lodash/escapeRegExp';
import filter from 'lodash/filter';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import find from 'lodash/find';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect, forwardRef, useContext, useMemo} from 'react';
import {useLocation} from 'react-router';
import {useHistory, useParams, Link} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import AuthContext from '../../components/AuthContext';
import NotificationSnackbar from '../../components/NotificationSnackbar';
import TextFieldFHG from '../../components/TextField';
import TitleCard from '../../components/TitleCard';
import {
   MIN_ZOOM_DEFAULT, MAX_ZOOM_DEFAULT, ZOOM_WITH_MARKER_DEFAULT, ZOOM_DEFAULT, CLIENT_DASHBOARD_PATH,
   CLIENT_ASSET_PATH, FIELD_GEO_JSON_COLOR, BULLETINS_DAYS_BACK, DATE_TIME_DB_FORMAT, POLL_FOR_BULLETINS, DATE_FORMAT,
   POLL_FOR_ALERTS
} from '../../Constants';
import useMutationFHG, {UPDATE_ACTION} from '../../fhg/components/data/useMutationFHG';
import useQueryOfflineFHG from '../../fhg/components/data/useQueryOfflineFHG';
import Grid from '../../fhg/components/Grid';
import MapFHG from '../../fhg/components/map/MapFHG';
import PageTitle from '../../fhg/components/PageTitle';
import TypographyFHG from '../../fhg/components/Typography';
import {editChange, toNumber} from '../../fhg/utils/Utils';
import {downLoadForOfflineStatus} from '../../index';
import {createMarker, createGeoJson} from '../../prototype/MapUtils';
import FacilityClient from './FacilityClient';
import FieldClient from './FieldClient';
import OfflineData from './record/OfflineData';
import WasteStorageClient from './WasteStorageClient';
import WellClient from './WellClient';
import SearchField from '../../fhg/components/SearchField';

const useStyles = makeStyles(theme => ({
   frameStyle: {
      padding: theme.spacing(3),
   },
   root: {
      height: '100%',
      minWidth: 300,
   },
   contentStyle: {
      [theme.breakpoints.down('xs')]: {
         maxHeight: '80%',
      },
   },
   titleCardRoot: {
      overflow: 'auto',
   }
}), {name: 'ClientDashboardStyles'});

const useMapStyles = makeStyles(theme => ({
   mapSectionStyle: {
      maxHeight: '100%',
      minHeight: 200,
      minWidth: 200,
      [theme.breakpoints.down('xs')]: {
         // minHeight: 0,
         minHeight: 100,
         minWidth: 100,
      },
   },
}), {name: 'ClientDashboardMapStyles'});

ClientDashboard.propTypes = {};

export const FACILITY_ASSET = 1;
export const WELL_ASSET = 2;
export const FIELD_ASSET = 3;
export const WASTE_STORAGE_ASSET = 4;
export const ALL_ASSETS = 5;
export const ALL_FACILITIES = 0.1;

export const ASSET_TYPES = [
   {labelKey: 'clientDashboard.allAssets.label', value: ALL_ASSETS},
   {labelKey: 'clientDashboard.facilityAsset.label', value: FACILITY_ASSET},
   {labelKey: 'clientDashboard.fieldAsset.label', value: FIELD_ASSET},
   {labelKey: 'clientDashboard.wellAsset.label', value: WELL_ASSET},
   {labelKey: 'clientDashboard.wasteStorageAsset.label', value: WASTE_STORAGE_ASSET},
];

export const CLIENT_BY_ID_DASHBOARD_QUERY = gql`
   query getClientByIdForClientDashboard ($clientId: Int!) {
      client: client_ById(clientId: $clientId) {
         id
         uuid
         name
         eulaAcknowleged
         isDeleted
      }
      gauges: gauge_AllByClient(clientId: $clientId) {
         id
         uuid
         allotments {
            uuid
            acreFeet
            allotment
         }
      }
      facilities: facility_AllWhere(facilitySearch: {clientId: [$clientId]}) {
         id
         uuid
         name
         latitude
         longitude
         fields {
            id
            uuid
            acreage
            annualSum
            annualSumFull
            facilityId
            fieldCoverId
            nmpMaxRate
               
            latitude
            longitude
            name
            boundaryData
            isDeleted
         }
         wells {
            id
            uuid
            latitude
            longitude
            facilityId
            name: commonName
            isDeleted
         }
         wasteStorages {
            id
            uuid
            name
            latitude
            longitude
            currentWaterLevel
            dec1OperatingLevel
            operatingLevel
            isDeleted
         }
      }
   }
`;

const BULLETINS_FOR_USER_QUERY = gql`
   query getBulletinsForClientDashboard ($beginDate: String, $endDate: String) {
      bulletins:bulletin_AllWhere (bulletinSearch: {beginDate: $beginDate, endDate: $endDate, isDeleted: [false]}) {
         id
         message
         createdDateTime
         isDeleted
      }
   }
`;

export const ALERTS_FOR_USER_QUERY = gql`
   query getAlertsForClientDashboard ($clientId: [Int]) {
      alerts:alert_AllWhere (alertSearch: {clientId: $clientId, dismissed: false, forAdmins: false}) {
         id
         uuid
         dismissed
         clientId
         messageData {
            text
         }
         createdDateTime
         isDeleted
      }
   }
`;

// Update the alert with the given properties.
const ALERT_UPDATE = {
   mutation: gql`
      mutation AlertUpdate($id: Int!, $clientId: Int!) {
         alert: alert_Update(alertId: $id, alert: {clientId: $clientId, dismissed: true}) {
            id
            dismissed
            messageData {
               text
            }
            createdDateTime
            isDeleted
         }
      }
   `,
   typeKey: 'alert.type',
   actionKey: UPDATE_ACTION,
};

const MapSection = forwardRef(
   function MapSection({
         facilities,
         markers,
         bounds,
         editValues,
         onChange,
         onMarkerClick,
         onPopupOpen,
         onPopupClose,
         geoJson,
         ...props
      },
      ref) {
      const theme = useTheme();
      const classes = useMapStyles();

      return (
         <Grid ref={ref} name={'ClientDashboard-mapSection'} container item xs={12} sm={8} direction={'column'}
               wrap={'nowrap'}
               className={classes.mapSectionStyle} alignItems={'stretch'} resizable>
            <Grid name={'ClientDashboard-mapFilter'} item container direction={'row'} resizable={false} fullWidth
                  fullHeight={false} spacing={1} style={{paddingBottom: theme.spacing(1)}}>
               {(facilities && facilities.length > 0) && (
                  <Grid item xs={6}>
                     <TextFieldFHG
                        name={'facilityId'}
                        labelKey={'clientDashboard.selectFacility.label'}
                        value={editValues.facilityId}
                        onChange={onChange}
                        select
                        fullWidth
                     >
                        <MenuItem value={ALL_FACILITIES}>
                           <TypographyFHG id={'clientDashboard.allFacilities.label'}/>
                        </MenuItem>
                        {facilities.map(facility => (
                           <MenuItem key={'facility ' + facility.id} value={facility.id}>
                              <TypographyFHG>{facility.name}</TypographyFHG>
                           </MenuItem>
                        ))}
                     </TextFieldFHG>
                  </Grid>
               )}
               <Grid item xs={6}>
                  <TextFieldFHG
                     name={'assetType'}
                     labelKey={'clientDashboard.selectAsset.label'}
                     value={editValues.assetType}
                     onChange={onChange}
                     select
                     fullWidth
                  >
                     {ASSET_TYPES.map((asset, index) => (
                        <MenuItem key={'asset' + index} value={asset.value}>
                           <TypographyFHG id={asset.labelKey}/>
                        </MenuItem>
                     ))}
                  </TextFieldFHG>
               </Grid>
            </Grid>
            <Grid name={'ClientDashboard-map'} item
                  style={{maxHeight: '100%', minHeight: 200}} resizable>
               <MapFHG
                  key={'ClientMap'}
                  name={'client map component'}
                  defaultZoom={markers && markers.length > 0 ? ZOOM_WITH_MARKER_DEFAULT : ZOOM_DEFAULT}
                  minZoom={MIN_ZOOM_DEFAULT}
                  maxZoom={MAX_ZOOM_DEFAULT}
                  bounds={bounds}
                  markers={markers}
                  draggableMarker={false}
                  draggableMap={true}
                  onMarkerClick={onMarkerClick}
                  onPopupClose={onPopupClose}
                  onPopupOpen={onPopupOpen}
                  geoJson={geoJson}
                  zoomOnGeo={geoJson && (!markers || markers.length <= 0)}
                  // geoAndMarkers={true}
               />
            </Grid>
         </Grid>
      );
   });

MapSection.propTypes = {
   facilities: PropTypes.array,
   markers: PropTypes.arrayOf(PropTypes.any),
   bounds: PropTypes.array,
   onMarkerClick: PropTypes.func,
   onPopupOpen: PropTypes.func,
   onPopupClose: PropTypes.func,
};

/**
 * Dashboard for the clients. Shows the map of assets for the client.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
function ClientDashboard() {
   const {clientId: id, uuid} = useParams();
   const theme = useTheme();
   const clientId = toNumber(id);
   const history = useHistory();
   const location = useLocation();
   const classes = useStyles();
   const {isAdmin} = useContext(AuthContext);
   const [downloadForOffline] = useRecoilState(downLoadForOfflineStatus);
   const [search, setSearch] = useState();

   //If the user hasn't gotten bulletins before, only go back BULLETINS_DAYS_BACK days.
   const beginDate = localStorage.lastBulletinDate ||
      moment().subtract(BULLETINS_DAYS_BACK, 'days').utc().format(DATE_TIME_DB_FORMAT);

   const {data: bulletinData} = useQueryOfflineFHG(BULLETINS_FOR_USER_QUERY,
      {variables: {beginDate}, pollInterval: POLL_FOR_BULLETINS}, 'bulletin.type');

   const {data: alertData} = useQueryOfflineFHG(ALERTS_FOR_USER_QUERY,
      {variables: {clientId}, pollInterval: POLL_FOR_ALERTS}, 'alert.type');
   const [alertUpdate] = useMutationFHG(ALERT_UPDATE);

   const {data} = useQueryOfflineFHG(CLIENT_BY_ID_DASHBOARD_QUERY,
      {variables: {clientId}, skip: clientId === null, errorPolicy: 'all'}, 'client.type');
   const [markers, setMarkers] = useState([]);
   const [geoJson, setGeoJson] = useState([]);

   const [bounds, setBounds] = useState();
   const [bulletinsToShow, setBulletinsToShow] = useState([]);

   const [editValues, setEditValues] = useState({facilityId: ALL_FACILITIES, assetType: ALL_ASSETS});
   const [facilities, setFacilities] = useState();
   const [selectedMarker, setSelectedMarker] = useState();
   const [action, setAction] = useState();

   const {wells, fields, wasteStorageList} = useMemo(() => {
      let list = {fields: [], wells: [], wasteStorageList: []};

      if (facilities?.length > 0) {
         for (const facility of facilities) {
            list.fields = concat(list.fields, facility.fields);
            list.wells = concat(list.wells, facility.wells);
            list.wasteStorageList = concat(list.wasteStorageList, facility.wasteStorages);
         }
      }

      return list;
   }, [facilities]);

   useEffect(() => {
      function setSelectMarkerByUuid(uuid) {
         const marker = find(markers, {uuid});
         if (marker) {
            setSelectedMarker(marker);
         } else {
            const geoJsonShape = find(geoJson, {uuid});
            if (geoJsonShape) {
               setSelectedMarker(geoJsonShape);
            } else {
               setSelectedMarker(undefined);
               console.log('Item with uuid, ', uuid, 'could not be found.');
            }
         }
      }

      if (uuid && (markers.length > 0 || geoJson.length > 0)) {
         setSelectMarkerByUuid(uuid);
      } else {
         setSelectedMarker(undefined);
      }
   }, [uuid, markers, geoJson]);

   /**
    * Filter the assets and create the markers and geoJSON for the assets still showing.
    */
   useEffect(() => {
      const findHighestPercent = (allotments) => {
         const max = maxBy(allotments, allotment => {
            return allotment.allotment ? allotment.acreFeet / allotment.allotment : 0;
         });
         return max.allotment ? max.acreFeet / max.allotment : 0;
      }
      const minMax = {};
      let markers = [];
      let geoJson = [];
      let gauges = get(data, 'gauges') || [];
      //Case insensitive regular expression search.
      const re = search && new RegExp(escapeRegExp(search), 'i');

      if (facilities && facilities.length > 0) {
         let filteredFacilities = editValues.facilityId === ALL_FACILITIES ? facilities :
            filter(facilities, {id: editValues.facilityId});

         if (filteredFacilities && filteredFacilities.length > 0) {
            for (const facility of filteredFacilities) {
               let facilityFound = !re || (facility.name || '').search(re) >= 0;
               if ((editValues.assetType === ALL_ASSETS || editValues.assetType === FACILITY_ASSET) && facilityFound) {
                  createMarker(facility, '#8AABBD', markers, minMax, [
                     {label: 'Facility Name', value: facility.name || 'Untitled'}
                  ], FacilityClient, 'facility');
               }
               if ((editValues.assetType === ALL_ASSETS || editValues.assetType === FIELD_ASSET) && facility.fields &&
                  facility.fields.length > 0) {
                  let filteredFields = !re ? facility.fields :
                     filter(facility.fields, item => (item.name || '').search(re) >= 0);
                  for (const field of filteredFields) {
                     let color;
                     if (field.nmpMaxRate > 0) {
                        const percent = field.annualSum && field.nmpMaxRate ?
                           (field.annualSum < field.nmpMaxRate ? field.annualSum / field.nmpMaxRate : 1) : 0;
                        color = percent > 0.9 ? theme.palette.error.main :
                           percent > 0.75 ? theme.palette.warning.main : theme.palette.success.main;
                     } else {
                        color = theme.palette.error.main;
                     }
                     createMarker(field, color, markers, minMax, [
                        {label: 'Field Name', value: field.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'}
                     ], FieldClient, 'field');
                     createGeoJson(field, FIELD_GEO_JSON_COLOR, geoJson, minMax, [
                        {label: 'Field Name', value: field.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'}
                     ], FieldClient);
                  }
               }
               if ((editValues.assetType === ALL_ASSETS || editValues.assetType === WELL_ASSET) &&
                  facility.wells && facility.wells.length > 0) {
                  let filteredWells = !re ? facility.wells :
                     filter(facility.wells, item => (item.name || '').search(re) >= 0);
                  for (const well of filteredWells) {
                     const gauge = find(gauges, {id: well.id});
                     let color;
                     if (gauge && gauge.allotments && gauge.allotments.length > 0) {
                        const percent = findHighestPercent(gauge.allotments);
                        color = percent > 0.9 ? theme.palette.error.main :
                           percent > 0.75 ? theme.palette.warning.main : theme.palette.success.main;
                     } else {
                        color = '#60ADDB';
                     }
                     createMarker(well, color, markers, minMax, [
                        {label: 'Well Name', value: well.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'}
                     ], WellClient, 'well');
                  }
               }
               if ((editValues.assetType === ALL_ASSETS || editValues.assetType === WASTE_STORAGE_ASSET) &&
                  facility.wasteStorages && facility.wasteStorages.length > 0) {
                  let filteredWasteStorage = !re ? facility.wasteStorages :
                     filter(facility.wasteStorages, item => (item.name || '').search(re) >= 0);
                  for (const wasteStorageElement of filteredWasteStorage) {
                     const freeBoardLevel = 2;
                     const color = wasteStorageElement.currentWaterLevel < freeBoardLevel ? theme.palette.error.main :
                        (wasteStorageElement.currentWaterLevel < wasteStorageElement.operatingLevel ?
                           theme.palette.warning.main : theme.palette.success.main);
                     createMarker(wasteStorageElement, color, markers, minMax, [
                        {label: 'Waste Storage Name', value: wasteStorageElement.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'}
                     ], WasteStorageClient, 'wasteStorage');
                  }
               }
            }
            if (Object.keys(minMax).length === 4 && (markers.length > 0 || geoJson.length > 0)) {
               setBounds([[minMax.minLat, minMax.minLng], [minMax.maxLat, minMax.maxLng]]);
            }
         }
      }
      setGeoJson(geoJson || [])
      setMarkers(markers || []);
      if ((markers && markers.length === 1) && (!geoJson || geoJson.length === 0)) {
         history.push(CLIENT_ASSET_PATH.replace(':clientId', id).replace(':uuid', markers[0].uuid));
      } else if ((!markers || markers.length === 0) && (geoJson && geoJson.length === 1)) {
         history.push(CLIENT_ASSET_PATH.replace(':clientId', id).replace(':uuid', geoJson[0].uuid));
      }
   }, [
      data, facilities, editValues, history, id, theme.palette.error.main, theme.palette.success.main, search,
      theme.palette.warning.main
   ]);

   /**
    * Set the facilities from the data.
    */
   useEffect(() => {
      if (data) {

         const facilities = get(data, 'facilities') || [];
         const sortedFacilities = sortBy(facilities, ['name']);
         setFacilities(sortedFacilities);
      }
   }, [data]);

   /**
    * Filter the bulletins to the ones the user hasn't seen yet.
    */
   useEffect(() => {

      if (bulletinData || alertData) {
         let filteredAlerts = [];

         const bulletins = get(bulletinData, 'bulletins') || []
         const filteredBulletins = bulletins.map(bulletin => ({
            severity: 'info',
            title: `Bulletin ${moment(bulletin.createdDateTime).format(DATE_FORMAT)}`, ...bulletin
         }));

         const alerts = get(alertData, 'alerts') || [];
         if (!sessionStorage.showNotificationsLastId || sessionStorage.showNotificationsLastId <
            get(alerts, `${[alerts.length - 1]}.id`, 0)) {
            filteredAlerts = filter(alerts, {dismissed: false});
            filteredAlerts = filteredAlerts.map(alert => ({
               severity: 'error',
               title: `Alert ${moment(alert.createdDateTime).format(DATE_FORMAT)}`,
               message: get(alert, 'messageData.text'), ...alert
            }));
         }
         setBulletinsToShow(filteredBulletins.concat(filteredAlerts));
      }
   }, [bulletinData, alertData]);

   /**
    * Handle filter changes.
    * @param event The event causing the filter change.
    */
   const handleChange = (event) => {
      setEditValues({...editValues, ...editChange(event)});
      setMarkers([]);
      setSelectedMarker(undefined);
      setSearch('');
      let path = CLIENT_DASHBOARD_PATH.replace(':clientId', id);
      history.push(path);
   };

   /**
    * When the popup opens set the URL to show the selected asset.
    * @param uuid The uuid of the selected asset from the marker or geoJson.
    */
   const handlePopupOpen = (uuid) => {

      if (uuid) {
         let path = CLIENT_ASSET_PATH.replace(':clientId', id).replace(':uuid', uuid);
         history.push(path);
      } else {
         console.log('Could not find UUID', uuid);
      }
   };

   /**
    * Show the selected action panel.
    * @param action The action selected.
    */
   const handleActionChange = (action) => {
      setAction(action);
   };

   /**
    * Handle closing the bulletin notification.
    */
   const handleClose = () => {
      const count = bulletinsToShow.length;
      let lastBulletinDate = get(bulletinData, `bulletins[${count - 1}].createdDateTime`);

      localStorage.lastBulletinDate = moment(lastBulletinDate).add(1, 'second');
      setBulletinsToShow([]);
      const alerts = get(alertData, 'alerts') || [];
      sessionStorage.showNotificationsLastId = get(alerts, `${[alerts.length - 1]}.id`);
   };

   const handleNotificationClose = (notification) => {
      if (!isAdmin) {
         if (notification.__typename === 'Alert') {
            alertUpdate({variables: {id: notification.id, clientId, dismissed: true}});
         }
      } else {
         console.log('Admins cannot close alerts for clients.');
      }
   };

   /**
    * Callback when the search text changes. Convert the text to lower case.
    * @param searchText The new search text.
    */
   const handleSearchChange = (searchText) => {
      let useSearch
      if (searchText.search !== undefined) {
         useSearch = toLower(trim(searchText.search));
      }
      setSearch(useSearch);
   };

   /**
    * Close the card showing the data.
    */
   const handleCloseCard = () => {
      setSelectedMarker(undefined);
      let path = CLIENT_DASHBOARD_PATH.replace(':clientId', id);
      history.push(path);
   }

   // Don't show anything until the necessary filters have options to show.
   if (editValues.assetType === undefined || editValues.facilityId === undefined) {
      return null;
   }

   let SelectComponent;
   let selectProps;
   //The selected marker(or GeoJSON) has a component to show for the asset.
   if (selectedMarker && selectedMarker.component) {
      SelectComponent = selectedMarker.component;
      selectProps = {
         defaultItem: selectedMarker.item,
         onActionChange: handleActionChange,
         onClose: handleCloseCard,
         facilities,
         clientName: get(data, 'client.name')
      }
   } else {
      SelectComponent = 'span';
      selectProps = {};
   }

   return (
      <PageTitle titleKey={'app.title'}>
         <TitleCard classes={{root: classes.titleCardRoot}} allowDrawer showTitle={false}>
            {(bulletinsToShow && bulletinsToShow.length > 0) &&
               <NotificationSnackbar notifications={bulletinsToShow} onClose={handleClose}
                                     onNotificationClose={handleNotificationClose}/>}
            {(localStorage.showNavigationLinks === 'true' && uuid) && (
               <IconButton style={{position: 'absolute', right: 0, top: 80}} color='primary' component={Link}
                           to={location.pathname.replace('/client', '/admin/client')}>
                  <EditLocation/>
               </IconButton>
            )}
            {downloadForOffline.shouldDownload && <OfflineData {...{facilities, wells, fields, wasteStorageList}}/>}
            <Hidden xsDown>
               <Grid name={'ClientDashboard-root'} item container alignItems={'stretch'} justify={'center'}
                     fullHeight fullWidth
                     direction={'row'}>
                  <MapSection key={'facilities map component'} classes={classes} facilities={facilities}
                              onPopupOpen={handlePopupOpen} editValues={editValues}
                              onChange={handleChange} markers={markers} bounds={bounds} geoJson={geoJson}/>
                  <Grid name={'MapCard-content'} fullHeight className={classes.contentStyle} container item
                        direction={'column'}
                        xs={12} sm={4} isScrollable isAddScrollPadding={false}>
                     {(SelectComponent !== 'span') ? (
                        <SelectComponent {...selectProps}/>
                     ) : (
                        <SearchField name='search' style={{width: '100%'}} value={search} onChange={handleSearchChange}
                                     labelKey={'clientSetup.search.label'}/>
                     )}
                  </Grid>
               </Grid>
            </Hidden>
            <Hidden smUp>
               <Grid name={'ClientDashboard-root'} item container fullWidth direction={'column'} resizable={false}
                     wrap={'nowrap'} style={{minHeight: '100%'}}>
                  <MapSection key={'facilities map component ' + action} classes={classes} facilities={facilities}
                              onPopupOpen={handlePopupOpen} editValues={editValues}
                              onChange={handleChange} markers={markers} bounds={bounds} geoJson={geoJson}/>
                  <Grid name={'MapCard-content'} className={classes.contentStyle} container item direction={'column'}>
                     {(SelectComponent !== 'span') ? (
                        <SelectComponent {...selectProps}/>
                     ) : (
                        <SearchField name='search' style={{width: 'calc(100% - 24px'}} value={search}
                                     onChange={handleSearchChange}
                                     labelKey={'clientSetup.search.label'}/>
                     )}
                  </Grid>
               </Grid>
            </Hidden>
         </TitleCard>
      </PageTitle>
   );
}

export default ClientDashboard;
