import get from 'lodash/get';
import {BOUNDING_BOX} from '../Constants';

/**
 * Calculate if the latitude and longitude point should change the minimum and maximum.
 *
 * @param minMax The accumulated minimum and maximum values.
 * @param latitude The latitude of the point to calculate.
 * @param longitude The longitude of the point to calculate.
 */
const calcMinMax = (minMax, latitude, longitude) => {
   if (latitude && longitude && minMax) {
      if (minMax.minLat === undefined || latitude < minMax.minLat) {
         if (latitude >= BOUNDING_BOX.minLat && latitude <= BOUNDING_BOX.maxLat) {
            minMax.minLat = latitude;
         } else {
            return;
         }
      }
      if (minMax.maxLat === undefined || latitude > minMax.maxLat) {
         if (latitude >= BOUNDING_BOX.minLat && latitude <= BOUNDING_BOX.maxLat) {
            minMax.maxLat = latitude;
         } else {
            return;
         }
      }
      if (minMax.minLng === undefined || longitude < minMax.minLng) {
         if (longitude >= BOUNDING_BOX.minLng && longitude <= BOUNDING_BOX.maxLng) {
            minMax.minLng = longitude;
         } else {
            return;
         }
      }
      if (minMax.maxLng === undefined || longitude > minMax.maxLng) {
         if (longitude >= BOUNDING_BOX.minLng && longitude <= BOUNDING_BOX.maxLng) {
            minMax.maxLng = longitude;
         }
      }
   }
};

/**
 * Find the minimum and maximum for the coordinates array.
 * @param minMax The accumulated minimum and maximum values.
 * @param coordinates The array of coordinates to calculate.
 */
function calcMinMaxArray(minMax, coordinates = []) {
   for (let i = 0; i < coordinates.length; i++) {
      calcMinMax(minMax, coordinates[i][1], coordinates[i][0]);
   }
}

/**
 * Create a geo JSON object for the item.
 *
 * @param item The item to create the marker for.
 * @param color The color of the marker created.
 * @param geoJson The list of geoJson objects to add the new geoJson to.
 * @param minMax The accumulated minimum and maximum points of all the markers and geoJson.
 * @param content The content for the tooltip and popup.
 * @param component The component to show if the marker is selected.
 */
export function createGeoJson(item, color, geoJson, minMax, content, component, componentProps, showPopup = true) {
   if (item.boundaryData !== 'null') {
      const data = JSON.parse(item.boundaryData);
      const type = get(data, 'geometry.type');
      const coordinates = get(data, 'geometry.coordinates');
      if (type === 'Point') {
         calcMinMaxArray(minMax, [coordinates]);
      } else if (type === 'Polygon') {
         for (let i = 0; i < coordinates.length; i++) {
            calcMinMaxArray(minMax, coordinates[i]);
         }
      } else {
         console.log(type + ' is not supported for calculating min and max boundaries for map.');
      }
      geoJson.push({data, color, content, type: 'field', uuid: item.uuid, facilityId: item.facilityId, item, component, componentProps, showPopup});
   }
}

/**
 * Create a marker object for the item.
 *
 * @param item The item to create the marker for.
 * @param color The color of the marker created.
 * @param markers The list of markers to add the new marker to.
 * @param minMax The accumulated minimum and maximum points of all the markers and geoJson.
 * @param content The content for the tooltip and popup.
 * @param component The component to show if the marker is selected.
 */
export function createMarker(item, color, markers, minMax, content, component, type, componentProps, closeButton= true) {
   if (item.latitude) {
      calcMinMax(minMax, item.latitude, item.longitude);

      markers.push({
         latitude: item.latitude,
         longitude: item.longitude,
         color,
         type,
         content,
         uuid: item.uuid,
         item,
         component,
         componentProps,
         closeButton,
      });
   }
}
