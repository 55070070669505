import Hidden from '@material-ui/core/Hidden';
import capitalize from '@material-ui/core/utils/capitalize';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import LocationOn from '@material-ui/icons/LocationOn';
import gql from 'graphql-tag';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import escapeRegExp from 'lodash/escapeRegExp';
import maxBy from 'lodash/maxBy';
import pick from 'lodash/pick';
import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';
import React, {useState, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useHistory, useLocation, useParams, Link} from 'react-router-dom';
import {v4 as uuidFunction} from 'uuid';
import TitleCard from '../components/TitleCard';
import {FIELD_GEO_JSON_COLOR, CLIENT_SETUP_ASSET_PATH, CLIENT_SETUP_PATH, ADMIN_CLIENT_PATH} from '../Constants';
import useMutationFHG, {UPDATE_ACTION, CREATE_UPDATE_ACTION} from '../fhg/components/data/useMutationFHG';
import useQueryFHG from '../fhg/components/data/useQueryFHG';
import Grid from '../fhg/components/Grid';
import PageTitle from '../fhg/components/PageTitle';
import SearchField from '../fhg/components/SearchField';
import {cacheAdd, cacheUpdate} from '../fhg/utils/DataUtil';
import {editChange, toNumber, formatMessage} from '../fhg/utils/Utils';
import FacilityEditInfo, {FACILITY_CREATE_UPDATE} from '../pages/admin/client/FacilityEditInfo2';
import FieldEditCard from '../pages/admin/client/FieldEditCard2';
import {WELL_FRAGMENT, FIELD_FULL_FRAGMENT, ALLOTMENT_FRAGMENT} from '../pages/admin/client/FragmentsGL';
import WasteStorageEditCard2 from '../pages/admin/client/WasteStorageEditCard2';
import WellEditInfo2 from '../pages/admin/client/WellEditInfo2';
import MapSection from './MapSection';
import {createMarker, createGeoJson} from './MapUtils';
import {WELLS_FOR_FACILITY_QUERY, WASTE_STORAGE_OPTIONS_QUERY} from '../data/QueriesGL';

const useStyles = makeStyles(
   (theme) => ({
      frameStyle: {
         padding: theme.spacing(3),
      },
      root: {
         height: '100%',
         minWidth: 300,
      },
      contentStyle: {
         [theme.breakpoints.down('xs')]: {
            maxHeight: '80%',
         },
      },
      titleCardRoot: {
         overflow: 'auto',
      },
   }),
   {name: 'ClientSetupStyles'}
);

ClientSetup.propTypes = {};

const FACILITY_ASSET = 1;
const WELL_ASSET = 2;
const FIELD_ASSET = 3;
const WASTE_STORAGE_ASSET = 4;
const ALL_ASSETS = 5;
const ALL_FACILITIES = 0.1;

export const CLIENT_BY_ID_DASHBOARD_QUERY = gql`
   query getClientByIdForClientDashboard($clientId: Int!) {
      client: client_ById(clientId: $clientId) {
         id
         uuid
         name
         contactName
         phone
         email
         isDeleted
      }
      gauges: gauge_AllByClient(clientId: $clientId) {
         id
         uuid
         allotments {
            uuid
            acreFeet
            allotment
         }
      }
   }
`;
export const FACILITY_BY_CLIENT_ID_QUERY = gql`
   query getFacilityByClientId($clientId: Int!) {
      facilities: facility_AllWhere(facilitySearch: {clientId: [$clientId]}) {
         id
         uuid
         name
         latitude
         longitude
      }
      client: client_ById(clientId: $clientId) {
         id
         uuid
         addressLineOne
         city {
            id
            name
         }
         state {
            id
            name
            abbreviation
         }
         zipCode
      }
   }
`;
export const FIELDS_BY_FACILITY_ID_QUERY = gql`
   query getFieldsByFacilityId($facilityId: [Int]) {
      fields: field_AllWhere(fieldSearch: {facilityId: $facilityId}) {
         ...fieldFullInfo
      }
   }
   ${FIELD_FULL_FRAGMENT}
`;

// Update the fields with the given properties.
const FIELD_CREATE_FULL_UPDATE = {
   mutation: gql`
      mutation FieldCreateUpdate(
         $uuid: String!
         $name: String
         $acreage: Float
         $facilityId: Int!
         $fieldCover: String
         $fieldCoverId: Int
         $latitude: Float
         $longitude: Float
         $nmpMaxRate: Float
         $boundaryData: String
      ) {
         field: field_CreateUpdate(
            field: {
               uuid: $uuid
               name: $name
               acreage: $acreage
               facilityId: $facilityId
               fieldCover: $fieldCover
               fieldCoverId: $fieldCoverId
               latitude: $latitude
               longitude: $longitude
               nmpMaxRate: $nmpMaxRate
               boundaryData: $boundaryData
            }
         ) {
            ...fieldFullInfo
         }
      }
      ${FIELD_FULL_FRAGMENT}
   `,
   actionKey: CREATE_UPDATE_ACTION,
   typeKey: 'field.type',
};

export const WELLS_BY_FACILITY_ID_QUERY = gql`
   query getWellByFacilityId($facilityId: [Int]) {
      wells: well_AllWhere(wellSearch: {facilityId: $facilityId}) {
         ...wellInfo
      }
   }
   ${WELL_FRAGMENT}
`;

export const ALLOTMENTS_BY_FACILITY_ID_QUERY = gql`
   query getAllotmentsByFacilityId($facilityId: [Int]) {
      allotments: allotment_AllWhere(allotmentSearch: {facilityId: $facilityId}) {
         ...allotmentInfo
      }
   }
   ${ALLOTMENT_FRAGMENT}
`;

export const WASTE_STORAGE_BY_FACILITY_ID_QUERY = gql`
   query getWasteStorageByFacilityId($facilityId: [Int]) {
      wasteStorageList: wasteStorage_AllWhere(wasteStorageSearch: {facilityId: $facilityId}) {
         id
         uuid
         name
         latitude
         longitude
         facilityId
         currentWaterLevel
         dec1OperatingLevel
         operatingLevel
         isDeleted
      }
   }
`;

// Update the wasteStorage with the given properties.
export const WELL_CREATE_UPDATE = {
   mutation: gql`
      mutation WellCreateUpdate(
         $uuid: String!
         $facilityId: Int
         $commonName: String
         $latitude: Float
         $longitude: Float
         $allotments: [AllotmentInput]
      ) {
         well: well_CreateUpdate(
            well: {
               uuid: $uuid
               facilityId: $facilityId
               longitude: $longitude
               latitude: $latitude
               commonName: $commonName
               allotments: $allotments
            }
         ) {
            ...wellInfo
         }
      }
      ${WELL_FRAGMENT}
   `,
   typeKey: 'well.type',
   actionKey: UPDATE_ACTION,
};

// Update the wasteStorage with the given properties.
export const WASTE_STORAGE_CREATE_UPDATE = {
   mutation: gql`
      mutation WasteStorageCreateUpdate(
         $uuid: String!
         $facilityId: Int
         $name: String
         $operatingLevel: Float
         $dec1OperatingLevel: Float
         $latitude: Float
         $longitude: Float
      ) {
         wasteStorage: wasteStorage_CreateUpdate(
            wasteStorage: {
               uuid: $uuid
               facilityId: $facilityId
               name: $name
               operatingLevel: $operatingLevel
               dec1OperatingLevel: $dec1OperatingLevel
               latitude: $latitude
               longitude: $longitude
            }
         ) {
            id
            uuid
            name
            operatingLevel
            dec1OperatingLevel
            currentWaterLevel
            latitude
            longitude
            facilityId
            isDeleted
         }
      }
   `,
   typeKey: 'wasteStorage.type',
   actionKey: UPDATE_ACTION,
};

/**
 * Setup for the client facilities. Shows the map of assets for the client.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
function ClientSetup({
   defaultCenter,
   defaultBounds,
   defaultZoom,
   facilities,
   facilityIds,
   fields,
   wells,
   wasteStorageList,
   allotments,
}) {
   const {clientId: id, uuid} = useParams();
   const theme = useTheme();
   const clientId = toNumber(id);
   const classes = useStyles();
   const intl = useIntl();
   const history = useHistory();
   const location = useLocation();

   const [search, setSearch] = useState();

   const [facilityCreateUpdate] = useMutationFHG(FACILITY_CREATE_UPDATE);

   const [fieldCreateUpdate] = useMutationFHG(FIELD_CREATE_FULL_UPDATE);

   const [wasteStorageCreateUpdate] = useMutationFHG(WASTE_STORAGE_CREATE_UPDATE);

   const [wellCreateUpdate] = useMutationFHG(WELL_CREATE_UPDATE);

   const [markers, setMarkers] = useState([]);
   const [geoJson, setGeoJson] = useState([]);

   const [bounds, setBounds] = useState(defaultBounds);

   const [editValues, setEditValues] = useState({facilityId: ALL_FACILITIES, assetType: ALL_ASSETS});
   const [allowBoundsChange, setAllowBoundsChange] = useState(true);

   const [selectedMarker, setSelectedMarker] = useState();

   const [geoJsonDelete, setGeoJsonDelete] = useState();

   const {data} = useQueryFHG(
      CLIENT_BY_ID_DASHBOARD_QUERY,
      {variables: {clientId}, skip: clientId === null, errorPolicy: 'all'},
      'client.type'
   );

   const filteredFacilities = useMemo(() => {
      return editValues.facilityId === ALL_FACILITIES ? facilities : filter(facilities, {id: editValues.facilityId});
   }, [editValues, facilities]);
   const filteredFacilityIds = useMemo(() => map(filteredFacilities, 'id'), [filteredFacilities]);

   useEffect(() => {
      if (defaultBounds) {
         setBounds(defaultBounds);
      }
   }, [defaultBounds]);

   useEffect(() => {
      function setSelectMarkerByUuid(uuid) {
         const geoJsonShape = find(geoJson, {uuid});
         if (geoJsonShape) {
            setSelectedMarker(geoJsonShape);
         } else {
            const marker = find(markers, {uuid});
            if (marker) {
               setSelectedMarker(marker);
            } else {
               setSelectedMarker(undefined);
               console.log('Item with uuid, ', uuid, 'could not be found.');
            }
         }
      }

      if (uuid && (markers.length > 0 || geoJson.length > 0)) {
         setSelectMarkerByUuid(uuid);
      } else {
         setSelectedMarker(undefined);
      }
   }, [uuid, markers, geoJson]);

   /**
    * Filter the assets and create the markers and geoJSON for the assets still showing.
    */
   useEffect(() => {
      const findHighestPercent = (allotments) => {
         const max = maxBy(allotments, (allotment) => {
            return allotment.acreFeet / allotment.allotment;
         });
         return max && max.acreFeet / max.allotment;
      };
      const minMax = {};
      let markers = [];
      let geoJson = [];
      //Case insensitive regular expression search.
      const re = search && new RegExp(escapeRegExp(search), 'i');

      let gauges = get(data, 'gauges') || [];
      if (data && fields && wells && wasteStorageList) {
         for (const facility of filteredFacilities) {
            if (
               (editValues.assetType === ALL_ASSETS || editValues.assetType === FACILITY_ASSET) &&
               (!re || (facility.name || '').search(re) >= 0)
            ) {
               createMarker(
                  facility,
                  '#8AABBD',
                  markers,
                  minMax,
                  [{label: 'Facility Name', value: facility.name || 'Untitled'}],
                  FacilityEditInfo,
                  'facility',
                  {client: data.client},
                  false
               );
            }

            if (editValues.assetType === ALL_ASSETS || editValues.assetType === FIELD_ASSET) {
               let filteredFields = filter(fields, {facilityId: facility.id});
               if (re) {
                  filteredFields = filter(filteredFields, (item) => (item.name || '').search(re) >= 0);
               }
               for (const field of filteredFields) {
                  const percent =
                     field.annualSum && field.nmpMaxRate
                        ? field.annualSum < field.nmpMaxRate
                           ? field.annualSum / field.nmpMaxRate
                           : 1
                        : 0;
                  const color =
                     percent > 0.9
                        ? theme.palette.error.main
                        : percent > 0.75
                        ? theme.palette.warning.main
                        : theme.palette.success.main;
                  createMarker(
                     field,
                     color,
                     markers,
                     minMax,
                     [
                        {label: 'Field Name', value: field.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'},
                     ],
                     FieldEditCard,
                     'field',
                     undefined,
                     false
                  );
                  createGeoJson(
                     field,
                     FIELD_GEO_JSON_COLOR,
                     geoJson,
                     minMax,
                     [
                        {label: 'Field Name', value: field.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'},
                     ],
                     FieldEditCard,
                     undefined,
                     false
                  );
               }
            }

            if (editValues.assetType === ALL_ASSETS || editValues.assetType === WELL_ASSET) {
               let filteredWells = filter(wells, {facilityId: facility.id});
               if (re) {
                  filteredWells = filter(filteredWells, (item) => (item.name || '').search(re) >= 0);
               }
               for (const well of filteredWells) {
                  const gauge = find(gauges, {id: well.id});
                  let color;
                  if (gauge && gauge.allotments && gauge.allotments.length > 0) {
                     const percent = findHighestPercent(gauge.allotments);
                     color =
                        percent > 0.9
                           ? theme.palette.error.main
                           : percent > 0.75
                           ? theme.palette.warning.main
                           : theme.palette.success.main;
                  } else {
                     color = '#60ADDB';
                  }
                  createMarker(
                     well,
                     color,
                     markers,
                     minMax,
                     [
                        {label: 'Well Name', value: well.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'},
                     ],
                     WellEditInfo2,
                     'well',
                     {allotments},
                     false
                  );
               }
            }

            if (editValues.assetType === ALL_ASSETS || editValues.assetType === WASTE_STORAGE_ASSET) {
               let filteredWasteStorage = filter(wasteStorageList, {facilityId: facility.id});
               if (re) {
                  filteredWasteStorage = filter(filteredWasteStorage, (item) => (item.name || '').search(re) >= 0);
               }

               for (const wasteStorageElement of filteredWasteStorage) {
                  const freeBoardLevel = 2;
                  const color =
                     wasteStorageElement.currentWaterLevel < freeBoardLevel
                        ? theme.palette.error.main
                        : wasteStorageElement.currentWaterLevel < wasteStorageElement.operatingLevel
                        ? theme.palette.warning.main
                        : theme.palette.success.main;
                  createMarker(
                     wasteStorageElement,
                     color,
                     markers,
                     minMax,
                     [
                        {label: 'Waste Storage Name', value: wasteStorageElement.name || 'Untitled'},
                        {label: 'Facility Name', value: facility.name || 'Untitled'},
                     ],
                     WasteStorageEditCard2,
                     'wasteStorage',
                     undefined,
                     false
                  );
               }
            }
         }
         if (allowBoundsChange && Object.keys(minMax).length === 4 && (markers.length > 0 || geoJson.length > 0)) {
            setBounds([
               [minMax.minLat, minMax.minLng],
               [minMax.maxLat, minMax.maxLng],
            ]);
         }
         setAllowBoundsChange(false);
         setGeoJson(geoJson || []);
         setMarkers(markers || []);
         if (selectedMarker) {
            setSelectedMarker(find(markers, {uuid: selectedMarker.uuid}));
         }
      }
   }, [
      data,
      filteredFacilities,
      fields,
      wells,
      wasteStorageList,
      editValues,
      id,
      search,
      allotments,
      allowBoundsChange,
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.warning.main,
   ]);

   /**
    * Handle filter changes.
    * @param event The event causing the filter change.
    */
   const handleChange = (event) => {
      setEditValues({...editValues, ...editChange(event)});
      setMarkers([]);
   };

   /**
    * When the popup is open, open the asset window.
    */
   const handlePopupOpen = (uuid) => {
      if (uuid) {
         let path = CLIENT_SETUP_ASSET_PATH.replace(':clientId', id).replace(':uuid', uuid);
         history.push(path);
      } else {
         console.log('Could not find setup asset UUID', uuid);
      }
   };

   const handleLocationChange = async (location, event, drawType) => {
      const uuid = get(event, 'sourceTarget.options.uuid', get(event, 'target.options.uuid'));
      if (uuid) {
         const marker = find(markers, {uuid});
         if (marker) {
            marker.latitude = location.lat;
            marker.longitude = location.lng;
            setSelectedMarker({...marker});
            const variables = {
               uuid,
               latitude: location.lat,
               longitude: location.lng,
               facilityId: marker.item.facilityId,
            };

            switch (marker.type) {
               case 'facility':
                  facilityCreateUpdate({variables});
                  break;
               case 'field':
                  fieldCreateUpdate({variables});
                  break;
               case 'well':
                  wellCreateUpdate({variables});
                  break;
               case 'wasteStorage':
                  wasteStorageCreateUpdate({variables});
                  break;
               default:
                  console.log('Cannot update type', marker.type);
                  break;
            }
            let path = CLIENT_SETUP_ASSET_PATH.replace(':clientId', id).replace(':uuid', uuid);
            history.push(path);
         }
      } else if (location && location.lat) {
         let newMarker = [];
         let result;
         const facilityId = filteredFacilityIds.length === 1 && filteredFacilityIds[0];
         const selectedFacility = filteredFacilities.length === 1 && filteredFacilities[0];
         const variables = {uuid: uuidFunction(), latitude: location.lat, longitude: location.lng};

         switch (drawType) {
            case FACILITY_ASSET:
               variables.clientId = clientId;
               variables.name = capitalize(formatMessage(intl, 'facility.type')) + ' ' + (facilities.length + 1);
               result = await facilityCreateUpdate({
                  variables,
                  update: cacheAdd(
                     [{query: FACILITY_BY_CLIENT_ID_QUERY, variables: {clientId}, queryPath: 'facilities'}],
                     'facility'
                  ),
               });
               const facility = result.data.facility;
               createMarker(
                  facility,
                  '#8AABBD',
                  newMarker,
                  undefined,
                  [{label: 'Facility Name', value: facility.name || 'Untitled'}],
                  FacilityEditInfo,
                  'facility',
                  {facilityDefaults: facility, client: data.client},
                  false
               );
               break;
            case FIELD_ASSET:
               //Intentionally left blank. The Fields are created through handleGeoChange.
               break;
            case WELL_ASSET:
               variables.facilityId = facilityId;
               variables.commonName = capitalize(formatMessage(intl, 'well.type')) + ' ' + (wells.length + 1);
               variables.allotments = [];
               result = await wellCreateUpdate({
                  variables,
                  update: cacheAdd(
                     [
                        {query: WELLS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}, queryPath: 'wells'},
                        {query: WELLS_FOR_FACILITY_QUERY, variables: {facilityId}, queryPath: 'wells'},
                     ],
                     'well'
                  ),
               });
               const well = result.data.well;
               createMarker(
                  well,
                  '#60ADDB',
                  newMarker,
                  undefined,
                  [
                     {label: 'Well Name', value: well.name || 'Untitled'},
                     {label: 'Facility Name', value: selectedFacility.name || 'Untitled'},
                  ],
                  WellEditInfo2,
                  'well'
               );
               break;
            case WASTE_STORAGE_ASSET:
               variables.facilityId = facilityId;
               variables.name = 'Waste Storage ' + (get(wasteStorageList, 'length', 0) + 1);

               result = await wasteStorageCreateUpdate({
                  variables,
                  update: cacheAdd(
                     [
                        {query: WASTE_STORAGE_OPTIONS_QUERY, variables: {facilityId}, queryPath: 'wasteStorageList'},
                        {
                           query: WASTE_STORAGE_BY_FACILITY_ID_QUERY,
                           variables: {facilityId: facilityIds},
                           queryPath: 'wasteStorageList',
                        },
                     ],
                     'wasteStorage'
                  ),
               });

               const wasteStorage = result.data.wasteStorage;
               createMarker(
                  wasteStorage,
                  theme.palette.error.main,
                  newMarker,
                  undefined,
                  [
                     {label: 'Waste Storage Name', value: wasteStorage.name || 'Untitled'},
                     {label: 'Facility Name', value: selectedFacility.name || 'Untitled'},
                  ],
                  WasteStorageEditCard2,
                  'wasteStorage',
                  {wasteStorage, facilityId, titleId: 'clientWasteStorage.title'},
                  false
               );
               break;
            default:
               console.log('Cannot create type', drawType);
               break;
         }
         if (newMarker.length > 0) {
            markers.push(newMarker[0]);
            setSelectedMarker(newMarker[0]);
            setSearch(undefined);
            let path = CLIENT_SETUP_ASSET_PATH.replace(':clientId', id).replace(':uuid', variables.uuid);
            history.push(path);
         }
      }
   };

   const handleGeoChange = async (eventGeoJson) => {
      const features = eventGeoJson.features ? eventGeoJson.features : [eventGeoJson];
      for (const feature of features) {
         let uuid = get(feature, 'properties.uuid');
         const latitude = get(feature, 'properties.latitude');
         const longitude = get(feature, 'properties.longitude');
         const facilityId = editValues.facilityId;
         const boundaryData = JSON.stringify(pick(feature, ['type', 'geometry', 'properties']));
         let variables = {uuid, latitude, longitude, facilityId, boundaryData};

         if (!uuid) {
            if (
               selectedMarker &&
               selectedMarker.type === 'field' &&
               get(selectedMarker, 'item.boundaryData', 'null') === 'null'
            ) {
               uuid = selectedMarker.uuid;

               variables = {
                  ...variables,
                  latitude: selectedMarker.latitude,
                  longitude: selectedMarker.longitude,
                  uuid: selectedMarker.uuid,
               };
            } else {
               variables = {
                  ...variables,
                  uuid: uuidFunction(),
                  name: capitalize(formatMessage(intl, 'field.type')) + ' ' + (fields.length + 1),
               };
            }
         }
         await fieldCreateUpdate({
            variables,
            update: cacheUpdate(
               [{query: FIELDS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}, queryPath: 'fields'}],
               uuid,
               'field'
            ),
         });
         let path = CLIENT_SETUP_ASSET_PATH.replace(':clientId', id).replace(':uuid', variables.uuid);
         setSearch(undefined);
         history.push(path);
      }
   };

   /*
   Use and effect with function. useCallback didn't work. Apparently the callback wasn't updating when markers updated.
    */
   useEffect(() => {
      const submit = async () => {
         if (geoJsonDelete) {
            const features = geoJsonDelete.features;
            for (const feature of features) {
               const uuid = get(feature, 'properties.uuid');
               const facilityId = get(feature, 'properties.facilityId');
               if (uuid) {
                  const marker = find(markers, {uuid});
                  if (marker) {
                     await fieldCreateUpdate({
                        variables: {
                           uuid,
                           facilityId,
                           latitude: marker.latitude,
                           longitude: marker.longitude,
                           boundaryData: 'null',
                        },
                     });
                  }
               }
            }
            setGeoJsonDelete(undefined);
         }
      };
      submit();
   }, [geoJsonDelete]);

   /**
    * Use this for a callback for deleting. Markers weren't updated when calling a useCallback function. So we
    * set the data and have a useEffect handle the delete.
    *
    * @param geoJsonDelete The geoJson from the delete event.
    */
   const handleGeoDelete = (geoJsonDelete) => {
      setGeoJsonDelete(geoJsonDelete);
   };

   const handleFilterChange = (filter) => {
      setAllowBoundsChange(true);
      setEditValues(filter);
   };

   const handleClose = () => {
      setSelectedMarker(undefined);
      let path = CLIENT_SETUP_PATH.replace(':clientId', id);
      history.push(path);
   };

   const handleSearchChange = (searchText) => {
      let useSearch;
      if (searchText.search !== undefined) {
         useSearch = toLower(trim(searchText.search));
      }
      setSearch(useSearch);
      setAllowBoundsChange(true);
   };

   // Don't show anything until the necessary filters have options to show.
   if (editValues.assetType === undefined || editValues.facilityId === undefined) {
      return null;
   }

   let SelectComponent;
   let selectProps;

   //The selected marker(or GeoJSON) has a component to show for the asset.
   if (selectedMarker && selectedMarker.component) {
      SelectComponent = selectedMarker.component;
      selectProps = {
         [selectedMarker.type]: selectedMarker.item,
         key: selectedMarker.item.uuid,
         onClose: handleClose,
         filteredFacilityIds,
         facilityIds,
         ...(selectedMarker.componentProps || {}),
      };
   } else {
      SelectComponent = 'span';
      selectProps = {};
   }

   return (
      <PageTitle titleKey={'app.title'}>
         <TitleCard
            classes={{root: classes.titleCardRoot}}
            titleId={'facility.title'}
            titleUrl={ADMIN_CLIENT_PATH.replace(':clientId?', clientId)}
            values={{
               clientName: get(data, 'client.name'),
               type: formatMessage(intl, 'client.titleEdit.label'),
            }}
            showTitle={false}
         >
            {localStorage.showNavigationLinks === 'true' && uuid && (
               <IconButton
                  style={{position: 'absolute', right: 0, top: 80}}
                  color='primary'
                  component={Link}
                  to={location.pathname.replace('/admin', '')}
               >
                  <LocationOn />
               </IconButton>
            )}
            <Hidden xsDown>
               <Grid
                  name={'ClientSetup-root'}
                  item
                  container
                  alignItems={'stretch'}
                  justify={'center'}
                  fullHeight
                  fullWidth
                  direction={'row'}
               >
                  <MapSection
                     key={'facilities map component'}
                     classes={classes}
                     facilities={facilities}
                     draggableMarker
                     closePopup={!selectedMarker}
                     defaultCenter={defaultCenter}
                     onFilter={handleFilterChange}
                     onPopupOpen={handlePopupOpen}
                     defaultZoom={defaultZoom}
                     editValues={editValues}
                     onChange={handleChange}
                     markers={markers}
                     bounds={bounds}
                     geoJson={geoJson}
                     onGeoChange={handleGeoChange}
                     onLocationChange={handleLocationChange}
                     onGeoDelete={handleGeoDelete}
                  />
                  <Grid
                     name={'MapCard-content'}
                     fullHeight
                     className={classes.contentStyle}
                     container
                     item
                     direction={'column'}
                     xs={12}
                     sm={4}
                     isScrollable
                     isAddScrollPadding={false}
                  >
                     {SelectComponent !== 'span' ? (
                        <SelectComponent {...selectProps} />
                     ) : (
                        <SearchField
                           name='search'
                           value={search}
                           onChange={handleSearchChange}
                           labelKey={'clientSetup.search.label'}
                        />
                     )}
                  </Grid>
               </Grid>
            </Hidden>
            <Hidden smUp>
               <Grid
                  name={'ClientSetup-root'}
                  item
                  container
                  fullWidth
                  direction={'column'}
                  resizable={false}
                  wrap={'nowrap'}
                  style={{minHeight: '100%'}}
               >
                  <MapSection
                     key={'facilities map component'}
                     classes={classes}
                     facilities={facilities}
                     draggableMarker
                     closePopup={!selectedMarker}
                     defaultCenter={defaultCenter}
                     onFilter={handleFilterChange}
                     onPopupOpen={handlePopupOpen}
                     defaultZoom={defaultZoom}
                     editValues={editValues}
                     onChange={handleChange}
                     markers={markers}
                     bounds={bounds}
                     geoJson={geoJson}
                     onGeoChange={handleGeoChange}
                     onLocationChange={handleLocationChange}
                     onGeoDelete={handleGeoDelete}
                  />
                  {SelectComponent !== 'span' ? (
                     <Grid
                        name={'MapCard-content'}
                        className={classes.contentStyle}
                        container
                        item
                        direction={'column'}
                     >
                        <SelectComponent {...selectProps} />
                     </Grid>
                  ) : (
                     <SearchField
                        name='search'
                        style={{width: 'calc(100% - 24px'}}
                        value={search}
                        onChange={handleSearchChange}
                        labelKey={'clientSetup.search.label'}
                     />
                  )}
               </Grid>
            </Hidden>
         </TitleCard>
      </PageTitle>
   );
}

export default ClientSetup;
