import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, FormattedNumber} from 'react-intl';

/**
 * The Typography component that supports intl. The default value is the child element. The id is the lookup value for
 * the locale file.
 *
 * Example:
 * <Typography id='path.suppliers' variant='button'>Suppliers</Typography>
 *
 * Reviewed: 3/26/20
 */
const TypographyFHG = ({id, children, values, type = 'text', hasBold, intlProps,  ...otherProps}) => {

   if (hasBold || (values && values.hasBold)) {
      values.b = (...chunks) => <b>{chunks}</b>;
   }
   if (id) {
      if (type === 'number') {
         return (
            <Typography id={id} {...otherProps} >
               <FormattedNumber
                  id={id}
                  defaultMessage={children}
                  values={values}
                  {...intlProps}
               />
            </Typography>
         )
      }
      return (
         <Typography id={id} {...otherProps} >
            <FormattedMessage
               id={id}
               defaultMessage={children}
               values={values}
               {...intlProps}
            />
         </Typography>
      );
   } else {
      return (
         <Typography {...otherProps} >
            {children}
         </Typography>
      );
   }
};

// noinspection JSUnresolvedVariable
TypographyFHG.propTypes = {
   id: PropTypes.string,                  // Key to message in the localization file.
   values: PropTypes.object,              // Values to use to fill parameters in the localized message.
   ...Typography.propTypes,               // Supports all the properties from Typography.
};

TypographyFHG.defaultProps = {
   variant: 'inherit',
};
TypographyFHG.displayName = 'TypographyFHG';

export default TypographyFHG;
