import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import StylesProvider from '@material-ui/styles/StylesProvider';
import 'leaflet/dist/leaflet.css';
import gql from 'graphql-tag';
import get from 'lodash/get';
import React, {useState, useEffect, lazy, Suspense, useCallback} from 'react';
import {Switch, Route, useLocation, useHistory, Redirect} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import Alerts from '../components/Alerts';
import AuthContext, {ADMIN_GROUP} from '../components/AuthContext';
import DrawerContext from '../components/DrawerContext';
import {CLIENT_DASHBOARD_PATH_OPTION} from '../Constants';
import {WATER_USE_REPORT_PATH} from '../Constants';
import {ADMIN_CLIENT_PATH} from '../Constants';
import {SETUP_PATH} from '../Constants';
import {
   DEFAULT_PATH, APPBAR_HEIGHT, ADMIN_PATH,
   CLIENT_PATH, CLIENT_DASHBOARD_PATH, CLIENT_ASSET_PATH, KDHE_REPORT_PATH, MESSAGES_CLIENT_PATH,
   BULLETIN_PATH, POLL_FOR_ALERTS, FILES_CLIENT_PATH,
} from '../Constants';
import {CREATE_UPDATE_ACTION} from '../fhg/components/data/useMutationFHG';
import FullscreenProvider from '../fhg/components/FullscreenProvider';
import Loading from '../fhg/components/Loading';
import MailProvider from '../fhg/components/MailProvider';
import MobileProvider from '../fhg/components/MobileProvider';
import PrivilegeRoute from '../fhg/components/security/PrivilegeRoute';
import SessionProvider from '../fhg/components/security/SessionProvider';
import StatusProvider from '../fhg/components/StatusProvider';
import StatusSnackbar from '../fhg/components/StatusSnackbar';
import {messageStatus, registerServiceWorkerPromise} from '../index';
import NavigationDrawer from './NavigationDrawer';

//Admin Screens
const WebAppBarKLA = lazy(() => import('../components/WebAppBarKLA'));
// const Alerts = lazy(() => import('../components/Alerts'));

//Client Screens
// const NavigationDrawer = lazy(() => import('./NavigationDrawer'));
const MobileAppBar = lazy(() => import('../components/MobileAppBar'));

const AdminMain = lazy(() => import('./admin/AdminMain'));
const SetupMain = lazy(() => import('./SetupMain'));
const ClientMain = lazy(() => import('./client/ClientMain'));

const useStyles = makeStyles(theme => ({
   root: {
      overflow: 'hidden',
      height: '100vh',
      fontFamily: theme.typography.fontFamily,
   },
   contentStyle: {
      width: '100%',
      minWidth: 1, // Required for some reason when drawer not permanent.
      height: `calc(100% - ${APPBAR_HEIGHT}px)`,
      flex: '1 1',
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
   },
   progressStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
   },
   toolbar: theme.mixins.toolbar,
}));

const ADMIN_ALERT_QUERY = gql`
   query getAdminAlerts {
      alerts: alert_AllWhere(alertSearch: {forAdmins: true, dismissed: false, sent: true}) {
         id
         uuid
         isDeleted
         dismissed
         createdDateTime
         messageData {
            text
         }
      }
   }
`;

// Create or Update the alert with the given properties.
const ADMIN_ALERT_UPDATE = {
   mutation: gql`
      mutation AlertCreateUpdate(
         $uuid: String!
         $dismissed: Boolean
      ) {
         alert: alert_CreateUpdate(alert: {
            uuid: $uuid
            dismissed: $dismissed
         }) {
            id
            uuid
            isDeleted
            dismissed
            createdDateTime
            messageData {
               text
            }
         }
      }
   `,
   typeKey: 'alert.type',
   actionKey: CREATE_UPDATE_ACTION,
};

/**
 * Main component accessible only if the user has been authenticated. Contains the routing for the application.
 *
 * Reviewed: 2/24/20
 */
export default function Main({authState, authData}) {
   const classes = useStyles();
   const location = useLocation();
   const [isAdmin, setIsAdmin] = useState(false);
   const [isClient, setIsClient] = useState(false);
   const [isUnmanagedAdmin, setIsUnmanagedAdmin] = useState(false);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [user, setUser] = useState();
   const history = useHistory();
   const [messageState] = useRecoilState(messageStatus);
   const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

   useEffect(() => {
      registerServiceWorkerPromise.then((result) => {
         if (result?.update) {
            setIsNewVersionAvailable(result);
         }
      });
   }, []);

   useEffect(() => {
      if (authState === 'signedIn' && user) {
         const groups = get(authData, 'signInUserSession.idToken.payload["cognito:groups"]', []);
         // TODO remove after unmanaged groups are added remove unmanaagedclient username condition.
         const hasAdminGroup = groups.indexOf(ADMIN_GROUP) >= 0;
         const isAdmin = hasAdminGroup && !user.clientId && user.isAdmin;
         const isUnmanagedAdmin = hasAdminGroup && !!user.clientId && user.isAdmin;
         setIsAdmin(isAdmin);
         setIsUnmanagedAdmin(isUnmanagedAdmin);
         setIsClient(!isAdmin && !isUnmanagedAdmin);
      }
   }, [authState, authData, user]);

   /**
    * When the user closes the sidebar, mark the sidebar closed.
    */
   const onDrawerClose = () => {
      setIsDrawerOpen(false);
   };

   /**
    * When the user opens the sidebar, mark the sidebar open.
    */
   const onDrawerOpen = () => {
      setIsDrawerOpen(true);
   };

   const handleUser = useCallback((user) => {
      setUser(user);
      setIsAdmin(user && !user.clientId);
   }, [history, location.pathname]);

   if (authState === 'signedIn') {
      return (
         <Suspense fallback={<Loading isLoading/>}>
            <AuthContext.Provider value={{isAdmin, isUnmanagedAdmin, isClient}}>
               <SessionProvider authData={authData} onUser={handleUser}>
                  {isAdmin && <Alerts alertQuery={ADMIN_ALERT_QUERY} pollInterval={POLL_FOR_ALERTS}
                                      alertUpdateMutation={ADMIN_ALERT_UPDATE}/>}
                  <MailProvider>
                     <FullscreenProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                           <MobileProvider>
                              <StatusProvider>
                                 {isNewVersionAvailable && <StatusSnackbar messageKey={'offline.newVersion.message'}/>}
                                 <StatusSnackbar {...messageState}/>
                                 <StylesProvider>
                                    <CssBaseline/>
                                    <DrawerContext.Provider value={{isDrawerOpen, onDrawerClose, onDrawerOpen}}>
                                       <Grid container direction={'row'} className={classes.root}>
                                          <CssBaseline/>
                                          {(location.pathname.indexOf(CLIENT_PATH) === 0) ? (
                                             <MobileAppBar/>
                                          ) : (
                                             <WebAppBarKLA/>
                                          )}
                                          <Route exact path={[
                                             CLIENT_DASHBOARD_PATH, CLIENT_ASSET_PATH, KDHE_REPORT_PATH,
                                             MESSAGES_CLIENT_PATH, FILES_CLIENT_PATH,
                                             BULLETIN_PATH, WATER_USE_REPORT_PATH,
                                          ]}>
                                             <NavigationDrawer/>
                                          </Route>
                                          {(isAdmin || isUnmanagedAdmin || isClient) && (
                                             <main className={classes.contentStyle}>
                                                <div className={classes.toolbar}/>
                                                <Switch>
                                                   <Route path={CLIENT_DASHBOARD_PATH_OPTION}>
                                                      <ClientMain user={user}/>
                                                   </Route>
                                                   <PrivilegeRoute hasPrivilege={isUnmanagedAdmin || isAdmin}
                                                                   path={SETUP_PATH}>
                                                      <SetupMain/>
                                                   </PrivilegeRoute>
                                                   <PrivilegeRoute hasPrivilege={isAdmin} path={ADMIN_PATH}>
                                                      <AdminMain/>
                                                   </PrivilegeRoute>
                                                   <Route
                                                      path={[DEFAULT_PATH, CLIENT_PATH]}
                                                      children={() => {
                                                         if (isAdmin || user) {
                                                            return (
                                                               <Redirect to={
                                                                  isAdmin ? ADMIN_PATH :
                                                                     isUnmanagedAdmin ?
                                                                        ADMIN_CLIENT_PATH.replace(':clientId',
                                                                           user.clientId) :
                                                                        isClient ? CLIENT_DASHBOARD_PATH.replace(
                                                                           ':clientId',
                                                                           user.clientId) : undefined}
                                                               />
                                                            )
                                                         }
                                                      }}
                                                   />
                                                </Switch>
                                             </main>
                                          )}
                                       </Grid>
                                    </DrawerContext.Provider>
                                 </StylesProvider>
                              </StatusProvider>
                           </MobileProvider>
                        </MuiPickersUtilsProvider>
                     </FullscreenProvider>
                  </MailProvider>
               </SessionProvider>
            </AuthContext.Provider>
         </Suspense>
      );
   } else {
      return null;
   }
}

