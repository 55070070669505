import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import {useIsConnected} from '@wora/detect-network';
import gql from 'graphql-tag';
import find from 'lodash/find';
import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import get from 'lodash/get';
import moment from 'moment';
import React, {Fragment, useEffect, useState, useRef} from 'react';
import {v4 as uuid} from 'uuid';
import DatePickerFHG from '../../../components/DatePickerFHG';
import KeyboardDatePickerFHG from '../../../components/KeyboardDatePickerFHG';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import {MONTH_FORMAT, DATE_DB_FORMAT} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useLazyQueryOfflineFHG from '../../../fhg/components/data/useLazyQueryOfflineFHG';
import useMutationFHG, {CREATE_UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useMutationFHG2 from '../../../fhg/components/data/useMutationFHG2';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import ConfirmDialog from '../../../fhg/components/dialog/ConfirmDialog';
import Form from '../../../fhg/components/edit/Form';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import Typography from '../../../fhg/components/Typography';
import TypographyFHG from '../../../fhg/components/Typography';
import {cacheUpdate, cacheDelete} from '../../../fhg/utils/DataUtil';
import useMessage from '../../../fhg/utils/useMessage';
import {LIVESTOCK_FRAGMENT} from '../../admin/client/FragmentsGL';
import {FACILITY_BY_UUID_FOR_CLIENT_QUERY} from '../FacilityClient';

const useStyles = makeStyles({
   paperStyle: {
      height: 'fit-content',
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
      position: 'relative',
   },
   buttonStyle: {
      marginBottom: '0.35em',
   }
}, {name: 'HeadcountRecordStyles'});

// Default properties needed for the headCount record.
const HEAD_COUNT_FRAGMENT = gql`
   fragment headCountRecordInfo on Headcount {
      id
      date
      average
      facilityId
      isDeleted
      livestockTypeId
      maximum
      uuid
   }
`;

const defaultHeadCountRecord = {
   id: 0,
   average: 0,
   maximum: 0,
   isDeleted: false,
}

// Query for all the headCount records for the facility.
export const HEAD_COUNT_BY_RANGE_QUERY = gql`
   query getHeadCountByRange($facilityId: [Int], $beginDate: String, $endDate: String)
   {
      headCount:headcount_AllWhere(headcountSearch: {facilityId: $facilityId, beginDate: $beginDate, endDate: $endDate}) {
         ...headCountRecordInfo
      }
   }
   ${HEAD_COUNT_FRAGMENT}
`;

// Create or Update the headCount with the given properties.
const HEAD_COUNT_CREATE_UPDATE = {
   mutation: gql`
      mutation HeadCountCreateUpdate(
         $average: Float
         $date: String
         $facilityId: Int
         $livestockTypeId: Int
         $maximum: Int
         $uuid: String!
      ) {
         headCount: headcount_CreateUpdate(headcount: {
            average: $average
            date: $date
            facilityId: $facilityId
            livestockTypeId: $livestockTypeId
            maximum: $maximum
            uuid: $uuid
         }) {
            ...headCountRecordInfo
         }
      }
      ${HEAD_COUNT_FRAGMENT}
   `,
   typeKey: 'headCount.type',
   actionKey: CREATE_UPDATE_ACTION,
};

// Delete the waste export on the server.
const HEAD_COUNT_DELETE = {
   mutation: gql`
      mutation HeadCountDelete($id: Int!) {
         headcount_Delete(headcountId: $id)
      }
   `,
   typeKey: 'headCount.type',
   actionKey: DELETE_ACTION,
};

// Query for all the livestock for the facility.
export const LIVESTOCK_HEADCOUNT_QUERY = gql`
   query getLivestockHeadcount($facilityId: [Int])
   {
      livestock:livestock_AllWhere(includeDeleted: true, livestockSearch: {facilityId: $facilityId}) {
         ...livestockInfoForEdit
      }
   }
   ${LIVESTOCK_FRAGMENT}
`;

// Query for the most recent headcount record.
export const HEADCOUNT_RECENT_QUERY = gql`
   query getHeadcountRecentDate($facilityId: Int!)
   {
      headcountReadingDate:headcount_MostRecentDate(facilityId: $facilityId)
   }
`;

HeadcountRecord.propTypes = {};

export default function HeadcountRecord({facility, livestockTypes = [], onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const headCountType = useMessage('headCount.type');
   const isConnected = useIsConnected();

   const {data} = useQueryOfflineFHG(LIVESTOCK_HEADCOUNT_QUERY, {variables: {facilityId: facility && facility.id}, skip: !facility, fetchPolicy: 'cache-and-network'}, 'livestock.type');
   const [loadHeadCountRange, {data: headCountRangeData}] = useLazyQueryOfflineFHG(HEAD_COUNT_BY_RANGE_QUERY, undefined,
      'headCount.type');

   const [headCountCreateUpdate] = useMutationFHG(HEAD_COUNT_CREATE_UPDATE);
   const [headCountDelete] = useMutationFHG2(HEAD_COUNT_DELETE);

   const [editValues, handleChange, {isChanged, setIsChanged, setEditValues, defaultValues, setDefaultValues}] = useEditData(
      [], ['id', 'uuid', 'facilityId', 'livestockTypeId'], true);

   const [daySelected, setDaySelected] = useState(moment());
   const [monthRange, setMonthRange] = useState(
      {beginDate: moment().startOf('month'), endDate: moment().endOf('month')});
   const [isPickerOpen, setIsPickerOpen] = useState(false);
   const [overMaximum, setOverMaximum] = useState();
   const [isSaving, setIsSaving] = useState(false);

   const livestock = get(data, 'livestock') || [];
   const ref = useRef({hasAutosetDate : false}).current
   const {data:recentHeadcountData} = useQueryFHG(HEADCOUNT_RECENT_QUERY, {variables: {facilityId: facility?.id}, skip: ref.hasAutosetDate || !facility, fetchPolicy: 'network-only'}, 'headCount.type');

   /**
    * Update the selected date to the next month that needs a headcount record.
    */
   useEffect(() => {
      const currentMonthBegin = moment().startOf('month');

      if ((isConnected !== false) || recentHeadcountData) {
         if (recentHeadcountData) {
            ref.hasAutosetDate = true;
            // Move to the next month after the last recorded headcount reading.
            const nextReadingDate = moment(recentHeadcountData.headcountReadingDate).startOf('month').add(1, 'month');

            // If the next reading is before the current month, select the next date that needs a record.
            if (nextReadingDate.isBefore(currentMonthBegin)) {
               handleDateChange(undefined, nextReadingDate);
            } else {
               handleDateChange(undefined, currentMonthBegin);
            }
         }
      } else if (!ref.hasAutosetDate) {
         ref.hasAutosetDate = true;
         handleDateChange(undefined, currentMonthBegin);
      }
   }, [recentHeadcountData, isConnected]);

   useEffect(() => {
      if (headCountRangeData) {
         const headCount = get(headCountRangeData, 'headCount') || [];
         const activeLivestock = filter(livestock, {isDeleted: false});

         // Find livestock setup in admin that don't have a headcount recorded yet.
         const missingLivestock = differenceBy(activeLivestock, headCount, 'livestockTypeId');

         //Initialize to the headcount already recorded.
         const allHeadCount = [...headCount];

         //Check if all types of livestock from admin are already in the headcount recorded.
         if (missingLivestock.length > 0) {
            // Add the missing livestock to the list of all headcount needed to be recorded.
            for (const missingLivestockItem of missingLivestock) {
               allHeadCount.push({
                  uuid: uuid(),
                  facilityId: missingLivestockItem.facilityId,
                  livestockTypeId: missingLivestockItem.livestockTypeId
               });
            }
         }
         setEditValues([]);

         if (allHeadCount.length > 0) {
            setDefaultValues(allHeadCount);
         } else {
            setDefaultValues([]);
         }
      } else {
         setDefaultValues([]);
      }
   }, [facility, headCountRangeData, setDefaultValues, setEditValues]);

   const getRefetchQueries = () => {
      return [
         {query: FACILITY_BY_UUID_FOR_CLIENT_QUERY, variables: {uuid: facility && facility.uuid}, path: 'facility.livestock'},
      ]
   };

   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);
            const date = daySelected.format(DATE_DB_FORMAT);

            for (const [index, editValue] of editValues.entries()) {
               if (editValue) {
                  try {
                     const cacheVariables = {
                        facilityId: facility && facility.id,
                        beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
                        endDate: monthRange.endDate.format(DATE_DB_FORMAT),
                     };
                     const variables = {...editValue, date};

                     await headCountCreateUpdate({
                        variables,
                        optimisticResponse: {
                           __typename: "Mutation",
                           refetchQueries: getRefetchQueries,
                           headCount: {
                              __typename: "Headcount",
                              ...defaultHeadCountRecord,
                              ...defaultValues[index],
                              ...variables,
                           }
                        },
                        refetchQueries: getRefetchQueries,
                        update: cacheUpdate({
                           query: HEAD_COUNT_BY_RANGE_QUERY,
                           variables: cacheVariables,
                        }, editValue?.uuid, 'headCount')
                     });
                  }
                  catch(e) {
                     console.log('Error saving headcount:', e);
                  }
               }
            }

            setIsChanged(false);
            onClose && onClose();
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         onClose && onClose();
      }
   };

   const getLivestockType = (livestock) => {
      return getLivestockTypeById(livestock.livestockTypeId);
   };

   const getLivestock = (headcountRecord, path) => {
      return get(find(livestock, {livestockTypeId: headcountRecord.livestockTypeId}), path);
   };

   const getLivestockTypeById = (livestockTypeId) => {
      return get(find(livestockTypes, {id: livestockTypeId}), 'name') || 'N/A';
   };

   const handleDateChange = (event, date) => {
      if (date && date.isValid && date.isValid()) {
         setDaySelected(date);
         const beginDate = moment(date).startOf('month');
         const endDate = moment(date).endOf('month');
         setMonthRange({beginDate, endDate});
         return loadHeadCountRange({
            variables: {
               facilityId: facility && facility.id,
               beginDate: beginDate.format(DATE_DB_FORMAT),
               endDate: endDate.format(DATE_DB_FORMAT),
            }
         });
      }
   };

   const handleDelete = async () => {
      const variables = {
         facilityId: facility && facility.id,
         beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
         endDate: monthRange.endDate.format(DATE_DB_FORMAT)
      }
      for (const defaultValue of defaultValues) {
         await headCountDelete({
            variables: {id: defaultValues[0].id},
            optimisticResponse: {
               headcount_Delete: 1
            },
            update: cacheDelete(
               {query: HEAD_COUNT_BY_RANGE_QUERY, variables}, defaultValues[0].uuid, 'headCount')
         });
      }
   };

   const handleCheckMaximums = () => {
      const overMaximum = [];
      for (const [index, editValue] of editValues.entries()) {
         if (editValue) {
            const foundLivestock = find(livestock, {livestockTypeId: editValue.livestockTypeId});
            if (foundLivestock && (editValue.maximum > foundLivestock?.max)) {
               overMaximum[index] = {type: getLivestockTypeById(editValue.livestockTypeId), max:foundLivestock?.max, editMax: editValue.maximum};
            }
         }
      }
      if (overMaximum.length <= 0) {
         handleSubmit();
      } else {
         setOverMaximum(overMaximum);
      }
   };
   const dayKey = monthRange.beginDate.format(DATE_DB_FORMAT);

   return (
      <Form className={classes.formStyle} onSubmit={handleCheckMaximums}>
         <TitleCardInner
            titleId={'clientFacility.headcountRecord.button'}
            variant={'subtitle1'}
            onClose={isPickerOpen ? undefined : onClose}
            classes={{paperStyle: classes.paperStyle}}
            headerAction={(
               <ConfirmButton
                  onConfirm={handleDelete}
                  style={{
                     display: headCountRangeData?.headCount?.length > 0 ? undefined : 'none'
                  }}
                  titleKey={'delete.confirm.title'}
                  messageKey={'delete.confirm.message'}
                  titleValues={{type: headCountType}}
                  values={{type: headCountType}}
                  color='secondary'
                  buttonLabelKey={'delete.button'}
                  disableRipple
                  // disabled={!defaultValues || !defaultValues.id}
                  className={classes.buttonStyle}
                  buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
               />
            )}
         >
            {overMaximum && (
               <ConfirmDialog
                  titleKey={'headcountRecord.confirmSave.title'}
                  messageKey={'headcountRecord.confirmSave.message'}
                  onConfirm={handleSubmit}
                  onClose={() => setOverMaximum(undefined)}
                  confirmKey={'confirmSave.label'}
                  confirmColor={'secondary'}
               >
                  {overMaximum && overMaximum.map(item => (
                     <TypographyFHG id={'headcountRecord.type.label'} display='block' values={item}/>
                  ))}
               </ConfirmDialog>
            )}
            <Grid container>
               <Grid item container direction={'row'} spacing={1} alignItems={'center'}
                     style={{padding: 8, height: 'fit-content'}}>
                  <Grid item xs={12}>
                     <Hidden mdUp>
                        <DatePickerFHG
                           name={'date'}
                           views={['month']}
                           format={MONTH_FORMAT}
                           labelKey={'date.label'}
                           value={daySelected}
                           onChange={handleDateChange}
                           disabled={isSaving || !ref.hasAutosetDate}
                           onOpen={() => setIsPickerOpen(true)}
                           onClose={() => setIsPickerOpen(false)}
                        />
                     </Hidden>
                     <Hidden smDown>
                        <KeyboardDatePickerFHG
                           name={'date'}
                           variant={'inline'}
                           fullWidth
                           required
                           views={['month']}
                           format={MONTH_FORMAT}
                           labelKey={'date.label'}
                           value={daySelected}
                           onChange={handleDateChange}
                           disabled={isSaving || !ref.hasAutosetDate}
                           onOpen={() => setIsPickerOpen(true)}
                           onClose={() => setIsPickerOpen(false)}
                        />
                     </Hidden>
                  </Grid>
                  {defaultValues.map((livestock, index) => (
                     <Fragment key={'headcount' + livestock?.uuid || index}>
                        <Grid key={'livestock' + livestock?.uuid || index} item xs={6} sm={12} lg={6}>
                           <TextFieldFHG
                              key={'maximum' + livestock?.uuid || index + dayKey}
                              isFormattedNumber
                              inputProps={{
                                 'data-index': index,
                                 'data-type': 'number',
                                 pattern: '^[0-9,]+$',
                                 title: 'Enter a valid positive number.',
                              }}
                              name={'maximum'}
                              label={<TypographyFHG id={'clientFacility.headcountMaximum.label'}
                                                    values={{name: getLivestockType(livestock)}}/>}
                              autoFocus={index === 0}
                              defaultValue={livestock.maximum}
                              value={get(editValues, `[${index}].maximum`)}
                              onChange={handleChange}
                              fullWidth
                              disabled={isSaving}
                              required={!getLivestock(livestock, 'isDeleted')}
                           />
                        </Grid>
                        <Grid key={'livestockAvg' + livestock?.uuid || index} item xs={6} sm={12} lg={6}>
                           <TextFieldFHG
                              key={'average' + livestock?.uuid || index + dayKey}
                              isFormattedNumber
                              inputProps={{
                                 'data-index': index,
                                 'data-type': 'number',
                                 pattern: '^[0-9,]+$',
                                 title: 'Enter a valid positive number.',
                              }}
                              name={'average'}
                              label={<TypographyFHG id={'clientFacility.headcountAverage.label'}
                                                    values={{name: getLivestockType(livestock)}}/>}
                              defaultValue={livestock.average}
                              value={get(editValues, `[${index}].average`)}
                              onChange={handleChange}
                              fullWidth
                              disabled={isSaving}
                           />
                        </Grid>
                     </Fragment>
                  ))}
                  <Grid item
                        style={{
                           marginTop: theme.spacing(1),
                           position: 'sticky',
                           bottom: 0,
                           backgroundColor: theme.palette.background.paper
                        }}
                        fullWidth>
                     <Button variant='contained' color='primary' type={'submit'} size={'small'} disabled={isSaving}>
                        <Typography variant={'inherit'} id={'save.label'}/>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </TitleCardInner>
      </Form>
   );
}
