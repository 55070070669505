import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import TextField from '@material-ui/core/TextField';
import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import NumberFormatCustom from '../fhg/components/NumberFormat';
import {formatMessage} from '../fhg/utils/Utils';
import CustomInputTextField from './CustomInputTextField';
import get from 'lodash/get';

const useStyles = makeStyles(theme => ({
   textFieldStyle: {
      marginTop: 24,
      marginBottom: 4,
      '& label': {
         zIndex: 0,
         marginTop: -20,
         fontWeight: 400,
         fontSize: 14,
         color: `${theme.palette.text.primary} !important`,
         transform: 'none !important',

         whiteSpace: 'nowrap',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         width: '100%',
      },
      '& label span': {
      },
      '& legend': {
         display: 'none !important',
      },
      '& fieldset': {
         top: 0,
      },
      '& > div': {
         marginTop: '0 !important',
      },
      '& label div': {
         display: 'inline-block',
      }
   }
}));

/**
 * The TextField with preset formats.
 */
export default function TextFieldFHG ({ className, name, labelKey, placeholderKey, helpKey, helperText, defaultValue, value, onChange, margin, InputLabelProps, InputProps, label, fullWidth = true, isFormattedNumber, classes:classesProp, ...textFieldProps }) {
   const classes = {...useStyles(), ...classesProp};
   const theme = useTheme();
   const intl = useIntl();
   const currentLabel = label || (labelKey && formatMessage(intl, labelKey)) || undefined;
   const currentPlaceholder = (placeholderKey && formatMessage(intl, placeholderKey)) || undefined;
   const helpText = helperText || (helpKey && formatMessage(intl, helpKey)) || undefined;
   const [isSet, setIsSet] = useState(value !== undefined && value !== null && value !== '');

   useEffect(() => {
       setIsSet(value !== undefined && value !== null && value !== '');
   }, [value]);

   const handleChange = (event) => {
      setIsSet(event.target.type === 'react-number-format' ? event.target.valueAsNumber !== defaultValue : true);
      onChange && onChange(event);
   };

   const useInputProps = {
      style:{marginTop: 32, backgroundColor: theme.palette.background.paper},
      ...InputProps,
   };
   if (isFormattedNumber) {
      if (get(InputProps, 'inputComponent') || get(InputProps, 'inputProps.component')) {
         console.log('isFormattedNumber cannot have a different input component.', InputProps);
      }
      useInputProps.inputComponent = CustomInputTextField;
      useInputProps.inputProps = {...get(InputProps, 'inputProps', {}), ...(textFieldProps.inputProps || {}), component: NumberFormatCustom};
   }
   return (
      <TextField
         {...textFieldProps}
         key={name}
         name={name}
         defaultValue={isFormattedNumber ? defaultValue : undefined}
         value={isSet || value !== undefined ? value : defaultValue !== undefined ? defaultValue : ''}
         onChange={handleChange}
         label={currentLabel}
         placeholder={currentPlaceholder}
         helperText={helpText}
         InputLabelProps={{
            ...InputLabelProps,
            shrink: true,
         }}
         InputProps={useInputProps}
         className={`${classes.textFieldStyle} ${className}`}
         variant='outlined'
         size="small"
         margin={margin || 'normal'}
         fullWidth={fullWidth}
      />
   );
}
