import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import defer from 'lodash/defer';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import TitleCardInner from '../../../components/TitleCardInner';
import {DATE_DB_FORMAT} from '../../../Constants';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import Grid from '../../../fhg/components/Grid';
import WellAnnualReportDetail from './WellAnnualReportDetail';

const useStyles = makeStyles(
   (theme) => ({
      paperStyle: {
         height: 'fit-content',
      },
      buttonStyle: {
         marginBottom: '0.35em',
      },
      buttonColorStyle: {
         color: theme.palette.primary.light,
      },
      buttonFrame: {
         marginTop: theme.spacing(2),
      },
      subtitleStyle: {
         marginBottom: theme.spacing(1),
      },
   }),
   {name: 'WellRecordStyles'}
);

const METER_FOR_WELL_FRAGMENT = gql`
   fragment meterInfoForWell on Meter {
      id
      uuid
      acreFeetMultiplier
      acreInchesMultiplier
      allotment {
         allotment
         fileNumbers
      }

      beginningMeter
      date
      gallonsUnitMultiplier
      isDeleted
      digits
      name
      unitTypeId
      useTypeId
      useType {
         id
         name
      }
      wellId
   }
`;

// Get the meters for the well.
export const METERS_FOR_WELL_QUERY = gql`
   query getMetersForWell($wellId: [Int], $beginActiveDate: String, $endActiveDate: String) {
      meters: meter_AllWhere(
         meterSearch: {wellId: $wellId, beginActiveDate: $beginActiveDate, endActiveDate: $endActiveDate}
      ) {
         ...meterInfoForWell
      }
   }
   ${METER_FOR_WELL_FRAGMENT}
`;

WellAnnualReport.propTypes = {
   parentItem: PropTypes.object.isRequired, // The default well item.
   onClose: PropTypes.func, // Callback when the panel is closed.
};

/**
 * Component to record meter readings.
 *
 * Reviewed: 7/9/2020
 */
export default function WellAnnualReport({parentItem = {}, meterGauges, onClose}) {
   const classes = useStyles();
   const ref = useRef();

   const variables = {
      wellId: parentItem && parentItem.id,
      beginActiveDate: moment().startOf('year').format(DATE_DB_FORMAT),
      endActiveDate: moment().endOf('year').format(DATE_DB_FORMAT),
   };
   const {data} = useQueryOfflineFHG(METERS_FOR_WELL_QUERY, {variables, skip: !variables.wellId}, 'well.type');

   /**
    * When the component has been displayed, scroll it into view.
    */
   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }
      // eslint-disable-next-line
   }, [ref, parentItem]);

   return (
      <TitleCardInner
         titleId={'wellAnnualReport.title'}
         variant={'subtitle1'}
         onClose={onClose}
         classes={{paperStyle: classes.paperStyle}}
      >
         <Grid ref={ref} container direction={'column'} fullHeight>
            <WellAnnualReportDetail meterGauges={meterGauges} metersData={data?.meters} />
         </Grid>
      </TitleCardInner>
   );
}
