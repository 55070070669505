import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useIsConnected} from '@wora/detect-network';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from './Grid';
import TypographyFHG from './Typography';

const useStyles = makeStyles((theme) => ({
   backdrop: {
      position: 'relative',
      zIndex: theme.zIndex.drawer + 1,
      // color: '#fff',
      // backgroundColor: 'rgba(0,0,0, .1)',
      opacity: '0.5 !important',
      width: '100%',
   },
   backdropDialog: {
      backgroundColor: '#fff',
      // position: 'relative',
      // zIndex: theme.zIndex.drawer + 1,
      // color: '#fff',
      // backgroundColor: 'rgba(0,0,0, .1)',
      opacity: '0.4 !important',
      // width: '100%',
   },
   paperStyle: {
      height: 60,
      minWidth: 300,
   },
   paperStyle2: {
      left: '50%',
      transform: 'translate(-50%, 0)',
      maxWidth: 300,
      width: `calc(100% - ${theme.spacing(2)}px)`,
      height: 60,
      zIndex: 2000,
      position: 'absolute'
   },
   textStyle: {
      zIndex: theme.zIndex.drawer + 2,
      lineHeight: 1.25,
      [theme.breakpoints.only('sm')]: {
         fontSize: 12,
      }
   },
   frameStyle: {
      padding: theme.spacing(2),
   }
}));
Online.propTypes = {
   showOfflineOnly: PropTypes.bool, // Indicates if children should be shown only when offline.
};

export default function Online({
   showOfflineOnly = false,
   messageKey = 'offline.message',
   disableBackground = false,
   marginTop = '20%',
   children
}) {
   const classes = useStyles();
   const isConnected = useIsConnected();

   if (disableBackground) {
      return (
         <Dialog
            open={showOfflineOnly !== isConnected}
            PaperProps={{className: classes.paperStyle}}
            disableBackdropClick
            BackdropProps={{className: classes.backdropDialog}}
         >
            <Grid container alignItems={'center'} justify={'center'} fullHeight>
               <TypographyFHG variant='subtitle1' color='textSecondary' id={messageKey}
                              className={classes.textStyle}/>
            </Grid>
         </Dialog>
      );
   }
   if (showOfflineOnly !== isConnected) {
      return children
   } else {
      return <>
         <Paper style={{marginTop}} className={classes.paperStyle2}>
            <Grid container alignItems={'center'} justify={'center'} fullHeight className={classes.frameStyle} >
               <TypographyFHG variant='subtitle1' color='textSecondary' id={messageKey}
                              className={classes.textStyle}/>
            </Grid>
         </Paper>
         <Backdrop className={classes.backdrop} open={true} invisible={true}>
            {children}
         </Backdrop>
      </>
   }
}
