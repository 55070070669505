import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from './Typography';

const useStyles = makeStyles(theme => ({
   spinnerMargin: {
      marginLeft: theme.spacing(0.5),
      color: 'white',
   },
}), {name: 'ProgressButtonStyles'});

/**
 * Button Component to show progress.
 *
 * Reviewed:
 */
ProgressButton.propTypes = {
   isProgress: PropTypes.bool.isRequired, //Indicates if the progress should be showing.
   labelKey: PropTypes.string,            // Localization key for the button label.
   typographyProps: PropTypes.any,        // The properties for the typography component.
   ...Button.propTypes,
};

export default function ProgressButton({isProgress = false, labelKey, children, typographyProps, ...buttonProperties}) {
   const classes = useStyles();

   return (
      <Button {...buttonProperties}>
         {labelKey && (
            <Typography variant={'inherit'} id={labelKey} {...typographyProps}/>
         )}
         {children}
         {isProgress && <CircularProgress className={classes.spinnerMargin} size={15} thickness={2.5}/>}
      </Button>
   );
}

