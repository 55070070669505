import React from "react";

// noinspection JSUnusedLocalSymbols
/**
 * Context to support the ErrorSnackbar display.
 *
 * Reviewed: 3/26/20
 */
export default React.createContext({
   errorKey: undefined,
   errorMessage: undefined,
   error: undefined,
   values: undefined,
   enableRefresh: true,
   progress: false,
   setError: (errorKey, errorMessage, error, values, enableRefresh) => {},
   setErrorGeneral: (generalErrorKey, typeKey, error, values, enableRefresh) => {},
   setProgress: (isProgress) => {}
});
