import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import delay from 'lodash/delay';
import defer from 'lodash/defer';
import pick from 'lodash/pick';
import get from 'lodash/get';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import useIntl from 'react-intl/lib/src/components/useIntl';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {v4 as uuidFunction} from 'uuid';
import AutocompleteFHG from '../../components/AutocompleteFHG';
import FormCard from '../../components/FormCard';
import TextFieldFHG from '../../components/TextField';
import TitleCardWeb from '../../components/TitleCardWeb';
import {DEFAULT_PATH, KANSAS_STATE_ID, USER_PATH} from '../../Constants';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import useLazyQueryFHG from '../../fhg/components/data/useLazyQueryFHG';
import useMutationFHG  from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Prompt from '../../fhg/components/edit/Prompt';
import useEditData from '../../fhg/components/edit/useEditData';
import Grid from '../../fhg/components/Grid';
import {PhoneNumberField} from '../../fhg/components/input/TextMaskCustom';
import PasswordTextField from '../../fhg/components/security/PasswordTextField';
import TypographyFHG from '../../fhg/components/Typography';
import useMessage from '../../fhg/utils/useMessage';
import {formatMessage, renderOptionsKey} from '../../fhg/utils/Utils';
import {USER_CREATE, USER_UPDATE, USER_DELETE, USER_BY_UUID_QUERY, USER_ALL_QUERY} from '../../data/QueriesGL';

const useStyles = makeStyles(theme => ({
   userFrameStyle: {
      // When the facilities wrap down, unset the height so the main frame scrolls.
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
         paddingRight: theme.spacing(0),
         height: 'unset',
      },
      maxWidth: 560,
   },
   zipStyle: {
      /* Chrome, Safari, Edge, Opera */
      '& input::-webkit-outer-spin-button': {
         '-webkit-appearance': 'none',
         margin: 0,
      },
      '& input::-webkit-inner-spin-button': {
         '-webkit-appearance': 'none',
         margin: 0,
      },
      /* Firefox */
      '& input[type=number]': {
         '-moz-appearance': 'textfield',
      }
   },
   selectStyle: {
      '&::focus': {
         backgroundColor: theme.palette.background.paper,
      }
   },
   progressStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 2000,
   },
   linkStyle: {
      marginLeft: 'auto',
      '&:hover': {
         backgroundColor: 'transparent',
      }
   }
}));

// The cities and states for dropdowns.
export const STATES_QUERY = gql`
   query getStates {
      cities:city_All {
         id
         name
         isDeleted
          uuid
      }
      states:state_All {
         id
         name
         isDeleted
          uuid
      }
   }
`;

/**
 * The component to edit and create new admin users.
 *
 * Reviewed: 7/29/2020
 */
export default function AdminEditCard() {
   const history = useHistory();
   const location = useLocation();
   const {uuid} = useParams();
   const usernameMessage = useMessage('user.usernameEdit.message');
   const newUserMessage = useMessage('user.newUser.message');
   const NEW_USER = useRef({id: -1, managerName: newUserMessage}).current;

   const intl = useIntl();
   const classes = useStyles();

   const {data: usersData, loading} = useQueryFHG(USER_ALL_QUERY, undefined, 'users.type');
   const [loadUser, {data: userData}] = useLazyQueryFHG(USER_BY_UUID_QUERY, undefined, 'user.type');
   const {data: stateData} = useQueryFHG(STATES_QUERY, undefined, 'states.type');
   const [userCreate] = useMutationFHG(USER_CREATE);
   const [userUpdate] = useMutationFHG(USER_UPDATE);
   const [userDelete] = useMutationFHG(USER_DELETE);

   const [isSaving, setIsSaving] = useState(false);

   const [editValues, handleChange, {isChanged, setIsChanged, setEditValues, defaultValues, setDefaultValues, resetValues}] = useEditData();
   const [user, setUser] = useState(NEW_USER);

   const [isNew, setIsNew] = useState(!uuid || location.state.isNew);

   useEffect(() => {
      if (!location.state.isNew && uuid) {
         setIsNew(false);
         if (uuid !== user.uuid) {
            loadUser({variables: {uuid}});
            setIsChanged(false);
         }
      } else {
         setUser(NEW_USER);
         resetValues();
         setIsNew(true);

         // noinspection JSValidateTypes
         delay(() => {
            const elements = document.getElementsByName(`managerName`);
            if (elements && elements.length > 0) {
               elements[elements.length - 1].scrollIntoView(true);
               elements[elements.length - 1].focus();
               elements[elements.length - 1].select();
            }
         })
      }
   }, [uuid, user.uuid, loadUser, resetValues, setIsChanged, NEW_USER, location.state.isNew]);

   /**
    * Set the default values from the existing user.
    */
   useEffect(() => {
      if (userData) {
         const user = get(userData, 'user') || NEW_USER;
         setUser(user);
         setEditValues(pick(user, ['id', 'email', 'uuid']));
         setDefaultValues(user);

         setIsNew(false);
         setIsChanged(false);
      }
   }, [userData, setDefaultValues, setEditValues, setIsChanged, NEW_USER]);

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param name
    * @param reason The reason of the value change if Autocomplete
    */
   const handleChangeCallback = useCallback((event, value, reason, newValue, name) => {
      handleChange(event, value, reason, newValue, name);

      if (name === 'password') {
         const target = document.getElementById('confirm_password');
         if (target) {
            target.setCustomValidity(
               this.state.confirm !== this.state.password ?
                  formatMessage(intl, 'user.confirmMismatch.message', 'Confirm does not match the password.') : '');
         }
      }
   }, [intl, handleChange]);

   /**
    * Callback when the user selects a new user from the dropdown.
    * @param event The select event.
    * @param value The user selected.
    */
   const handleUserChange = (event, value) => {
      if (value.managerName === NEW_USER.managerName) {
         handleCreateNewUser();
      } else {
         history.replace(USER_PATH.replace(':uuid?', value && value.uuid), {...location.state, isNew: false});

         //Set the original user in case the Prompt is canceled, so the dropdown reflects the correct user.
         if (isChanged) {
            setUser({...user});
         }
      }
   };

   /**
    * Handle onSubmit for the form. Mutates the database object with the changes.
    */
   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);

            if (defaultValues.id) {
               const variables = {id: defaultValues.id, ...editValues, isAdmin: true};
               if (editValues.cityId || editValues.city) {
                  variables.stateId = defaultValues.stateId;
               }
               await userUpdate({variables});
            } else {
               await userCreate({variables: editValues}, false, [{query: USER_ALL_QUERY}]);
            }

            resetValues();
            setIsSaving(false);
            history.replace(get(location, 'state.backUrl') || DEFAULT_PATH);
         } catch (e) {
            setIsSaving(false);
         }
      } else {
         history.replace(get(location, 'state.backUrl') || DEFAULT_PATH);
      }
   };

   /**
    * Cancel the dialog and go back.
    */
   const handleCancel = () => {
      setIsChanged(false);

      // Delay so that the isChanged is false so prompt doesn't display.
      // noinspection JSValidateTypes
      defer(() => {
         const backUrl = get(location, 'state.backUrl') || DEFAULT_PATH;
         history.replace(backUrl);
      });
   };

   /**
    * Create a new user.
    */
   const handleCreateNewUser = () => {
      history.replace(USER_PATH.replace(':uuid?', uuidFunction()), {...location.state, isNew: true});
   };

   /**
    * Delete the selected user.
    */
   const handleDelete = () => {
      userDelete({variables: {id: defaultValues.id}}, true, [{query: USER_ALL_QUERY}]);
      history.replace(USER_PATH.replace(':uuid?', uuidFunction()), {...location.state, isNew: true});
   };

   const renderOption = (option, {inputValue}) => {
      if (option.managerName === NEW_USER.managerName) {
         return (
            <Grid direction={'column'} fullWidth>
               <TypographyFHG color={'primary'}><b>{NEW_USER.managerName}</b></TypographyFHG>
               <Divider/>
            </Grid>
         );
      }
      return renderOptionsKey('managerName')(option, {inputValue});
   };

   if (!stateData) {
      return <CircularProgress className={classes.progressStyle}/>;
   }

   return (
      <TitleCardWeb titleId={'admin.title'}>
         <Prompt when={isChanged}/>
         <FormCard onSubmit={handleSubmit} buttonLocation={'right'} onCancel={handleCancel} noGrid disabled={isSaving}>
            <Grid name={'Admin Form Frame'} container justify={'space-between'} spacing={2} isScrollable
                  fullHeight={false}>
               <Grid name={'Admin Edit Frame'} item container direction={'column'} justify={'flex-start'} spacing={1}
                     fullHeight={false} fullWidth xs={12} sm={6} md={8} className={classes.userFrameStyle}>
                  <Grid item fullWidth>
                     <AutocompleteFHG
                        name={'user'}
                        classes={{select: classes.selectStyle}}
                        labelKey={'user.selectAdmin.label'}
                        disableClearable
                        options={[NEW_USER, ...((usersData && usersData.users) || [])]}
                        optionKey={'managerName'}
                        renderOption={renderOption}
                        isOptionObjects
                        defaultValue={user}
                        onChange={handleUserChange}
                        disabled={isSaving || loading}
                        fullWidth
                     />
                  </Grid>
                  <Grid item fullWidth>
                     <TextFieldFHG
                        name={'managerName'}
                        labelKey={'user.name.label'}
                        autoFocus
                        defaultValue={defaultValues.managerName}
                        value={editValues.managerName}
                        onChange={handleChangeCallback}
                        disabled={isSaving}
                        required
                     />
                  </Grid>
                  <Grid item fullWidth>
                     <TextFieldFHG
                        name={'addressLineOne'}
                        labelKey={'user.address.label'}
                        defaultValue={defaultValues.addressLineOne}
                        value={editValues.addressLineOne}
                        onChange={handleChangeCallback}
                        disabled={isSaving}
                     />
                  </Grid>
                  <Grid item fullWidth>
                     <TextFieldFHG
                        name={'addressLineTwo'}
                        labelKey={'user.address2.label'}
                        defaultValue={defaultValues.addressLineTwo}
                        value={editValues.addressLineTwo}
                        onChange={handleChangeCallback}
                        disabled={isSaving}
                     />
                  </Grid>
                  <Grid item container fullWidth spacing={1}>
                     <Grid item fullWidth xs={12} sm={12} md={5} lg={6}>
                        <AutocompleteFHG
                           name={'cityId'}
                           editName={'city'}
                           labelKey={'user.city.label'}
                           autoSelect={false}
                           options={stateData && stateData.cities}
                           defaultValue={get(defaultValues, 'cityId')}
                           onChange={handleChangeCallback}
                           disableClearable
                           disabled={isSaving}
                        />
                     </Grid>
                     <Grid item fullWidth xs={8} sm={6} md={4} lg={4}>
                        <AutocompleteFHG
                           name={'stateId'}
                           labelKey={'user.state.label'}
                           options={stateData && stateData.states}
                           defaultValue={defaultValues.stateId || KANSAS_STATE_ID}
                           onChange={handleChangeCallback}
                           disableClearable
                           required={editValues.city}
                           disabled={isSaving}
                        />
                     </Grid>
                     <Grid item fullWidth xs={4} sm={6} md={3} lg={2}>
                        <TextFieldFHG
                           name={'zipCode'}
                           className={classes.zipStyle}
                           inputProps={{
                              'data-type': 'number',
                              maxLength: 5,
                              pattern: '[0-9]{5}',
                              title: 'Five digit zip code'
                           }}
                           labelKey={'user.zip.label'}
                           defaultValue={defaultValues.zipCode}
                           value={editValues.zipCode}
                           onChange={handleChangeCallback}
                           disabled={isSaving}
                        />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid name={'User Info'} container direction={'column'} spacing={1} item xs={12} sm={6} md={4}
                     fullHeight={false}>
                  <Grid item fullWidth>
                     <TextFieldFHG
                        name={'username'}
                        labelKey={'user.userName.label'}
                        helperText={!isNew && usernameMessage}
                        defaultValue={defaultValues.username}
                        value={editValues.username}
                        onChange={handleChangeCallback}
                        disabled={isSaving || !isNew}
                        required={isNew}
                     />
                  </Grid>
                  <PasswordTextField
                     name='password'
                     fullWidth
                     isNew={isNew}
                     disabled={isSaving}
                     onChange={handleChangeCallback}
                     password={editValues.password}
                     confirm={editValues.confirm}
                  />
                  <Grid item fullWidth>
                     <PhoneNumberField
                        name='phonePrimary'
                        labelKey={'user.phone.label'}
                        defaultValue={defaultValues.phonePrimary}
                        value={editValues.phonePrimary}
                        onChange={handleChangeCallback}
                        disabled={isSaving}
                     />
                  </Grid>
                  <Grid item fullWidth>
                     <PhoneNumberField
                        name='phoneSecondary'
                        labelKey={'user.secondaryPhone.label'}
                        defaultValue={defaultValues.phoneSecondary}
                        value={editValues.phoneSecondary}
                        onChange={handleChangeCallback}
                        disabled={isSaving}
                     />
                  </Grid>
                  <Grid item fullWidth>
                     <TextFieldFHG
                        name={'email'}
                        type='email'
                        labelKey={'user.email.label'}
                        defaultValue={defaultValues.email}
                        value={editValues.email}
                        onChange={handleChangeCallback}
                        disabled={isSaving}
                        required
                     />
                  </Grid>
                  {!isNew && <>
                     <ConfirmButton
                        className={classes.linkStyle}
                        disableRipple
                        onConfirm={handleDelete}
                        titleKey={'user.deleteAdmin.label'}
                        messageKey={'user.confirmDelete.message'}
                        values={{...defaultValues, b: (...chunks) => <b>{chunks}</b>}}
                        color='secondary'
                        style={{textDecoration: 'underline'}}
                        buttonLabelKey={'user.deleteAdmin.label'}
                        disabled={isSaving}
                     />
                  </>}
               </Grid>
            </Grid>
         </FormCard>
      </TitleCardWeb>
   );
}
