import Drawer from '@material-ui/core/Drawer/Drawer';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import DrawerContext from '../../components/DrawerContext';
import {DRAWER_WIDTH, APPBAR_HEIGHT} from '../../Constants';

const useStyles = makeStyles(theme => ({
   drawer: {
      '@media print': {
         display: 'none',
      },
      [theme.breakpoints.up('lg')]: {
         width: DRAWER_WIDTH,
         height: DRAWER_WIDTH,
         flexShrink: 0,
      },
   },
   drawerPaper: {
      // backgroundColor: theme.palette.environment.light.level1.base,
      // minWidth: 300,
      width: DRAWER_WIDTH, //EQUIPMENT_LIST_PROPERTY_WIDTH,
      maxWidth: '100%',
      // position: 'relative !important',
      marginTop: APPBAR_HEIGHT,
      // marginLeft: 'auto',
   },
   closeButtonStyle: {
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         position: 'absolute',
         top: 0,
         right: 0,
      },
      '@supports not (-ms-high-contrast: none)': {
         position: 'sticky',
      },
      right: 0,
      top: 0,
      marginLeft: 'auto',
      marginBottom: theme.spacing(-6),
      zIndex: 1001,
   },
}));

/**
 * Responsive Drawer Component which changes between a permanent drawer above the breakpoint and is temporary at or
 * below the breakpoint. The close button floats above the children and stays at in the top right of the component. The
 * children should allow space in the upper right corner for the close button.
 */
export default function ResponsiveMobileDrawer({children, width, backgroundColor}) {
   const classes = useStyles();
   const {onDrawerClose, onDrawerOpen, isDrawerOpen} = useContext(DrawerContext);

   return (
      <nav className={classes.drawer} aria-label='mailbox folders'>
         {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
         <Hidden lgUp implementation='css'>
            <SwipeableDrawer
               variant='temporary'
               anchor='left'
               open={isDrawerOpen}
               onClose={onDrawerClose}
               onOpen={onDrawerOpen}
               PaperProps={{style: {width, backgroundColor}}}
               classes={{
                  paper: classes.drawerPaper,
               }}
               ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
               }}
            >
               {children}
            </SwipeableDrawer>
         </Hidden>
         <Hidden mdDown implementation='css'>
            <Drawer
               anchor='left'
               PaperProps={{style: {width, backgroundColor}}}
               classes={{
                  paper: classes.drawerPaper,
               }}
               variant='permanent'
               open
            >
               {children}
            </Drawer>
         </Hidden>
      </nav>
   );
}

ResponsiveMobileDrawer.propTypes = {
   children: PropTypes.any.isRequired,       // Children in the drawer.
   width: PropTypes.string,                  // Width of the draw to override the constant DRAWER_WIDTH.
   backgroundColor: PropTypes.string,        // Background color of the drawer.
};
