import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import React, {useState, useEffect, Fragment} from 'react';
import {Marker, Popup, Tooltip} from 'react-leaflet';
import {useParams} from 'react-router-dom';
import {WELL_ICON, FIELD_ICON, WASTE_ICON, FACILITY_ICON} from '../../../Constants';
import './MarkerColoredFHG.scss';

// https://github.com/PaulLeCam/react-leaflet/issues/255

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
   iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
   iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

export default function MarkerColoredFHG({marker, name, location, color = 'blue', type, label, content, onPopupClose, onPopupOpen, ...markerProps}) {
   const [iconUse, setIconUse] = useState(undefined);
   const {uuid} = useParams();

   useEffect(() => {
      let iconType, iconSize, iconAnchor, width, height;

      switch (type) {
         case 'well':
            iconType = WELL_ICON;
            width=16;
            height='auto';
            iconSize = [20, 38];
            iconAnchor = [10, 38];
            break;
         case 'field':
            iconType = FIELD_ICON;
            width=24;
            height='auto';
            iconSize = [20, 38];
            iconAnchor = [10, 38];
            break
         case 'wasteStorage':
            iconType = WASTE_ICON;
            width=20;
            height='auto';
            iconSize = [20, 38];
            iconAnchor = [10, 38];
            break;
         case 'facility':
         default:
            iconType = FACILITY_ICON;
            width=24;
            height='auto';
            iconSize = [30, 32];
            iconAnchor = [10, 38];
            break;
      }
      const icon = L.divIcon({
         className: 'custom-div-icon',
         html: `<div style='background-color:${color};' class='marker-pin'></div><img alt='markerIcon' width=${width} height=${height} src='${iconType}'/>`,
         iconSize,
         iconAnchor
      });
      setIconUse(icon);

   }, [color, type]);

   if (iconUse) {
      return (
         <Marker key={`coloredMarker${name}`} opacity={.75} selected={true}
                 position={[location.latitude, location.longitude]} icon={iconUse} {...markerProps}>
            {content && <>
               {uuid !== markerProps.uuid && (
                  <Tooltip key={`tooltip ${name}`}>
                     {content.map((item, index) => (
                        <Fragment key={'tooltip internal' + index}>
                           <b>{item.label}</b>&nbsp;
                           {item.value}
                           <br/>
                        </Fragment>
                     ))}
                  </Tooltip>
               )}
               <Popup
                  key={`popup ${name}`}
                  closeButton={marker.closeButton !== false }
                  onClose={() => onPopupClose && onPopupClose(markerProps.uuid)}
                  onOpen={() => onPopupOpen && onPopupOpen(markerProps.uuid)}
               >
                  {content.map((item, index) => (
                     <Fragment key={'popup internal' + index}>
                        <b>{item.label}</b>&nbsp;
                        {item.value}
                        <br/>
                     </Fragment>
                     ))}
               </Popup>
            </>}
         </Marker>
      );
   } else {
      return (
         <Marker key={`marker ${name}`} position={[location.latitude, location.longitude]} {...markerProps}/>
      );
   }
}

