import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import find from 'lodash/find';
import map from 'lodash/map';
import {useMemo} from 'react';
import React from 'react';
import {useIntl} from 'react-intl';
import {MONTHS} from '../../../Constants';
import Grid from '../../../fhg/components/Grid';
import {formatMessage} from '../../../fhg/utils/Utils';
import {Row} from './MeterBreakdownTable';

const useStyles = makeStyles({
   root: {
      margin: 3,
      width: 'fit-content',
      minWidth: 'calc(100% - 12px)',
      maxWidth: 'calc(100% - 12px)',
      height: 'fit-content',
      maxHeight: 'calc(100% - 12px)',
      overflow: 'hidden',
   },
   container: {
      maxHeight: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
   },
   totalLabelStyle: {
      fontWeight: 500,
      fontSize: 16,
   },
   totalStyle: {
      fontWeight: 500,
      fontSize: '0.875rem',
   },
});
const columns = [
   {id: 'month', label: 'Month', minWidth: 80},
   {id: 'meter', label: 'End Reading', minWidth: 80, align: 'right'},
   {id: 'gallons', label: 'Gallons', minWidth: 80, align: 'right'},
   {id: 'acreFeet', label: 'Acre Feet', minWidth: 80, align: 'right'},
];

const columsSubTable = [
   {id: 'name', label: 'Meter', minWidth: 70},
   {id: 'endMeterReading', label: 'End Reading', minWidth: 80, align: 'right'},
   {
      id: 'gallons',
      label: 'Gallons',
      minWidth: 80,
      align: 'right',
      type: 'number',
      maximumFractionDigits: 2,
   },
   {
      id: 'acreFeet',
      label: 'Acre Feet',
      minWidth: 80,
      align: 'right',
      type: 'number',
      maximumFractionDigits: 2,
   },
];

/**
 * Display the meter monthly breakdown.
 *
 * @param meterGauges The list meter gauges.
 * @param metersData The data that descrips the meter (i.e. name).
 * @return {JSX.Element} the Table of Waste Export Report.
 * @constructor
 */
export default function WellAnnualReportDetail({meterGauges, metersData}) {
   const classes = useStyles();
   const intl = useIntl();

   const rows = useMemo(() => {
      if (meterGauges?.length > 0) {
         return MONTHS.map((key) => {
            return {
               month: formatMessage(intl, key),
               meters: map(meterGauges, (meter) => {
                  const meterData = find(metersData, {id: meter.id});
                  return {...meter.monthly[key], name: meterData?.name};
               }),
            };
         });
      }
      return [];
   }, [meterGauges]);

   if (!meterGauges) {
      return null;
   }

   return (
      <Grid container direction={'column'} fullHeight>
         <Divider />
         <Paper className={classes.root} elevation={1}>
            <TableContainer className={classes.container}>
               <Table stickyHeader aria-label='sticky table' size={'small'}>
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                              {column.label}
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody style={{maxHeight: 'calc(100% - 64px)'}}>
                     {rows.map((row) => (
                        <Row key={row.name} row={row} columns={columsSubTable} />
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Paper>
      </Grid>
   );
}
