import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as PropTypes from 'prop-types';
import React, {forwardRef, useContext, useState, useEffect} from 'react';
import TextFieldFHG from '../components/TextField';
import {COUNTY_PATH, FACILITY_ICON, FIELD_ICON, WELL_ICON, WASTE_ICON, MIN_ZOOM_DEFAULT} from '../Constants';
import useEditData from '../fhg/components/edit/useEditData';
import Grid from '../fhg/components/Grid';
import {counties} from '../fhg/components/map/CountySelectionMapComponent';
import MapFHG from '../fhg/components/map/MapFHG';
import StatusContext from '../fhg/components/StatusContext';
import TypographyFHG from '../fhg/components/Typography';
import {fetchData} from '../fhg/utils/Utils';
import {
   ASSET_TYPES, ALL_FACILITIES, ALL_ASSETS, FACILITY_ASSET, FIELD_ASSET, WELL_ASSET, WASTE_STORAGE_ASSET
} from '../pages/client/ClientDashboard';

const useMapStyles = makeStyles(theme => ({
   mapSectionStyle: {
      maxHeight: '100%',
      minHeight: 200,
      minWidth: 200,
      [theme.breakpoints.down('xs')]: {
         // minHeight: 0,
         minHeight: 100,
         minWidth: 100,
      },
   },
   buttonRoot: {
      backgroundColor: `${theme.palette.primary.light} !important`,
   },
   buttonSelected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
   },
   editStyle: {
      cursor: 'pointer',
   },
}), {name: 'ClientSetupMapStyles'});

const MapSection = forwardRef(
   function MapSection({
         facilities, markers, bounds, onLocationChange, onFilter, onGeoChange, closePopup, defaultCenter, defaultZoom,
         draggableMarker, onGeoDelete, onMarkerClick, onPopupOpen, onPopupClose, geoJson, limitShapes,  ...props
      },
      ref) {
      const theme = useTheme();
      const classes = useMapStyles();
      const {setError} = useContext(StatusContext);

      //handleCountyChange is needed even though not used.
      const [editCountyValues, handleCountyChange, {setEditValues}] = useEditData({});
      const [editFilterValues, handleFilterChange, {setEditValues: setFilterValues}] = useEditData({},
         {facilityId: ALL_FACILITIES, assetType: ALL_ASSETS});

      const [drawType, setDrawType] = useState();

      useEffect(() => {
         let facilityId = facilities && facilities.length === 1 ? facilities[0].id : ALL_FACILITIES;

         setFilterValues({...editFilterValues, facilityId});
      }, [facilities]);

      const handleDrawType = (event, drawTypes) => {
         setDrawType(drawTypes);
      };

      const handleLocationChange = (location, event) => {
         onLocationChange(location, event, drawType);
      };

      const handlePopupOpen = (uuid, isGeoShape) => {
         if (isGeoShape && editFilterValues.facilityId !== ALL_FACILITIES) {
            setDrawType(FIELD_ASSET);
         }
          onPopupOpen && onPopupOpen(uuid, isGeoShape);
      };

      useEffect(() => {
         if (editFilterValues) {
            onFilter && onFilter(editFilterValues);
         }
      }, [editFilterValues]);

      /**
       * Handle onChange events for the inputs.
       *
       * NOTE:
       * Input components MUST have their name set to be set in the editValues.
       *
       * @param event The event that changed the input.
       * @param value The value if the component is an Autocomplete
       * @param reason The reason of the value change if Autocomplete
       */
      const handleChange = async (event, value, reason) => {

         if (reason === 'clear') {
            setEditValues({
               ...editCountyValues,
               county: undefined,
               center: undefined,
               bounds: undefined,
            });
         } else if (value && reason === 'select-option') {
            try {
               const data = await fetchData(COUNTY_PATH.format({county: value}), 'GET');
               setEditValues({
                  ...editCountyValues,
                  county: value,
                  center: {lat: data[0].lat, lng: data[0].lon},
                  bounds: [
                     [data[0].boundingbox[0], data[0].boundingbox[2]],
                     [data[0].boundingbox[1], data[0].boundingbox[3]]
                  ]
               });
            } catch (error) {
               setError('county.error', undefined, error);
            }
         }
      };

      return (
         <Grid ref={ref} name={'ClientSetup-mapSection'} container item xs={12} sm={8} direction={'column'}
               wrap={'nowrap'}
               className={classes.mapSectionStyle} alignItems={'stretch'} resizable>
            <Grid name={'ClientSetup-mapFilter'} item container direction={'row'} resizable={false} fullWidth
                  alignItems={'center'}
                  fullHeight={false} spacing={1} style={{paddingBottom: theme.spacing(1)}}>
               <Grid item xs={6} lg={3}>
                  <Autocomplete
                     name={'county'}
                     autoSelect
                     autoComplete
                     autoHighlight
                     options={counties}
                     value={editCountyValues.county}
                     onChange={handleChange}
                     fullWidth
                     renderInput={params => (
                        <TextFieldFHG name={'county'}{...params} labelKey={'county.filter.label'}/>
                     )}
                  />
               </Grid>
               {(facilities && facilities.length > 0) && (
                  <Grid item xs={6} lg={3}>
                     <TextFieldFHG
                        name={'facilityId'}
                        labelKey={'clientDashboard.selectFacility.label'}
                        value={editFilterValues.facilityId}
                        onChange={handleFilterChange}
                        select
                        fullWidth
                     >
                        <MenuItem value={ALL_FACILITIES}>
                           <TypographyFHG id={'clientDashboard.allFacilities.label'}/>
                        </MenuItem>
                        {facilities.map(facility => (
                           <MenuItem key={'facility ' + facility.id} value={facility.id}>
                              <TypographyFHG>{facility.name}</TypographyFHG>
                           </MenuItem>
                        ))}
                     </TextFieldFHG>
                  </Grid>
               )}
               <Grid item xs={6} lg={3}>
                  <TextFieldFHG
                     name={'assetType'}
                     labelKey={'clientDashboard.selectAsset.label'}
                     value={editFilterValues.assetType}
                     onChange={handleFilterChange}
                     select
                     fullWidth
                  >
                     {ASSET_TYPES.map((asset, index) => (
                        <MenuItem key={'asset' + index} value={asset.value}>
                           <TypographyFHG id={asset.labelKey}/>
                        </MenuItem>
                     ))}
                  </TextFieldFHG>
               </Grid>
               <Grid item xs={6} lg={3} style={{marginTop: theme.spacing(2)}}>
                  <ToggleButtonGroup value={drawType} onChange={handleDrawType} aria-label='text formatting' exclusive style={{height: 40, marginTop: 4}}>
                     <ToggleButton value={FACILITY_ASSET}>
                        <div style={{backgroundColor:'#8AABBD'}} className='marker-pin2'/>
                        <img alt='markerIcon' style={{zIndex: 100, marginTop: -9}} width={24} height='auto' src={FACILITY_ICON}/>
                     </ToggleButton>
                     {(editFilterValues.facilityId !== ALL_FACILITIES) && ([
                        <ToggleButton key='fieldToggle' value={FIELD_ASSET}>
                           <div style={{backgroundColor:theme.palette.success.main}} className='marker-pin2'/>
                           <img alt='markerIcon' style={{zIndex: 100, marginTop: -9}} width={24} height='auto' src={FIELD_ICON}/>
                        </ToggleButton>,
                        <ToggleButton key='wellToggle' value={WELL_ASSET}>
                           <div style={{backgroundColor: '#60ADDB'}} className='marker-pin2'/>
                           <img alt='markerIcon' style={{zIndex: 100, marginTop: -9}} width={16} height='auto' src={WELL_ICON}/>
                           {/*<img src={WELL_ICON} width={24} height={24}/>*/}
                        </ToggleButton>,
                        <ToggleButton key='wasteToggle' value={WASTE_STORAGE_ASSET}>
                           <div style={{backgroundColor: '#e3872b'}} className='marker-pin2'/>
                           <img alt='markerIcon' style={{zIndex: 100, marginTop: -9}} width={20} height='auto' src={WASTE_ICON}/>
                           {/*<img src={WASTE_ICON} width={24} height={24}/>*/}
                        </ToggleButton>
                     ])}
                  </ToggleButtonGroup>
               </Grid>
            </Grid>
            <Grid name={'ClientSetup-map'} item
                  style={{maxHeight: '100%', minHeight: 200}} resizable>
               <MapFHG
                  name={'client map component'}
                  className={drawType ? classes.editStyle: undefined}
                  markers={markers}
                  closePopup={closePopup}
                  defaultZoom={defaultZoom}
                  draggableMarker={draggableMarker}
                  geoAndMarkers={true}
                  limitShapes={limitShapes}
                  onMarkerClick={onMarkerClick}
                  onChange={handleLocationChange}
                  onPopupOpen={handlePopupOpen}
                  onPopupClose={onPopupClose}
                  allowEdit={drawType === FIELD_ASSET}
                  geoJson={geoJson}
                  onGeoChange={onGeoChange}
                  onGeoDelete={onGeoDelete}
                  minZoom={MIN_ZOOM_DEFAULT}
                  maxZoom={17}
                  zoomOnGeo={false}
                  bounds={editCountyValues.bounds || bounds}
                  center={editCountyValues.center || (!(editCountyValues.bounds || bounds) && defaultCenter)}
               />
            </Grid>
         </Grid>
      );
   });

MapSection.propTypes = {
   facilities: PropTypes.array,
   markers: PropTypes.arrayOf(PropTypes.any),
   bounds: PropTypes.array,
   onLocationChange: PropTypes.func,
   onGeoDelete: PropTypes.func,
   onGeoChange: PropTypes.func,
   onChange: PropTypes.func,
   onMarkerClick: PropTypes.func,
   onPopupOpen: PropTypes.func,
   onPopupClose: PropTypes.func,
};

export default MapSection;