import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from "graphql-tag";
import map from 'lodash/map';
import pick from 'lodash/pick';
import findIndex from 'lodash/findIndex';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import React, {useState, useCallback, useRef, useEffect, useMemo} from 'react';
import {v4 as uuid} from 'uuid';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useMutationFHG, {DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import Form from '../../../fhg/components/edit/Form';
import Prompt from '../../../fhg/components/edit/Prompt';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import ProgressButton from '../../../fhg/components/ProgressButton';
import ReactSelect from '../../../fhg/components/ReactSelect';
import TypographyFHG from '../../../fhg/components/Typography';
import {toNumber, removeOne} from '../../../fhg/utils/Utils';
import {
   WELL_CREATE_UPDATE, WELLS_BY_FACILITY_ID_QUERY
} from '../../../prototype/ClientSetup';
import {GAUGE_QUERY} from '../../client/WellClient';
import Allotment2 from './Allotment2';
import MetersEditCard2 from './MetersEditCard2';

const useStyles = makeStyles(theme => ({
   paperStyle: {
      maxHeight: `calc(100% - 1px)`,
      margin: theme.spacing(0, 0, 0, 2),
   },
   meterStyle: {
      marginTop: theme.spacing(1),
   },
   textFieldStyle: {
      marginBottom: theme.spacing(0.5),
   },
   radioStyle: {
      padding: theme.spacing(0, .5, 0, 0),
   },
   expansionStyle: {
      borderTop: '1px solid lightgrey',
      borderLeft: '1px solid lightgrey',
      borderRight: '1px solid lightgrey',
      width: '100%',
   },
   formStyle: {
      maxHeight: '100%',
      overflow: 'hidden',
   },
   rootStyle: {
      maxHeight: `calc(100% - ${theme.spacing(5)}px)`,
      overflow: 'auto',
   },
   buttonPanelStyle: {
      padding: theme.spacing(2, 0),
   },
   selectRoot: {
      paddingLeft: theme.spacing(0.5),
      // marginTop: theme.spacing(1),
      // flex: 1,
   },
}), {name: 'wellEditInfo2Styles'});

// Update the fields with the given properties.
export const WELL_DELETE = {
   mutation: gql`
      mutation WellDelete($id: Int!) {
         well_Delete(wellId: $id)
      }
   `,
   typeKey: 'well.type',
   actionKey: DELETE_ACTION,
};


export default function WellEditInfo({well = {}, allotments, facilityIds = [], onClose}) {
   const classes = useStyles();
   const theme = useTheme();
   const meterRef = useRef();

   const [wellCreateUpdate] = useMutationFHG(WELL_CREATE_UPDATE);
   const [wellDelete] = useMutationFHG(WELL_DELETE, undefined,
      {query: WELLS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}});

   const [editValues, handleChange, {isChanged, setIsChanged, defaultValues, handleSelectChange, setDefaultValues}] = useEditData(
      {uuid: uuid(), ...well}, ['id', 'uuid', 'commonName', 'allotments', 'facilityId', 'latitude', 'longitude']);

   const [editAllotment, setEditAllotment] = useState();
   const [anchorEl, setAnchorEl] = useState();
   const [isSaving, setIsSaving] = useState(false);

   useEffect(() => {
       if (well.allotments) {
          setDefaultValues({uuid: uuid(), ...well});
       }
   }, [well, setDefaultValues]);

   const handleSubmit = useCallback(() => {
      const getRefetchQueries = () => {
         return [
            {query: GAUGE_QUERY, variables: {wellId: well && well.id}, skip: !well},
            {query: WELLS_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}},
            ]
      }
      const getAllotments = () => {
         const allotmentsList = editValues.allotments;
         // noinspection JSValidateTypes
         if (isArray(allotmentsList)) {
            return map(allotmentsList, allotment => {
               const filteredAllotment = pick(allotment, 'id', 'facilityId', 'allotment', 'fileNumbers', 'years',
                  'startYear');
               if (filteredAllotment.allotment === null || filteredAllotment.fileNumbers === null) {
                  console.log('Allotment is null');
                  console.trace();
               }
               if (filteredAllotment.startYear) {
                  //Is the startYear a moment object?
                  if (typeof filteredAllotment.startYear === 'object') {
                     filteredAllotment.startYear = filteredAllotment.startYear.year();
                  } else {
                     filteredAllotment.startYear = toNumber(filteredAllotment.startYear);
                  }
               }
               return filteredAllotment;
            });
         }
         else {
            return [];
         }
      };
      const submit = async () => {
         let result;

         try {
            setIsSaving(true);
            let wellId = well && well.id;
            if (isChanged) {
               const allotments = getAllotments();
               result = await wellCreateUpdate({variables: {...editValues, allotments}, refetchQueries: getRefetchQueries});
               wellId = result.data.well.id;
            }
            await meterRef.current.submit(wellId);
            setIsChanged(false);
            onClose && onClose();
         } finally {
            setIsSaving(false);
         }
      }
      submit();
   }, [wellCreateUpdate, isChanged, editValues, onClose, setIsChanged, well]);

   const handleDelete = () => {
      wellDelete({variables: {id: well.id}}, true);
   };

   const handleMultiClick = (event, allotment) => {
      event.stopPropagation();
      event.preventDefault();

      setAnchorEl(event.currentTarget);
      setEditAllotment(allotment);
   };

   const handleCloseAllotment = () => {
      setEditAllotment(undefined);
      setAnchorEl(undefined);
   };

   const handleAllotmentSubmit = (allotment, isNew) => {
      let existingAllotments;

      if (isNew) {
         try {
            if (isArray(editValues.allotments)) {
               existingAllotments = editValues.allotments;
            }
            if (existingAllotments) {
               handleSelectChange([...existingAllotments, allotment], 'allotments');
            } else {
               handleSelectChange([allotment], 'allotments');
            }
         } catch (e) {
            console.log('Error updating after allotment save.', existingAllotments, allotment);
         }
      }
   };

   const handleAllotmentDelete = (allotment) => {
      const index = findIndex(editValues.allotments || defaultValues.allotments, {id: allotment.id});
      handleSelectChange([...removeOne(editValues.allotments || defaultValues.allotments, index)], 'allotments');
   };

   const getLabel = useCallback(option => `${option.fileNumbers} ${option.allotment}`, []);

   const allotmentOptions = useMemo(() => {
      return ReactSelect.createOptionsFromObject(allotments || [], getLabel, 'uuid');
   }, [allotments, getLabel]);

   const allotmentDefaults = useMemo(() => {
      return ReactSelect.createOptionsFromObject(get(defaultValues, 'allotments') || [], getLabel, 'uuid');
   }, [defaultValues, getLabel]);

   const allotmentsEdit = useMemo(() => {
      return ReactSelect.createOptionsFromObject(get(editValues, 'allotments') || [], getLabel, 'uuid');
   }, [editValues, getLabel]);

   return (
      <Grid container fullWidth fullHeight>
         <TitleCardInner
            titleId={'clientWell.title'}
            values={{name: defaultValues.name, hasBold: true}}
            classes={{paperStyle: classes.paperStyle}}
            onClose={onClose}
            headerAction={(
               <ConfirmButton
                  onConfirm={handleDelete}
                  titleKey={'delete.confirm.title'}
                  messageKey={'delete.confirm.message'}
                  titleValues={{type: defaultValues.name}}
                  values={{type: defaultValues.name}}
                  color='secondary'
                  buttonLabelKey={'delete.button'}
                  disableRipple
                  // className={classes.buttonStyle}
                  buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
               />
            )}
         >
            <Form onSubmit={handleSubmit} className={classes.formStyle}>
               <Prompt when={isChanged}/>
               <Grid name={'Well Edit Root'} container item fullWidth spacing={1} className={classes.rootStyle}>
                  <Grid item fullWidth xs={12}>
                     <TextFieldFHG
                        name={'commonName'}
                        labelKey={'facility.commonName.label'}
                        defaultValue={get(defaultValues, 'commonName')}
                        value={editValues.commonName}
                        onChange={handleChange}
                        disabled={isSaving}
                        fullWidth
                        required
                     />
                  </Grid>
                  <ReactSelect
                     name={'allotments'}
                     label={<TypographyFHG display={'inline'} id={'facility.allotments.label'}/>}
                     classes={{root: classes.selectRoot}}
                     onMultiClick={handleMultiClick}
                     openMenuOnClick={false}
                     options={allotmentOptions}
                     defaultValue={allotmentDefaults}
                     value={allotmentsEdit}
                     hideSelectedOptions
                     onChange={handleSelectChange}
                     isMulti
                     fullWidth
                     margin='none'
                     disabled={isSaving}
                  />
                  <Allotment2 well={well} facilityId={well && well.facilityId} facilityIds={facilityIds} anchorEl={anchorEl}
                              allotment={editAllotment} onClose={handleCloseAllotment} onSubmit={handleAllotmentSubmit} onDelete={handleAllotmentDelete}/>
                  <Grid item style={{padding: theme.spacing(2, 0, 1, 2)}} xs={12}>
                     <TypographyFHG id={'facility.meters.title'} variant='subtitle1' color={'inherit'}
                                    style={{fontSize: '1.25rem'}}/>
                     <MetersEditCard2 ref={meterRef} well={well}/>
                  </Grid>
               </Grid>
               <Grid item fullWidth className={classes.buttonPanelStyle}>
                  <ProgressButton isProgress={isSaving} variant='contained' color='primary' type={'submit'}
                                  size={'small'} labelKey={'save.label'} disabled={isSaving}/>
               </Grid>
            </Form>
         </TitleCardInner>
      </Grid>
   );
}

