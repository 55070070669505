import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, {useState} from 'react';
import AppearanceContext from './AppearanceContext';

export const DARK_MODE = 'darkMode';
export const LIGHT_MODE = 'lightMode';
export const AUTO_MODE = 'autoMode';

/**
 * The AppearanceSettingProvider component. Contains three modes: 1) Dark Mode, 2) Light Mode, and 3) Auto. Auto uses
 * device setting.  Dark and Light modes force the appearance accordingly without regard for the device setting.
 *
 * Reviewed: 8/21/2020
 */
export default function AppearanceSettingProvider({children}) {
   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
   const [appearanceSetting, setAppearanceSetting] = useState(localStorage.appearanceSetting || AUTO_MODE);

   // Use the setting if dark mode, otherwise if auto, use the device setting.
   const isDarkMode = (appearanceSetting === DARK_MODE) || (appearanceSetting === AUTO_MODE && prefersDarkMode);

   /**
    * Handle the changes to dark mode preference
    * @param newAppearanceSetting DARK_MODE, LIGHT_MODE, or AUTO_MODE.
    */
   const handleAppearanceSetting = async (newAppearanceSetting) => {
      localStorage.appearanceSetting = newAppearanceSetting;
      setAppearanceSetting(newAppearanceSetting);
   };

   return (
      <AppearanceContext.Provider value={{setAppearanceSetting: handleAppearanceSetting, isDarkMode, appearanceSetting}}>
         {children}
      </AppearanceContext.Provider>
   );
}
