import map from 'lodash/map';
import moment from 'moment';
import React, {useMemo} from 'react';
import {DATE_DB_FORMAT} from '../../../Constants';
import useQueryArrayFHG from '../../../fhg/components/data/useQueryArrayFHG';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import {STATES_QUERY} from '../../admin/AdminEditCard';
import {FACILITY_BY_UUID_FOR_CLIENT_QUERY} from '../FacilityClient';
import {WASTE_APPLICATION_BY_RANGE_QUERY} from './FieldRecord';
import {LAST_METER_READING_BY_RANGE_QUERY, METER_READING_BY_RANGE_QUERY} from '../../../data/QueriesGL'
import {FIELD_OPTIONS_QUERY, FIELD_WASTE_APPLICATION_QUERY} from './FieldRecordEdit';
import {LIVESTOCK_HEADCOUNT_QUERY, HEAD_COUNT_BY_RANGE_QUERY} from './HeadcountRecord';
import {POND_LEVEL_BY_RANGE_QUERY} from './PondLevelRecord';
import {PRECIPITATION_BY_RANGE_QUERY} from './PrecipitationRecord';
import {WASTE_EXPORT_BY_RANGE_QUERY} from './WasteExport';
import {WASTE_EXPORT_OPTIONS_QUERY} from './WasteExportEdit';
import {WASTE_TRANSFER_BY_RANGE_QUERY} from './WasteTransferRecord';
import {WELL_BY_UUID_QUERY} from '../WellClient';
import {WASTE_STORAGE_BY_UUID_QUERY} from '../WasteStorageClient';
import {METERS_FOR_WELL_QUERY} from './WellRecord';
import {WASTE_STORAGE_OPTIONS_QUERY} from '../../../data/QueriesGL';

export default function useOfflineData({
   facilities = [],
   fields = [],
   wells = [],
   wasteStorageList = [],
   skip = false
}) {
   const monthRange = useMemo(() => ({beginDate: moment().startOf('month'), endDate: moment().endOf('month')}), []);

   const fieldIdList = map(fields, 'id');
   const facilityIdList = map(facilities, 'id');
   const facilitiesUuidList = map(facilities, 'uuid');
   const wellIdList = map(wells, 'id');
   const wellUuidList = map(wells, 'uuid');
   const wasteStorageIdList = map(wasteStorageList, 'id');
   const wasteStorageUuidList = map(wasteStorageList, 'uuid');

   const beginDate = monthRange.beginDate.format(DATE_DB_FORMAT);
   const endDate = monthRange.endDate.format(DATE_DB_FORMAT);

   const variables = {
      facilityId: facilityIdList,
      beginDate,
      endDate,
   };

   const {loading: facilityLoading, complete: facilityComplete} = useQueryArrayFHG(FACILITY_BY_UUID_FOR_CLIENT_QUERY, {variables: {uuid: facilitiesUuidList}, skip: skip || facilitiesUuidList?.length <= 0, errorPolicy: 'all'}, 'uuid', 'facility.type');
   const {loading: wellLoading, complete: wellComplete} = useQueryArrayFHG(WELL_BY_UUID_QUERY, {variables: {uuid: wellUuidList}, skip: skip || wellUuidList?.length <= 0}, 'uuid','well.type');
   const {loading: wasteStorageLoading, complete: wasteStorageComplete} = useQueryArrayFHG(WASTE_STORAGE_BY_UUID_QUERY, {variables: {uuid: wasteStorageUuidList}, skip: skip || wasteStorageUuidList?.length <= 0}, 'uuid','wasteStorage.type');

   /* PrecipitationRecord queries */
   const {loading: precipitationRangeLoading, complete: precipitationRangeComplete} = useQueryArrayFHG(
      PRECIPITATION_BY_RANGE_QUERY,
      {variables, skip: skip || facilities.length <= 0, fetchPolicy: 'network-only'}, 'facilityId', 'precipitation.type');

   /* HeadcountRecord queries */
   const {loading: livestockHeadCountLoading, complete: livestockHeadCountComplete} = useQueryArrayFHG(LIVESTOCK_HEADCOUNT_QUERY,
      {variables: {facilityId: facilityIdList}, skip: skip || facilities.length <= 0, fetchPolicy: 'network-only'}, 'facilityId', 'livestock.type');

   const {loading: headCountRangeLoading, complete: headCountRangeComplete} = useQueryArrayFHG(HEAD_COUNT_BY_RANGE_QUERY,
      {variables, skip: skip || facilities.length <= 0, fetchPolicy: 'network-only'}, 'facilityId', 'headCount.type');

    /* Waste Export queries */
   const {loading: wasteExportRangeLoading, complete: wasteExportRangeComplete} = useQueryArrayFHG(WASTE_EXPORT_BY_RANGE_QUERY,
      {variables, skip: skip || facilities.length <= 0, fetchPolicy: 'network-only'}, 'facilityId', 'wasteExport.type');
   const {loading: stateLoading} = useQueryOfflineFHG(STATES_QUERY, {fetchPolicy: 'network-only'}, 'states.type');
   const {loading: optionLoading} = useQueryOfflineFHG(WASTE_EXPORT_OPTIONS_QUERY, {fetchPolicy: 'network-only'}, 'wasteType.type');

   /* Waste Application queries for FieldRecord */
   const {loading: fieldOptionsLoading} = useQueryOfflineFHG(FIELD_OPTIONS_QUERY,
      {skip: skip || !fieldIdList, errorPolicy: 'all', fetchPolicy: 'network-only'}, 'field.type');
   const {loading: wasteApplicationLoading2, complete: wasteApplicationComplete2} = useQueryArrayFHG(FIELD_WASTE_APPLICATION_QUERY,
      {variables: {fieldId: fieldIdList}, skip: skip || fieldIdList.length <= 0, fetchPolicy: 'network-only', errorPolicy: 'all'}, 'fieldId',
      'wasteStorage.type');
   const {loading: wasteApplicationLoading, complete: wasteApplicationComplete}  = useQueryArrayFHG(WASTE_APPLICATION_BY_RANGE_QUERY,
      {
         variables: {fieldId: fieldIdList, beginDate, endDate},
         skip: skip || fields.length <= 0,
         fetchPolicy: 'network-only'
      }, 'fieldId', 'wasteApplication.type');

   const today = moment();
   const variablesForYear = {
      fieldId: fieldIdList,
      beginDate: today.startOf('year').format(DATE_DB_FORMAT),
      endDate: today.endOf('year').format(DATE_DB_FORMAT),
   };
   const {loading: wasteApplicationLoading3, complete: wasteApplicationComplete3}  = useQueryArrayFHG(WASTE_APPLICATION_BY_RANGE_QUERY,
      {
         variables: variablesForYear,
         skip: skip || fields.length <= 0,
         fetchPolicy: 'network-only',
      }, 'fieldId', 'wasteApplication.type');

   /* WellRecord queries */
   const wellVariables = {
      wellId: wellIdList,
      beginActiveDate: monthRange.beginDate.format(DATE_DB_FORMAT),
      endActiveDate: monthRange.endDate.format(DATE_DB_FORMAT),
   };
   const variablesMeterRange = {wellId: wellIdList, beginDate, endDate};
   const {loading: metersLoading, complete: metersComplete} = useQueryArrayFHG(METERS_FOR_WELL_QUERY, {variables: wellVariables, skip: skip || wells.length <= 0}, 'wellId', 'well.type');
   const {loading: meterReadingRangeLoading, complete: meterReadingRangeComplete} = useQueryArrayFHG(METER_READING_BY_RANGE_QUERY,
      {variables: variablesMeterRange, skip: skip || wells.length <= 0, fetchPolicy: 'network-only'}, 'wellId', 'meterReading.type');

   const lastMonthBeginDate = moment(monthRange.beginDate).subtract(1, 'month');
   const variablesLastMeterRange = {
      wellId: wellIdList,
      beginDate: lastMonthBeginDate.format(DATE_DB_FORMAT),
      endDate: moment(lastMonthBeginDate).endOf('month').format(DATE_DB_FORMAT),
   };
   const {loading: meterLastReadingRangeLoading, complete: meterLastReadingRangeComplete} = useQueryArrayFHG(
      LAST_METER_READING_BY_RANGE_QUERY,
      {variables: variablesLastMeterRange, skip: skip || wells.length <= 0, fetchPolicy: 'network-only'},
      'wellId', 'meterReading.type');

   /* PondLevelRecord queries */
   const variablesPondLevel = {
      wasteStorageId: wasteStorageIdList,
      beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
      endDate: monthRange.endDate.format(DATE_DB_FORMAT),
   }
   const {loading: pondLevelRangeLoading, complete: pondLevelRangeComplete} = useQueryArrayFHG(POND_LEVEL_BY_RANGE_QUERY,
      {variables: variablesPondLevel, skip: skip || wasteStorageList.length <= 0, fetchPolicy: 'network-only'},
      'wasteStorageId', 'pondLevel.type');

   /* WasteTransferRecord queries */
   const {loading: wasteStorageLoading2, complete: wasteStorageComplete2} = useQueryArrayFHG(WASTE_STORAGE_OPTIONS_QUERY,
      {variables: {facilityId: facilityIdList}, skip: skip || facilityIdList?.length <= 0, fetchPolicy: 'network-only'},
      'facilityId', 'wasteStorage.type');


   const variablesWasteTransfer = {
      toWasteStorageId: wasteStorageIdList,
      beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
      endDate: monthRange.endDate.format(DATE_DB_FORMAT),
   }
   const {loading: wasteTransferRangeLoading, complete: wasteTransferRangeComplete} = useQueryArrayFHG(WASTE_TRANSFER_BY_RANGE_QUERY,
      {variables: variablesWasteTransfer, skip: skip || wasteStorageList.length <= 0, fetchPolicy: 'network-only'},
      'toWasteStorageId', 'wasteTransfer.type');

   const loading = precipitationRangeLoading || facilityLoading || wellLoading || wasteStorageLoading ||
      livestockHeadCountLoading || headCountRangeLoading || fieldOptionsLoading || wasteApplicationLoading2 ||
      wasteApplicationLoading3 ||
      wasteApplicationLoading || wasteStorageLoading2 || meterReadingRangeLoading || meterLastReadingRangeLoading ||
      pondLevelRangeLoading || wasteTransferRangeLoading || stateLoading || optionLoading || wasteExportRangeLoading ||
      metersLoading;

   const complete = precipitationRangeComplete || facilityComplete || wellComplete || wasteStorageComplete ||
      livestockHeadCountComplete || headCountRangeComplete || wasteApplicationComplete2 || wasteApplicationComplete3 ||
      wasteApplicationComplete || wasteStorageComplete2 || meterReadingRangeComplete || meterLastReadingRangeComplete ||
      pondLevelRangeComplete || wasteTransferRangeComplete || wasteExportRangeComplete || metersComplete;

   return {loading, complete: complete && !loading}
}
