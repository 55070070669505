import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from './Typography';

const useStyles = makeStyles(theme => ({
   errorMessage: {
      backgroundColor: `${theme.palette.error.light} !important`,
      paddingRight: theme.spacing(6),
   },
   closeButtonStyle: {
      position: 'absolute',
      right: 0,
      top: 0,
      marginLeft: 'auto',
      zIndex: 1001,
   },
}), {name: 'ErrorSnackbarStyles'});

/**
 * Component to show the error messages.
 *
 * Note:
 *    Message is the default property in values.
 *
 * Reviewed: 3/26/20
 */
export default function ErrorSnackbar({open, onClose, errorId, values, message, enableRefresh}) {
   const classes = useStyles();

   /**
    * Force a browser reload.
    */
   const handleRefresh = () => {
      window.location.reload();
   };

   return (
      <Snackbar
         open={open}
         anchorOrigin={{vertical: 'top', horizontal: 'center'}}
         ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
               root: classes.errorMessage,
            }
         }}
         message={<Typography id={errorId} values={values} color={'inherit'}>{message}</Typography>}
         action={[
            enableRefresh && <Button key='undo' color='inherit' size='small' onClick={handleRefresh}>
               <Typography color='inherit' id='refresh'>Refresh</Typography>
            </Button>,
            onClose && (
               <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.closeButtonStyle}
                  onClick={onClose}
               >
                  <CloseIcon/>
               </IconButton>
            ),
         ]}
      />
   );
}

ErrorSnackbar.propTypes = {
   open: PropTypes.bool,                     // Indicates if the Error snackbar should be shown to the user.
   enableRefresh: PropTypes.bool,            // Indicates if the Refresh action should be shown / enabled.
   onClose: PropTypes.func,                  // Callback when the snackbar is closed.
   errorId: PropTypes.string,                // Intl ID for the error message.
   message: PropTypes.string,                // Text for the error message.
   values: PropTypes.object,                 // Value object for the error message.
};

ErrorSnackbar.defaultProps = {
   enableRefresh: true,
   values: {message: 'N/A'},
};
