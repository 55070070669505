import debounce from 'lodash/debounce';
import { useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

useResize.propTypes = {
   ref: PropTypes.any.isRequired,   //Ref to the component to check for width changes.
   defaultWidth: PropTypes.number,  // The number to initialize the width to before it can get the actual width.
   delay: PropTypes.number,          // The number delay the resize checks.
};

/**
 * Hook to get the clientWidth on resize of the window.
 *
 * @param ref
 * @param defaultWidth
 * @param delay
 * @return {unknown}
 */
export default function useResize(ref, defaultWidth, delay = 500) {

   const [width, setWidth] = useState(defaultWidth);

   const handleResize = () => {
      setWidth(get(ref, 'current.clientWidth'));
   };

   useEffect(() => {
       if (ref.current) {
          setWidth(get(ref, 'current.clientWidth'));
       }
   }, [ref]);


   // Debounce the check for resize to avoid continual changes.
   const handleResizeDebounced = useRef(debounce(handleResize, delay)).current;

   useEffect(() => {
      window.addEventListener('resize', handleResizeDebounced);
      return () => window.removeEventListener('resize', handleResizeDebounced);
   }, [handleResizeDebounced]);

   return width;
}
