import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import difference from 'lodash/difference';
import get from 'lodash/get';
import set from 'lodash/set';
import {useEffect, useRef, useState} from 'react';
import useLazyQueryOfflineFHG from './useLazyQueryOfflineFHG';

/**
 * Hook to query for the array of items in variableProperty. The array at variableProperty is removed from the query and
 * a separate query is made for each property in the array.
 *
 * @param query The query with the array of properties at variableProperty
 * @param options The options for the query.
 * @param variableProperty The property that contains the array that is to be used for all the queries.
 * @param typeKey The typeKey of the type of the property. Used for error messages.
 * @param showLoading Indicates if a progress indicator should be shown.
 * @return {{loading: boolean, error: ApolloError, complete: boolean}} The loading will indicate until all the queries
 *         are no longer loading. error is the error coming back from the query and may change if multiple errors occur.
 *         complete Indicates if all the queries have returned their data.
 */
export default function useQueryArrayFHG(query, options, variableProperty, typeKey, showLoading) {

   const [isLoading, setIsLoading] = useState(false);
   const [isComplete, setIsComplete] = useState(false);

   const variableRef = useRef({fullList: [], queue: [], completedList: []}).current;

   /**
    * Pops the next query off the queue and starts loading it.
    */
   const nextQuery = () => {
      const propertyValue = variableRef.queue.pop();

      if (propertyValue) {
         load(propertyValue);
      }
   };

   const [queryFunction, {error, loading}] = useLazyQueryOfflineFHG(query, {...options, variables: undefined}, typeKey, showLoading);

   /**
    * Load the data from the query. The query will be skipped if it is in the completed list.
    * @param propertyValue
    */
   const load = (propertyValue) => {
      if (variableRef.completedList.indexOf(propertyValue) < 0) {
         variableRef.completedList.push(propertyValue);

         const useOptions = set({...options}, `variables.${variableProperty}`, propertyValue);
         queryFunction({...useOptions});

      } else {
         console.log('Already completed', propertyValue);
      }
   }

   /**
    * Listens for loading to change and starts the next query when the previous query is finished. If all queries are
    * finished, the status is updated.
    */
   useEffect(() => {
      if (!loading) {
          if (variableRef.queue?.length > 0) {
             nextQuery();
          } else if (variableRef.queue?.length <= 0 && variableRef.completedList?.length > 0) {
             setIsLoading(false);
             setIsComplete(true);
          }
      }
   }, [loading, variableRef.queue]);

   /**
    * Listens for the options or variableProperty to change. If they change the queue is updated with any new entries.
    * No entries in the queue are removed. So updating the options will only add more queries.
    */
   useEffect(() => {
      variableRef.fullList = union(variableRef.fullList, get(options, `variables.${variableProperty}`));
      const newQueue = difference(variableRef.fullList, variableRef.completedList);

      if (newQueue?.length > 0) {
         if (!isEqual(newQueue, variableRef.queue)) {
            variableRef.queue = newQueue
            setIsLoading(true);
            setIsComplete(false);
         }
         // nextQuery();
      }
   }, [options, variableProperty]);

   return {loading: isLoading, error, complete: isComplete};
}
