import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import {useIsConnected} from '@wora/detect-network';
import {Storage} from 'aws-amplify';
import gql from 'graphql-tag';
import localForage from 'localforage';
import defer from 'lodash/defer';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';
import round from 'lodash/round';
import set from 'lodash/set';
import get from 'lodash/get';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import AutocompleteFHG from '../../../components/AutocompleteFHG';
import CheckboxFHG from '../../../components/CheckboxFHG';
import ConvertUnits from '../../../components/ConvertUnits';
import DatePickerFHG from '../../../components/DatePickerFHG';
import KeyboardDatePickerFHG from '../../../components/KeyboardDatePickerFHG';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import {DATE_DB_FORMAT, BUCKET_NAME, MONTH_FORMAT} from '../../../Constants';
import {getMultiplier, getUnit} from '../../../data/KLADataUtils';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useLazyQueryFHG from '../../../fhg/components/data/useLazyQueryFHG';
import useLazyQueryOfflineFHG from '../../../fhg/components/data/useLazyQueryOfflineFHG';
import useMutationFHG, {CREATE_UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import useQueryOfflineFHG from '../../../fhg/components/data/useQueryOfflineFHG';
import Form from '../../../fhg/components/edit/Form';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import ProgressButton from '../../../fhg/components/ProgressButton';
import TypographyFHG from '../../../fhg/components/Typography';
import Typography from '../../../fhg/components/Typography';
import Video from '../../../fhg/components/video/Video';
import {cacheUpdate, cacheDelete} from '../../../fhg/utils/DataUtil';
import useMessage from '../../../fhg/utils/useMessage';
import {toNumber} from '../../../fhg/utils/Utils';
import {Calculate} from '../../../Icons';
import {METER_OPTIONS_QUERY} from '../../admin/client/MeterEditInfo';
import {ALERTS_FOR_USER_QUERY} from '../ClientDashboard';
import {GAUGE_QUERY} from '../WellClient';
import {LAST_METER_READING_BY_RANGE_QUERY, METER_READING_BY_RANGE_QUERY} from '../../../data/QueriesGL';
import {METER_READING_FRAGMENT} from '../../admin/client/FragmentsGL';
import {useIntl} from 'react-intl';
import findIndex from 'lodash/findIndex';

const useStyles = makeStyles(
   (theme) => ({
      paperStyle: {
         height: 'fit-content',
      },
      buttonStyle: {
         marginBottom: '0.35em',
      },
      buttonColorStyle: {
         color: theme.palette.primary.light,
      },
      buttonFrame: {
         marginTop: theme.spacing(2),
      },
      subtitleStyle: {
         marginBottom: theme.spacing(1),
      },
   }),
   {name: 'WellRecordStyles'}
);

const METER_FOR_WELL_FRAGMENT = gql`
   fragment meterInfoForWell on Meter {
      id
      uuid
      acreFeetMultiplier
      acreInchesMultiplier
      allotment {
         allotment
         fileNumbers
      }

      beginningMeter
      date
      gallonsUnitMultiplier
      isDeleted
      digits
      name
      unitTypeId
      useTypeId
      useType {
         id
         name
      }
      wellId
   }
`;
const defaultMeterReading = {
   id: 0,
   beginningMeter: 1,
   isDeleted: false,
   imageData: null,
   note: '',
   wellId: 1,
};

// Get the meters for the well.
export const METERS_FOR_WELL_QUERY = gql`
   query getMetersForWell($wellId: [Int], $beginActiveDate: String, $endActiveDate: String) {
      meters: meter_AllWhere(
         meterSearch: {wellId: $wellId, beginActiveDate: $beginActiveDate, endActiveDate: $endActiveDate}
      ) {
         ...meterInfoForWell
      }
   }
   ${METER_FOR_WELL_FRAGMENT}
`;

// Create or Update the meterReading with the given properties.
const METER_READING_CREATE_UPDATE = {
   mutation: gql`
      mutation MeterReadingCreateUpdate(
         $date: String
         $endMeterReading: Float
         $finalReading: Float
         $imageData: ImageS3Data
         $meterId: Int
         $replacedDate: String
         $estimatedUse: Float
         $uuid: String!
         $parentItemId: Int
         $note: String
      ) {
         meterReading: meterReading_CreateUpdate(
            meterReading: {
               uuid: $uuid
               date: $date
               endMeterReading: $endMeterReading
               finalReading: $finalReading
               imageS3Data: $imageData
               meterId: $meterId
               replacedDate: $replacedDate
               estimatedUse: $estimatedUse
               wellId: $parentItemId
               note: $note
            }
         ) {
            ...meterReadingInfo
         }
      }
      ${METER_READING_FRAGMENT}
   `,
   typeKey: 'meterReading.type',
   actionKey: CREATE_UPDATE_ACTION,
};

// Delete the waste export on the server.
const METER_READING_DELETE = {
   mutation: gql`
      mutation MeterReadingDelete($id: Int!) {
         meterReading_Delete(meterReadingId: $id)
      }
   `,
   typeKey: 'meterReading.type',
   actionKey: DELETE_ACTION,
};

WellRecord.propTypes = {
   parentItem: PropTypes.object.isRequired, // The default well item.
   onClose: PropTypes.func, // Callback when the panel is closed.
};

// Query for the most recent headcount record.
export const METER_READING_RECENT_QUERY = gql`
   query getMeterReadingRecentDate($meterId: Int!) {
      meterReadingDate: meterReading_MostRecentDate(meterId: $meterId)
   }
`;

/**
 * Component to record meter readings.
 *
 * Reviewed: 7/9/2020
 */
export default function WellRecord({parentItem = {}, onClose}) {
   const {clientId} = useParams();
   const classes = useStyles();
   const intl = useIntl();
   const meterReadingType = useMessage('meterReading.type');
   const ref = useRef();
   const [useCamera, setUseCamera] = useState(false);
   const [photoUri, setPhotoUri] = useState();
   const [showNote, setShowNote] = useState(false);
   const isConnected = useIsConnected();
   const [image, setImage] = useState();

   const [loadMeterReadingRange, {data: meterReadingRangeData}] = useLazyQueryOfflineFHG(
      METER_READING_BY_RANGE_QUERY,
      undefined,
      'meterReading.type'
   );

   const [loadLastMeterReadingRange, {data: meterLastReadingRangeData}] = useLazyQueryOfflineFHG(
      LAST_METER_READING_BY_RANGE_QUERY,
      {fetchPolicy: 'cache-and-network'},
      'meterReading.type'
   );

   const [monthRange, setMonthRange] = useState({
      beginDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
   });

   const variables = {
      wellId: parentItem && parentItem.id,
      beginActiveDate: monthRange.beginDate.format(DATE_DB_FORMAT),
      endActiveDate: monthRange.endDate.format(DATE_DB_FORMAT),
   };
   const {data} = useQueryOfflineFHG(METERS_FOR_WELL_QUERY, {variables, skip: !variables.wellId}, 'well.type');
   const {data: meterOptionsData} = useQueryOfflineFHG(METER_OPTIONS_QUERY, undefined, 'meter.type');

   const [meterReadingCreateUpdate, {loading}] = useMutationFHG(METER_READING_CREATE_UPDATE);
   const [meterReadingDelete, {loading: deleteLoading}] = useMutationFHG(METER_READING_DELETE);

   const [
      editValues,
      handleChange,
      {getValue, setEditValues, isChanged, defaultValues, setDefaultValues, setIsChanged},
   ] = useEditData({uuid: uuid(), parentItemId: get(parentItem, 'id')}, ['id', 'uuid', 'parentItemId']);

   const [entryDays, setEntryDays] = useState([]);
   const [lastReading, setLastReading] = useState(0);

   const [daySelected, setDaySelected] = useState(moment());
   const [meterSelected, setMeterSelected] = useState();

   const [isPickerOpen, setIsPickerOpen] = useState(false);
   const [isSaving, setIsSaving] = useState(false);
   const [isError, setIsError] = useState(false);

   const [useTypes, setUseTypes] = useState([]);
   const [meters, setMeters] = useState([]);
   const [showConvert, setShowConvert] = useState(false);

   const autosetRef = useRef({dateSet: false}).current;
   const [loadRecentMeterReading, {data: recentMeterReadingData}] = useLazyQueryFHG(
      METER_READING_RECENT_QUERY,
      undefined,
      'meterReading.type'
   );

   /**
    * Update the selected date to the next month that needs a meter reading record.
    */
   useEffect(() => {
      const currentMonthBegin = moment().startOf('month');
      let nextReadingDate;

      if (isConnected !== false || recentMeterReadingData) {
         if (recentMeterReadingData && meterSelected && !autosetRef.dateSet && !autosetRef[meterSelected.id]) {
            autosetRef[meterSelected.id] = true;
            // Move to the next month after the last recorded meter reading.
            if (recentMeterReadingData.meterReadingDate) {
               nextReadingDate = moment(recentMeterReadingData.meterReadingDate).startOf('month').add(1, 'month');
            } else if (moment(meterSelected.date).isSameOrAfter(moment(currentMonthBegin).subtract(3, 'month'))) {
               // If no recordings go to the start date unless it is more than 3 months old.
               nextReadingDate = moment(meterSelected.date).startOf('month');
            } else {
               nextReadingDate = moment().startOf('month');
            }

            // If the next reading is before the current month, select the next date that needs a record.
            if (nextReadingDate.isBefore(currentMonthBegin)) {
               handleDateChange(undefined, nextReadingDate);
            } else {
               handleDateChange(undefined, currentMonthBegin);
            }
         }
      } else if (meterSelected && !autosetRef.dateSet && !autosetRef[meterSelected.id]) {
         autosetRef[meterSelected.id] = true;
         handleDateChange(undefined, currentMonthBegin);
      }
   }, [recentMeterReadingData, isConnected]);

   /**
    * Set the meter options when loaded.
    */
   useEffect(() => {
      if (!!meterOptionsData && useTypes.length <= 0) {
         setUseTypes(meterOptionsData.useTypes);
      }
   }, [meterOptionsData, useTypes.length]);

   /**
    * Get the name of the meter and add the use type for better user information.
    * @param meter The meter to get the name.
    * @param index The index of the meter. Used if the meter doesn't have a name.
    * @return {{name: (*|string)}} The meter name to display to the user.
    */
   const getMeterName = (meter, index) => {
      let name = meter.name || `Meter ${index + 1}`;
      if (meter.useTypeId) {
         name = `${name} (${get(meter, 'useType.name', 'N/A')})`;
      }
      return {...meter, name};
   };

   /**
    * Set the meters and calculate the names from the fetched data.
    */
   useEffect(() => {
      let selectedIndex = 0;

      if (!!data) {
         const meters = get(data, 'meters') || [];
         setMeters(meters.map(getMeterName));

         if (meterSelected) {
            if (meters?.length > 0) {
               const currentSelectedIndex = findIndex(meters, {id: meterSelected.id});

               if (currentSelectedIndex >= 0) {
                  selectedIndex = currentSelectedIndex;
               }
               setMeterSelected(meters[selectedIndex]);
            } else {
               setMeterSelected(undefined);
            }
         } else {
            handleMeterChange(undefined, meters[selectedIndex]);
         }
      }
   }, [data]);

   /**
    * Set the last meter reading from the fetched data.
    */
   useEffect(() => {
      if (!!meterLastReadingRangeData) {
         const lastMeterReading = get(meterLastReadingRangeData, 'meterReading[0].endMeterReading');
         if (lastMeterReading !== undefined) {
            setLastReading(lastMeterReading || 0);
         } else if (meterSelected && meterSelected.date) {
            const itemDate = moment(meterSelected.date, DATE_DB_FORMAT);
            if (itemDate.isSame(daySelected, 'month')) {
               setLastReading(meterSelected.beginningMeter);
            } else {
               setLastReading(0);
            }
         }
      } else {
         setLastReading(0);
      }
   }, [meterLastReadingRangeData, daySelected, meterSelected]);

   /**
    * When the component has been displayed, scroll it into view.
    */
   useEffect(() => {
      if (ref.current) {
         defer(() => {
            if (ref.current) {
               if (ref.current.scrollIntoViewIfNeeded) {
                  ref.current.scrollIntoViewIfNeeded();
               } else {
                  ref.current.scrollIntoView(false);
               }
            }
         });
      }
      // eslint-disable-next-line
   }, [ref, parentItem]);

   /**
    * When the month range from the date or the selected meter changes, fetch the last meter reading.
    */
   useEffect(() => {
      if (monthRange && meterSelected) {
         const beginDate = moment(monthRange.beginDate).subtract(1, 'month');
         const variables = {
            wellId: parentItem && parentItem.id,
            meterId: meterSelected.id,
            beginDate: beginDate.format(DATE_DB_FORMAT),
            endDate: moment(beginDate).endOf('month').format(DATE_DB_FORMAT),
         };
         loadLastMeterReadingRange({variables});
      }
   }, [meterSelected, parentItem, monthRange, loadLastMeterReadingRange]);

   /**
    * Set the entry days to mark the calendar and filter the data to the one to display.
    * @type {function(*=, *=, *=): void}
    */
   const setInitialValues = useCallback(
      (isSetEntryDays = false, date, meter) => {
         const meterReading = get(meterReadingRangeData, 'meterReading') || [];

         if (meterReading.length > 0) {
            if (isSetEntryDays) {
               setEntryDays(meterReading.map((item) => item.date));
            }

            const beginMonth = moment(date).startOf('month');
            const endMonth = moment(date).endOf('month');
            const filteredDays =
               filter(meterReading, (item) => {
                  const itemDate = moment(item.date, DATE_DB_FORMAT);
                  return (
                     meter && item.meterId === meter.id && itemDate.isBetween(beginMonth, endMonth, undefined, '[]')
                  );
               }) || [];
            if (filteredDays.length > 0) {
               setDefaultValues({
                  ...filteredDays[0],
                  isMeterReplaced: !!(filteredDays[0].replacedDate || filteredDays[0].finalReading),
                  isEstimatedUse: !!filteredDays[0].estimatedUse,
               });
               setShowNote(!!get(filteredDays, '[0].note'));
            } else {
               setDefaultValues({uuid: uuid(), parentItemId: get(parentItem, 'id')});
               setShowNote(false);
            }
         } else {
            if (isSetEntryDays) {
               setEntryDays([]);
            }
            setDefaultValues({uuid: uuid(), parentItemId: get(parentItem, 'id')});
            setShowNote(false);
         }
      },
      [meterReadingRangeData, parentItem, setDefaultValues]
   );

   /**
    * When meter data is finished loading, set the calendar and the current meter.
    */
   useEffect(() => {
      if (meterReadingRangeData) {
         setInitialValues(true, daySelected, meterSelected);
      }
      //Don't update for on daySelected changes.
      // eslint-disable-next-line
   }, [setInitialValues, meterReadingRangeData, meterSelected]);

   /**
    * Queries to refetch when a new meter reading is added or deleted to refresh the cache.
    * @return List of queries.
    */
   const getCacheQueries = () => {
      const variables = {
         wellId: parentItem && parentItem.id,
         beginDate: monthRange.beginDate.format(DATE_DB_FORMAT),
         endDate: monthRange.endDate.format(DATE_DB_FORMAT),
      };
      const lastVariables = {
         ...variables,
         wellId: parentItem && parentItem.id,
      };
      return [
         {query: METER_READING_BY_RANGE_QUERY, variables},
         {query: LAST_METER_READING_BY_RANGE_QUERY, variables: lastVariables},
      ];
   };

   /**
    * Queries to refetch when a new meter reading is added or deleted to refresh the cache.
    * @return List of queries.
    */
   const getRefetchQueries = () => {
      return [
         {
            query: GAUGE_QUERY,
            variables: {wellId: parentItem && parentItem.id},
            skip: !parentItem,
         },
         {query: ALERTS_FOR_USER_QUERY, variables: {clientId: toNumber(clientId)}},
      ];
   };

   /**
    * Submit the meter reading.
    * @return {Promise<void>}
    */
   const handleSubmit = async () => {
      let useImageData = {};

      if (isChanged) {
         try {
            setIsSaving(true);
            const imageData = photoUri ? await handleCameraSubmit() : photoUri;

            const meterId = meterSelected.id;
            let replacedDate;
            let estimatedUse;

            if (
               editValues.isEstimatedUse ||
               (editValues.isEstimatedUse === undefined && defaultValues.isEstimatedUse)
            ) {
               estimatedUse = editValues.estimatedUse || defaultValues.estimatedUse;
            } else {
               estimatedUse = null;
            }
            if (
               editValues.isMeterReplaced ||
               (editValues.isMeterReplaced === undefined && defaultValues.isMeterReplaced)
            ) {
               replacedDate =
                  editValues.replacedDate || defaultValues.replacedDate || daySelected.format(DATE_DB_FORMAT);
            } else {
               replacedDate = null;
            }
            const variables = {
               ...editValues,
               replacedDate,
               estimatedUse,
               meterId,
               date: daySelected.format(DATE_DB_FORMAT),
            };

            if (imageData) {
               variables.imageData = imageData;
               const imageFilename = imageData.imageLocation.substring((BUCKET_NAME + '/upload/').length);
               //Set the values for the optimistic response. The values sent to the server for imageData is different
               // than those coming back.
               useImageData = {
                  imageData: {
                     imageS3: imageData.imageLocation,
                     __typename: 'ImageData',
                     id: `meterReading/${variables.uuid}-${imageFilename}`,

                     uuid: uuid(),
                  },
               };
            }
            await meterReadingCreateUpdate({
               variables,
               optimisticResponse: {
                  __typename: 'Mutation',
                  refetchQueries: getRefetchQueries,
                  meterReading: {
                     __typename: 'MeterReading',
                     ...defaultMeterReading,
                     ...defaultValues,
                     ...variables,
                     ...useImageData,
                  },
               },
               update: cacheUpdate(getCacheQueries(), editValues?.uuid, 'meterReading'),
               refetchQueries: getRefetchQueries,
            });
            setIsChanged(false);
            setIsSaving(false);
            onClose && onClose();
         } catch (e) {
            setIsSaving(false);
         }
      } else {
         onClose && onClose();
      }
   };

   /**
    * On the select meter event, set the selected meter.
    * @param event The event for selecting a meter.
    * @param value The value of the selection.
    */
   const handleMeterChange = (event, value) => {
      setMeterSelected(value);

      if (!autosetRef.dateSet && !autosetRef[value?.id]) {
         loadRecentMeterReading({variables: {meterId: value?.id}, fetchPolicy: 'network-only'});
      }
   };

   /**
    * When the date is changed, set the selected day, and load the current month.
    * @param event the date change event.
    * @param date The new date.
    * @param reason The reason for the change event. Expects 'date-picker' if the event came from the component.
    * @return {*} the promise for when the meter data is loaded.
    */
   const handleDateChange = (event, date, reason) => {
      if (date && date.isValid && date.isValid()) {
         setDaySelected(date);
         if (reason === 'date-picker') {
            autosetRef.dateSet = true;
         }
         const beginDate = moment(date).startOf('month');
         setIsError(beginDate.isAfter(new Date()));
         const endDate = moment(date).endOf('month');
         if (meterSelected && moment(meterSelected?.date).isAfter(endDate)) {
            setMeterSelected(undefined);
         }
         setMonthRange({beginDate, endDate});
         return loadMeterReadingRange({
            variables: {
               wellId: parentItem && parentItem.id,
               beginDate: beginDate.format(DATE_DB_FORMAT),
               endDate: endDate.format(DATE_DB_FORMAT),
            },
         });
      }
   };

   const handleReplacedChange = (event, date, reason) => {
      if (date && date.isValid && date.isValid()) {
         setIsError(date.isAfter(new Date()));
         handleChange(event, date, reason);
      }
   };

   /**
    * Delete the meter reading.
    * @return {Promise<void>}
    */
   const handleDelete = async () => {
      const storedImage = get(defaultValues, 'imageData.imageS3');
      if (storedImage?.indexOf(BUCKET_NAME) === 0) {
         const imageKey = storedImage.substring(BUCKET_NAME.length + 1);
         await localForage.removeItem(imageKey);
      }
      handleDeletePhoto();

      await meterReadingDelete({
         variables: {id: defaultValues.id},
         optimisticResponse: {meterReading_Delete: 1, refetchQueries: getRefetchQueries},
         update: cacheDelete(getCacheQueries(), defaultValues.uuid, 'meterReading'),
         refetchQueries: getRefetchQueries,
      });
      setDefaultValues({uuid: uuid(), parentItemId: get(parentItem, 'id')});
   };

   /**
    * When a photo is taken, save the new photo.
    * @param photoUri The URI of the new photo.
    */
   const handleTakePhoto = (photoUri) => {
      setPhotoUri(photoUri);
      setIsChanged(true);
   };

   /**
    * When submitting, put the photo in S3 storage and return the location to be added to the meterReading. If
    * offline add to the localForage.
    *
    * @return {Promise<undefined|{imageLocation: string}>}
    */
   const handleCameraSubmit = async () => {
      if (photoUri) {
         const imagePath = `upload/Photo_${Date.now()}.jpg`;

         if (isConnected) {
            await Storage.put(imagePath, photoUri.blob, {
               level: 'public',
               contentType: get(photoUri.blob, 'blob.type', 'image/jpeg'),
            });
         } else {
            await localForage.setItem(imagePath, photoUri);
         }
         return {imageLocation: `${BUCKET_NAME}/${imagePath}`};
      } else {
         return undefined;
      }
   };

   /**
    * When the photo is deleted, remove the photo URI and show the take photo button.
    */
   const handleDeletePhoto = () => {
      setPhotoUri(null);
      setUseCamera(false);
      set(defaultValues, 'imageData.imageS3', null);

      setIsChanged(true);
      setImage(undefined);
   };

   /**
    * Handle the camera error. Display the camera button on error. Video displays the message.
    */
   const handleCameraError = () => {
      setUseCamera(false);
   };

   /**
    * Callback when user clicks Zero Pumped button. Copy the last meter reading.
    * @param event The button clicked event.
    */
   const handleZeroPumped = (event) => {
      event.stopPropagation();
      event.preventDefault();

      setEditValues({...editValues, endMeterReading: lastReading});
      setIsChanged(true);
   };

   const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setShowConvert(event.currentTarget);
   };

   const handleEnterGallons = (gallons) => {
      setEditValues({...editValues, estimatedUse: round(gallons, 1)});
      setIsChanged(true);
      setShowConvert(null);
   };

   useEffect(() => {
      const getImage = async () => {
         let image = get(defaultValues, 'imageData.imageS3');
         if (image) {
            if (image?.indexOf(BUCKET_NAME) === 0) {
               const imageKey = image.substring(BUCKET_NAME.length + 1);
               const image2 = await localForage.getItem(imageKey);
               // The cached meter reading imageData may still point to offline storage but not exist because it has
               // been uploaded. This will cause the missing image graphic to display. If online it will be replaced
               // with the actual photo soon and if not it will show the offline message.
               setImage(image2 ? image2?.src : 'http://' + imageKey);
               setUseCamera(true);
            } else {
               setImage(image);
               setUseCamera(true);
            }
         } else {
            setImage(undefined);
            setUseCamera(false);
         }
      };
      if (!photoUri) {
         getImage();
      }
   }, [defaultValues]);

   /**
    * Convert using the conversion factor for the unit.
    * @param readingToConvert The reading to convert.
    * @return {*|number} The converted reading. Rounded to 0 precision for gallons.
    */
   const convertDisplay = (readingToConvert) => {
      let reading;
      const multiplier = getMultiplier(intl, meterSelected);
      const unit = getUnit(intl, meterSelected);

      if (meterSelected && !meterSelected.gallonsUnitMultiplier) {
         reading = readingToConvert * (meterSelected.acreFeetMultiplier || meterSelected.acreInchesMultiplier);
      } else {
         reading = round(readingToConvert, 0);
      }
      return {reading, unit, multiplier};
   };

   const digitLimit = (inputObj) => {
      const {value} = inputObj;
      const {digits = 6} = meterSelected;

      if (value.length <= +digits) {
         return inputObj;
      }
   };

   return (
      <TitleCardInner
         titleId={'wellRecord.title'}
         variant={'subtitle1'}
         onClose={isPickerOpen ? undefined : onClose}
         classes={{paperStyle: classes.paperStyle}}
         headerAction={
            <ConfirmButton
               onConfirm={handleDelete}
               style={{display: !defaultValues || !defaultValues.id ? 'none' : undefined}}
               titleKey={'delete.confirm.title'}
               messageKey={'delete.confirm.message'}
               titleValues={{type: meterReadingType}}
               values={{type: meterReadingType}}
               color='secondary'
               buttonLabelKey={'delete.button'}
               disableRipple
               disabled={!defaultValues || !defaultValues.id}
               className={classes.buttonStyle}
               isProgress={deleteLoading}
               buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
            />
         }
      >
         {!!showConvert && (
            <ConvertUnits
               anchorEl={showConvert}
               defaultValue={editValues.estimatedUse || defaultValues.estimatedUse}
               onClose={() => {
                  setShowConvert(undefined);
               }}
               onSubmit={handleEnterGallons}
            />
         )}
         <Grid ref={ref} container overflow={'visible'}>
            <TypographyFHG
               className={classes.subtitleStyle}
               hasBold
               variant={'subtitle1'}
               id={'wellRecord.lastMonthReading.label'}
               values={convertDisplay(lastReading)}
            />
            <Form onSubmit={meterSelected && handleSubmit}>
               <Grid
                  item
                  container
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  style={{padding: 0, height: 'fit-content'}}
                  overflow={'visible'}
               >
                  <Grid item xs={6} md={12} lg={6}>
                     <Hidden mdUp>
                        <DatePickerFHG
                           name={'date'}
                           views={['month']}
                           format={MONTH_FORMAT}
                           labelKey={'field.date.label'}
                           value={daySelected}
                           disabled={isSaving}
                           onChange={handleDateChange}
                           onOpen={() => setIsPickerOpen(true)}
                           onClose={() => setIsPickerOpen(false)}
                           renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                              const isSelected = indexOf(entryDays, day.format(DATE_DB_FORMAT)) >= 0;
                              return (
                                 <Badge invisible={!isSelected} variant={'dot'} overlap={'circle'} color={'secondary'}>
                                    {dayComponent}
                                 </Badge>
                              );
                           }}
                           required
                        />
                     </Hidden>
                     <Hidden smDown>
                        <KeyboardDatePickerFHG
                           name={'date'}
                           views={['month']}
                           format={MONTH_FORMAT}
                           labelKey={'field.date.label'}
                           value={daySelected}
                           disabled={isSaving}
                           onChange={handleDateChange}
                           onOpen={() => setIsPickerOpen(true)}
                           onClose={() => setIsPickerOpen(false)}
                           required
                        />
                     </Hidden>
                  </Grid>
                  <Grid item xs={6} md={12} lg={6} style={{position: 'relative', alignSelf: 'flex-start'}}>
                     <AutocompleteFHG
                        key={defaultValues.meterId}
                        name={'meterId'}
                        autoFocus
                        options={meters}
                        labelKey={'meters.label'}
                        defaultValue={defaultValues.meterId || get(meters, '[0].id')}
                        value={meterSelected && meterSelected.id}
                        onChange={handleMeterChange}
                        disableClearable
                        disabled={isSaving}
                        helperText={meters?.length <= 0 ? 'No meters exist for this well.' : undefined}
                        required
                     />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6}>
                     <TextFieldFHG
                        name={'endMeterReading'}
                        autoFocus
                        isFormattedNumber={meterSelected?.gallonsUnitMultiplier}
                        inputProps={{
                           'data-type': 'number',
                           title: `Enter ${meterSelected?.digits || 6} digits`,
                           allowNegative: false,
                           allowLeadingZeros: true,
                           decimalScale: 0,
                           isAllowed: digitLimit,
                        }}
                        type={'text'}
                        labelKey={'wellRecord.endMeterReading.label'}
                        defaultValue={defaultValues.endMeterReading}
                        value={editValues.endMeterReading}
                        helperText={getValue('isMeterReplaced') ? 'Reading of replaced meter.' : undefined}
                        onChange={handleChange}
                        disabled={isSaving || meters?.length <= 0}
                        required={!photoUri}
                     />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} className={classes.buttonFrame}>
                     <Button
                        onClick={handleZeroPumped}
                        className={classes.buttonColorStyle}
                        disabled={!meterLastReadingRangeData}
                     >
                        <TypographyFHG
                           variant='button'
                           color='inherit'
                           style={{textDecoration: 'underline'}}
                           id={'wellRecord.zeroPumped.button'}
                        />
                     </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} overflow={'visible'}>
                     <CheckboxFHG
                        key={'isMeterReplaced' + defaultValues.uuid}
                        name={'isMeterReplaced'}
                        onChange={handleChange}
                        color={'default'}
                        labelKey={'wellRecord.isMeterReplaced.label'}
                        value={'isMeterReplaced'}
                        defaultChecked={defaultValues.isMeterReplaced}
                        checked={editValues.isMeterReplaced}
                        disabled={isSaving || meters?.length <= 0}
                     />
                  </Grid>
                  {(editValues.isMeterReplaced ||
                     (editValues.isMeterReplaced === undefined && defaultValues.isMeterReplaced)) && (
                     <>
                        <Grid item xs={6} sm={12} md={6} style={{alignSelf: 'flex-start'}}>
                           <Hidden mdUp>
                              <DatePickerFHG
                                 name={'replacedDate'}
                                 labelKey={'wellRecord.meterReplacedDate.label'}
                                 defaultValue={defaultValues.replacedDate || daySelected}
                                 minDate={monthRange.beginDate}
                                 maxDate={monthRange.endDate}
                                 value={editValues.replacedDate}
                                 onChange={handleReplacedChange}
                                 disabled={isSaving || meters?.length <= 0}
                                 required
                              />
                           </Hidden>
                           <Hidden smDown>
                              <KeyboardDatePickerFHG
                                 name={'replacedDate'}
                                 labelKey={'wellRecord.meterReplacedDate.label'}
                                 defaultValue={defaultValues.replacedDate || daySelected}
                                 minDate={monthRange.beginDate}
                                 maxDate={monthRange.endDate}
                                 value={editValues.replacedDate}
                                 onChange={handleReplacedChange}
                                 disabled={isSaving || meters?.length <= 0}
                                 required
                              />
                           </Hidden>
                        </Grid>
                     </>
                  )}
                  <Grid item xs={12} overflow={'visible'}>
                     <CheckboxFHG
                        key={'isEstimatedUse' + defaultValues.uuid}
                        name={'isEstimatedUse'}
                        onChange={handleChange}
                        color={'default'}
                        labelKey={'wellRecord.isEstimatedUse.label'}
                        value={'isEstimatedUse'}
                        defaultChecked={defaultValues.isEstimatedUse}
                        checked={editValues.isEstimatedUse}
                        disabled={isSaving || meters?.length <= 0}
                     />
                  </Grid>
                  {(editValues.isEstimatedUse ||
                     (editValues.isEstimatedUse === undefined && defaultValues.isEstimatedUse)) && (
                     <Grid item xs={12}>
                        <TextFieldFHG
                           name={'estimatedUse'}
                           isFormattedNumber
                           autoFocus
                           labelKey={'wellRecord.estimatedUse.label'}
                           defaultValue={defaultValues.estimatedUse}
                           value={editValues.estimatedUse}
                           onChange={handleChange}
                           disabled={isSaving}
                           required
                           InputProps={{
                              'aria-label': 'calculator',
                              endAdornment: (
                                 <InputAdornment position='end'>
                                    <Tooltip title={'Convert other units to gallons'}>
                                       <IconButton onMouseDown={handleClick} size={'small'}>
                                          <Calculate />
                                       </IconButton>
                                    </Tooltip>
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </Grid>
                  )}
                  <Grid item container direction={'row'} spacing={1}>
                     {!showNote && !(editValues.isEstimatedUse || defaultValues.isEstimatedUse) ? (
                        <Grid item xs={6} sm={12} md={6}>
                           <Button
                              color='primary'
                              size={'small'}
                              onClick={() => {
                                 setShowNote(true);
                              }}
                              disabled={isSaving || meters?.length <= 0}
                           >
                              <Typography variant={'inherit'} id={'pond.addNote.button'} />
                           </Button>
                        </Grid>
                     ) : (
                        <Grid item fullWidth>
                           <TextFieldFHG
                              name={'note'}
                              labelKey={'pond.note.label'}
                              autoFocus={!(editValues.isEstimatedUse || defaultValues.isEstimatedUse)}
                              defaultValue={defaultValues.note}
                              value={editValues.note}
                              onChange={handleChange}
                              rows={2}
                              rowsMax={4}
                              fullWidth
                              multiline
                              required={editValues.isEstimatedUse || defaultValues.isEstimatedUse}
                              disabled={isSaving}
                           />
                        </Grid>
                     )}
                     {!useCamera && (
                        <Grid item xs={6} sm={12} md={6}>
                           <Button
                              color='primary'
                              size={'small'}
                              onClick={() => {
                                 setUseCamera(true);
                              }}
                              disabled={isSaving || meters?.length <= 0}
                           >
                              <AddAPhoto />
                              <Typography
                                 className={classes.addPhotoStyle}
                                 variant={'inherit'}
                                 id={'pond.addPhoto.button'}
                              />
                           </Button>
                        </Grid>
                     )}
                     {useCamera && (
                        <Video
                           image={image}
                           onTakePhoto={handleTakePhoto}
                           onDeletePhoto={handleDeletePhoto}
                           onError={handleCameraError}
                           isConnected={isConnected}
                        />
                     )}
                  </Grid>
                  <Grid item fullWidth>
                     <ProgressButton
                        isProgress={loading}
                        variant='contained'
                        color='primary'
                        type={'submit'}
                        size={'small'}
                        labelKey={'save.label'}
                        disabled={isSaving || isError}
                     />
                  </Grid>
               </Grid>
            </Form>
         </Grid>
      </TitleCardInner>
   );
}
