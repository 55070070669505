import {formatMessage} from '../fhg/utils/Utils';

export function getUnit(intl, meter) {
   if (meter?.acreFeetMultiplier) {
      return formatMessage(intl, 'facility.acreFeet.label');
   } else if (meter?.acreInchesMultiplier) {
      return formatMessage(intl, 'facility.acreInches.label');
   } else {
      return formatMessage(intl, 'facility.gallons.label');
   }
}

export function getMultiplier(intl, meter) {
   if (meter) {
      const multiplier = meter.gallonsUnitMultiplier || meter.acreFeetMultiplier || meter.acreInchesMultiplier;
      return 'x ' + multiplier;
   }
   return '';
}
