import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Delete from '@material-ui/icons/Delete';
import {dataURLToBlob} from 'blob-util/';
import debounce from 'lodash/debounce';
import delay from 'lodash/delay';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';
import {PLACEHOLDER_IMAGE} from '../../../Constants';
import {convertImageToWrapper} from '../../utils/Utils';
import Grid from '../Grid';
import StatusContext from '../StatusContext';
import Typography from '../Typography';
import {imageCaptureInitialize} from './ImageCapture';
import './Video.css';

const styles = {
   photo: {
      backgroundColor: '#FFF',
      position: 'relative',
      top: 0,
      left: 0,
      objectFit: 'contain',
      width: '100%',
      // height: '100%',
      // paddingBottom: '75%',
      maxWidth: 640,
      maxHeight: 480,
   },
   video: {
      width: '100%',
      height: '100%',
   },
   progress: {
      position: 'relative',
      zIndex: 1001,
      display: 'table',
      transform: 'translateY(-50%) translateX(-50%)',
      top: '50%',
      left: '50%',
   },
   cameraStyle: {
      height: '100%',
      width: '100%',
      '& .react-html5-camera-photo ': {
         height: '100%',
         width: '100%',
      },
      '& .react-html5-camera-photo>video': {
         height: '100%',
         width: '100%',
         maxHeight: 640,
         maxWidth: 640,
         objectFit: 'contain',
      },
   },
   saveProgressStyle: {
      marginLeft: 'auto',
      position: 'relative',
      marginRight: 'auto',
   },
   frameStyle: {
      position: 'relative',
      flex: '0 0 auto',
      height: 'calc(100% - 47px)',
      width: '100% !important',
      margin: 'auto',
      flexDirection: 'column',
      maxWidth: 640,
      maxHeight: 640,
      display: 'flex',
      '& > div': {
         flex: '0 0 auto',
         overflow: 'hidden',
      },
   },
   buttonStyle: {
      position: 'absolute',
      right: 0,
      backgroundColor: 'rgba(255,255,255, 0.4)',
   },
};

imageCaptureInitialize();

class Video extends PureComponent {
   static contextType = StatusContext;

   static propTypes = {
      placeHolderImage: PropTypes.string,
      image: PropTypes.string,
      direction: PropTypes.string,
      onTakePhoto: PropTypes.func.isRequired,
      onDeletePhoto: PropTypes.func,
      onError: PropTypes.func,
      isConnected: PropTypes.bool,
   };

   static defaultProps = {
      placeHolderImage: PLACEHOLDER_IMAGE,
      direction: 'column',
      isConnected: true,
   };

   constructor(props) {
      super(props);

      this.state = {
         stream: undefined,
         refresh: Date.now(),
         image: convertImageToWrapper(props.image),
      };
   }

   componentDidMount() {
      this.handleResize();
      window.addEventListener('resize', this.handleDebounceResize);
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.handleDebounceResize);
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.image !== this.props.image && nextProps.image) {
         this.setState({image: nextProps.image}, () => {
            this.img.src = convertImageToWrapper(nextProps.image);
            setTimeout(() => {
               this.handleResize();
            }, 500);
         });
      }
   }

   saveImage = () => {
      this.setState({isSaving: true});
      // const {image} = this.state;
      this.setState({isSaving: false});
      // resolve({src: image, blob: dataURLToBlob(image)});
   };

   handleTakePhoto = (dataUri) => {
      this.img.src = dataUri;
      this.setState({image: this.img.src}, () => {
         this.handleResize();
         this.props.onTakePhoto({src: dataUri, blob: dataURLToBlob(dataUri)});
      });
   };

   handleResize = () => {
      if (this.mainRef) {
         this.mainNode = ReactDOM.findDOMNode(this.mainRef);
         let height, width, marginHeight, marginWidth;
         const mainNode = this.mainNode;
         const image = get(this, 'img');
         const panelWidth = Math.min(mainNode.parentNode.clientWidth, 640);
         const panelHeight = Math.min(mainNode.parentNode.clientHeight, 640);

         if (image) {
            if (image.naturalHeight === 0 && image.naturalWidth === 0) {
               return;
            }
            // Image is landscape and dialog is landscape
            if (image.naturalWidth >= image.naturalHeight && panelWidth >= panelHeight) {
               const calcHeight = panelWidth * 0.75;

               if (calcHeight > panelHeight) {
                  width = panelHeight / 0.75;
                  height = panelHeight;
               } else {
                  width = panelWidth;
                  height = calcHeight;
               }
               marginHeight = (mainNode.clientHeight - height) / 2;
               marginWidth = (mainNode.clientWidth - width) / 2;

               // Image is portrait and dialog is landscape
            } else if (image.naturalWidth <= image.naturalHeight && panelWidth >= panelHeight) {
               width = panelHeight * 0.75;

               marginWidth = (mainNode.clientWidth - width) / 2;
               marginHeight = 0;

               // Image is landscape and dialog is portrait
            } else if (image.naturalWidth >= image.naturalHeight && panelWidth <= panelHeight) {
               height = panelWidth * 0.75;

               marginWidth = 0;
               marginHeight = (mainNode.clientHeight - height) / 2;

               // Image is portrait and dialog is portrait
            } else {
               marginWidth = 0;
               marginHeight = 0;
            }
         } else if (panelWidth > panelHeight) {
            const calcHeight = panelWidth * 0.75;

            if (calcHeight > panelHeight) {
               width = panelHeight / 0.75;
               marginHeight = 0;
               marginWidth = (panelWidth - width) / 2;
            } else {
               width = panelWidth;
               height = calcHeight;
               marginHeight = (panelHeight - height) / 2;
               marginWidth = (panelWidth - width) / 2;
            }
         } else {
            marginWidth = 0;
            marginHeight = 0;
         }

         this.setState({width: mainNode.clientWidth, height: mainNode.clientHeight, marginWidth, marginHeight});
      }
   };

   /**
    * Handle the window resizing after a debounce.
    */
   handleDebounceResize = debounce(this.handleResize, 250);

   /**
    * Callback when the camera is started. Use cache the media stream to use for zooming.
    *
    * @param mediaStream Media stream for the camera.
    */
   handleCameraStart = mediaStream => {
      // Once crbug.com/711524 is fixed, we won't need to wait anymore. This is
      // currently needed because capabilities can only be retrieved after the
      // device starts streaming. This happens after and asynchronously w.r.t.
      // getUserMedia() returns.
      delay(() => {
         const track = mediaStream.getVideoTracks()[0];
         const capabilities = track.getCapabilities();
         const settings = track.getSettings();

         if (!('zoom' in capabilities)) {
            return;
         }
         const input = document.querySelector('input[type="range"]');
         const element = document.getElementById('camera.zoom.label');
         input.min = capabilities.zoom.min;
         input.max = capabilities.zoom.max;
         input.step = capabilities.zoom.step;
         input.value = settings.zoom;
         input.oninput = function (event) {
            mediaStream.getVideoTracks()[0].applyConstraints({advanced: [{zoom: event.target.value}]});
         };
         input.hidden = false;
         element.hidden = false;
      }, 1000);
   };

   /**
    * Handle the errors from the camera and display the error message. Usually a permission error.
    * @param cameraError The error from the camera.
    */
   handleCameraError = (cameraError) => {
      const {onError} = this.props;
      const {setErrorGeneral} = this.context;

      setErrorGeneral({error: cameraError, errorKey: 'camera.error'});
      onError && onError(cameraError);
   }

   handleDelete = () => {
      const { onDeletePhoto } = this.props;

      this.setState({image: null});
      onDeletePhoto && onDeletePhoto();
   }

   render() {
      const {style, classes, direction, onDeletePhoto, isConnected} = this.props;
      const {refresh, image, isSaving} = this.state;

      return (
         <Grid container style={style} direction={direction}>
            {isSaving && (
               <Grid container alignItems={'center'} justify={'center'} direction={'column'}>
                  <CircularProgress className={classes.saveProgressStyle}/>
               </Grid>
            )}
            <Grid item resizable={true} className={classes.cameraStyle}>
               {!image && (
                  <div name={'Camera'} className={classes.frameStyle}>
                     <Camera
                        onTakePhoto={this.handleTakePhoto}
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        idealResolution={{width: 640, height: 480}}
                        imageType={IMAGE_TYPES.JPG}
                        imageCompression={1}
                        isDisplayStartCameraError={true}
                        isFullscreen={false}
                        isImageMirror={false}
                        onCameraStart={this.handleCameraStart}
                        onCameraError={this.handleCameraError}
                     />
                     <Typography id={'camera.zoom.label'} gutterBottom hidden={true}/>
                     <input type='range' hidden={true}/>
                  </div>
               )}
               <Grid item style={{position: 'relative'}}>
                  {(!isConnected && image?.indexOf('http') >= 0) && <Typography id={'camera.offline.message'} style={{marginLeft:8}}/>}
                  <img key={'key' + refresh} id='image' alt='Camera' className={classes.photo} src={image}
                       style={{display: !image ? 'none' : undefined}}
                       ref={img => this.img = img} onError={(e) => {
                     // eslint-disable-next-line
                     this.props.placeHolderImage ? e.target.src = this.props.placeHolderImage : undefined
                  }}/>
                  {onDeletePhoto && (
                     <IconButton aria-label='Property image' onClick={this.handleDelete}
                                 style={{display: !image ? 'none' : undefined}}
                                 className={classes.buttonStyle}>
                        <Delete/>
                     </IconButton>
                  )}
               </Grid>
            </Grid>
         </Grid>
      );
   }
}

export default withStyles(styles)(Video);
