import useTheme from '@material-ui/core/styles/useTheme';
import {color} from 'd3-color';
import {interpolateRgb} from 'd3-interpolate';
import isNaN from 'lodash/isNaN';
import round from 'lodash/round';
import * as PropTypes from 'prop-types';
import React, {useRef} from 'react';
import LiquidFillGauge from 'react-liquid-gauge';
import Grid from '../../fhg/components/Grid';
import TypographyFHG from '../../fhg/components/Typography';
import useResize from '../../fhg/hooks/useResize';

const Gauge = ({radius = 200, amount = 1, allotment = 1, ...props}) => {
   const theme = useTheme();
   const startColor = 'rgb(23, 139, 202)'; // cornflowerblue
   const endColor = 'rgb(23, 139, 202)'; //'#dc143c'; // crimson
   const interpolate = interpolateRgb(startColor, endColor);
   const fillColor = interpolate(amount / allotment);
   const gradientStops = [
      {
         key: '0%',
         stopColor: color(fillColor).darker(0.5).toString(),
         stopOpacity: 1,
         offset: '0%'
      },
      {
         key: '50%',
         stopColor: fillColor,
         stopOpacity: 0.75,
         offset: '50%'
      },
      {
         key: '100%',
         stopColor: color(fillColor).brighter(0.5).toString(),
         stopOpacity: 0.5,
         offset: '100%'
      }
   ];
   const remaining = allotment - amount;
   const value = allotment === 0 ? 0 : (remaining / allotment) * 100;

   return (
      <LiquidFillGauge
         {...props}
         width={radius * 2}
         height={radius * 2}
         value={value}
         percent='ac-ft'
         label='remaining'
         textSize={0.8}
         textOffsetX={0}
         textOffsetY={0}
         textRenderer={({_, width, height, textSize, percent, label}) => {
            const radius = Math.min(height / 2, width / 2);
            const textPixels = (textSize * radius / 2);
            const valueStyle = {
               fontSize: textPixels,
               fill: theme.palette.text.primary
            };
            const percentStyle = {
               fontSize: textPixels * 0.6,
               fill: theme.palette.text.primary
            };
            const gallonsStyle = {
               fontSize: textPixels * 0.5,
               fill: theme.palette.text.primary
            };
            const labelStyle = {
               fontSize: textPixels * 0.7,
               fill: theme.palette.text.primary
            };

            return (
               <tspan dy='-1.2em'>
                  <tspan className='value' style={valueStyle}>{round(remaining, 1) || 0}</tspan>
                  <tspan style={percentStyle}>{percent}</tspan>
                  <tspan dy='1.2em' x='0' className='value' style={gallonsStyle}>{round(remaining * 325851.43188913, 0).toLocaleString() || 0} gal</tspan>
                  <tspan dy='1.2em' x='0' style={labelStyle}>{label}</tspan>
               </tspan>
            );
         }}
         riseAnimation
         waveAnimation
         waveFrequency={2}
         waveAmplitude={1}
         gradient
         gradientStops={gradientStops}
         circleStyle={{
            fill: fillColor
         }}
         waveStyle={{
            fill: fillColor
         }}
         textStyle={{
            fill: color('#444').toString(),
            fontFamily: 'Arial'
         }}
         waveTextStyle={{
            fill: color('#fff').toString(),
            fontFamily: 'Arial'
         }}
      />
   );
};

AllotmentGauge.propTypes = {
   allotment: PropTypes.object,
};

/**
 * Component to show the gauge for a single allotment.
 *
 * Reviewed:
 *
 * @param allotment The allotment to show the gauge for.
 */
export default function AllotmentGauge({allotment = {}}) {
   const ref = useRef();
   const width = useResize(ref, 0, 250);

   const amountUsed = allotment.acreFeet;
   const allotmentValue = allotment.allotment;
   const amount = round(isNaN(amountUsed) || amountUsed === undefined || amountUsed === null ? 0 : amountUsed, 2) || 0;
   const useAllotment = isNaN(allotmentValue) || allotmentValue === undefined || allotmentValue === null ? 0 :
      allotmentValue;
   const remaining = round(useAllotment - amount, 2) || 0;
   return (
      <Grid name={'allotment gauge frame'} container direction={'column'} style={{padding: 8}}>
         <Grid name={'allotment details'} container item direction={'row'} alignItems={'center'}>
            <Grid item xs={12}>
               <TypographyFHG variant={'subtitle1'} style={{marginRight: 8}} id={'clientWell.fileNumbers.label'} hasBold
                              values={allotment}/>
            </Grid>
            <Grid item xs={12}>
               <TypographyFHG variant={'subtitle1'} style={{marginRight: 8}} id={'clientWell.amount.label'} hasBold
                              values={{amount}}/>
            </Grid>
            <Grid item xs={12}>
               <TypographyFHG variant={'subtitle1'} style={{marginRight: 8}} id={'clientWell.allotment.label'} hasBold
                              values={{allotment: allotmentValue}}/>
            </Grid>
            <Grid item xs={12}>
               <TypographyFHG variant={'subtitle1'} style={{marginRight: 8}} id={'clientWell.remaining.label'} hasBold
                              values={{remaining}}/>
            </Grid>
         </Grid>
         <Grid ref={ref} container item name={'allotmentGauge'}>
            <Gauge
               style={{margin: '0 auto 20px auto'}}
               radius={width < 200 ? width / 2 : 100}
               amount={amount}
               allotment={useAllotment}
            />
         </Grid>
      </Grid>
   );
}
