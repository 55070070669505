// Dates displayed to the user or to match the DB format.
export const DATE_FORMAT = 'M/D/YYYY';
export const DATE_FORMAT_KEYBOARD = 'MM/DD/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const YEAR_FORMAT = 'YYYY';
export const DATE_TIME_FORMAT = 'M/D/YYYY hh:mm a';
export const DATE_DB_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_DB_FORMAT = 'YYYY-MM-DD HH:mm (zzz)';

//zIndex values for the different levels.
export const MIDDLE_INDEX = 2000;
export const TOP_INDEX = 3000;
export const BOTTOM_INDEX = 1000;
export const NORMAL_INDEX = 1;

export const ADMIN_TO_CLIENT = 1;
export const CLIENT_TO_ADMIN = 2;

// The maximum number of inches a user can enter without a warning.
export const MAXIMUM_PRECIPITATION = 10; // in inches

// Bulletins will be polled for every 24 hours when the bulletins screen or client dashboard is left open.
export const POLL_FOR_BULLETINS = 24 * 60 * 60 * 1000;

// Alerts will be polled for every 4 hours.
export const POLL_FOR_ALERTS = 4 * 60 * 60 * 1000;

// Messages will be polled for every 1 hours.
export const MAIL_POLL = 60 * 60 * 1000; // 1 hours.

// All bulletins will show for the last 30 days.
export const BULLETINS_DAYS_BACK = 30; // 2 hours.

export const BUCKET_NAME = 'kla-images';

export const PLACEHOLDER_IMAGE = '/images/placeholder.png';

export const KANSAS_STATE_ID = 16;
export const GALLON_UNIT = 'Gallons';
export const ACRE_INCHES_UNIT = 'AcreInches';
export const ACRE_FEET_UNIT = 'AcreFeet';

export const LOGO_SMALL = '/images/klaenvLogo.png';
export const FIELD_ICON = '/images/field.png';
export const FACILITY_ICON = '/images/facility.png';
export const WASTE_ICON = '/images/icons8-poo-52.png';
export const WELL_ICON = '/images/water5.png';

export const FIELD_GEO_JSON_COLOR = '#AAAAAA';

export const CENTER_MAP_DEFAULT = [38.48583333, -98.68583333];
export const ZOOM_DEFAULT = 8;
export const MIN_ZOOM_DEFAULT = 8;
export const MAX_ZOOM_DEFAULT = 20;
export const ZOOM_WITH_MARKER_DEFAULT = 15;

export const drawerWidth = 240;
export const DRAWER_WIDTH = drawerWidth;

export const ENDPOINT = !process.env.REACT_APP_ENDPOINT
   ? '/api/graphql/'
   : `https://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;
console.log('Endpoint = ', ENDPOINT);
console.log('Version = ', process.env.REACT_APP_VERSION);

export const APPBAR_HEIGHT = 80;
export const APPBAR_SMALL_HEIGHT = 70;

export const NEW_AUTO_STATUS = 'New Auto';
export const NEW_STATUS = 'New';
export const ADD_STATUS = 'Add';
export const EDIT_STATUS = 'Edit';
export const DELETE_STATUS = 'Delete';
export const DISABLED_STATUS = 'Disabled';

export const SUPPORT_PHONE_NUMBER = '785-296-1679';

export const MAX_USERS = 5;

export const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

//Paths
export const DEFAULT_PATH = '/';

// Bounds for the state of Kansas and Oklahoma to ensure markers outside aren't used for auto zooming.
// KANSAS - {minLng: -102.051744, minLat: 36.993016, maxLng: -94.588413, maxLat: 40.003162};
// OKLAHOMA -  {minLng: -103.002565, minLat: 33.615833, maxLng: -94.430662, maxLat: 37.002206};
export const BOUNDING_BOX = {minLng: -103.002565, minLat: 33.615833, maxLng: -94.588413, maxLat: 40.003162};

export const ADMIN_PATH = '/admin';
export const USERS_PATH = '/admin/user';
export const USER_PATH = '/admin/user/:uuid?';
export const SETUP_PATH = '/setup';
export const ADMIN_CLIENT_PATH = '/setup/client/:clientId?';
export const MANAGE_USER_PATH = '/setup/client/:clientId/user/:uuid?';
export const CLIENT_SETUP_PATH = '/setup/client/:clientId/setup';
export const CLIENT_SETUP_ASSET_PATH = '/setup/client/:clientId/item/:uuid';
export const WASTE_EXPORT_REPORT_PATH = '/setup/client/:clientId/report/wasteExport';
export const ANNUAL_REPORT_PATH = '/setup/client/:clientId/report/annual';

export const CLIENT_NEW_PATH = '/setup/client';
export const MESSAGES_TYPE_PATH = '/admin/messages/:type?';
export const MESSAGES_PATH = '/admin/messages';
export const CLIENT_PATH = '/client';
export const CLIENT_DASHBOARD_PATH_OPTION = '/client/:clientId?';
export const CLIENT_DASHBOARD_PATH = '/client/:clientId';
export const CLIENT_ASSET_PATH = '/client/:clientId/item/:uuid';
export const MESSAGES_CLIENT_PATH = '/client/:clientId/messages';
export const FILES_CLIENT_PATH = '/client/:clientId/files';

export const KDHE_REPORT_PATH = '/client/:clientId/report/kdheReport';
export const WATER_USE_REPORT_PATH = '/client/:clientId/report/waterUseReport';
export const BULLETIN_PATH = '/client/:clientId/bulletin';

export const ERROR_COLOR = '#AA0B06';
export const WARNING_COLOR = '#F5CD19';
export const SUCCESS_COLOR = '#5C9E52';

//Fetch URLs
export const COUNTY_PATH = `https://nominatim.openstreetmap.org/search?format=json&q={county} county, Kansas`;
export const FACILITY_LOCATION_PATH = `https://nominatim.openstreetmap.org/search?format=json&city={city}&postalcode={zipCode}&state={state}`;
export const FACILITY_LOCATION_GEOCOD_PATH =
   'https://api.geocod.io/v1.7/geocode?q={addressLineOne}, {city}, {state} {zipCode}&limit=1&api_key=b43994faf1113ab55f11a41faf223221f5ba3a9';
// File upload
export const FILE_MIME_TYPES = [
   'image/jpeg',
   'image/png',
   'image/gif',
   'text/plain',
   'text/csv',
   'application/pdf',
   'application/vnd.ms-excel',
   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
