import {useLazyQuery} from '@apollo/react-hooks';
import {useCallback} from 'react';
import {useRef} from 'react';
import {useContext, useEffect} from 'react';
import StatusContext from '../StatusContext';

export default function useLazyQueryFHG(query, options, typeKey, showLoading) {
   const {setError, setErrorGeneral, setProgress} = useContext(StatusContext);

   const [queryFunction, result] = useLazyQuery(query, {fetchPolicy: 'cache-and-network', ...options});
   const resolveRef = useRef();

   useEffect(() => {
      if ((result.called || result.data) && !result.loading && resolveRef.current) {
         resolveRef.current(result);
         resolveRef.current = undefined;
      }
   }, [result]);

   useEffect(() => {
      return () => {
         if (showLoading) {
            setProgress(false);
         }
      };
   }, [setProgress, showLoading]);

   useEffect(() => {
      if (showLoading) {
         setProgress(result?.loading);
      }
   }, [result?.loading, showLoading, setProgress]);

   useEffect(() => {
      if (result.error && typeKey) {
         console.log('Error type', typeKey);
         console.log(result.error, result.error.stackTrace);
         if (typeKey) {
            setErrorGeneral({typeKey, error: result.error, actionKey: 'load'});
         }
      }
   }, [result.error, setErrorGeneral, setError, typeKey]);

   const localQueryFunction = useCallback(
      async (variables, context) => {
         await queryFunction(variables, context);

         return new Promise((resolve) => {
            resolveRef.current = resolve;
         });
      },
      [queryFunction]
   );

   return [localQueryFunction, result];
}
