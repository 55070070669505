import filter from 'lodash/filter';
import get from 'lodash/get';
import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {CLIENT_DASHBOARD_PATH, DATE_TIME_FORMAT} from '../Constants';
import useMutationFHG from '../fhg/components/data/useMutationFHG';
import useQueryFHG from '../fhg/components/data/useQueryFHG';
import NotificationSnackbar from './NotificationSnackbar';

/**
 * The component to show alerts. The snackbar can be closed, but it will open again when a new alert is given. Alerts
 * must be individually closed to remain closed.
 *
 * @param onClose Callback when the alert snackbar is closed.
 * @param alertQuery The query for the alerts.
 * @param variables The variables for the query for the alerts.
 * @param pollInterval The polling time for running the alert query in milliseconds.
 * @param typeKey The localization key for the alert.
 * @param alertUpdateMutation The update mutation for the alert. The required fields are id, clientId, and dismissed.
 * @return {JSX.Element|null}
 * @constructor
 */
export default function Alerts({onClose, alertQuery, variables, pollInterval, typeKey = 'alert.type', alertUpdateMutation, queryFieldPath = 'alerts'}) {
   let clientPathMatch = useRouteMatch({path: CLIENT_DASHBOARD_PATH, strict: false, sensitive: false});
   const clientId = get(clientPathMatch, 'params.clientId');

   const [showAlerts, setShowAlerts] = useState(true);
   const [alerts, setAlerts] = useState([]);

   const {data} = useQueryFHG(alertQuery, {variables, pollInterval}, typeKey);

   const [alertUpdate] = useMutationFHG(alertUpdateMutation);

   /**
    * When the alert query has new data, show the alerts if there are some alerts to show.
    */
   useEffect(() => {
      if (!!data) {
         const alerts = get(data, queryFieldPath) || [];
         setShowAlerts(alerts.length > 0);
         let filteredAlerts = filter(alerts, {dismissed: false});
         filteredAlerts = filteredAlerts.map(alert => ({severity: 'error', title: `Alert ${moment(alert.createdDateTime).format(DATE_TIME_FORMAT)}`, message: get(alert, 'messageData.text'), ...alert}));
         setAlerts(filteredAlerts);
      }
   }, [data, queryFieldPath]);

   /**
    * Handle closing the alert snackbar.
    */
   const handleClose = () => {
      setShowAlerts(false);
      onClose && onClose();
   };

   /**
    * Handle closing the individual alerts. Once closed they will not be shown again.
    * @param alert The alert to close.
    */
   const handleAlertClose = (alert) => {
      if (alert.__typename === 'Alert') {
         alertUpdate({variables: {uuid: alert.uuid, clientId, dismissed: true}}, false, {query: alertQuery, variables});
      }
   };

   //If there are new alerts to show and the snackbar hasn't been closed show the snackbar.
   if (showAlerts && alerts && alerts.length > 0) {
      return (
         <NotificationSnackbar notifications={alerts} onClose={handleClose} onNotificationClose={handleAlertClose}/>
      )
   } else {
      return null;
   }
}
