import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {v4 as uuid} from 'uuid';
import TextFieldFHG from '../../../components/TextField';
import TitleCardInner from '../../../components/TitleCardInner';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import useMutationFHG, {UPDATE_ACTION, DELETE_ACTION} from '../../../fhg/components/data/useMutationFHG';
import Form from '../../../fhg/components/edit/Form';
import Prompt from '../../../fhg/components/edit/Prompt';
import useEditData from '../../../fhg/components/edit/useEditData';
import Grid from '../../../fhg/components/Grid';
import ProgressButton from '../../../fhg/components/ProgressButton';
import {WASTE_STORAGE_BY_FACILITY_ID_QUERY} from '../../../prototype/ClientSetup';

const useStyles = makeStyles(theme => ({
   facilityFrameStyle: {
      // When the facilities wrap down, unset the height so the main frame scrolls.
      paddingRight: theme.spacing(2),
      // height: 'fit-content',
      [theme.breakpoints.down('xs')]: {
         paddingRight: theme.spacing(0),
         // height: 'unset',
      },
   },
   textFieldStyle: {
      marginBottom: theme.spacing(0.5),
   },
}));

// Update the fields with the given properties.
const WASTE_STORAGE_DELETE = {
   mutation: gql`
      mutation WasteStorageDelete($id: Int!) {
         wasteStorage_Delete(wasteStorageId: $id)
      }
   `,
   typeKey: 'wasteStorage.type',
   actionKey: DELETE_ACTION,
};

// Update the wasteStorage with the given properties.
const WASTE_STORAGE_CREATE_UPDATE = {
   mutation: gql`
      mutation WasteStorageCreateUpdate($uuid: String!, $facilityId: Int, $name: String, $operatingLevel: Float, $dec1OperatingLevel: Float, $latitude: Float, $longitude: Float) {
         wasteStorage: wasteStorage_CreateUpdate(wasteStorage: {uuid:$uuid, facilityId: $facilityId, name: $name, operatingLevel: $operatingLevel, dec1OperatingLevel: $dec1OperatingLevel, latitude: $latitude, longitude: $longitude}) {
            id
            uuid
            name
            operatingLevel
            dec1OperatingLevel
            latitude
            longitude
            facilityId
         }
      }
   `,
   typeKey: 'wasteStorage.type',
   actionKey: UPDATE_ACTION
};

export default function WasteStorageEditCard({wasteStorage = {}, facilityIds=[], onClose}) {
   const classes = useStyles();

   const [wasteStorageCreateUpdate] = useMutationFHG(WASTE_STORAGE_CREATE_UPDATE);
   const [wasteStorageDelete] = useMutationFHG(WASTE_STORAGE_DELETE);

   const [editValues, handleChange, {isChanged, setIsChanged, defaultValues}] = useEditData(
      {uuid: uuid(), ...wasteStorage}, ['id', 'uuid', 'facilityId', 'latitude', 'longitude']);
   const [isSaving, setIsSaving] = useState(false);

   const handleSubmit = async () => {
      if (isChanged) {

         try {
            setIsSaving(true);
            await wasteStorageCreateUpdate({variables: {...editValues}}, false, !wasteStorage.id ? [
               {query: WASTE_STORAGE_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}}
            ] : undefined);
            setIsChanged(false);
            onClose && onClose();
         } finally {
            setIsSaving(false);
         }
      }
   }

   const handleDelete = () => {
      wasteStorageDelete({variables: {id: wasteStorage.id}}, true, [
         {query: WASTE_STORAGE_BY_FACILITY_ID_QUERY, variables: {facilityId: facilityIds}}
      ]);
      onClose && onClose();
   };

   return (
      <TitleCardInner
         titleId={'clientWasteStorage.title'}
         values={{name: defaultValues.name, hasBold: true}}
         onClose={onClose}
         headerAction={(
            <ConfirmButton
               onConfirm={handleDelete}
               titleKey={'delete.confirm.title'}
               messageKey={'delete.confirm.message'}
               titleValues={{type: defaultValues.name}}
               values={{type: defaultValues.name}}
               color='secondary'
               buttonLabelKey={'delete.button'}
               disableRipple
               // className={classes.buttonStyle}
               buttonTypographyProps={{color: 'secondary', style: {textDecoration: 'underline'}}}
            />
         )}
      >
         <Form onSubmit={handleSubmit}>
            <Prompt when={isChanged}/>
            <Grid container spacing={2}>
               <Grid container item fullWidth spacing={2}>
                  <Grid item fullWidth xs={12}>
                     <TextFieldFHG
                        name={'name'}
                        autoFocus
                        className={classes.textFieldStyle}
                        labelKey={'facility.storageName.label'}
                        defaultValue={defaultValues.name}
                        value={editValues.name}
                        onChange={handleChange}
                        disabled={isSaving}
                        fullWidth
                     />
                  </Grid>
                  <Grid item fullWidth xs={6} sm={12} md={6}>
                     <TextFieldFHG
                        name={'operatingLevel'}
                        type={'number'}
                        className={classes.textFieldStyle}
                        labelKey={'facility.operatingLevel.label'}
                        defaultValue={defaultValues.operatingLevel}
                        value={editValues.operatingLevel}
                        onChange={handleChange}
                        disabled={isSaving}
                        fullWidth
                     />
                  </Grid>
                  <Grid item fullWidth xs={6} sm={12} md={6}>
                     <TextFieldFHG
                        name={'dec1OperatingLevel'}
                        type={'number'}
                        className={classes.textFieldStyle}
                        labelKey={'facility.decemberOperatingLevel.label'}
                        defaultValue={defaultValues.dec1OperatingLevel}
                        value={editValues.dec1OperatingLevel}
                        onChange={handleChange}
                        fullWidth
                     />
                  </Grid>
               </Grid>
               <Grid item fullWidth>
                  <ProgressButton isProgress={isSaving} variant='contained' color='primary' type={'submit'}
                                  size={'small'} labelKey={'save.label'} disabled={isSaving}/>
               </Grid>
            </Grid>
         </Form>
      </TitleCardInner>
   );
}
