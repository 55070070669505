import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, Fragment} from 'react';
import {Link} from 'react-router-dom';
import Grid from '../fhg/components/Grid';
import MobileContext from '../fhg/components/MobileContext';
import PageTitle from '../fhg/components/PageTitle';
import Typography from '../fhg/components/Typography';

const useStyles = makeStyles(theme => ({
   root: {
      padding: 16,
   },
   titleStyle: {
      width: '100%',
      color: theme.palette.text.primary
   },
   subtitleStyle: {
      width: '100%',
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
   },
   titleGridStyle: {
      [theme.breakpoints.up('sm')]: {
         width: 'calc(100% - 240px)',
      },
      marginBottom: theme.spacing(3),
   },
   titleGridStyleWithDrawer: {
      [theme.breakpoints.up('md')]: {
         width: 'calc(100% - 240px)',
      },
      marginBottom: theme.spacing(3),
   },
   actionGridStyle: {
      marginTop: theme.spacing(3),
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
   },
}), {name: 'titleCardStyles'});

/**
 * The component used as a placeholder for not implemented components.
 */
export default function TitleCard({classes: outsideClasses = {}, title, titleId, titleUrl, subtitle, subtitleId, values, children, previous, next, allowDrawer, showTitle = true}) {
   const classes = useStyles();
   const {setTitle, setSubtitle, setTitleUrl, clear} = useContext(MobileContext);

   useEffect(() => {
      if (!showTitle && (titleId || title || subtitleId || subtitle || titleUrl)) {
         setTitle(titleId, values, title);
         setSubtitle(subtitleId, undefined, subtitle);
         setTitleUrl(titleUrl);
      }
      return () => {
         clear();
      }
   }, [setSubtitle, setTitle, showTitle, titleId, values, title, subtitle, subtitleId]);

   const LinkComponent = titleUrl ? Link : Fragment;
   const toProp = titleUrl ? {to: titleUrl} : {};

   return (
      <PageTitle titleKey={titleId} title={title} values={values}>
         <Grid name={'TitleCard-root'} className={`${classes.root} ${outsideClasses.root}`} container item fullWidth
               direction={'column'}
               wrap={'nowrap'}>
            {showTitle && (
               <Grid name={'TitleCard-title'} item
                     className={allowDrawer ? classes.titleGridStyleWithDrawer : classes.titleGridStyle}
                     resizable={false} fullWidth>
                  <LinkComponent {...toProp}>
                     <Typography id={titleId} values={values} className={classes.titleStyle} variant={'h5'} noWrap
                                 align={'center'}>
                        {title}
                     </Typography>
                  </LinkComponent>
                  {subtitle && (
                     <Typography id={subtitleId} className={classes.subtitleStyle} variant={'subtitle1'} noWrap
                                 align={'center'}>
                        {subtitle}
                     </Typography>
                  )}
               </Grid>
            )}
            {/*<Grid name={'TitleCard-children'} container item resizable fullWidth>*/}
            {children}
            {/*</Grid>*/}
            {(previous || next) && (
               <Grid name={'TitleCard-actions'} container item className={classes.actionGridStyle} resizable={false}
                     fullWidth direction={'row'} justify={'space-between'}>
                  {previous && (
                     <Button className={classes.buttonStyle} style={{visibility: !!previous ? 'visible' : 'hidden'}}
                             variant='contained' color='primary' component={Link} to={previous}>
                        <KeyboardArrowLeft/>
                        <Typography id={'previous.button'} variant={'subtitle1'}/>
                     </Button>
                  )}
                  {next && (
                     <Button className={classes.buttonStyle} variant='contained' color='primary' type={'submit'}>
                        <Typography id={'next.button'} variant={'subtitle1'}/>
                        <KeyboardArrowRight/>
                     </Button>
                  )}
               </Grid>
            )}
         </Grid>
      </PageTitle>
   );
}

TitleCard.propTypes = {
   title: PropTypes.string,                  //The title to display for the card.
   titleId: PropTypes.string,                //The titleId for the title to display for the card.
   subtitle: PropTypes.string,               //The subtitle to display for the card.
   subtitleId: PropTypes.string,             //The subtitleId for the subtitle to display for the card.
   children: PropTypes.any,                  //Children for the component.
   next: PropTypes.string,                   //The path for the next card.
   previous: PropTypes.string,               //The path for the previous card.
};
